import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { DisplayControlInput } from 'src/app/core/infrastructure/enums/display-control-input.enum';
import { formatDate } from '@angular/common';
import { LookUpDisplayControl } from 'src/app/core/infrastructure/classes/metadataDisplayControls/controls/look-up-display-control';
import { LookUpMappingType } from 'src/app/core/infrastructure/enums/look-up-mapping-type.enum';
import {
  CountryCode,
  GridName,
} from 'src/app/theme/shared/forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';
import {
  DemandField,
  ItemField,
  ItemMetricField,
  ShipmentDetailField,
  VendorField,
} from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import {
  conditionalOperator,
  sortColumnsByField,
  transformFieldType,
  transformSortableField,
} from '@stockaid/utils';
import _ from 'lodash';
import { CompanyService } from '../../company.service';
import { MinMaxValidator } from '../../../infrastructure/classes/metadataDisplayControls/validators/min-max-validator';
import { IFieldValidator } from 'src/app/core/infrastructure/interfaces/i-field-validator';
import { FieldValidatorType } from 'src/app/core/infrastructure/enums/field-validator-type.enum';
import { CustomPatternValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/custom-pattern-validator';
import { LessMoreValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/less-more-validator';
import { IModalConfig } from './input-form-modal.interface';
import { Item } from 'src/app/core/models/item';
import { Demand } from 'src/app/core/models/demand';
import { Supply } from 'src/app/core/models/supply';
import { SummaryByVendor, Vendor } from 'src/app/core/models/vendor';
import { Supplier } from 'src/app/core/models/supplier';
import { Bom } from 'src/app/core/models/bom';
import { POItem } from 'src/app/core/models/po-items';
import { ShipmentDetail } from 'src/app/core/models/shipment-detail';
import { RestockSuggestionSupplier } from 'src/app/core/models/restock-suggestion-supplier';
import { ItemSite } from 'src/app/core/models/item-site';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { getContrastYIQ } from '@stockaid/utils';
import {
  ICountry,
  IState,
} from 'src/app/core/infrastructure/interfaces/country.interface';
import { COUNTRIES } from 'src/app/core/constants/countries.constant';
import { STATES } from 'src/app/core/constants/states.constant';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, of } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { SnotifyService } from 'ng-snotify';
import { AdditionalKeysType } from 'src/app/core/infrastructure/interfaces/additional-keys.interface';
import { ShipmentService } from '../../shipment.service';
import { CustomValidators } from '../../form/form.validator';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';
import { PHONE_NUMMER_PATTERN } from 'src/app/core/constants/patterns.constant';
import {
  COMMON_FORMAT_DATE,
  FORMAT_PATTERN,
} from 'src/app/core/constants/format-date.constant';
import { Shipment } from 'src/app/core/models/shipment';

@Component({
  selector: 'app-input-form-modal',
  templateUrl: './input-form-modal.component.html',
  styleUrls: ['./input-form-modal.component.scss'],
})
export class InputFormModalComponent<T>
  extends BaseComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Output() addNewSupplier = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter<any>();
  @Output() supplierCreated = new EventEmitter<any>();
  @Input() modalConfig: IModalConfig;
  @Input() inputFields: MetaDataField[] = [];
  @Input() selectedVendor: any;
  @Input() isAddressModal: boolean = false;
  @Input() shipment: Shipment;
  @Input() set selectingItem(value: any) {
    this._selectingItem = value;
  }
  @ViewChild('content') content: ElementRef;
  @ViewChild('addNewAddress') addNewAddress: ElementRef;

  loading: boolean = false;
  GridName = GridName;
  inputTypeEnum = DisplayControlInput;
  addressSelection: any;
  ItemField = ItemField;
  modalReference: NgbModalRef;
  modalAddressTitle: string = 'Add Address';
  newItem: any;
  leadTimeAndOrderIntervalTotalError: boolean;
  autofillVendor: any;
  countryDropdownItem = [...COUNTRIES];
  public isDateConflict: boolean = false;
  public items: any;
  public addressData = [];
  public inputForm: FormGroup;
  public supplierForm: FormGroup;

  readonly states: IState[] = STATES;
  readonly countries: ICountry[] = COUNTRIES;
  readonly FORMAT_DATE = COMMON_FORMAT_DATE;
  readonly FORMAT_PATTERN = FORMAT_PATTERN;

  private _selectingItem: any;
  getContrastYIQ = getContrastYIQ;

  get currentCompany() {
    return this.companyService.currentCompany();
  }

  get country(): AbstractControl {
    return this.supplierForm?.controls?.country;
  }

  get addressLine1(): AbstractControl {
    return this.supplierForm?.controls?.addressLine1;
  }

  get city(): AbstractControl {
    return this.supplierForm?.controls?.city;
  }

  get stateOrProvince(): AbstractControl {
    return this.supplierForm?.controls?.stateOrProvince;
  }

  get usState(): AbstractControl {
    return this.supplierForm?.controls?.usState;
  }

  get postalCode(): AbstractControl {
    return this.supplierForm?.controls?.postalCode;
  }

  get phoneNumber(): AbstractControl {
    return this.supplierForm?.controls?.phoneNumber;
  }

  get selectingItem(): any {
    return this._selectingItem;
  }

  get finalItemName(): string {
    switch (this.modalConfig.itemName) {
      case GridName.Bom:
        return 'Kit';

      case GridName.ItemSite:
        return 'Item';

      default:
        return this.modalConfig.itemName;
    }
  }

  constructor(
    private snotifyService: SnotifyService,
    private formBuilder: FormBuilder,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private shipmentService: ShipmentService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedVendor']) {
      if (this.selectedVendor && this.inputForm) {
        this.inputForm.controls['vendorName'].setValue(
          this.selectedVendor.name
        );
        this.inputForm.controls['vendorKey'].setValue(this.selectedVendor.key);
      }
    }
  }

  ngOnInit(): void {
    if (this.modalConfig.selectingVendor) {
      this.autofillVendor = {
        name: this.modalConfig.selectingVendor.vendorName,
        key: this.modalConfig.selectingVendor.vendorKey,
      };
    }

    if (!this.modalConfig.keyName) {
      this.modalConfig.keyName = 'key';
    }
    this.loadAdditionalData();
  }

  ngAfterViewInit(): void {
    if (this.isAddressModal) {
      this.openAddressModal(this.addNewAddress);
    } else {
      this.open(this.content);
    }
  }

  open(content: any): void {
    this.newItem = this.instantiateType(this.modalConfig.itemName);
    this.inputForm = this.createFormGroup(
      this.newItem,
      false,
      this.modalConfig.forbiddenFields || []
    );
    if (this.modalConfig.itemName === GridName.PurchaseOrder) {
      this.newItem.docDate = new Date();
      if (this.modalConfig.selectingVendor) {
        this.newItem = {
          ...this.newItem,
          dueDate: formatDate(new Date(this.modalConfig.selectingVendor.earliestDueDate), 'YYYY-MM-dd', 'en'),
          vendorName: this.modalConfig.selectingVendor.vendorName,
          vendorKey: this.modalConfig.selectingVendor.vendorKey,
        };
      }
    }
    this.inputForm.patchValue({ ...this.newItem });
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      keyboard: false,
      centered: true,
      backdrop: 'static',
      backdropClass: 'no-background',
    });
    switch (this.modalConfig.itemName) {
      case GridName.ForecastShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
      case GridName.AmazonShipmentItem:
        this.inputForm.setValidators([
          CustomValidators.divisibleBy(
            ShipmentDetailField.shipmentQty,
            ShipmentDetailField.caseQty,
            this.inputFields
          ),
          ...(this.modalConfig.isLocalQtyValidationDisabled || false
            ? []
            : [
                CustomValidators.lessThanOrEqual(
                  ShipmentDetailField.shipmentQty,
                  ShipmentDetailField.localQty,
                  this.inputFields
                ),
              ]),
        ]);
        this.inputForm.updateValueAndValidity();
        break;

      case GridName.Demand:
        this.inputForm.setValidators([
          CustomValidators.lessThanOrEqual(
            DemandField.openQty,
            DemandField.orderQty,
            this.inputFields
          ),
          CustomValidators.greaterThanOrEqual(
            DemandField.orderQty,
            DemandField.openQty,
            this.inputFields
          ),
        ]);
        this.inputForm.updateValueAndValidity();
        break;

      case GridName.Item:
      case GridName.Supplier:
        this.handleValueNewFormChange();
        break;
    }
  }

  public createFormGroup(
    dataItem: any,
    disableRequiredField = false,
    forbiddenFields: string[] = [],
    cellSelected: string = ''
  ): FormGroup {
    const formGroup = new FormGroup({});
    const dateObject = {};
    this.inputFields.forEach((mdf: MetaDataField) => {
      this.buildFormGroup(
        dataItem,
        disableRequiredField,
        forbiddenFields,
        cellSelected,
        formGroup,
        dateObject,
        mdf
      );
    });

    if (this.modalConfig.isShipmentDetail) {
      formGroup.addControl(
        ShipmentDetailField.shipmentId,
        this.formBuilder.control('', [])
      );
      formGroup.addControl(
        ShipmentDetailField.isShipByCase,
        this.formBuilder.control('', [])
      );

      formGroup.addControl(
        ShipmentDetailField.whoPreps,
        this.formBuilder.control('SELLER', [])
      );

      formGroup.addControl(
        ShipmentDetailField.shipmentId,
        this.formBuilder.control('', [])
      );
    }

    if (this.modalConfig.itemName === GridName.Supplier) {
      const restockModelMetadata = this.inputFields.find(
        (s) => s.field === VendorField.restockModel
      );
      dataItem.restockModel = conditionalOperator(
        dataItem.restockModel,
        dataItem.restockModel,
        restockModelMetadata?.displayControl?.selectSource?.[0]?.val
      );
    }

    formGroup.patchValue({ ...dataItem, ...dateObject });
    return formGroup;
  }

  private buildFormGroup(
    dataItem: any,
    disableRequiredField,
    forbiddenFields: string[],
    cellSelected: string,
    formGroup: FormGroup,
    dateObject: any,
    mdf: MetaDataField
  ) {
    if (
      forbiddenFields.includes(mdf.field) ||
      (this.modalConfig.bulkEditAllowedFields?.length &&
        !this.modalConfig.bulkEditAllowedFields?.includes(mdf.field))
    ) {
      return;
    }

    if (
      ([ItemField.leadTime, ItemField.orderInterval] as string[]).includes(
        mdf.field
      )
    ) {
      this.setSpecificFieldValidator(mdf, dataItem, cellSelected);
    }

    let validators = this.extractValidators(
      mdf,
      disableRequiredField,
      dataItem,
      cellSelected
    );

    if (
      this.modalConfig.itemName === 'Item' &&
      [ItemField.history, ItemField.links, ItemField.imageUrl].includes(
        mdf.field as ItemField
      )
    ) {
      formGroup.addControl(mdf.field, this.formBuilder.control(null));
      return;
    }

    // This is for add modal, there is isReadOnly() that disable editable cell
    // Make asin, id, key related fields readonly
    if (mdf.field === 'createdAt' || mdf.field?.includes('Key')) {
      formGroup.addControl(
        mdf.field,
        this.formBuilder.control({ value: '', disabled: true }, [])
      );
      return;
    }

    switch (mdf.displayControl.input) {
      case DisplayControlInput.Date:
        formGroup.addControl(
          mdf.field,
          this.formBuilder.control(
            formatDate(null, this.FORMAT_DATE, 'en'),
            validators
          )
        );

        const transformedDate = conditionalOperator(
          dataItem[mdf.field],
          new Date(dataItem[mdf.field]),
          null
        );
        dateObject[mdf.field] = conditionalOperator(
          isNaN(transformedDate),
          new Date(),
          transformedDate
        );
        break;

      case DisplayControlInput.LookUp:
        formGroup.addControl(
          mdf.field,
          this.formBuilder.control('', validators)
        );

        let lookupControl = mdf.displayControl as LookUpDisplayControl;
        //get the field that will hold the key
        let mapping = lookupControl.lookUpConfig.mapping.find((x) => {
          return x.type === LookUpMappingType.Key;
        });

        if (mapping) {
          formGroup.addControl(mapping.to, this.formBuilder.control(''));
        }
        break;

      default:
        formGroup.addControl(
          mdf.field,
          this.formBuilder.control(
            '',
            conditionalOperator(
              this.modalConfig.isLocalQtyValidationDisabled &&
                mdf.field === ShipmentDetailField.localQty,
              [],
              validators
            )
          )
        );
    }
  }

  setSpecificFieldValidator(mdf, dataItem, cell) {
    let vendorLeadTime;
    let vendorOrderInterval;
    let trueValue;

    if (!!cell && this.modalConfig.vendorsGroupedByKey?.[dataItem?.vendorKey]) {
      vendorLeadTime = _.head(
        this.modalConfig.vendorsGroupedByKey[dataItem.vendorKey]
      ).leadTime;
      vendorOrderInterval = _.head(
        this.modalConfig.vendorsGroupedByKey[dataItem.vendorKey]
      ).orderInterval;
    }

    if (mdf.field === ItemField.leadTime) {
      trueValue =
        dataItem?.orderInterval ??
        vendorOrderInterval ??
        this.currentCompany.orderInterval;
      trueValue = cell ? trueValue : this.currentCompany.orderInterval;
      const maxValue = 360 - trueValue > 0 ? 360 - trueValue : 1;
      mdf.validators = [new MinMaxValidator({ min: 1, max: maxValue })];
      return;
    }

    if (mdf.field === ItemField.orderInterval) {
      trueValue =
        dataItem?.leadTime ?? vendorLeadTime ?? this.currentCompany.leadTime;
      trueValue = cell ? trueValue : this.currentCompany.leadTime;
      const maxValue = 360 - trueValue > 0 ? 360 - trueValue : 0;
      mdf.validators = [new MinMaxValidator({ min: 0, max: maxValue })];
    }
  }

  private extractValidators(
    mdf: MetaDataField,
    disableRequiredField: boolean,
    dataItem: any,
    cellSelected: string = ''
  ): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (!disableRequiredField && mdf.required) {
      //if field is required then add validators
      validators.push(Validators.required);
    }

    if (!mdf.validators || mdf.validators.length === 0) {
      return validators;
    }

    //loop through validators array, and add to form field.
    mdf.validators.forEach((validator: IFieldValidator) => {
      switch (validator.type) {
        case FieldValidatorType.MinMax:
          let minMax = validator as MinMaxValidator;

          validators.push(Validators.min(minMax.min));
          validators.push(Validators.max(minMax.max));
          break;
        case FieldValidatorType.Pattern:
          let pattern = validator as CustomPatternValidator;
          validators.push(Validators.pattern(pattern.pattern));
          break;
        case FieldValidatorType.Email:
          validators.push(Validators.email);
          break;
        case FieldValidatorType.LessMore:
          const lessMoreVali = validator as LessMoreValidator;
          validators.push(lessMoreVali.condition);
          break;
      }
    });

    return validators;
  }

  private instantiateType(typeName: string): any {
    let newItem: any;

    switch (typeName) {
      case GridName.Item:
        newItem = this.activator(Item);
        break;
      case GridName.Demand:
        newItem = this.activator(Demand);
        break;
      case GridName.Supply:
        newItem = this.activator(Supply);
        break;
      case GridName.Supplier:
        newItem = this.activator(Vendor);
        break;
      case GridName.ManageSupplier:
        newItem = this.activator(Supplier);
        break;
      case GridName.Bom:
        newItem = this.activator(Bom);
        break;
      case GridName.SuggestedPos:
        newItem = this.activator(SummaryByVendor);
        break;
      case GridName.ItemsInPo:
      case GridName.AllAvailableItems:
        newItem = this.activator(POItem);
        break;
      case GridName.ForecastShipmentItem:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        newItem = this.activator(ShipmentDetail);
        break;
      case GridName.SupplierList:
        newItem = this.activator(RestockSuggestionSupplier);
        break;
      case GridName.ItemSite:
        newItem = this.activator(ItemSite);
        break;
      case GridName.PurchaseOrder:
        newItem = this.activator(PurchaseOrder);
        break;
      default:
        throw new Error('Type name not accounted for.');
    }

    return newItem;
  }

  private activator<T>(type: { new (): T }): T {
    return new type();
  }

  loadAdditionalData() {
    if (!this.modalConfig.forbiddenFields) {
      this.modalConfig.forbiddenFields = [];
    }
    this.modalConfig.forbiddenFields = this.modalConfig.forbiddenFields.concat([
      ItemField.isHidden,
      ItemField.createdAt,
      ItemField.useBackfill,
      ItemField.useHistoryOverride,
      ItemField.imageUrl,
      ItemField.itemHistoryLength,
      ItemMetricField.s7d,
      ItemMetricField.s30d,
      ItemMetricField.s90d,
      ItemMetricField.s365d,
      VendorField.averageHistoryLength,
    ]);

    if (this.modalConfig.itemName === GridName.Supplier) {
      if (!this.modalConfig.isShowRestockAMZ) {
        this.modalConfig.forbiddenFields =
          this.modalConfig.forbiddenFields.concat([
            VendorField.phoneNumber,
            VendorField.countryCode,
            VendorField.addressLine1,
            VendorField.addressLine2,
            VendorField.stateOrProvinceCode,
            VendorField.postalCode,
            VendorField.city,
          ]);
      }
    }
  }

  transformColumns(): void {
    this.inputFields = this.inputFields.map((column) => {
      column.dataType = transformFieldType(column.dataType);
      column.sortable = transformSortableField(column);
      column.reOrderable = true;
      // column.filterable = transformFilterableColumn(column, this.advancedFilter);
      // column.width = column.width ? column.width : transformColumnWidth(column, this.isPrintList, this.itemName);
      return column;
    });
    this.inputFields = sortColumnsByField(
      this.inputFields,
      'initialUpload',
      'desc'
    );
  }

  handleAddress() {
    let newAddress = new Supplier({
      key: '',
      vendorKey: '',
      countryCode: this.country.value.countryCode,
      addressLine1: this.addressLine1.value,
      city: this.city.value,
      stateOrProvinceCode:
        (this.stateOrProvince.value !== CountryCode.US &&
          this.stateOrProvince.value) ||
        this.usState.value.stateCode,
      postalCode: this.postalCode.value,
      phoneNumber: this.phoneNumber.value,
    });
    this.modalReference.close();
    if (this.isAddressModal) {
      this.save.emit(newAddress);
      this.cancel.emit(true);
    } else {
      if (this.modalAddressTitle === 'Edit Address') {
        this.addressData[this.addressSelection.rowIndex] = newAddress;
        this.modalAddressTitle = 'Add Address';
      } else {
        this.addressData.push(newAddress);
      }
    }
    this.buildAddNewAddressForm(new Supplier());
  }

  private buildAddNewAddressForm(supplier: Supplier) {
    this.supplierForm = this.formBuilder.group({
      country: [
        this.countries.find((c) => c.countryCode === supplier.countryCode) ||
          this.countries[0],
        [Validators.required],
      ],
      addressLine1: [
        supplier.addressLine1,
        [Validators.required, Validators.maxLength(180)],
      ],
      addressLine2: [supplier.addressLine2, [Validators.maxLength(60)]],
      city: [supplier.city, [Validators.required, Validators.maxLength(30)]],
      stateOrProvince: [supplier.stateOrProvinceCode],
      usState: [
        this.states.find((c) => c.stateCode === supplier.stateOrProvinceCode) ||
          this.states[0],
        [Validators.required],
      ],
      postalCode: [
        supplier.postalCode,
        [Validators.required, Validators.maxLength(30)],
      ],
      phoneNumber: [
        supplier.phoneNumber,
        [Validators.maxLength(40), Validators.pattern(PHONE_NUMMER_PATTERN)],
      ],
    });
  }

  openAddressModal(content: any, selection?: any): void {
    this.addressSelection = selection;

    const supplier = selection?.dataItem || new Supplier();
    this.supplierForm = this.formBuilder.group({
      country: [
        this.countries.find((c) => c.countryCode === supplier?.countryCode) ||
          this.countries[0],
        [Validators.required],
      ],
      addressLine1: [
        supplier?.addressLine1,
        [Validators.required, Validators.maxLength(180)],
      ],
      city: [supplier?.city, [Validators.required, Validators.maxLength(30)]],
      stateOrProvince: [supplier.stateOrProvinceCode],
      usState: [
        this.states.find(
          (c) => c.stateCode === supplier?.stateOrProvinceCode
        ) || this.states[0],
        [Validators.required],
      ],
      postalCode: [
        supplier?.postalCode,
        [Validators.required, Validators.maxLength(30)],
      ],
      phoneNumber: [
        supplier?.phoneNumber,
        [Validators.maxLength(40), Validators.pattern(PHONE_NUMMER_PATTERN)],
      ],
    });

    this.modalReference = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      backdropClass: 'no-background',
    });
  }

  public handleSelectedVendor(value) {
    this.selectedVendor = value;
    if (!!value && value?.leadTime !== null && value?.orderInterval !== null) {
      const maxLeadTime =
        360 - value.orderInterval > 0 ? 360 - value.orderInterval : 1;
      const maxOrderInterval =
        360 - value.leadTime > 0 ? 360 - value.leadTime : 0;
      this.inputForm.controls.leadTime.setValidators([
        Validators.min(1),
        Validators.max(maxLeadTime),
      ]);
      this.inputForm.controls.orderInterval.setValidators([
        Validators.min(0),
        Validators.max(maxOrderInterval),
      ]);
      this.inputForm.controls.leadTime.updateValueAndValidity({
        emitEvent: false,
      });
      this.inputForm.controls.orderInterval.updateValueAndValidity({
        emitEvent: false,
      });
      return;
    }

    if (!value) {
      const maxLeadTime =
        360 - this.currentCompany.orderInterval > 0
          ? 360 - this.currentCompany.orderInterval
          : 1;
      const maxOrderInterval =
        360 - this.currentCompany.leadTime > 0
          ? 360 - this.currentCompany.leadTime
          : 0;
      this.inputForm.controls.leadTime.setValidators([
        Validators.min(1),
        Validators.max(maxLeadTime),
      ]);
      this.inputForm.controls.orderInterval.setValidators([
        Validators.min(0),
        Validators.max(maxOrderInterval),
      ]);
      this.inputForm.controls.leadTime.updateValueAndValidity({
        emitEvent: false,
      });
      this.inputForm.controls.orderInterval.updateValueAndValidity({
        emitEvent: false,
      });
    }
  }

  handleValueNewFormChange() {
    combineLatest([
      this.inputForm.controls.leadTime.valueChanges,
      this.inputForm.controls.orderInterval.valueChanges,
    ])
      .pipe(
        tap(([leadTime, orderInterval]) => {
          if (leadTime === null || orderInterval === null) {
            this.leadTimeAndOrderIntervalTotalError = false;
            this.handleSelectedVendor(this.selectedVendor);
            return;
          }
          const maxOrderInterval = 360 - leadTime > 0 ? 360 - leadTime : 0;
          const maxLeadTime = 360 - orderInterval > 0 ? 360 - orderInterval : 1;
          this.leadTimeAndOrderIntervalTotalError =
            leadTime > 0 && orderInterval > 0 && leadTime + orderInterval > 360;
          this.inputForm.controls.leadTime.setValidators([
            Validators.min(1),
            Validators.max(maxLeadTime),
          ]);
          this.inputForm.controls.orderInterval.setValidators([
            Validators.min(0),
            Validators.max(maxOrderInterval),
          ]);
          this.inputForm.controls.leadTime.updateValueAndValidity({
            emitEvent: false,
          });
          this.inputForm.controls.orderInterval.updateValueAndValidity({
            emitEvent: false,
          });
        })
      )
      .subscribe();
  }

  onCheckDateConflict(due: string, doc: string): boolean {
    if (!due || !doc) {
      return false;
    }

    const dueDate = new Date(formatDate(due, this.FORMAT_DATE, 'en'));
    const docDate = new Date(formatDate(doc, this.FORMAT_DATE, 'en'));
    return dueDate.getTime() < docDate.getTime();
  }

  public saveItem(modelRef: NgbModalRef): void {
    if (!this.inputForm.valid) {
      return;
    }

    const findBomFunc = (item) =>
      this.newItem.parentKey === item.parentKey &&
      this.newItem.childKey === item.childKey;

    Object.assign(this.newItem, this.inputForm.getRawValue());
    let additionalKeys = {};

    switch (this.modalConfig.itemName) {
      case GridName.Item:
        this.fireWarehouseError();
        break;

      case GridName.Bom:
        additionalKeys = { type: AdditionalKeysType.createBom };

        if (this.items.find(findBomFunc)) {
          additionalKeys = { type: AdditionalKeysType.updateBom };
        }
        break;

      case GridName.Supply:
      case GridName.Demand:
        if (
          this.modalConfig.itemName == GridName.Supply &&
          !this.newItem.vendorKey
        ) {
          delete this.newItem.vendorKey;
          delete this.newItem.vendorName;
        }
        additionalKeys = { type: AdditionalKeysType.demandAndSupply };
        this.newItem.docType = '';
        this.newItem.orderKey = '';
        this.newItem.rowKey = '';
        break;

      case GridName.Supplier:
        if (this.modalConfig.isShowRestockAMZ && this.addressData?.length) {
          this.newItem.addressData = this.addressData;
        }
        break;

      case GridName.ItemSite:
        additionalKeys = { type: AdditionalKeysType.itemSite };
        break;

      case GridName.ForecastShipmentItem:
      case GridName.AmazonShipmentItem:
      case GridName.ForecastShipmentItemChosen:
      case GridName.ForecastShipmentItemValid:
        this.newItem.restockKey = this.selectingItem?.key;
        break;
    }

    this.isDateConflict = this.onCheckDateConflict(
      this.newItem.dueDate,
      this.newItem.docDate
    );

    if (this.isDateConflict) {
      this.snotifyService.error(
        `The Receive Date must be greater than the PO Date`
      );
      return;
    }

    this.loading = true;
    const saveFunc = this.modalConfig.customSaveFunc
      ? this.modalConfig.customSaveFunc(this.newItem)
      : this.modalConfig.resourceService.save(
          this.newItem,
          this.modalConfig.keyName,
          additionalKeys
        );

    saveFunc
      .pipe(
        this.autoCleanUp(),
        tap((res: any) => {
          this.save.emit(res);
          this.cancel.emit(true);
        }),
        switchMap(this.handleSaveItemWhichHasPassedShipmentOptions),
        tap(() => {
          modelRef.close('Successful Save');
          this.handleSaveItemFromModalSuccessful();
        }),
        finalize(() => (this.loading = false))
      )
      .subscribe();
  }

  public cancelAddNewAddress(isClearData?: boolean) {
    if (isClearData) {
      this.addressData = [];
    }

    this.buildAddNewAddressForm(new Supplier());
    this.modalAddressTitle = 'Add Address';
    this.addressSelection = null;
  }

  public openAddNewSupplier() {
    this.inputForm.controls['vendorName'].setValue(null);
    this.inputForm.controls['vendorKey'].setValue(null);

    this.addNewSupplier.emit(true);
  }

  public cellAddressClick(content, selection) {
    this.modalAddressTitle = 'Edit Address';
    this.openAddressModal(content, selection);
  }

  public deleteAddress() {
    this.addressData = this.addressData.filter((item) => {
      return item !== this.addressSelection?.dataItem;
    });
    this.modalAddressTitle = 'Add Address';
    this.modalReference.close();
    this.buildAddNewAddressForm(new Supplier());
  }

  private fireWarehouseError() {
    if (
      this.currentCompany &&
      !this.currentCompany.willDisableMultipleWarehouses
    ) {
      this.snotifyService.info(
        'As you are enabling Multiple Warehouses, the inputted Warehouse Qty will not apply to the created Item.'
      );
    }
  }

  private handleSaveItemWhichHasPassedShipmentOptions = () => {
    if (
      this.modalConfig.itemName === GridName.AmazonShipmentItem ||
      !this.selectingItem?.stepProgress?.shipmentOptions
    ) {
      return of(null);
    }

    this.selectingItem.stepProgress.shipmentOptions = false;
    this.selectingItem.stepProgress.shipmentReview = false;
    this.selectingItem.stepProgress.complete = false;
    return this.shipmentService.save(this.selectingItem, 'key');
  };

  private handleSaveItemFromModalSuccessful() {
    this.snotifyService.success(
      `${
        this.finalItemName === 'Supply' ? 'Purchase Order' : this.finalItemName
      } successfully created.`
    );
    this.inputForm.reset();
    this.newItem = {};
    this.addressData = [];
    this.loading = false;

    switch (this.modalConfig.itemName) {
      case GridName.Item:
        this.saveAndRemindToRunForecast();
        break;
    }
  }

  saveAndRemindToRunForecast(): void {
    this.companyService
      .updateCompanyInfo({
        ...this.currentCompany,
        willRemindToRunForecast: true,
      })
      .pipe(this.autoCleanUp())
      .subscribe((c) =>
        this.companyService.updateCacheCompany({
          ...c,
          isAuthorized: this.currentCompany.isAuthorized,
        })
      );
  }

  public countryChange(country: ICountry) {
    if (country.countryCode !== CountryCode.US) {
      this.stateOrProvince.setValue('');
    }
  }

  onAddressCancel(modal) {
    modal.dismiss('Cross click');
    this.cancelAddNewAddress();
    if (this.isAddressModal) {
      this.cancel.emit(true);
    }
  }

  handleCountryFilter(event) {
    if (event) {
      this.countryDropdownItem = this.countries.filter((item) =>
        item.name.includes(event)
      );
    } else this.countryDropdownItem = this.countries;
  }

  valueCountryChange(event) {
    this.supplierForm.controls?.country.setValue(event);
  }
}
