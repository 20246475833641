import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Item } from 'src/app/core/models/item';
import { Company } from 'src/app/core/models/company';

@Component({
  selector: 'app-copy-history-modal',
  templateUrl: './copy-history-modal.component.html',
  styleUrls: ['./copy-history-modal.component.scss'],
})
export class CopyHistoryModalComponent {
  @Input() set alreadyChoosenItem(value: Item) {
    this._alreadyChoosenItem = value;
    this.itemSourceInfo = `${value.name || 'Item Without Name (' + value.asin + ')'}`;
  }
  get alreadyChoosenItem(): Item {
    return this._alreadyChoosenItem;
  }
  @Input() currentCompany: Company;

  @Output() copyHistoryCompleted = new EventEmitter();

  choosenItem: Item;
  copyInfo: string = null;
  isLookupVisible: boolean = true;
  isHistoryVisible: boolean = false;
  copySource: any;
  shouldShowHiddenItems = false;
  itemSourceInfo: string;

  private _alreadyChoosenItem: Item;

  constructor(public activeModal: NgbActiveModal) {}

  itemChoosen(item: Item): void {
    this.choosenItem = item;
    this.copyInfo = `${item.name || 'Item Without Name (' + item.asin + ')' }  ->  `;
    this.isLookupVisible = false;
    this.isHistoryVisible = true;
  }

  changeSourceItem(): void {
    this.isLookupVisible = true;
    this.isHistoryVisible = false;
    this.choosenItem = null;
    this.copyInfo = null;
  }

  getCopySource(historyData: any): void {
    this.copySource = historyData;
  }

  completeCopyHistory(): void {
    this.copyHistoryCompleted.emit(this.copySource);
    this.activeModal.close();
  }

  showHidden(should: boolean): void {
    this.shouldShowHiddenItems = should;
  }
}
