import { SummaryOnHandType } from "../infrastructure/enums/summary-on-hand-type.enum";
import { SalesVelocitySettingData } from "./sales-velocity";

export class Company {
  companyType: string;
  companyKey?: string;
  companyName: string;
  forecastKey?: string;
  forecastName?: string;
  moq?: number;
  additionalInventory?: number;
  leadTime?: number;
  orderInterval?: number;
  operation?: string;
  serviceLevel?: number;
  email?: string;
  lastForecastDate?: Date;
  lastSyncDate?: Date;
  sellerId?: string;
  marketplaceId?: string;
  mwsAuthToken?: string;
  ratings?: any[];
  spikePercent?: number;
  plungePercent?: number;
  isManualInventory?: boolean;
  requestsOnboarding?: boolean;
  customerId?: string;
  subscriptionId?: string;
  subscriptionStatus?: string;
  purchaseItems?: number;
  purchasePrice?: number;
  purchasePriceWithMoq?: number;
  purchaseUniques?: number;
  pastDuePoLines?: number;
  expediteItemLines?: number;
  jobInitiator?: string;
  jobProcessing?: string;
  qbfsSyncStatus?: any;
  isNotifyingAfterSync?: boolean;
  isNotifyingAfterForecast?: boolean;
  isLostSaleTracking?: boolean;
  isLocked?: boolean;
  isPromptedOnLogin?: boolean;
  disabledFeatures?: any;
  phone?: string;
  fax?: string;
  website?: string;
  addressShippingUuid?: string;
  addressBillingUuid?: string;
  criticalErrorCode?: string;
  companyPreferences?: CompanyPreferences;
  accessToken?: string;
  refreshToken?: string;
  createdAt?: Date;
  updatedAt?: Date;
  initialSyncDate?: string;
  inventorySourcePreference?: string;
  isAuthorized?: boolean;
  restockModel?: string;
  localLeadTime?: number;
  targetQtyOnHandMin?: number;
  targetQtyOnHandMax?: number;
  salesVelocityType?: string;
  salesVelocitySettingData?: SalesVelocitySettingData;
  shipmentLastRefresh?: Date
  purchasingSalesVelocityType?: string;
  purchasingSalesVelocitySettingData?: SalesVelocitySettingData;
  displayRestockAMZ?: boolean;
  pendingOrderToggle?: boolean;
  summaryCounts?: Record<string, number>;
  uploadedColumns?: string[];
  isUsingAdjustedDailySalesRate?: boolean;
  isUsingInboundWorking?: boolean;
  willRemindToRunForecast?: boolean;
  isAdjustingDailySalesRateForOutOfStock?: boolean;
  isUsingInboundShipped?: boolean;
  isUsingInboundReceiving?: boolean;
  isUsingFcTransfer?: boolean;
  isUsingWarehouseInventory?: boolean;
  isUsingOnOrder?: boolean;
  isUsingOnHandFbm?: boolean;
  latestTotalOrder?: number;
  last3Months?: string[];
  ignoreShipmentDays?: number;
  isUnlockedFirstTime?: boolean;
  skipOnboardingComment?: string;
  isSkipOnboarding?: boolean;
  willDisableMultipleWarehouses?: boolean;
  currencyCode?: string;
  isModifyForecastUsingUnit?: boolean;
  autoHideMaxOrderQty?: number;
  summaryOnHandType?: SummaryOnHandType;
  isUsingAWDInventoryQty?: boolean;
  ignoreOldPOsDays?: number;
  shouldIncludeAllOpenPOs?: boolean;
  vendorLastUploadDate?: Date;
  demandLastUploadDate?: Date;
  supplyLastUploadDate?: Date;
  itemLastUploadDate?: Date;
  bomLastUploadDate?: Date;
  historyOverrideLastUploadDate?: Date;
  forecastedHistorySettingLastUploadDate?: Date;
  willSyncQuickbooksAll?: boolean;
  demandSourcePreference?: string;
  shouldHidePOKitRecommendation?: boolean;
  logoUrl?: string;
  willRemindToRunSync?: boolean;

  constructor(init?: Partial<Company>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export class CompanyPreferences {
  config: {
    company: {
      skipped: boolean;
      completed: boolean;
    };
    shipment?:{
      skipped: boolean;
      completed: boolean;
    }
    vendor: {
      skipped: boolean;
      completed: boolean;
      uploaded: boolean;
    };
    item: {
      skipped: boolean;
      completed: boolean;
      uploaded: boolean;
    };
    bom?: {
      skipped: boolean;
      completed: boolean;
      uploaded: boolean;
    };
    supply?: {
      skipped: boolean;
      completed: boolean;
      uploaded: boolean;
    };
    demand?: {
      skipped: boolean;
      completed: boolean;
      uploaded: boolean;
    };
    forecast: {};
    hasScheduled?: boolean;
  };
}
