<div>
  <div class="text-secondary">
    <div class="mt-2 mb-4">
      <p>
        Your shipment was successfully
        {{ restock?.isShipByCase || restock?.isShipManual ? "downloaded" : "created" }}.
        <span
          class="text-danger font-weight-bold"
          *ngIf="restock?.isShipByCase || restock?.isShipManual"
        >
          Upload your file to Seller Central
          <a
            href="https://sellercentral.amazon.com/fba/sendtoamazon/confirm_content_step"
            target="_blank"
            rel=noopener
            ><b>Here</b></a
          ></span
        >
      </p>
      <p>
        Here is a preview of your label. Please select any additional line items
        to be printed, the format and output option. For thermal printers,
        choose the Custom Size format and enter your sticker size in milimeters.
      </p>
      <p>
        For best result, we suggest using the Download button and printing from
        Adobe reader.
      </p>
    </div>

    <span class="modify" (click)="handleModifySticker()">{{
      modifySticker
    }}</span>
    <form *ngIf="stickerForm" [formGroup]="stickerForm">
      <!-- Optional Data -->
      <div
        *ngIf="!isModifySticker"
        class="d-flex flex-column justify-content-center my-5 barcode"
      >
        <div class="container">
          <div id="test" class="barcode__content" [style]="generateBarcode()">
            <svg id="barcode"></svg>
            <div>FNSKU &nbsp;&nbsp;&nbsp; Condition</div>
            <div>Title/Description</div>
            <div>
              <div *ngIf="optionalBarcodeText1 !== OptionalDatas.blank">
                {{ optionalBarcodeText1 }}
              </div>
              <div
                *ngIf="
                  optionalBarcodeText1 !== OptionalDatas.blank &&
                  optionalBarcodeText2 !== OptionalDatas.blank &&
                  stickerFormat.value !== '_27_1' && stickerFormat.value !== '_30_66'
                "
              >
                {{ optionalBarcodeText2 }}
              </div>
            </div>
          </div>
          <!-- Optional Data 1 -->
          <div class="form-group row barcode__options">
            <label class="col-2 col-form-label"></label>
            <div class="col-10">
              <select
                class="form-select"
                formControlName="optionalData1"
                (change)="handleOptionalData1Change()"
              >
                <option
                  *ngFor="let item of OptionalDatas | keyvalue"
                  [value]="item.key"
                >
                  {{ item.value }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="shouldShowCustomText1" class="form-group row">
            <label
              class="col-3 col-form-label barcode__options-label"
              for="customText1"
              >Custom Text 1:</label
            >
            <div class="barcode__options-input">
              <input
                type="text"
                class="form-control"
                id="customText1"
                formControlName="customText1"
                (change)="generateBarcode()"
                [ngClass]="{
                  'is-invalid':
                    !customText1?.valid &&
                    (customText1?.dirty || customText1?.touched)
                }"
              />

              <div
                *ngIf="customText1?.errors && customText1?.touched"
                class="text-danger mt-2"
              >
                <span *ngIf="customText1.hasError('required')">
                  <b>Custom text is required.</b>
                </span>
              </div>
            </div>
          </div>
          <!-- Optional Data 2 -->
          <div *ngIf="stickerFormat.value !== '_27_1' && stickerFormat.value !== '_30_66'" class="form-group row barcode__options">
            <label class="col-2 col-form-label"></label>
            <div class="col-10">
              <select
                class="form-select"
                formControlName="optionalData2"
                [attr.disabled]="
                  optionalBarcodeText1 === OptionalDatas.blank ? true : null
                "
                (change)="handleOptionalData2Change()"
              >
                <option
                  *ngFor="let item of OptionalDatas | keyvalue"
                  [value]="item.key"
                >
                  {{ item.value }}
                </option>
              </select>
            </div>
          </div>
          <div *ngIf="shouldShowCustomText2 && stickerFormat.value !== '_27_1' && stickerFormat.value !== '_30_66'" class="form-group row">
            <label
              class="col-3 col-form-label barcode__options-label"
              for="customText2"
              >Custom Text 2:</label
            >
            <div class="barcode__options-input">
              <input
                type="text"
                class="form-control"
                id="customText2"
                formControlName="customText2"
                (change)="generateBarcode()"
                [ngClass]="{
                  'is-invalid':
                    !customText2?.valid &&
                    (customText2?.dirty || customText2?.touched)
                }"
              />

              <div
                *ngIf="customText1?.errors && customText1?.touched"
                class="text-danger mt-2"
              >
                <span *ngIf="customText1.hasError('required')">
                  <b>Custom text is required.</b>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        [style.display]="isModifySticker ? 'block' : 'none'"
        class="my-5 preview"
      >
        <div class="input-group mb-2">
          <input
            #searchBar
            class="form-control border"
            type="search"
            placeholder="Search Items by SKU"
            (input)="clearSearch(searchBar.value)"
          />
          <span class="input-group-append">
            <div class="input-group-append">
              <button class="btn btn-primary" (click)="search(searchBar.value)">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </span>
        </div>

        <app-forecast-rxdata-table
          *ngIf="metadataFields?.length && gridState && restockKey"
          [restockKey]="restockKey"
          [itemName]="GridName.ForecastShipmentItemValid"
          [modelType]="shipmentDetailRef"
          [resourceService]="shipmentDetailService"
          [columns]="metadataFields"
          [userMetaDataFields]="userMetaDataFields"
          [shouldLoadGrid]="shouldLoadGrid"
          [addIsVisible]="false"
          [deleteIsVisible]="false"
          [bulkMngtIsVisible]="false"
          keyName="shipmentItemKey"
          [notiDisplayName]="itemName"
          [additionalReadOnlyCols]="additionalReadOnlyCols"
          [isExtraExportVisible]="false"
          [initGridState]="gridState"
          [saveGridStateIsVisible]="false"
          (stateChange)="onStateChange($event)"
          (columnChange)="onColumnChange($event)"
          (reorderChange)="onColumnReorder($event)"
          (columnResize)="onColumnResize($event)"
          [isLocalQtyValidationDisabled]="
            restock?.restockType !== RestockType.Warehouse
          "
          [fullScreenIconIsVisible]="false"
        ></app-forecast-rxdata-table>
      </div>

      <hr />
      <!-- Sticker Format -->
      <div class="form-group row">
        <label class="col-2 col-form-label" for="stickerFormat">Format:</label>
        <div class="col-8">
          <select
            class="form-select"
            id="stickerFormat"
            formControlName="stickerFormat"
            (change)="handleStickerFormatChange()"
          >
            <option
              *ngFor="let item of StickerFormats | keyvalue"
              [value]="item.key"
            >
              {{ item.value }}
            </option>
          </select>

          <div *ngIf="shouldShowCustomSize" class="form-row mt-3">
            <div class="form-group col-6">
              <label for="customWidth">Width (mm)</label>
              <input
                class="form-control"
                type="number"
                id="customWidth"
                formControlName="customWidth"
                [ngClass]="{
                  'is-invalid':
                    !customWidth?.valid &&
                    (customWidth?.dirty || customWidth?.touched)
                }"
              />

              <div
                *ngIf="customWidth?.errors && customWidth?.touched"
                class="text-danger mt-2"
              >
                <span *ngIf="customWidth.hasError('required')">
                  <b>Custom width is required.</b>
                </span>
              </div>

              <small
                class="form-text text-muted"
                [ngClass]="{ 'text-danger': customWidth.hasError('min') }"
              >
                Minumum width is 45.00mm
              </small>
            </div>
            <div class="form-group col-6">
              <label for="customHeight">Height (mm)</label>
              <input
                class="form-control"
                type="number"
                id="customHeight"
                formControlName="customHeight"
                [ngClass]="{
                  'is-invalid':
                    !customHeight?.valid &&
                    (customHeight?.dirty || customHeight?.touched)
                }"
              />

              <div
                *ngIf="customHeight?.errors && customHeight?.touched"
                class="text-danger mt-2"
              >
                <span *ngIf="customHeight.hasError('required')">
                  <b>Custom height is required.</b>
                </span>
              </div>

              <small
                class="form-text text-muted"
                [ngClass]="{ 'text-danger': customHeight.hasError('min') }"
              >
                Minumum width is 25.40mm
              </small>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-check mb-1">
            <input
              class="form-check-input"
              type="checkbox"
              id="printRequired"
              formControlName="printRequiredStickerOnly"
            />
            <label class="form-check-label" for="printRequired">
              Print Required Sticker Only
            </label>
          </div>
          <button
            class="btn btn-primary btn-block"
            (click)="downloadPDF(null)"
            [disabled]="isDownloading"
          >
            <span
              class="spinner-border spinner-border-sm text-light alig"
              *ngIf="isDownloading"
            ></span>
            Download
          </button>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label" for="printOption">Option:</label>
        <div class="col-8">
          <select
            class="form-select"
            id="printOption"
            formControlName="printOption"
          >
            <option
              *ngFor="let item of PrintOptions | keyvalue"
              [value]="item.key"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <button
            class="btn btn-primary btn-block"
            (click)="downloadPDF(null, false)"
            [disabled]="
              (shouldShowCustomSize && !stickerForm.valid) || isPrinting
            "
          >
            <span
              class="spinner-border spinner-border-sm text-light alig"
              *ngIf="isPrinting"
            ></span>
            Print
          </button>
        </div>
      </div>
    </form>

    <div class="mb-2 mt-5">
      <p>
        Ready to print a Pick List?<br />
        Please go to the Manage Shipments page and click the print option for
        your shipment(s).
      </p>
      <p>
        *Reminder: Update FBM inventory in Seller Central when applicable
      </p>
      <div *ngIf="restock?.isShipByCase || restock?.isShipManual" class="text-danger font-weight-bold">
        <div>
          This order will be deleted from RestockAMZ. You will be able to track your shipment in RestockAMZ once the shipment has been create in Seller Central.
        </div>
        <form
          [formGroup]="subtractQuantityForm"
        >
          <div class="form-check mb-1">
            <input
              class="form-check-input"
              type="checkbox"
              id="substractQuantity"
              formControlName="isSubtractQuantity"
            />
            <label class="form-check-label" for="substractQuantity">
              Subtract shipment quantities from Warehouse Inventory.
            </label>
          </div>
        </form>
        <div>
          If shipment quantity is later revised in Seller Central, please adjust
          Warehouse Quantity manually.
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <div class="btn-group mt-3">
      <button
        type="button"
        class="btn btn-secondary"
        [disabled]="isProcessing"
        (click)="onBackClick()"
      >
        Back
      </button>
      <span
        placement="bottom"
        container="body"
        [ngbTooltip]="shouldShowMaintenanceMessage ? 'The Amazon API is currently undergoing unscheduled maintenance' : null"
      >
        <button
          type="button"
          class="btn btn-success"
          (click)="complete()"
          [disabled]="isDownloading || isPrinting || isProcessing || shouldShowMaintenanceMessage"
        >
          Complete
        </button>
      </span>
    </div>
  </div>

  <div id="barcode-wrapper">
    <img alt="" id="temp-barcode" />
  </div>

  <div class="complete__processing" *ngIf="isProcessing">
    <div class="spinner-border text-light" role="status">
      <span class="sr-only"></span>
    </div>
    <p class="mt-4">
      {{ header }}
      <br />
      {{ message }}
    </p>
  </div>
</div>
