import { LookUpMapping } from "./look-up-mapping";

export class LookUpConfig {

  //needs to be an observable<...>
  service: any;

  //this is the property from the source object you want  to use as the label in the lookup
  //in the drop down/combo box
  sourceLabelProperty: string = '';

  //this is the property from the source object you want  to use as the value in the lookup
  //in the drop down/combo box
  sourceValueProperty: string = '';
  searchField?: string;

  mapping: LookUpMapping[] = [];

  constructor(init?: Partial<LookUpConfig>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }

}
