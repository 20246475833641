import { Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IModalOptions, MODAL_DATA } from './modal.model';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private modal: NgbModal) {}

  open<T>(content: any, options?: Partial<IModalOptions<T>>) {
    const { data, onSuccess, onError } = options || {};

    this.modal
      .open(content, {
        ...options,
        centered: true,
        injector: this.configInjector(data),
      })
      .result.then(
        (rs) => onSuccess?.(rs),
        (err) => onError?.(err)
      );
  }

  dismissAll() {
    this.modal.dismissAll();
  }

  private configInjector(data: any) {
    return Injector.create({
      providers: [
        {
          provide: MODAL_DATA,
          useValue: data,
        },
      ],
    });
  }
}
