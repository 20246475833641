import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicScriptsService {

  loadDynamicScript(src: string): Observable<any>  {
    return new Observable<any>((observer: Observer<any>) => {
      if (this.isScriptLoaded(src)) {
        return;
      }

      const scriptElement = window.document.createElement('script');
      scriptElement.src = src;
      scriptElement.async = true;
      scriptElement.defer = true;
  
      scriptElement.onload = () => {
        observer.next(scriptElement);
        observer.complete();
      };
  
      scriptElement.onerror = () => {
        observer.error('Cannot load script ');
      };
      window.document.body.appendChild(scriptElement);
    });
  }

  isScriptLoaded(url: string): boolean {
    if (!url) {
      return false;
    }

    const scripts = document.getElementsByTagName('script');
    for (let i = scripts.length; i--;) {
      if (scripts[i].src == url) return true;
    }

    return false;
  }
}