import { Pipe, PipeTransform } from '@angular/core';
import {
  ItemField,
  VendorField,
} from '../../../../core/infrastructure/enums/meta-data-field.enum';
import { MetaDataField } from '../../../../core/infrastructure/classes/meta-data-field';
import { GridName } from '../../forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';
import { Item } from 'src/app/core/models/item';
import { Vendor } from 'src/app/core/models/vendor';
import { ServiceLevelListDisplay } from 'src/app/core/constants/service-level.constant';
import { Company } from 'src/app/core/models/company';

@Pipe({
  name: 'dataOutput',
})
export class DataOutputPipe implements PipeTransform {
  transform(
    metadataField: MetaDataField,
    dataItem: any,
    itemName: string,
    vendorsGroupedByKey: Record<string, Vendor[]>,
    company: Company
  ): any {
    const value = dataItem[metadataField.field];
    let source = metadataField?.displayControl?.selectSource?.find((src) => {
      return src.val === value;
    });

    switch (true) {
      case itemName === GridName.Supplier &&
        metadataField.field === VendorField.restockModel:
        // Set default value for restock model of vendor
        source ??=
          metadataField?.displayControl?.selectSource &&
          metadataField?.displayControl?.selectSource[0];
        break;

      case itemName === GridName.Item &&
        metadataField.field === ItemField.serviceLevel:
        // Attach source of service level for Item
        return this.getServiceLevelDisplayNameForItem(
          dataItem,
          company?.serviceLevel,
          vendorsGroupedByKey
        );

      case itemName === GridName.Supplier &&
        metadataField.field === ItemField.serviceLevel:
        // Attach source of service level for Supplier
        return this.getServiceLevelDisplayNameForVendor(
          dataItem,
          company?.serviceLevel
        );
    }

    return source?.display;
  }

  private getServiceLevelDisplayNameForItem(
    item: Item,
    globalServiceLevel: number,
    vendorsGroupedByKey: Record<string, Vendor[]>
  ) {
    if (item.serviceLevel) {
      return `${ServiceLevelListDisplay.get(item.serviceLevel).numOnly} (${
        GridName.Item
      })`;
    }

    const [vendor] = vendorsGroupedByKey[item.vendorKey] || [];
    if (vendor?.serviceLevel) {
      return `${ServiceLevelListDisplay.get(vendor.serviceLevel).numOnly} (${
        GridName.Supplier
      })`;
    }

    return `${
      ServiceLevelListDisplay.get(globalServiceLevel).numOnly
    } (Global)`;
  }

  private getServiceLevelDisplayNameForVendor(
    vendor: Vendor,
    globalServiceLevel: number
  ) {
    if (vendor?.serviceLevel) {
      return `${ServiceLevelListDisplay.get(vendor.serviceLevel).numOnly} (${
        GridName.Supplier
      })`;
    }

    return `${
      ServiceLevelListDisplay.get(globalServiceLevel).numOnly
    } (Global)`;
  }
}
