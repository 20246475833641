import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/base';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';

@Component({
  selector: 'app-create-address-modal',
  templateUrl: './create-address-modal.component.html',
  styleUrls: ['./create-address-modal.component.scss']
})
export class CreateAddressModalComponent extends BaseComponent{

  @Input() fields: MetaDataField[];
  @Input() modalConfig: any;

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  public onSave(e: Event) {
    this.activeModal.close(e);
  }

}
