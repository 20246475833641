import { Directive, ElementRef, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

// Attach data-testid attribute to element,
// help QAs to identify elements more easier
@Directive({
  selector: '[dataTestID]',
})
export class DataTestIDDirective {
  @Input() set dataTestID(value: string) {
    if (environment.production || !this.elements) {
      return;
    }

    this.elements.nativeElement.setAttribute('data-testid', value);
  }
  constructor(private elements: ElementRef) {}
}
