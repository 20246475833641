import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RealmService } from '../services/realm.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthenticationService,
    private realmService: RealmService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.getLoggedInUserFromSession(true).pipe(
      switchMap(() => this.realmService.getAll()),
      catchError(() => of(null)),
      map((realms) => {
        const companyKey = route.queryParamMap.get('companyKey');
        const companyType = route.queryParamMap.get('companyType');
        const redirectedRealm = realms?.find(
          (r) => r.companyKey === companyKey && r.companyType === companyType
        );
        if (realms && companyKey && companyType && !redirectedRealm) {
          this.router.navigate(['/dashboard/home'], {
            queryParams: { error: 'wrong-user-redirected' },
          });
          return false;
        }

        if (this.authService.isAuthenticated) {
          return true;
        }

        sessionStorage.setItem('previousUrlSegment', state.url);
        this.router.navigate(['/account/signin']);
        return false;
      })
    );
  }
}
