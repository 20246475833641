<div class="agreement-modal">
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5>Welcome to ForecastRx</h5>
      <div>
        <p>
          <span *ngIf="isAddedByCompanyOwner">
            {{ addRequest?.existingEmail }} has invited you to join {{ addRequest?.companyName }} on
            ForecastRx.
          </span>
          <span *ngIf="isQboSSO">
            Quickbooks Online Single Sign-On logged in successful
          </span>
          <span *ngIf="isAmazonSSO">
            Amazon Single Sign-On logged in successful
          </span>
        </p>
        <p>
          Please agree to the privacy policy and terms of service below to
          continue using the app.
        </p>
      </div>
    </div>
  </div>

  <div class="modal-body">
    <div class="card">
      <div class="card-info m-t-20 m-b-20">
        <a
          href="http://www.forecastrx.com/privacy-policy/?_ga=2.140446896.28200849.1649412476-526199340.1649412476"
          target="_blank"
          rel=noopener
        >
          <h4>Privacy Policy</h4>
        </a>
      </div>
      <div class="card-info m-b-20">
        <a
          href="http://www.forecastrx.com/terms/?_ga=2.140446896.28200849.1649412476-526199340.1649412476"
          target="_blank"
          rel=noopener
        >
          <h4>Terms of Service</h4>
        </a>
      </div>
    </div>
    <div class="action">
      <div class="p-b-20">
        <button type="button" class="btn btn-success mx-1" (click)="agree()">
          Agree
        </button>
        <button type="button" class="btn btn-danger mx-1" (click)="logOut()">
          Log Out
        </button>
      </div>
    </div>
  </div>
</div>
