<div class="nav-item__sync">
  <!-- Button -->
  <div
    class="nav-item__button-container"
    [ngClass]="{ 'nav-item__button-container--modified': isSyncInProcess }"
  >
    <div
      class="nav-item__button"
      [ngClass]="{ 'nav-item__button--modified': isSyncInProcess }"
      (click)="isSyncDisabled ? popupMaintenanceMessage() : togglePopup()"
    >
      <i class="nav-item__button-icon icon feather icon-repeat feather--modified"></i>
      <div class="nav-item__timeInfo">
        <p #lastSyncMessage *ngIf="lastSync" class="nav-item__timeDetail">
          Last Sync:
          <br />
          {{ lastSync | date : "fullDate" }} {{ lastSync | date : "shortTime" }}
        </p>
      </div>
    </div>
  </div>

  <!-- Popup Confirmation -->
  <div
    class="nav-item__message-container"
    [class]="isHide ? 'nav-item__message-container--modified' : ''"
  >
    <div
      *ngIf="
        [CompanyType.ASC, CompanyType.QBO].includes(currentCompany?.companyType)
      "
      class="nav-item__message-box nav-item__message-box--modified"
    >
      <div class="nav-item__message-header">
        <i class="icon feather icon-repeat"></i>
        <p class="nav-item__message-name">
          Sync with
          {{
            isCurrentCompanyASC ? "Amazon Seller Central" : "QuickBooks Online"
          }}
        </p>
      </div>
      <div class="nav-item__message-body">
        <p class="nav-item__message-para">
          You have chosen to sync your data. This will pull the latest data
          {{
            isCurrentCompanyASC ? "and all the shipments with open status" : ""
          }}
          from
          <b>{{
            isCurrentCompanyASC ? "Amazon Seller Central" : "QuickBooks Online"
          }}</b
          >. Please note that this may take some time.
        </p>
        <p class="nav-item__message-question">
          Would you like to sync your data now?
        </p>
      </div>
      <div class="nav-item__message-button">
        <a
          href="javascript:"
          class="nav-item__message-link"
          (click)="togglePopup()"
          >Cancel</a
        >
        <a
          href="javascript:"
          class="nav-item__message-link"
          (click)="startSync()"
          >Start Sync</a
        >
      </div>
      <div class="nav-item__message-close">
        <i
          class="fa fa-times fa-times--modified"
          (click)="togglePopup()"
          aria-hidden="true"
        ></i>
      </div>
    </div>

    <div
      *ngIf="currentCompany?.companyType === CompanyType.QBFS"
      class="nav-item__message-box nav-item__message-box--modified"
    >
      <div class="nav-item__message-header">
        <i class="icon feather icon-repeat"></i>
        <p class="nav-item__message-name">Sync with QuickBooks Desktop</p>
      </div>
      <div class="nav-item__message-body">
        <p class="nav-item__message-para">
          Please initiate your data syncs from your <b>QuickBooks Web Connector</b>.
        </p>
      </div>
      <div class="nav-item__message-button">
        <a
          href="javascript:"
          class="nav-item__message-link"
          (click)="togglePopup()"
          >OK</a
        >
      </div>
      <div class="nav-item__message-close">
        <i
          class="fa fa-times fa-times--modified"
          (click)="togglePopup()"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>

  <!-- Spinner -->
  <div class="nav-item__sync-cover" *ngIf="isProcessing">
    <div class="nav-item__sync-cover-content">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only"></span>
      </div>
      <p class="nav-item__sync-cover-message">
        {{ header }}
        <br />
        {{ message }}
      </p>
    </div>
  </div>
</div>
