import { ReportType } from '../../../../../core/infrastructure/enums/report-type.enum';
export const ReportHeaders = {
  [ReportType.FbaInventoryReport]: [
    'sku',
    'fnsku',
    'asin',
    'product-name',
    'condition',
    'your-price',
    'afn-fulfillable-quantity',
    'afn-reserved-quantity',
  ],
  [ReportType.FbmReport]: [
    'item-name',
    'seller-sku',
    'quantity',
    'item-condition',
    'asin1',
    'fulfillment-channel',
  ],
  [ReportType.InventoryHealthReport]: [
    'snapshot-date',
    'sku',
    'fnsku',
    'asin',
    'product-name',
    'condition',
    'inv-age-0-to-90-days',
    'inv-age-91-to-180-days',
    'inv-age-181-to-270-days',
    'inv-age-271-to-365-days',
    'inv-age-365-plus-days',
    'currency',
    'your-price',
    'sales-price',
    'product-group',
    'sales-rank',
    'weeks-of-cover-t30',
    'weeks-of-cover-t90',
  ],
  [ReportType.RestockReport]: [
    'Product Name',
    'FNSKU',
    'Merchant SKU',
    'ASIN',
    'Condition',
    'Price',
    'Sales last 30 days',
    'Inbound',
    'Available',
    'FC transfer',
    'FC Processing',
    'Customer Order',
    'Unfulfillable',
    'Working',
    'Shipped',
    'Receiving',
    'Alert',
  ],
  [ReportType.FbaFeesReport]: [
    'sku',
    'fnsku',
    'asin',
    'longest-side',
    'median-side',
    'shortest-side',
    'length-and-girth',
    'unit-of-dimension',
    'item-package-weight',
    'unit-of-weight',
    'product-size-tier',
    'currency',
    'estimated-fee-total',
    'estimated-referral-fee-per-unit',
    'estimated-variable-closing-fee',
    'expected-fulfillment-fee-per-unit',
  ],
  [ReportType.AllOrdersDataReport]: [
    'amazon-order-id',
    'order-status',
    'sales-channel',
    'sku',
    'asin',
    'item-status',
    'quantity',
  ],
};
