import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'manageInventory',
        title: 'Manage Company',
        type: 'collapse',
        icon: 'feather icon-pie-chart',
        children: [
          {
            id: 'companyDetails',
            title: 'Company Details',
            type: 'item',
            url: '/dashboard/manage/companyDetails',
          },
          {
            id: 'vendors',
            title: 'Suppliers',
            type: 'item',
            url: '/dashboard/manage/suppliers',
          },
          {
            id: 'items',
            title: 'Items',
            type: 'item',
            url: '/dashboard/manage/items',
          },
          {
            id: 'demand',
            title: 'Demand',
            type: 'item',
            url: '/dashboard/manage/demand',
          },
          {
            id: 'supply',
            title: 'Purchase Orders',
            type: 'item',
            url: '/dashboard/manage/purchase-order',
          },
          {
            id: 'advanced',
            title: 'Advanced',
            type: 'collapse',
            icon: 'feather icon-bar-chart-2',
            children: [
              {
                id: 'editKits',
                title: 'Edit Kits',
                type: 'item',
                url: '/dashboard/manage/edit-kits',
              },
              {
                id: 'editItemHistory',
                title: 'Edit Item History',
                type: 'item',
                url: '/dashboard/manage/edit-item-history',
              },
              {
                id: 'warehouses',
                title: 'Warehouses',
                type: 'item',
                url: '/dashboard/manage/warehouses',
              },
              {
                id: 'modifyForecast',
                title: 'Modify Forecast',
                type: 'item',
                url: '/dashboard/manage/modify-forecast',
              },
            ],
          },
        ],
      },
      {
        id: 'purchasing',
        title: 'Purchasing',
        type: 'collapse',
        icon: 'feather icon-target',
        children: [
          {
            id: 'mySuggested',
            title: 'My Suggested POs',
            type: 'item',
            url: '/dashboard/purchases/my-suggested',
          },
          {
            id: 'custom',
            title: 'Custom',
            type: 'item',
            url: '/dashboard/purchases/custom',
          },
          {
            id: 'managePOVault',
            title: 'Manage PO Vault',
            type: 'item',
            url: '/dashboard/purchases/po-vault',
          },
        ],
      },
      {
        id: 'shipments',
        title: 'RestockAMZ (Beta)',
        type: 'collapse',
        icon: 'feather icon-box',
        children: [
          {
            id: 'createNew',
            title: 'Restock from Upload',
            type: 'item',
            url: '/dashboard/shipments/create',
          },
          {
            id: 'restockSuggestions',
            title: 'Restock Recommendations',
            type: 'item',
            url: '/dashboard/shipments/restock-suggestions',
          },
          {
            id: 'manageSuppliers',
            title: 'Address Book',
            type: 'item',
            url: '/dashboard/shipments/suppliers',
          },
          {
            id: 'manageShipments',
            title: 'Manage Shipments',
            type: 'item',
            url: '/dashboard/shipments/manage-shipments',
          },
          {
            id: 'shipments-settings',
            title: 'Settings',
            type: 'item',
            url: '/dashboard/shipments/shipments-settings',
          },
        ],
      },
      {
        id: 'administration',
        title: 'Administration',
        type: 'collapse',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'companyAdmin',
            title: 'Companies',
            type: 'item',
            url: '/administration/companies/list',
          },
          {
            id: 'userAdmin',
            title: 'Users',
            type: 'item',
            url: '/administration/users/list',
          },
        ],
      },
    ],
  },
];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
