<!-- Search result -->
<div [class.has-navbar-collapse]="isNavbarCollapse" class="grid-search">
  <div class="w-100 float-end mr-1 grid-search__close">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <kendo-grid
    [data]="gridItems"
    [pageSize]="30"
    [pageable]="true"
    [skip]="currentOffset"
    height="300"
    (pageChange)="pageChange($event)"
    (cellClick)="chooseItem($event)"
    class="grid-search__kendo-grid"
  >
    <kendo-grid-column
      *ngFor="let column of columns"
      [field]="column.field"
      [title]="column.displayName"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span [style]="dataItem['isHidden'] ? hiddenStyle : ''">{{
          dataItem[column.field]
        }}</span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
