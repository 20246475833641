import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item, IItemResponse } from '../models/item'

@Injectable({
  providedIn: 'root'
})
export class PurchaseAsService {
  apiUrl = '/api/purchaseAs';

  constructor(private httpClient: HttpClient) {}

  getItems(itemKey: string): Observable<Item[]> {
    return this.httpClient.get<IItemResponse>(`${this.apiUrl}?id=${itemKey}`).pipe(
      map((Iitem) => Iitem.model)
    )
  }
}
