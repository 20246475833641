import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import {
  DemandAggregation,
  IDemandAggregationResponse,
} from '../models/demand-aggregation';
import { map } from 'rxjs/operators';
import _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class DemandAggregationService {
  apiUrl = '/api/demand-aggregation';

  constructor(private httpClient: HttpClient) {}

  getDemandAggregation(itemKey: string): Observable<DemandAggregation[]> {
    if (!itemKey) {
      return throwError('missing item key');
    }
    const thisMonth = new Date().getMonth();
    const thisYear = new Date().getFullYear();
    const start = new Date(thisYear - 4, thisMonth, 1);
    const end = new Date(thisYear, thisMonth, 0, 23, 59, 59);
    const instrct = {
      key: itemKey,
      operation: 'range',
      start: start.toISOString(),
      end: end.toISOString(),
    };

    return this.httpClient
      .post<IDemandAggregationResponse>(`${this.apiUrl}`, instrct)
      .pipe(map((Iaggregation) => Iaggregation.model));
  }

  getDemandAggregationsOfMultipleItems(itemKeys: string[]): Observable<DemandAggregation[][]> {
    if (!itemKeys?.length) {
      return of([]);
    }

    const thisMonth = new Date().getMonth();
    const thisYear = new Date().getFullYear();
    const start = new Date(thisYear - 4, thisMonth, 1);
    const end = new Date(thisYear, thisMonth, 0, 23, 59, 59);
    const instrct = {
      itemKeys,
      operation: 'getByMultipleItemKeys',
      start: start.toISOString(),
      end: end.toISOString(),
    };

    return this.httpClient
      .post<DemandAggregation[]>(`${this.apiUrl}`, instrct)
      .pipe(map((aggregations) => {
        const aggregationsGroupedByItemKey = _.groupBy(aggregations, 'itemKey');
        const aggregationsArray: DemandAggregation[][] = [];
        Object.keys(aggregationsGroupedByItemKey).forEach((itemKey) => {
          aggregationsArray.push(aggregationsGroupedByItemKey[itemKey]);
        })

        return aggregationsArray;
      }));
  }
}
