export enum DonutChartName {
  ShipmentQty = 'Shipment Qty',
  Received = 'Received',
  Difference = 'Difference',
  DifferenceByStatus = 'Difference By Status'
}

export const DonutChartField = {
  working: {
    displayName: 'Working',
    colorField: '#4B5C6B'
  },
  shipped: {
    displayName: 'Shipped',
    colorField: '#6558F5'
  },
  inTransit: {
    displayName: 'In Transit',
    colorField: '#F7C325'
  },
  received: {
    displayName: 'Received',
    colorField: '#1AAE9F'
  },
  receiving: {
    displayName: 'Receiving',
    colorField: '#1AAE9F'
  },
  delivered: {
    displayName: 'Delivered',
    colorField: '#897A5F'
  },
  checkedIn: {
    displayName: 'Checked In',
    colorField: '#BD34D1'
  },
  error: {
    displayName: 'Error',
    colorField: '#D3455B'
  },
  null: {
    displayName: 'Null',
    colorField: '#C3CFD9'
  },
}

export class DonutChart {
  type: string;
  value: number;
  colorField: string;

  constructor(type: string, value: number, colorField: string) {
    this.type = type;
    this.value = value;
    this.colorField = colorField;
  }
}

export const STATUS_CHART = [
  new DonutChart(DonutChartField.working.displayName, 0, DonutChartField.working.colorField),
  new DonutChart(DonutChartField.shipped.displayName, 0, DonutChartField.shipped.colorField),
  new DonutChart(DonutChartField.inTransit.displayName, 0, DonutChartField.inTransit.colorField),
  new DonutChart(DonutChartField.receiving.displayName, 0, DonutChartField.receiving.colorField),
  new DonutChart(DonutChartField.delivered.displayName, 0, DonutChartField.delivered.colorField),
  new DonutChart(DonutChartField.checkedIn.displayName, 0, DonutChartField.checkedIn.colorField),
  new DonutChart(DonutChartField.error.displayName, 0, DonutChartField.error.colorField)
]
