import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import lodash from 'lodash';

import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { CompanyService } from 'src/app/core/services/company.service';
import { ConsolidationService } from 'src/app/core/services/consolidation.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { ItemService } from 'src/app/core/services/item.service';
import { RestockSuggestionService } from 'src/app/core/services/restock-suggestion.service';
import { ResultService } from 'src/app/core/services/result.service';
import { SnapshotService } from 'src/app/core/services/snapshot.service';
import { SummaryService } from 'src/app/core/services/summary.service';
import { SupplyService } from 'src/app/core/services/supply.service';

import { Consolidation } from 'src/app/core/models/consolidation';
import { Item } from 'src/app/core/models/item';
import { Result } from 'src/app/core/models/result';
import { Snapshot } from 'src/app/core/models/snapshot';
import { Summary } from 'src/app/core/models/summary';
import { Supply } from 'src/app/core/models/supply';

import {
  getConsolidate,
  getFutureProjections,
  getInventoryStatus,
  getItemRelationships,
  getKitDemand,
  getOrderingParameters,
  getParentKitDemand,
  getPurchaseOrders,
  getRecentHistoricalData,
  getSalesHistory,
  getVendorInformation,
} from './data-display-name';

import { conditionalOperator } from '@stockaid/utils';
import 'hammerjs';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import { Observable, forkJoin, from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ServiceLevelListDisplay } from 'src/app/core/constants/service-level.constant';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { RestockType } from 'src/app/core/infrastructure/enums/restock-type.enum';
import { PurchasingSummary } from 'src/app/core/models/purchasing-summary';
import { RestockSuggestion } from 'src/app/core/models/restock-suggestion';
import { Bom } from '../../../../core/models/bom';
import { BomService } from '../../../../core/services/bom.service';

@Component({
  selector: 'app-item-card-detail',
  templateUrl: './item-card-detail.component.html',
  styleUrls: ['./item-card-detail.component.scss'],
})
export class ItemCardDetailComponent extends BaseComponent implements OnInit {
  @ViewChild('itemDetail') itemDetail: ElementRef<any>;

  @Input() set choosenItem(value: Item) {
    this._choosenItem = value;
    this.isHidden = value.isHidden;
    this.isItemUsingCustom = value.isCustom;
    if (!value.isHidden) {
      this.loadSummary();
    }
  }
  @Input() restockType: RestockType = RestockType.Supplier;
  @Input() isPurchasing: boolean = false;
  @Input() isTotalComponentDataOn = false;

  isHidden = false;
  isReady = false;
  isExportPDF = false;
  isLoadingExport = false;
  isItemUsingCustom = false;

  // Purchasing Calculationss and Details, Settings data
  summary: Summary;
  // Forecast data
  // Total Months (Purchasing Calculationss and Details)
  result: Result;
  restockSuggestion: RestockSuggestion;

  bom: Bom[] = [];

  // Use to determine if item is included in kit or not (Settings)
  snapshotsByGroup: Snapshot[] = [];
  // Use to determine if item has kits included (Settings)
  // Kit Demand (Children) data
  snapshots: Snapshot[] = [];

  // Use to determine if included in kit (Settings)
  // Kit Demand (Parent) data
  kitSnapshots: Snapshot[] = [];

  // Purchase Orders
  supplies: Supply[] = [];

  // Consolidate
  consolidations: Consolidation[] = [];

  isLoading = true;
  set isFinished(value: any) {
    this._isFinished = value;

    this.handleLoading(value);
  }
  get isFinished(): any {
    return this._isFinished;
  }
  columns: any[] = [];
  gridItems: any[] = [];
  chartData: any[] = [];
  displayName = [
    'Legend',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  months = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  reversedAccurateMonths: string[] = [];
  legends: string[] = [];
  sort: SortDescriptor[] = [
    {
      field: 'legend',
      dir: 'desc',
    },
  ];
  colorArray = ['#355070', '#6D597A', '#B56576', '#E56B6F', '#EAAC8B'];
  chartColorArray: string[];

  // Purchasing Calculations and Details
  recentHistoricalData: any = {};
  inventoryStatusData: any = {};
  futureProjectionsData: any = {};
  salesHistoryData: any = {};

  // Settings
  orderingParametersData: any = {};
  vendorInformationData: any = {};
  itemRelationshipsData: any = {};

  // Purchase Orders
  purchaseOrdersData: any = {};

  // Kit Demand (Children)
  kitDemandData: any = {};

  // Kit Demand (Parent)
  parentKitDemandData: any = {};

  // Consolidate
  consolidateData: any = {};

  alreadyLoadGridItems = false;
  alreadyLoadPurchasingCalcsDetails = false;
  alreadyLoadSettings = false;
  alreadyLoadPurchaseOrders = false;
  alreadyLoadKitDemand = false;
  alreadyLoadConsolidate = false;
  itemName: string;
  projectedSalesOverHorizon: number = 0;
  purchasingSummariesGroupedByItemKey: Record<string, PurchasingSummary[]> = {};

  readonly ServiceLevelListDisplay = ServiceLevelListDisplay;

  private _choosenItem: Item;
  private _isFinished: any = {};

  get choosenItem(): Item {
    return this._choosenItem;
  }

  get shouldDisplayOverride(): boolean {
    return this.result?.shouldDisplayOverride;
  }

  get isChildItem(): boolean {
    return this.summary?.isChild;
  }

  get isTotalComponentDataToggleVisible(): boolean {
    return (
      this.isPurchasing &&
      this.isChildItem &&
      [CompanyType.ASC, CompanyType.QBFS].includes(
        this.companyService.currentCompany()?.companyType as CompanyType
      )
    );
  }

  constructor(
    public activeModal: NgbActiveModal,
    private itemService: ItemService,
    private summaryService: SummaryService,
    private resultService: ResultService,
    private restockSuggestionService: RestockSuggestionService,
    private bomService: BomService,
    private snapshotService: SnapshotService,
    private supplyService: SupplyService,
    private consolidationService: ConsolidationService,
    private router: Router,
    private headerService: HeaderService,
    private companyService: CompanyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.itemName = this.choosenItem.name || this.choosenItem?.fnsku;
    this.chartColorArray = this.colorArray.slice().reverse().concat('#D00000');

    const currentMonth = new Date().getMonth();
    let accurateHeaders = [];
    accurateHeaders.push(this.displayName[0]);
    accurateHeaders.push(...this.displayName.slice(currentMonth + 1));
    accurateHeaders.push(...this.displayName.slice(1, currentMonth + 1));
    accurateHeaders.forEach((name) => {
      this.columns.push({
        field: name.toLowerCase(),
        displayName: name,
      });
      this.reversedAccurateMonths.push(name.toLowerCase());
    });

    this.reversedAccurateMonths.shift();
    this.reversedAccurateMonths.reverse();

    const thisYear = new Date().getFullYear();
    for (let year = thisYear - 4; year <= thisYear; year++) {
      this.legends.push(`${year}-${year + 1}`);
    }
  }

  sortChange(sort: SortDescriptor[]): void {
    if (sort[0].dir) {
      this.colorArray.reverse();
    }
    this.sort = sort;
    this.gridItems = orderBy(this.gridItems, sort);
  }

  makeVisible(): void {
    this.isHidden = false;
    this.choosenItem.isHidden = false;
    this.itemService.save(this.choosenItem, 'key').subscribe();
    this.loadSummary();
  }

  openForecastModal(): void {
    this.headerService.requestToOpenForecastSubject.next();
    this.activeModal.dismiss();
  }

  pickTextColorBasedOnBgColor(bgColor) {
    if (!bgColor) {
      return 'black';
    }
    const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'black' : 'white';
  }

  goToItemManagement(): void {
    this.headerService.goToItemManagementSubject.next();
    this.activeModal.close();
    this.router.navigateByUrl(
      `/dashboard/manage/items?id=${this.choosenItem.key}`
    );
  }

  openAll(): Array<HTMLElement> {
    const openButton = document.querySelectorAll('.card-header > button');
    const arrayBtnDontOpen: Array<HTMLElement> = [];
    let isOpen: boolean;

    openButton.forEach((button) => {
      isOpen = button.children[0].className.includes('fa-minus');
      if (!isOpen) {
        if (button instanceof HTMLElement) {
          arrayBtnDontOpen.push(button);
          button.click();
          isOpen = true;
        }
      }
    });
    return arrayBtnDontOpen;
  }

  createCanvas(): Observable<HTMLCanvasElement> {
    return from(htmlToImage.toCanvas(this.itemDetail.nativeElement));
  }

  onExportPDF(): void {
    const arrayCurrent = this.openAll();
    this.isExportPDF = true;

    setTimeout(() => {
      this.createCanvas()
        .pipe(
          this.autoCleanUp(),
          tap((canvas) => {
            const pdf = new jsPDF({
              orientation: 'portrait',
              unit: 'mm',
              format: [canvas.height, canvas.width],
            });
            pdf.addImage(
              canvas,
              'JPEG',
              0,
              0,
              canvas.width,
              canvas.height,
              '',
              'FAST'
            );
            pdf.save(`${this.itemName}_item-card`);
          })
        )
        .subscribe(() => {
          this.isExportPDF = false;
          this.isLoadingExport = false;
          arrayCurrent.forEach((button) => {
            button.click();
          });
        });
      this.isLoadingExport = true;
    }, 1000);
  }

  handleOverrideClick() {
    this.choosenItem.isCustom = !this.choosenItem.isCustom;
    this.summaryService.getSummary(this.choosenItem.key)
      .pipe(
        this.autoCleanUp(),
        tap((summary) => {
          const [purchasingSummary] = this.purchasingSummariesGroupedByItemKey[this.choosenItem.key] || [];
          const snapshot = !this.choosenItem.isCustom && purchasingSummary?.snapshot;
          if(snapshot) {
            summary.meanLtd = snapshot.meanLtd;
            summary.restockMeanLtd = snapshot.restockMeanLtd;
            summary.poMeanLtd = snapshot.poMeanltd;
            summary.restockPoMeanLtd = snapshot.restockPoMeanLtd;
            summary.fullMeanLtd = snapshot.fullMeanLtd;
            summary.restockFullMeanLtd = snapshot.restockFullMeanLtd;
            summary.newMeanLtd = snapshot.newMeanLtd;
            summary.newPoMeanLtd = snapshot.newPoMeanLtd;
            summary.newFullMeanLtd = snapshot.newFullMeanLtd;
            summary.warehouseMeanLtd = snapshot.warehouseMeanLtd;
            summary.warehousePoMeanLtd = snapshot.warehousePoMeanLtd;
            summary.warehouseFullMeanLtd = snapshot.warehouseFullMeanLtd;
            summary.outOfStockDate = snapshot.outOfStockDate;
            summary.newOutOfStockDate = snapshot.newOutOfStockDate;

            if (summary.isolatedValues) {
              summary.isolatedValues.outOfStockDate = snapshot.restockOutOfStockDate;
            }
          }
          this.summary = summary;
          this.loadGridItems();
          this.loadPurchasingCalcsDetails();
          this.modifyPurchasingConsolidations();
          this.modifyRSConsolidations();
        })
      )
      .subscribe();
  }

  handleTotalComponentDataClick(event: MouseEvent) {
    (event.target as HTMLElement).blur();
    this.isTotalComponentDataOn = !this.isTotalComponentDataOn;
    this.loadGridItems();
    this.loadPurchasingCalcsDetails();
    this.modifyPurchasingConsolidations();
  }

  private loadSummary(): void {
    this.summaryService
      .getSummary(this.choosenItem.key)
      .subscribe((summary) => {
        this.summary = summary;

        this.isFinished.summary = true;

        // Trigger onChanges
        this.isFinished = Object.assign({}, this.isFinished);

        if (this.summary) {
          this.isReady = true;
          this.loadResult().subscribe(() => {
            this.loadConsolidations();
          });
          this.loadBom();
          this.loadSupplies();
        } else {
          this.isLoading = false;
        }
      });
  }

  private loadBom(): void {
    this.bomService
      .getBomsByParentId(this.choosenItem.key)
      .pipe(this.autoCleanUp())
      .subscribe((rows) => {
        this.bom = rows;

        this.isFinished.boms = true;

        // Trigger onChanges
        this.isFinished = Object.assign({}, this.isFinished);
        this.loadSnapshots();
      });
  }

  private loadResult(): Observable<any> {
    return forkJoin([
      this.resultService.getResult(this.choosenItem.key),
      this.companyService.currentCompany()?.companyType === CompanyType.ASC
        ? this.restockSuggestionService
            .getByIdWithType(this.choosenItem.key)
            .pipe(catchError(() => of(null)))
        : of(null),
    ]).pipe(
      this.autoCleanUp(),
      tap(([result, restockSuggestion]) => {
        this.result = result;
        this.restockSuggestion = restockSuggestion;

        this.isFinished.result = true;

        // Trigger onChanges
        this.isFinished = Object.assign({}, this.isFinished);
      })
    );
  }

  private loadSnapshots(): void {
    this.snapshotService
      .getSnapshotsByGroup(this.choosenItem.key, this.isPurchasing)
      .pipe(this.autoCleanUp())
      .subscribe((snapshots) => {
        this.snapshotsByGroup = snapshots;
        this.isFinished.snapshotsByGroup = true;

        // Trigger onChanges
        this.isFinished = Object.assign({}, this.isFinished);
        // Snapshots are supposed to be returned by item key and tree level
        // At the moment all snapshots are returned
        // Temporary method: array.filter
        this.isFinished.snapshots = true;
        if (this.bom.length) {
          this.snapshots = this.snapshotsByGroup.filter(
            (x) => x.itemKey !== this.choosenItem.key
          );
        } else {
          this.kitSnapshots = this.snapshotsByGroup.filter(
            (x) => x.itemKey !== this.choosenItem.key
          );
        }

        // Trigger onChanges
        this.isFinished = Object.assign({}, this.isFinished);
      });
  }

  private loadSupplies(): void {
    this.supplyService
      .getSuppliesItemKey(this.choosenItem.key)
      .pipe(this.autoCleanUp())
      .subscribe((supplies) => {
        this.supplies = supplies;

        this.isFinished.supplies = true;

        // Trigger onChanges
        this.isFinished = Object.assign({}, this.isFinished);
      });
  }

  private loadConsolidations(): void {
    this.itemService
      .getConsolidatedQty(this.choosenItem.key)
      .pipe(
        this.autoCleanUp(),
        tap(
          (x) =>
            (this.purchasingSummariesGroupedByItemKey = lodash.groupBy(x, 'itemKey'))
        ),
        switchMap(() =>
          this.consolidationService.getConsolidations(this.choosenItem.key)
        )
      )
      .subscribe((consolidations) => {
        this.consolidations = consolidations.map((c) => {
          if (this.isPurchasing) {
            const [purchasingSummary] =
              this.purchasingSummariesGroupedByItemKey[c.itemKey] || [];
            const psConsolidatedQty = conditionalOperator(
              this.isTotalComponentDataOn,
              purchasingSummary?.consolidatedQty,
              purchasingSummary?.restockConsolidatedQty
            );
            c.consolidatedQty = conditionalOperator(
              Boolean(purchasingSummary),
              psConsolidatedQty,
              c.consolidatedQty
            );

            return c;
          }

          if (c.itemKey !== this.restockSuggestion?.key) {
            return c;
          }

          this.recalculateConsolidatedQtyForRS(c);

          return c;
        });

        this.isFinished.consolidations = true;

        // Trigger onChanges
        this.isFinished = Object.assign({}, this.isFinished);
      });
  }

  private loadGridItems(): void {
    this.alreadyLoadGridItems = true;

    let items = [];
    let historyIndex = conditionalOperator(
      this.isPurchasing && this.isTotalComponentDataOn,
      this.result.historySnapshot.length - 1,
      this.result.restockHistorySnapshot.length - 1
    );
    this.legends
      .slice()
      .reverse()
      .forEach((legend, index) => {
        let item: any = {};
        item.legend = legend;

        if (index === 0) {
          items.push(item);
          return;
        }

        this.reversedAccurateMonths.forEach((month) => {
          if (historyIndex < 0) {
            item[month] = null;
            return;
          }
          item[month] = conditionalOperator(
            this.isPurchasing && this.isTotalComponentDataOn,
            this.result?.historySnapshot[historyIndex],
            this.result?.restockHistorySnapshot[historyIndex]
          );
          --historyIndex;
        });

        items.push(item);
      });

    const accurateMonths = this.reversedAccurateMonths.slice().reverse();
    accurateMonths.forEach((month, index) => {
      if (this.isPurchasing && this.isTotalComponentDataOn) {
        items[0][month] = conditionalOperator(
          Boolean(
            this.choosenItem?.isCustom &&
              this.result?.customMeanForecast?.length
          ),
          this.result?.customMeanForecast &&
            this.result?.customMeanForecast[index],
          this.result?.meanForecast[index]
        );
        return;
      }

      items[0][month] = conditionalOperator(
        Boolean(
          this.choosenItem?.isCustom &&
            this.result?.restockCustomMeanForecast?.length
        ),
        this.result?.restockCustomMeanForecast &&
          this.result?.restockCustomMeanForecast[index],
        this.result?.restockMeanForecast[index]
      );
    });

    items.reverse();

    this.gridItems = orderBy(items, this.sort);
    this.loadChartData(items);
  }

  private loadChartData(items: any[]): void {
    const accurateMonths = this.reversedAccurateMonths.slice().reverse();

    this.chartData = items.map((item) => {
      let result = [];
      accurateMonths.forEach((month, index) => {
        result.push({
          qty: item[month],
          month: `${month[0].toUpperCase()}${month.slice(1)}`,
        });
      });

      return result;
    });

    let result = [];
    accurateMonths.forEach((month, index) => {
      result.push({
        qty:
          this.isPurchasing && this.isTotalComponentDataOn
            ? this.result.safetyStockForecast[index]
            : this.result.restockSafetyStockForecast[index],
        month: `${month[0].toUpperCase()}${month.slice(1)}`,
      });
    });

    const leadTime = this.result?.settingsSources?.leadTime?.value || 0;
    this.chartData.push(result);
    result.splice(
      Math.ceil((this.projectedSalesOverHorizon + leadTime) / 30),
      result.length
    );
  }

  private loadPurchasingCalcsDetails(): void {
    this.alreadyLoadPurchasingCalcsDetails = true;

    let dataSource: any = {};
    const currentCompany = this.companyService.currentCompany();

    dataSource.company = currentCompany;
    dataSource.summary = this.summary;
    dataSource.restockSuggestion = this.restockSuggestion;
    dataSource.item = this.choosenItem;
    this.recentHistoricalData.title = 'Recent Historical Data';
    this.recentHistoricalData.rows = getRecentHistoricalData(
      dataSource,
      this.isTotalComponentDataOn,
      currentCompany.companyType
    );

    this.inventoryStatusData.title = 'Inventory Status';
    this.inventoryStatusData.rows = getInventoryStatus(
      dataSource,
      this.isPurchasing,
      this.isTotalComponentDataOn
    );

    dataSource.snapshotsByGroup = this.snapshotsByGroup;
    dataSource.result = this.result;

    this.futureProjectionsData.title = 'Future Projections';
    this.futureProjectionsData.rows = getFutureProjections(
      dataSource,
      this.restockType,
      this.isPurchasing,
      this.isTotalComponentDataOn,
      this.choosenItem.isCustom
    );
    this.projectedSalesOverHorizon = this.futureProjectionsData.rows[0].val;

    dataSource.item = this.choosenItem;
    this.salesHistoryData.title = 'Sales History';
    this.salesHistoryData.rows = getSalesHistory(
      dataSource,
      this.isTotalComponentDataOn
    );
  }

  private loadSettings(): void {
    this.alreadyLoadSettings = true;

    let dataSource: any = {};

    dataSource.item = this.choosenItem;
    dataSource.result = this.result;
    this.orderingParametersData.title = 'Ordering Parameters';
    this.orderingParametersData.rows = getOrderingParameters(dataSource);

    this.vendorInformationData.title = 'Supplier Information';
    this.vendorInformationData.rows = getVendorInformation(dataSource);

    dataSource.snapshots = this.snapshots;
    dataSource.snapshotsByGroup = this.snapshotsByGroup;
    this.itemRelationshipsData.title = 'Item Relationships';
    this.itemRelationshipsData.rows = getItemRelationships(dataSource);
  }

  private loadPurchaseOrders(): void {
    this.alreadyLoadPurchaseOrders = true;

    this.purchaseOrdersData.columns = getPurchaseOrders();
    this.purchaseOrdersData.gridItems = this.supplies;
  }

  private loadKitDemand(): void {
    this.alreadyLoadKitDemand = true;

    this.kitDemandData.columns = getKitDemand();
    this.kitDemandData.gridItems = this.snapshots.map((snapshot) => {
      snapshot.directDemand = Math.round(snapshot.directDemand);
      snapshot.snapshotQty = Math.round(snapshot.snapshotQty);
      snapshot.childName = snapshot.itemName;
      snapshot.unitsPerAssembly = snapshot.bomQty;

      return snapshot;
    });

    this.kitDemandData.gridItems.sort((a, b) => {
      const x = (a.childName || '').toLowerCase();
      const y = (b.childName || '').toLowerCase();

      return x.localeCompare(y);
    });
  }

  private loadParentKitDemand(): void {
    this.alreadyLoadKitDemand = true;

    this.parentKitDemandData.columns = getParentKitDemand();
    this.parentKitDemandData.gridItems = this.kitSnapshots.map((snapshot) => {
      snapshot.directDemand = Math.round(snapshot.directDemand);
      snapshot.snapshotQty = Math.round(
        snapshot.directDemand - snapshot.supply
      );
      return snapshot;
    });

    this.parentKitDemandData.gridItems.sort((a, b) => {
      const x = (a.childName || '').toLowerCase();
      const y = (b.childName || '').toLowerCase();

      return x.localeCompare(y);
    });
  }

  private loadConsolidate(): void {
    this.alreadyLoadConsolidate = true;

    this.consolidateData.columns = getConsolidate(
      this.companyService.currentCompany().companyType === CompanyType.ASC
    );
    this.consolidateData.gridItems = this.consolidations;
  }

  private handleLoading(value: any): void {
    if (value.result && value.snapshotsByGroup && !this.alreadyLoadGridItems) {
      this.loadPurchasingCalcsDetails();
      this.loadGridItems();
    }

    if (
      value.result &&
      value.snapshots &&
      value.snapshotsByGroup &&
      !this.alreadyLoadSettings
    ) {
      this.loadSettings();
    }
    if (value.supplies && !this.alreadyLoadPurchaseOrders) {
      this.loadPurchaseOrders();
    }
    // I do not see the point of this check.  It's resulting in missing the childName field
    // every other time the component is opened.
    this.loadKitDemand();
    this.loadParentKitDemand();
    // }
    if (value.consolidations && !this.alreadyLoadConsolidate) {
      this.loadConsolidate();
    }

    this.isLoading = false;
    for (let property in value) {
      if (!value[property]) {
        this.isLoading = true;
        break;
      }
    }
  }

  private modifyPurchasingConsolidations() {
    if (!this.isPurchasing) {
      return;
    }

    const isCustom = this.choosenItem?.isCustom;
    this.consolidations.forEach((c) => {
      const [purchasingSummary] =
        this.purchasingSummariesGroupedByItemKey[c.itemKey] || [];
      const snapshot = purchasingSummary?.snapshot;

      if (this.isItemUsingCustom && !isCustom && !lodash.isEmpty(snapshot)) {
        c.consolidatedQty = this.isTotalComponentDataOn
          ? snapshot.consolidatedQty
          : snapshot.restockConsolidatedQty;
        return;
      }

      const psConsolidatedQty = this.isTotalComponentDataOn
        ? purchasingSummary?.consolidatedQty
        : purchasingSummary?.restockConsolidatedQty;
      c.consolidatedQty = purchasingSummary
        ? psConsolidatedQty
        : c.consolidatedQty;
    });
  }

  private modifyRSConsolidations() {
    if (this.isPurchasing) {
      return;
    }

    this.consolidations.forEach((c) => {
      this.recalculateConsolidatedQtyForRS(c);
    })
  }

  private recalculateConsolidatedQtyForRS(consolidation: Consolidation) {
    const isCustom = this.choosenItem?.isCustom;
    const [purchasingSummary] = this.purchasingSummariesGroupedByItemKey?.[consolidation.itemKey] || [];
    const { snapshot } = purchasingSummary || {};

    const overridenValue = this.restockType === RestockType.Warehouse
      ? purchasingSummary?.warehouseConsolidatedQty
      : purchasingSummary?.newConsolidatedQty;
    const snapshotValue = this.restockType === RestockType.Warehouse
      ? snapshot?.warehouseConsolidatedQty
      : snapshot?.newConsolidatedQty;
    const consolidatedQty = this.isItemUsingCustom && !isCustom && !lodash.isEmpty(snapshot)
      ? snapshotValue
      : overridenValue;

    consolidation.consolidatedQty = Number(consolidatedQty);
  }
}
