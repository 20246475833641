  <div>
    <kendo-combobox
      #combo
      [style.width]="'100%'"
      [data]="view"
      [loading]="loading"
      [textField]="searchField"
      [valueField]="searchField"
      [filterable]="true"
      (filterChange)="handleFilter($event)"
      (valueChange)="valueChange($event)"
      [disabled]="isDisabled"
      [placeholder]="placeholder"
      >
    </kendo-combobox>
  </div>
