import { Address } from './address';
export class Supplier {
  key: string = '';
  vendorKey: string = '';
  countryCode: string = '';
  fullName: string = '';
  addressLine1: string = '';
  addressLine2?: string = '';
  city: string = '';
  stateOrProvinceCode: string = '';
  postalCode: string = '';
  phoneNumber: string = '';
  billingAddress: Address = new Address();
  shippingAddress: Address = new Address();

  constructor(init?: Partial<Supplier>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
