<div
  class="inbound-analyzer"
  *ngIf="itemName"
  [class.disable-view]="isLoadingAdd"
>
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5 class="text-white inbound-analyzer__title">Inbound Shipments</h5>
      <h5 class="text-white inbound-analyzer__title">{{ itemName }}</h5>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="chart-wrapper p-3">
      <div class="status-chart">
        <div class="d-flex">
          <div *ngFor="let statusChart of statusCharts">
            <div class="status-chart-title text-center">
              {{ statusChart?.name }}
            </div>
            <kendo-chart>
              <ng-template kendoChartDonutCenterTemplate>
                <span [ngSwitch]="statusChart?.name">
                  <div *ngSwitchCase="DonutChartName.ShipmentQty">
                    <div class="status-chart-total" title="{{ totalShipmentQty }}">
                      {{ formatTotal(totalShipmentQty) }}
                    </div>
                    <small>{{
                      isMany(totalShipmentQty) ? "Units" : "Unit"
                    }}</small>
                  </div>

                  <div *ngSwitchCase="DonutChartName.Received">
                    <div class="status-chart-total" title="{{ totalReceived }}">
                      {{ formatTotal(totalReceived) }}
                    </div>
                    <small>{{
                      isMany(totalReceived) ? "Units" : "Unit"
                    }}</small>
                  </div>

                  <div *ngSwitchCase="DonutChartName.Difference">
                    <div class="status-chart-total" title="{{ totalDifference }}">
                      {{ formatTotal(totalDifference) }}
                    </div>
                    <small>{{
                      isMany(totalDifference) ? "Units" : "Unit"
                    }}</small>
                  </div>
                </span>
              </ng-template>
              <kendo-chart-series>
                <kendo-chart-series-item
                  type="donut"
                  [data]="statusChart?.field"
                  categoryField="type"
                  field="value"
                  colorField="colorField"
                  [holeSize]="30"
                  [size]="12"
                >
                  <kendo-chart-series-item-tooltip
                    *ngIf="statusChart.isShowToolTip"
                  ></kendo-chart-series-item-tooltip>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend position="bottom" [visible]="false">
              </kendo-chart-legend>
            </kendo-chart>
          </div>
        </div>

        <div class="status-chart-legend d-flex">
          <div
            *ngFor="let item of STATUS_CHART"
            class="status-chart-legend__item d-flex mr-2"
          >
            <div
              [style.backgroundColor]="item.colorField"
              class="status-chart-legend__item_box"
            ></div>
            <span class="ml-2">{{ item?.type }}</span>
          </div>
        </div>
      </div>

      <div class="difference-status-chart">
        <div class="difference-status-chart-title">
          {{ DonutChartName?.DifferenceByStatus }}
        </div>
        <div class="d-flex">
          <kendo-chart>
            <ng-template kendoChartDonutCenterTemplate>
              <span title="{{ totalStatus }}">
                {{ formatTotal(totalStatus) }}
              </span>
              <small class="difference-status-chart-units">{{
                isMany(totalStatus) ? "Units" : "Unit"
              }}</small>
            </ng-template>
            <kendo-chart-series>
              <kendo-chart-series-item
                type="donut"
                [data]="differenceByStatusChart"
                categoryField="type"
                field="value"
                colorField="colorField"
                [holeSize]="30"
                [size]="12"
              >
                <kendo-chart-series-item-labels
                  position="outsideStart"
                  background="none"
                  [visible]="false"
                >
                </kendo-chart-series-item-labels>
                <kendo-chart-series-item-tooltip
                  *ngIf="totalStatus > 0"
                ></kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>
            <kendo-chart-legend position="bottom" [visible]="false">
            </kendo-chart-legend>
          </kendo-chart>

          <div class="difference-status-chart-legend">
            <div
              *ngFor="let item of DIFFERENCE_BY_STATUS_CHART"
              class="difference-status-chart-legend__item d-flex mb-1"
            >
              <div
                [style.backgroundColor]="item.colorField"
                class="difference-status-chart-legend__item_box"
              ></div>
              <span class="ml-2">{{ item?.type }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Shipments Table -->
    <kendo-grid
      #grid
      [data]="gridItems$ | async"
      [height]="gridHeight"
      [pageable]="{ pageSizes: pageSizes }"
      [pageSize]="gridState.take"
      [skip]="gridState.skip"
      [reorderable]="true"
      [resizable]="true"
      [navigable]="true"
      [sort]="gridState.sort"
    >
      <kendo-grid-column
        *ngFor="let field of fields"
        [width]="getColumnWidth(field)"
        [field]="getDisplayName(field)"
        [style]="{ 'vertical-align': 'top' }"
        [headerStyle]="{ 'vertical-align': 'top' }"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <div [ngSwitch]="field">
            <div
              class="d-flex justify-content-between"
              *ngSwitchCase="ShipmentField.shipmentId"
            >
              <div class="shorten-text">
                <a
                  class="inbound-analyzer__link-to-ASC"
                  [href]="getAmazonShipmentDetailLink(dataItem?.shipmentId)"
                  target="_blank"
                  [class.inbound-analyzer__disable-link]="!dataItem?.shipmentId"
                  rel=noopener
                >
                  {{ dataItem[field] }}
                </a>
              </div>
            </div>

            <div
              class="d-flex justify-content-between"
              *ngSwitchCase="ShipmentField.updatedAt"
            >
              <div class="shorten-text">
                {{ dataItem[field] | date : FORMAT_DATE }}
              </div>
            </div>

            <div
              class="d-flex justify-content-between"
              *ngSwitchCase="ShipmentField.receiveDate"
            >
              <div class="shorten-text">
                {{ dataItem[field] | date : FORMAT_DATE }}
              </div>
            </div>

            <div class="d-flex justify-content-between" *ngSwitchDefault>
              <div class="shorten-text">
                {{ dataItem[field] }}
              </div>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
