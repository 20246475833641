<div>
  <kendo-grid
    [data]="gridItems"
    [sortable]="true"
    [sort]="sort"
    height="300"
    (sortChange)="sortChange($event)"
    [resizable]="true"
    [navigatable]="true"
  >
    <kendo-grid-column
      *ngFor="let col of columns"
      [field]="col.field"
      [title]="col.displayName"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
        let-columnIndex="columnIndex"
      >
        <span
          *ngIf="col.type === metaDataTypeEnum.DateOnly"
          class="text-center d-block"
        >
          {{ toDateOnly(dataItem[col.field]) }}
        </span>
        <span
          *ngIf="col.type !== metaDataTypeEnum.DateOnly"
          class="text-center d-block"
        >
          {{ dataItem[col.field] }}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
