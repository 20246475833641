import { Component, Input, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@stockaid/core';
import { Shipment } from 'src/app/core/models/shipment';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  AbstractControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { SnotifyService } from 'ng-snotify';
import { HeaderService } from 'src/app/core/services/header.service';
import { COMMON_FORMAT_DATE, FORMAT_PATTERN } from 'src/app/core/constants/format-date.constant';

@Component({
  selector: 'app-change-created-date-modal',
  templateUrl: './change-created-date-modal.component.html',
})
export class ChangeCreatedDateModalComponent
  extends BaseComponent
  implements OnInit
{
  @Input() shipment: Shipment;
  oldCreatedDate: string;
  changeShipmentCreatedDate: FormGroup;
  isProcessing: boolean = false;
  readonly FORMAT_DATE = COMMON_FORMAT_DATE;
  readonly FORMAT_PATTERN = FORMAT_PATTERN;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private shipmentService: ShipmentService,
    private snotifyService: SnotifyService,
    private headerService: HeaderService
  ) {
    super();
  }

  ngOnInit() {
    this.oldCreatedDate = formatDate(this.shipment.createdAt, this.FORMAT_DATE, 'en');
    this.changeShipmentCreatedDate = this.fb.group({
      newCreatedDate: [new Date(this.shipment.createdAt), [Validators.required, this.checkValidCreatedDate()]],
    });
  }

  get newCreatedDate(): AbstractControl {
    return this.changeShipmentCreatedDate.controls.newCreatedDate;
  }

  checkValidCreatedDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = new Date(control.value).getTime();
      const currentTime = new Date().getTime();
      if (!value) {
        return null;
      }

      const hasGreaterThan = currentTime <= value;
      return hasGreaterThan ? { isInvalidDate: true } : null;
    };
  }

  save(): void {
    const newCreatedAt = new Date(this.newCreatedDate.value);
    this.isProcessing = true;
    this.shipmentService
      .save(
        {
          ...this.shipment,
          createdAt: newCreatedAt
        } as Shipment,
        'key'
      )
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.headerService.loadShipmentSubject.next();

        this.isProcessing = false;
        this.closeModal();
        this.snotifyService.success('Change created date successfully');
      });
  }

  closeModal(): void {
    this.activeModal.dismiss();
  }
}
