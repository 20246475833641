<div class="change-create-date-modal">
  <div class="modal-header">
    <div class="modal-title w-100">
      <h5>Change Created Date</h5>
    </div>
  </div>
  <div class="modal-body">
    <p><b>Created Date:</b> {{ oldCreatedDate }}</p>
    <p><b>New Created Date:</b></p>
    <form class="form-group" [formGroup]="changeShipmentCreatedDate">
      <kendo-datepicker
        id="change-created-date"
        [style.width.%]="100"
        placeholder="Change Created Day"
        aria-label="Amount (to the nearest dollar)"
        calendarType="classic"
        format="{{FORMAT_DATE}}"
        [formatPlaceholder]="FORMAT_PATTERN"
        formControlName="newCreatedDate"
        >
      </kendo-datepicker>
    </form>
    <div
      class="text-danger"
      *ngIf="
        newCreatedDate.errors &&
        (newCreatedDate.touched || newCreatedDate.dirty)
      "
    >
      <small>Date must be valid and cannot be a future date</small>
    </div>
  </div>

  <div class="modal-footer">
    <button class="btn btn-light" (click)="closeModal()">Close</button>
    <button
      class="btn btn-primary"
      [ngStyle]="{
        cursor: changeShipmentCreatedDate.valid ? 'pointer' : 'not-allowed'
      }"
      [disabled]="changeShipmentCreatedDate.invalid || isProcessing"
      (click)="save()"
    >
      <span
        class="spinner-border spinner-border-sm"
        *ngIf="isProcessing"
      ></span>
      <span class="load-text ml-2" *ngIf="isProcessing">Saving...</span>
      <span class="load-text" *ngIf="!isProcessing">Save</span>
    </button>
  </div>
</div>
