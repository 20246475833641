import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTestIDDirective } from './directives/data-testid/data-testid.directive';

@NgModule({
  declarations: [DataTestIDDirective],
  exports: [DataTestIDDirective],
  imports: [CommonModule],
})
export class CoreModule {}
