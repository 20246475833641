import { Inject, Injectable } from '@angular/core';
import { WindowToken } from '../misc/window';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  private sessionStorage: Storage;

  constructor(
    @Inject(WindowToken) public window: Window
  ) {
      this.sessionStorage = window.sessionStorage;
   }

   get<T>(key: string): T {

    if (!this.isSessionStorageSupported){
      return null;
    }

    if (this.sessionStorage.getItem(key)) {
      return JSON.parse(this.sessionStorage.getItem(key)) as T;
    }

    return null;
  }
  set(key: string, value: any): boolean {
    if (this.isSessionStorageSupported) {
      this.sessionStorage.setItem(key, JSON.stringify(value));
      return true;
    }
    return false;
  }
  remove(key: string): boolean {
    if (this.isSessionStorageSupported) {
      this.sessionStorage.removeItem(key);
      return true;
    }
    return false;
  }

   get isSessionStorageSupported(): boolean {
    return !!this.sessionStorage;
  }
}
