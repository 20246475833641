import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { Company } from 'src/app/core/models/company';
import { CompanyService } from 'src/app/core/services/company.service';
import { MARKET_PLACE_IDS } from '../../../../../core/constants/asc.constant';
import { MODAL_DATA } from '@stockaid/services';
import { SnotifyService } from 'ng-snotify';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpOService } from 'src/app/core/services/spo.service';
import { PopupService } from 'src/app/core/services/utils/popup.service';
import { takeUntil } from 'rxjs/operators';
import { CompanySelectionService } from 'src/app/core/services/company-selection.service';
import { COMMON_FORMAT_DATE, FORMAT_PATTERN } from 'src/app/core/constants/format-date.constant';
import { subtractByYears } from '@stockaid/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-spo-company-modal',
  templateUrl: './update-spo-company-modal.component.html',
  styleUrls: ['./update-spo-company-modal.component.scss'],
})
export class UpdateSPOCompanyModalComponent implements OnInit, OnDestroy {
  companyForm: FormGroup;
  savingCompany: boolean;
  message: string;
  marketplaceIds = MARKET_PLACE_IDS;
  ascInitialDateRange: any = {};
  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  readonly FORMAT_PATTERN = FORMAT_PATTERN;
  readonly FORMAT_DATE = COMMON_FORMAT_DATE;

  constructor(
    @Inject(MODAL_DATA) public data: any,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private snotifyService: SnotifyService,
    private companySelectionService: CompanySelectionService,
    private ascoService: SpOService,
    private popupService: PopupService
  ) {
    this.companyForm = this.fb.group({
      companyType: [''],
      companyInfo: this.fb.group({}),
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    const min = new Date();
    min.setFullYear(new Date().getFullYear() - 4);
    this.ascInitialDateRange = {
      min,
      max: new Date(),
    };

    this.buildCompanyForm(this.data.company);
  }

  get companyType(): FormControl {
    return this.companyForm.controls['companyType'] as FormControl;
  }

  get companyInfo(): FormGroup {
    return this.companyForm.controls['companyInfo'] as FormGroup;
  }

  get companyName(): FormControl {
    return this.companyForm.controls['companyInfo'].get(
      'companyName'
    ) as FormControl;
  }

  get currentCompany(): Company {
    return this.companyService.currentCompany();
  }

  private buildCompanyForm(company?: Company) {
    this.message = '';
    this.companyForm = this.fb.group({
      companyType: [''],
      companyInfo: this.fb.group({}),
    });

    this.companyInfo.setControl(
      'companyName',
      this.fb.control(company?.companyName || '', Validators.required)
    );
    this.companyInfo.setControl(
      'marketplaceId',
      this.fb.control(
        this.marketplaceIds.find((m) => m.val === company?.marketplaceId),
        Validators.required
      )
    );

    this.companyInfo.setControl(
      'initialSyncDate',
      this.fb.control(
        { value: subtractByYears(new Date(), 4), disabled: environment.production },
        Validators.required
      )
    );
  }

  deleteCompany(company: Company) {
    if (company.lastSyncDate) {
      return;
    }

    // Delete the new company hasn't been authorized
    this.companyService.delete(company.companyKey).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.companySelectionService.removeCompany(company);
    });
  }

  updateSPOCompany(): void {
    if (this.companyForm.invalid) {
      return;
    }

    const companyName =
      this.companyForm.controls['companyInfo'].get('companyName').value;
    const initialSyncDate =
      this.companyForm.controls['companyInfo'].get('initialSyncDate').value;
    const marketplaceId =
      this.companyForm.controls['companyInfo'].get('marketplaceId').value.val;
    const startDate = new Date(initialSyncDate);
    startDate.setHours(0, 0, 0, 0);
    const companyData: Company = {
      ...this.data.company,
      companyName,
      initialSyncDate: startDate.toISOString(),
      marketplaceId
    };
    this.savingCompany = true;

    // Re-authorize for existed companies
    if (companyData.sellerId) {
      this.ascoService.getAuthUri(companyData.marketplaceId, companyData.companyKey).subscribe((uri) => {
        this.popupService.openASCOPopup(uri);
      });

      return;
    }

    // Re-authorize for new companies
    this.companyService.updateCompanyInfo(companyData).subscribe(
      (res) => {
        this.snotifyService.success(`Save Company Info successfully.`);
        this.activeModal.dismiss();
        this.savingCompany = false;

        this.ascoService.getAuthUri(res.marketplaceId, res.companyKey).subscribe((uri) => {
          this.popupService.openASCOPopup(uri);
        });
      },
      (error) => {
        this.savingCompany = false;
        this.message = error.statusText;
      }
    );
  }
}
