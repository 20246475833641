import { Component, Input, OnInit } from '@angular/core';
import { CreateSupplierModalComponent } from '../create-supplier-modal/create-supplier-modal.component';
import { GridName } from 'src/app/theme/shared/forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';
import { BaseComponent } from 'src/app/core/base';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VendorService } from '../../vendor.service';
import { Vendor } from 'src/app/core/models/vendor';
import { MetadataService } from '../../metadata.service';
import { PurchaseOrderService } from '../../purchase-order.service';
import { switchMap, tap } from 'rxjs/operators';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';
import { PurchaseOrderField } from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { Shipment } from 'src/app/core/models/shipment';

@Component({
  selector: 'app-create-po-wizard-modal',
  templateUrl: './create-po-wizard-modal.component.html',
  styleUrls: ['./create-po-wizard-modal.component.scss'],
})
export class CreatePoWizardModalComponent
  extends BaseComponent
  implements OnInit
{
  @Input() selectingVendor: any;
  @Input() itemKeys: string[];
  @Input() removedKeys: string[];
  @Input() shipment: Shipment;

  createdVendor: Vendor;
  vendorMetadataFields: MetaDataField[] = [];
  purchaseOrderMetadataFields: MetaDataField[] = [];
  modalConfig: any;

  readonly GridName = GridName;

  constructor(
    public purchaseOrderService: PurchaseOrderService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private vendorService: VendorService,
    private metadataService: MetadataService
  ) {
    super();
  }

  ngOnInit(): void {
    const customSaveFunc = this.shipment
      ? (newItem) =>
        this.purchaseOrderService.createPOFromShipment(
          newItem,
          this.shipment
        )
      : (newItem) =>
          this.purchaseOrderService.createPO(
            newItem,
            this.itemKeys,
            this.removedKeys,
            this.selectingVendor?.vendorKey
          );
    this.getVendorMetaData()
      .pipe(
        this.autoCleanUp(),
        switchMap(() => this.getPurchaseOrderMetaData()),
        tap(() => {
          this.modalConfig = {
            isAllowAddLookUp: true,
            itemName: GridName.PurchaseOrder,
            displayName: 'Purchase Order',
            isShowRestockAMZ: false,
            forbidenFields: ['vendorKey'],
            resourceService: this.purchaseOrderService,
            keyName: 'key',
            selectingVendor: this.selectingVendor,
            customSaveFunc,
          };
        })
      )
      .subscribe();
  }

  public openAddNewSupplier() {
    const modalRef = this.modalService.open(CreateSupplierModalComponent, {
      size: 'lg',
      keyboard: false,
      centered: true,
      modalDialogClass: 'purchase-order-dialog',
      backdrop: 'static',
      backdropClass: 'no-background',
    });

    modalRef.componentInstance.fields = this.vendorMetadataFields.filter(
      (item) => item.field !== 'links' && item.displayControl
    );
    modalRef.componentInstance.modalConfig = {
      displayName: 'Supplier',
      itemName: GridName.Supplier,
      resourceService: this.vendorService,
      isShowRestockAMZ: true,
    };
    modalRef.result
      .then((e) => {
        this.createdVendor = e;
      })
      .catch(() => {});
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  public onSave(purchaseOrder: PurchaseOrder) {
    this.activeModal.close(purchaseOrder);
  }

  private getVendorMetaData() {
    return this.metadataService.getVendorMetaData().pipe(
      tap((md) => {
        this.vendorMetadataFields = md.fields;
      })
    );
  }

  private getPurchaseOrderMetaData() {
    return this.metadataService.getPurchaseOrderMetaData().pipe(
      tap((md) => {
        this.purchaseOrderMetadataFields = md.fields?.filter((f) =>
          [
            PurchaseOrderField.refNum,
            PurchaseOrderField.docDate,
            PurchaseOrderField.dueDate,
            PurchaseOrderField.vendorName,
          ].includes(f.field as PurchaseOrderField)
        );
        this.purchaseOrderMetadataFields = this.reorderFields(
          this.purchaseOrderMetadataFields
        );
      })
    );
  }

  reorderFields(fields) {
    return [
      PurchaseOrderField.refNum,
      PurchaseOrderField.docDate,
      PurchaseOrderField.dueDate,
      PurchaseOrderField.vendorName,
    ].map((item) => {
      return fields.find((innerItem) => innerItem.field === item);
    });
  }
}
