import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpOService {
  constructor(private httpClient: HttpClient) {}

  getAuthUri(marketplaceId: string, companyKey: string): Observable<string> {
    return this.httpClient.get(`/api/v2/sp/authUri?marketplaceId=${marketplaceId}&companyKey=${companyKey}`, { responseType: 'text' });
  }
}
