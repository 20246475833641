export class SortAttribute {
  priority: number
  direction: string;
  field: string;

  constructor(init?: Partial<SortAttribute>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }
}
