import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { loadCalendly } from '@stockaid/utils';
import { tap } from 'rxjs/operators';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  selector: 'app-nav-left',
  templateUrl: './nav-left.component.html',
  styleUrls: ['./nav-left.component.scss'],
})
export class NavLeftComponent implements OnInit {
  windowWidth: number;
  isHiddenIconCollapse: boolean = false;
  isNavBarCollapsed = false;

  @Input() isSubscriptionExpired: boolean = false;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();

  constructor(private headerService: HeaderService) {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    if (this.windowWidth < 992) {
      this.isHiddenIconCollapse = true;
    }

    const Calendly = (window as any).Calendly;

    loadCalendly(Calendly);

    this.headerService.navbarCollapseSubject$
      .pipe(
        tap((value) => {
          if (!value) {
            return;
          }

          this.isNavBarCollapsed = !this.isNavBarCollapsed;

          if (!this.isNavBarCollapsed && Calendly){
            loadCalendly(Calendly);
          } else {
            Calendly.destroyBadgeWidget();
          }
        })
      )
      .subscribe();
  }

  navCollapse() {
    if (this.isSubscriptionExpired) {
      return;
    }

    this.headerService.setNavbarCollapse(true);
  }
}
