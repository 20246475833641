import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { InjectionToken } from '@angular/core';

export interface IModalOptions<T> extends NgbModalOptions {
  data: Partial<T>;
  size: 'sm' | 'lg' | 'xl';
  onSuccess: (result?: any) => void;
  onError: (err: any) => void;
}

export const MODAL_DATA: InjectionToken<any> = new InjectionToken<any>(
  'MODAL_DATA'
);
