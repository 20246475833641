import {Pipe, PipeTransform} from '@angular/core';
import {MetaDataField} from '../../../../core/infrastructure/classes/meta-data-field';
import { FieldValidatorType } from '../../../../core/infrastructure/enums/field-validator-type.enum';
import { IFieldValidator } from '../../../../core/infrastructure/interfaces/i-field-validator';
import { MinMaxValidator } from '../../../../core/infrastructure/classes/metadataDisplayControls/validators/min-max-validator';

@Pipe({
  name: 'dataGetMax'
})

export class DataGetMaxPipe implements PipeTransform {

  transform(col: MetaDataField): number {
    const minMaxValidator = col?.validators?.find((v: IFieldValidator) => {
      return v.type === FieldValidatorType.MinMax;
    });

    let max = Number.MAX_VALUE;

    if (minMaxValidator) {
      max = (minMaxValidator as MinMaxValidator).max;
    }

    return max;
  }
}
