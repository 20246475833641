export class ShipmentDetail {
  shipmentItemKey: string = '';
  itemKey: string = '';
  restockKey: string = '';
  shipmentId: string = '';
  destinationFulfillmentCenterId: string = '';
  labelType: string = '';
  localQty: number = 1;
  orderQty: number = 0;
  shipmentQty: number = 1;
  isShipByCase: boolean = false;
  whoPreps: string = '';
  caseQty: number = 0;
  cost: number = 0;
  receivedQty: number = 0;
  hasSticker: boolean = true;
  stickerQty: number = 0;
  itemName: string = '';
  description: string = '';
  asin: string = '';
  tags: string [];
  vendorKey: string = '';
  vendorName: string = '';
  fnsku: string = '';
  lotMultipleQty: number = 0;
  s7d: number = 0;
  s30d: number = 0;
  s90d: number = 0;
  mwsFulfillmentFee: number = 0;
  inbound: number = 0;
  onHand: number = 0;
  inboundWorking: number = 0;
  amazonRemaining: number = 0;
  localRemaining: number = 0;
  inboundUnfulfillable: number = 0;
  condition: string = '';
  recommendedQty: number = 0;
  forecastedQty: number = 0;
  openPurchaseOrders: number = 0;
  reserved: number = 0;
  imageUrl: string;
  upc: string;
  ean: string;
  inboundShippingCost: number = 0;
  reshippingCost: number = 0;
  repackagingMaterialCost: number = 0;
  repackingLaborCost: number = 0;
  fba: number = 0;
  nonFba: number = 0;
  lowestFba: number = 0;
  lowestNonFba: number = 0;
  packageWeight: number = 0;
  dimensionalWeight: number = 0;
  hazmat: boolean;
  oversized: boolean;
  average7DayPrice: number = 0;
  listPrice: number = 0;
  newBuyBox: number = 0;
  s60d: number = 0;
  s2d: number = 0;
  s180d: number = 0;
  estimatedMargin: number = 0;
  estimatedMarginPercentage: number = 0;
  estimatedMarkupPercentage: number = 0;
  inboundFcTransfer: number = 0;
  sum: number = 0;
  inboundShipped: number = 0;
  inboundReceiving: number = 0;
  inboundTotal: number = 0;
  category: string = '';
  rank: number = 0;
  referralFee: number = 0;
  fbaFee: number = 0;
  warehouseQty: number = 0;
  isHidden: boolean;
  boxLength: number = 0;
  boxWidth: number = 0;
  boxHeight: number = 0;
  boxWeight: number = 0;
  casePackSuggShip: number = 0;
  casePackSuggReorder: number = 0;
  casePackForecastRecommendedQty: number = 0;

  constructor(init?: Partial<ShipmentDetail>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
