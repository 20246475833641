import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import Rollbar  from 'rollbar';
import { environment } from './../../../environments/environment';

const rollbarConfig = {
  accessToken: environment.rollbarToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
};

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const  RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable({
  providedIn: 'root',
})
export class LoggingService {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  critical(message:string, error?: Error | HttpErrorResponse, additionalInfo?: any ): void {
    this.rollbar.critical(message, error, additionalInfo);
  }

  error(message: string, error?: Error | HttpErrorResponse, additionalInfo?: any): void {
    this.rollbar.error(message, error, additionalInfo);
  }

  warning(message: string, error?: Error | HttpErrorResponse, additionalInfo?: any): void {
    this.rollbar.warning(message, error, additionalInfo);
  }

  info(message: string, additionalInfo?: any): void {
    this.rollbar.info(message, additionalInfo);
  }

  debug(title: string, additionalInfo?: any): void {
    this.rollbar.debug(title, additionalInfo);
  }
}
