export const RESTOCK_MODELS = [
  { display: 'Supplier Ships to FBA', val: 'DIRECT_SHIP' },
  { display: 'Warehouse Ships to FBA ', val: 'LOCAL' },
];

export enum RESTOCK_MODELS_SETTINGS {
  DIRECT_SHIP_GLOBAL = "Supplier Ships to FBA (Global)",
  DIRECT_SHIP_ITEM = "Supplier Ships to FBA (Item)",
  DIRECT_SHIP_SUPPLIER = "Supplier Ships to FBA (Supplier)",
  LOCAL_GLOBAL = "Warehouse Ships to FBA  (Global)",
  LOCAL_ITEM = "Warehouse Ships to FBA  (Item)",
  LOCAL_SUPPLIER = "Warehouse Ships to FBA  (Supplier)",
}
