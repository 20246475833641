import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '../models/company';
import { User } from '../models/user';
import { CacheableResourceService } from './cacheable-resource-service';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends CacheableResourceService<Company> {
  private currentCompany$: Subject<Company> = new ReplaySubject<Company>(1);
  public adminApiUrl = '/api/admin/companies';

  constructor(httpClient: HttpClient) {
    super(httpClient, '/api/company');
  }

  getAssociatedUsers(
    companyKey: string,
    companyType?: string
  ): Observable<User[]> {
    return this.httpClient.get<User[]>(
      `${this.apiUrl}/${companyKey}/users${
        companyType ? '?type=' + companyType : ''
      }`
    );
  }

  getTotalLockedCompanies() {
    return this.httpClient.get<number>(`${this.apiUrl}/in-progress/count`);
  }

  unlockProcessingCompanies() {
    return this.httpClient.put<any>(`${this.apiUrl}/in-progress/mark-unlock`, []);
  }

  unlockProcessingCompany(company: Company) {
    return this.httpClient.put<any>(`${this.apiUrl}/in-progress/${company.companyKey}`, [company]);
  }

  getById(id: any, makeCurrent: boolean = false): Observable<Company> {
    if (!id) {
      return of(null);
    }

    if (makeCurrent) {
      return super.getById(id, makeCurrent).pipe(
        tap((company) => {
          this.cache['currentCompany'] = company;
          this.currentCompany$.next(company);
        })
      );
    } else {
      return super.getById(id);
    }
  }

  updateCompanyInfo(company: Partial<Company>): Observable<Company> {
    return this.httpClient.put<Company>(
      `${this.apiUrl}/${company.companyKey}`,
      company
    );
  }

  lockCompany(company: Company, isLocked: boolean): Observable<Company> {
    return this.httpClient.put<Company>(
      `${this.adminApiUrl}/${company.companyKey}/${company.companyType}/lock`,
      { isLocked }
    );
  }

  currentCompany(): Company {
    return this.cache['currentCompany'];
  }

  getCurrentCompany(): Observable<Company> {
    return this.currentCompany$;
  }

  getNewById(id: any): Observable<Company> {
    return this.httpClient.get<Company>(`${this.apiUrl}/${id}`);
  }

  updateCacheCompany(company: Company): void {
    this.cache['currentCompany'] = company;
    this.currentCompany$.next(company);
  }

  clearCompany(): void {
    this.clearCache();
    this.currentCompany$ = new ReplaySubject<Company>(1);
  }

  deleteCompanyForAdmin(
    { companyKey, companyType }: Company,
    deleteType: string
  ): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.adminApiUrl}/${companyKey}/${companyType}?deleteType=${deleteType}`
    );
  }

  getCompanyByIdForAdmin(companyKey, companyType): Observable<Company> {
    return this.httpClient.get<Company>(
      `${this.adminApiUrl}/${companyKey}/${companyType}`
    );
  }

  setCompanyGridState(state: any) {
    this.cache['companyGridState'] = state;
  }

  getCompanyGridState() {
    return this.cache['companyGridState'];
  }
}
