import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyInprogressComponent } from './company-inprogress.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { CardModule } from '../card/card.module';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    CardModule,
    LoaderModule,
    /*AnimatorModule*/
  ],
  declarations: [CompanyInprogressComponent],
  exports: [CompanyInprogressComponent],
  providers: [/*AnimationService*/]
})
export class CompanyInprogressModule { }
