const tooltips = {
  generic: {
    flag: `Orange - Restock now\nYellow - Restock soon\nRed - Out of stock\nGreen - Well stocked\nTeal - New product`,
    alertDate: 'The Date the flag alert was created',
    status: 'SKU Restock Importance Filter',
    sku: 'Your unique product identifier',
    productName: 'The name of the product',
    asin: "Amazon's product identifier",
    type: 'Product type of Kit or Part (of kit)',
    imageUrl: 'Show Image',
    tag: 'Your custom product tags',
    prepGuide: "Amazon's product prep instructions",
    skuNotes: 'Your custom product notes',
    prepNotes: 'Your custom product prep notes',
    supplier: 'The name of the supplier for this product',
    supplierSku: "The supplier's unique product identifier",
    supplierCost: 'The current supplier cost of the product',
    supplierRebate:
      'Per unit value of any discounts, rebates, or allowances you receive from the supplier. This value is used in your estimated margin calculation. ',
    inboundShippingCost:
      'Your cost to ship this product from your supplier to its destination.',
    reshippingCost:
      'Your cost to ship this product from your warehouse to Amazon',
    repackagingMaterialCost:
      'The cost of materials required to repackage this product before sending it to Amazon',
    repackingLaborCost:
      'The cost of labor required to repackage this product before sending it to Amazon',
    restockModel: 'The method by which you typically replenish this product',
    fnsku: "Amazon's identifier to FBA product",
    upc: 'Universal Product Cost - UPC is a barcode used for tracking products',
    ean: 'European Article Number - EAN is a barcode used for tracking products',
    avg7DPrice:
      'The average price the product has sold for over the past 7 days',
    listPrice: 'The current list price of the product',
    soldBy: 'The Amazon icon displays when Amazon is a seller of this product',
    newBuyBox:
      'The most recent buy box price for this product in new condition.',
    fba: '# FBA sellers offering the product',
    lowestFba: 'The lowest list price by any FBA sellers',
    nonFba: '# non-FBA sellers offering the product.',
    lowestNonFba: 'The lowest list price by any FBA seller',
    packageWeight: 'The weight of the product package in pounds.',
    dimensionalWeight:
      ' The dimensional weight, calculated by Amazon, of the product.',
    casePackQuantity:
      'The number of units that this product must be ordered in from as supplier.',
    hazmat: 'Products that require special hading and storage by Amazon.',
    oversized:
      'Denotes products that must be delivered using special delivery options due to their size, weight, special handing requirements, or other restrictions.',
    s2d: '2D',
    s7d: '7D',
    s30d: '30D',
    s60d: '60D',
    s90d: '90D',
    s180d: '180D',
    em: 'The estimated margin expected from your next sale, based on your ForecastRx price and cost settings.',
    emPercent: 'The estimated margin expected as a percentage.',
    markupPercent: 'The estimated markup expressed as a percentage.',
    category: 'The Amazon product category of the product.',
    rank: "The product's rank within is Amazon product category.",
    referralFee:
      'The fee charged by Amazon every time you sell 1 unit of this product based on your current list price.',
    fbaFee:
      'The additional fees charged by Amazon to pick, pack, and ship a customer order.',
    qoh: 'The total quantity on hand across the Amazon fulfillment center network.',
    inbound:
      'The number of unit included in all shipments on the way to an Amazon fulfillment center.',
    sum: 'Your total Amazon QoH plus inbound units',
    inbWorking:
      "The number of units included in pending shipments with the 'Working' status.",
    inbShipped:
      'The number of units included in shipments that are en route to an Amazon fulfillment center.',
    inbReceiving:
      'The number of units included in shipments that are being received by an Amazon fulfillment center.',
    inboundTotal:
      'The total number of units included in all inbound shipments.',
    targetDays:
      'The maximum number of days of inventory coverage you would like to keep at Amazon',
    remaining:
      'The number of days of inventory coverage remaining at Amazon given your current Average Daily Sales Rate.',
    demand:
      'The forecasted sales per day for this product given our current Average Daily Sales Rate.',
    outOfStockPercent:
      'The percentage of time this product has been out stock.',
    reserved:
      'Number of units Amazon has set aside for customer orders, FC transfer, or FC processing.',
    unfulfillable: "Amazon's reported # of damaged or returned units.",
    pending: 'Number of units included in pending Amazon customer orders.',
    localQty: 'Inventory you have outside of Amazon FBA and FBM.',
    maximumShipmentQty:
      'ASIN-level quantity limit for products stored in Amazon fulfillment centers.',
    suggShip:
      'The number of units ForecastRx suggests shipping from your warehouse inventory to Amazon.',
    suggReorder:
      'The number of units ForecastRx suggests reordering from your supplier.',
    onOrder: 'The number of units on order from your supplier',
    restockNeeded:
      'Ideal Max Quantity on Hand minus Amazon QoH.',
    orderQty: 'The number of units entered for an in progress purchase order.',
    outOfStockPercentage: '% of time out of stock over target days',
    doNotRestock: 'Hides from your restock recommendations',
    forecastRecommendedQty: 'Recommended qty based on forecast models only',
    recommendedSupplierQty: 'The number of units ForecastRx suggests reordering from your supplier.',
    recommendedWarehouseQty: 'The number of units ForecastRx suggests shipping from your warehouse inventory to Amazon.',
    casePackForecastRecommendedQty: 'Recommended qty based on forecast models only',
    casePackSuggReorder: 'The number of units ForecastRx suggests reordering from your supplier.',
    casePackSuggShip: 'The number of units ForecastRx suggests shipping from your warehouse inventory to Amazon.',
    forecastedQty: 'Recommended qty based on forecast models only',
    recommendedQty: 'The number of units ForecastRx suggests shipping from your warehouse inventory to Amazon.',
    itemVolume: 'Storage volume per unit',
    storageVolume: 'Total usage for this product in fulfillment centers minus inventory pending removal',
    storageType: 'FBA storage type',
    historicalDaysOfSupply: 'Ratio of avg. on hand sellable units to avg. daily shipped units by parent ASIN'
  },
};

export function getFieldGroupTip(field: string) {
  let tip = tooltips.generic[field];
  return tip || '';
}

export enum ItemStatus {
  ACTIVE = 'ACTIVE',
  WATCH = 'WATCH',
  IGNORE = 'IGNORE',
  INACTIVE = 'INACTIVE',
}

export enum FlagName {
  ORANGE = 'Restock now',
  YELLOW = 'Restock soon',
  RED = 'Out of Stock',
  TEAL = 'New',
  GREEN = 'Well stocked'
}

export enum ConditionOperator {
  AND = 'AND',
  OR = 'OR',
}

export interface ShipmentDropDown {
  text: string;
  value: string;
}
