<div
  #historyView
  class="view-of-history"
  *ngIf="choosenItem"
  [class.disable-view]="isLoadingAdd"
>
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5 class="text-white">History</h5>
      <div class="view-of-history__item-name">{{ choosenItem.name }}</div>

      <div
        class="view-of-history__export d-flex align-items-center justify-content-center"
      >
        <button
          class="k-button btn btn-sm btn-primary"
          (click)="onExportPdf()"
          [disabled]="currentTabsExport?.length === 0 || isLoadingAdd"
        >
          <i class="fas fa-download"></i> Export PDF:
          {{ currentTabsExport?.length || 0 }} table(s)
        </button>
        <span
          (click)="onRefreshTable()"
          [class.hide]="currentTabsExport?.length === 0 || isLoadingAdd"
          ><i
            [ngbTooltip]="REFRESH_TOOLTIP"
            placement="right"
            class="view-of-history__export__icon-refresh feather icon-refresh-cw"
          ></i
        ></span>
      </div>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card">
      <ul ngbNav #nav="ngbNav" class="nav-tabs view-of-history__tabs">
        <li [ngbNavItem]="'direct'">
          <a ngbNavLink (click)="changeTab(HistoryTabs.DirectSales)"
            >Direct Sales</a
          >
          <ng-template ngbNavContent>
            <app-history-data-table-reduced
              [isLoading]="isLoading"
              [columns]="columns"
              [legends]="legends"
              [sort]="sort"
              [colorMatrix]="colorMatrix"
              [gridItems$]="gridItems$"
              [chartData]="chartData"
              (sortChange)="sortChange($event)"
              (cellClick)="cellClickHandler($event)"
              (cellClose)="cellCloseHandler($event)"
            ></app-history-data-table-reduced>
          </ng-template>
        </li>

        <li [ngbNavItem]="'kit'">
          <a ngbNavLink (click)="changeTab(HistoryTabs.KitSales)">Kit Sales</a>
          <ng-template ngbNavContent>
            <app-history-data-table-reduced
              [isLoading]="isLoading"
              [columns]="columns"
              [legends]="legends"
              [sort]="sort"
              [colorMatrix]="kitColorMatrix"
              [gridItems$]="gridItems$"
              [chartData]="chartData"
              (sortChange)="sortChange($event)"
            ></app-history-data-table-reduced>
          </ng-template>
        </li>

        <li [ngbNavItem]="'total'">
          <a ngbNavLink (click)="changeTab(HistoryTabs.TotalSales)"
            >Total Sales</a
          >
          <ng-template ngbNavContent>
            <app-history-data-table-reduced
              [isLoading]="isLoading"
              [columns]="columns"
              [legends]="legends"
              [sort]="sort"
              [colorMatrix]="kitColorMatrix"
              [gridItems$]="gridItems$"
              [chartData]="chartData"
              (sortChange)="sortChange($event)"
            ></app-history-data-table-reduced>

            <ngb-accordion
              *ngIf="additionalItems.length > 0"
              activeIds="panel-0"
            >
              <ngb-panel id="panel-0">
                <ng-template ngbPanelHeader let-opened="opened">
                  <button
                    ngbPanelToggle
                    class="btn btn-primary container-fluid text-left pl-0"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    Additional Demand Sources
                  </button>
                </ng-template>

                <ng-template ngbPanelContent>
                  <app-generic-kendo-grid
                    *ngIf="additionalItems"
                    [columns]="additionalColumns"
                    [gridItems]="additionalItems"
                  ></app-generic-kendo-grid>
                </ng-template>
              </ngb-panel>
            </ngb-accordion>
          </ng-template>
        </li>
      </ul>

      <div
        class="view-of-history__menu d-flex justify-content-end align-items-center mt-2 mr-4"
      >
        <!-- Backfill switch -->
        <div
          class="view-of-history__switch"
          [class.view-of-history__switch--disabled]="
            isBackfillDisabled && !choosenItem.useBackfill
          "
          *ngIf="isSwitchVisible"
          [ngbTooltip]="backfillInfo"
          placement="bottom"
          closeDelay="300"
        >
          <span>Backfill Sales</span>
          <label>
            <input
              type="checkbox"
              [checked]="choosenItem.useBackfill"
              [disabled]="isBackfillDisabled && !choosenItem.useBackfill"
              (click)="toggleBackfill()"
            />
            <span class="slider slider-backfillSales round"></span>
          </label>
        </div>

        <!-- Overrides switch -->
        <div
          class="view-of-history__switch"
          [class.view-of-history__switch--disabled]="isOverrideDisabled"
          *ngIf="isSwitchVisible"
          [ngbTooltip]="overrideInfo"
          placement="bottom"
          closeDelay="300"
        >
          <span>Apply Overrides</span>
          <label>
            <input
              type="checkbox"
              [checked]="choosenItem.useHistoryOverride"
              [disabled]="isOverrideDisabled"
              (click)="toggleOverrides()"
            />
            <span class="slider slider-applyOverrides round"></span>
          </label>
        </div>

        <!-- Lost Sales switch -->
        <div
          class="view-of-history__switch"
          [class.view-of-history__switch--disabled]="isLostSalesSwitchDisabled"
          *ngIf="isSwitchVisible && isAscCompany"
          [ngbTooltip]="lostSalesInfo"
          placement="bottom"
          closeDelay="300"
        >
          <span>Include Lost Sales</span>
          <label>
            <input
              type="checkbox"
              [checked]="choosenItem.useLostSalesOverride"
              [disabled]="isLostSalesSwitchDisabled"
              (click)="toggleLostSales()"
            />
            <span class="slider slider-includeLostSales round"></span>
          </label>
        </div>

        <button
          class="btn btn-sm btn-primary"
          (click)="onAddRemovePdf()"
          [disabled]="isLoadingAdd || isDisabledAdd"
        >
          <span
            class="spinner-border spinner-border-sm text-light alig"
            *ngIf="isLoadingAdd"
          ></span>
          <i class="fas fa-plus" *ngIf="!isLoadingAdd" aria-hidden="true"></i>
          Add to export PDF
        </button>

        <div *ngIf="currentTab === HistoryTabs.DirectSales" ngbDropdown dropdownClass="custom-dropdown" container="body" class="d-inline-block">
          <button
            class="mx-1 btn btn-sm btn-outline-primary"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <span
              *ngIf="isLoading"
              title="Please wait while the application is uploading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            >
            </span>
            Bulk Management
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button class="sub-menu" ngbDropdownItem (click)="downloadInitialTemplate()">
              Download Template
            </button>

            <div ngbDropdown container="body" class="my-1">
              <button
                class="btn btn-outline-primary d-flex justify-content-between align-items-center sub-menu"
                id="dropdownBasic2"
                ngbDropdownToggle
              >
                Edit as overrides
              </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Override, UploadHistoryAmountType.Single)">
                  Single Item
                </button>
                <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Override, UploadHistoryAmountType.Multiple)">
                  Multiple Items
                </button>
              </div>
            </div>

            <div ngbDropdown container="body" class="my-1">
              <button
                class="btn btn-outline-primary d-flex justify-content-between align-items-center sub-menu"
                id="dropdownBasic3"
                ngbDropdownToggle
              >
                Add to existing history
              </button>

              <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
                <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Add, UploadHistoryAmountType.Single)">
                  Single Item
                </button>
                <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Add, UploadHistoryAmountType.Multiple)">
                  Multiple Items
                </button>
              </div>
            </div>

            <button
              *ngIf="remainingUndoTime > 0"
              class="sub-menu"
              ngbDropdownItem
              (click)="undoLastUpload()"
            >
              Undo Last Upload ({{remainingUndoTime}} hrs left)
            </button>
          </div>
        </div>
      </div>

      <div [ngbNavOutlet]="nav" class="m-2"></div>
    </div>
  </div>
</div>
