<div class="edit-tags-modal">
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5 class="text-white">Edit Tags</h5>
      <h4 class="text-white">
        {{ title }}
      </h4>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card edit-tags-modal__card">
      <p>Type in tags for {{ multipleEditTags ? 'multiple items' : 'this item'}}, press Enter to separate tag</p>
      <div
        class="edit-tags-modal__card__control"
        [class.edit-tags-modal__card__control--focus]="isFocus"
      >
        <kendo-chip
          *ngFor="let tag of tags"
          [label]="tag"
          [removable]="true"
          (remove)="deleteTag($event)"
          [class.k-chip-duplicate]="tagForm?.invalid && tagName.value === tag"
        >
        </kendo-chip>
        <form [formGroup]="tagForm" class="edit-tags-modal__card__form">
          <input
            (focus)="toggleBorder(true)"
            (blur)="toggleBorder(false)"
            formControlName="tags"
            type="text"
            id="tagForm"
            class="edit-tags-modal__card__control--input"
            placeholder="Add new tag"
            (keyup.enter)="addNewTag()"
          />
        </form>
      </div>
      <div
        *ngIf="tagForm.get(RestockSuggestionItemField.tags)?.hasError('invalidTag')"
        class="text-danger text-small edit-tags-modal__card--text-error"
      >
        Please enter a tag that is not the same tag you already have.
      </div>
      <button
        class="btn btn-outline-primary edit-tags-modal__card--clear-all border-0"
        (click)="clearAllTags()"
      >
        <i class="fas fa-times"></i>&nbsp;&nbsp;Clear Tags
      </button>
    </div>
  </div>
  <button
    type="button"
    class="btn btn-primary mr-4 edit-tags-modal__btn--save"
    (click)="saveTags()"
  >
    Save changes
  </button>
</div>
