import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  LostSalesOverride,
  ILostSalesOverrideResponse,
} from '../models/lostsales-override';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LostSalesOverrideService {
  constructor(private httpClient: HttpClient) {}

  apiUrl = '/api/lostsales-override';

  getLostSalesOverride(itemKey: string): Observable<LostSalesOverride[]> {
    return this.httpClient
      .get<ILostSalesOverrideResponse>(`${this.apiUrl}?id=${itemKey}`)
      .pipe(map((ILostSalesOverride) => ILostSalesOverride.model));
  }

  save(itemKey: string, lostsales: LostSalesOverride[]): Observable<any> {
    const requestBody = {
      key: itemKey,
      rows: lostsales,
    };

    return this.httpClient.put<any>(`${this.apiUrl}`, requestBody);
  }
}
