import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@stockaid/core';
import { ItemField, RestockSuggestionItemField } from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { ChipRemoveEvent } from '@progress/kendo-angular-buttons';
import { ResourceService } from 'src/app/core/services/resource-service';
import { GridName } from '../forecast-rxdata-table/forcast-rxdata-table.constant';
import { ItemService } from 'src/app/core/services/item.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Item } from 'src/app/core/models/item';

@Component({
  selector: 'app-edit-tags-modal',
  templateUrl: './edit-tags-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./edit-tags-modal.component.scss'],
})
export class EditTagsModalComponent<T> extends BaseComponent implements OnInit {
  @Input() selectedTagRows: any[];
  @Input() selectedKeyTag: any;
  @Input() resourceService: ResourceService<T>;
  @Input() itemName: string = '';
  @Output() saveTagClicked = new EventEmitter();

  multipleEditTags: boolean = false;
  title:string = 'Multiple Items';
  tags: string[] = [];
  tagForm: FormGroup;
  isFocus: boolean = false;
  readonly RestockSuggestionItemField = RestockSuggestionItemField;

  public get tagName(): AbstractControl {
    return this.tagForm.controls[RestockSuggestionItemField.tags];
  }

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private itemService: ItemService
  ) {
    super();
    this.createForm();
  }

  ngOnInit() {
    if (this.selectedTagRows?.length >= 2) {
      this.multipleEditTags = true;
    } else {
      this.tags = [...this.selectedTagRows[0][RestockSuggestionItemField.tags]];
    }

    switch(this.itemName){
      case GridName.Item:
        this.title = !this.multipleEditTags ? this.selectedTagRows[0][ItemField.name] : this.title;
        break;
      case GridName.RestockSuggestion:
        this.title = !this.multipleEditTags ? this.selectedTagRows[0][RestockSuggestionItemField.sku] : this.title;
        break;
      default:
    }
  }

  createForm(): void {
    this.tagForm = this.fb.group({
      tags: ['', [this.checkDuplicateTags()]],
    });
  }

  checkDuplicateTags(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }

      return !this.tags.includes(value) ? null : { invalidTag: true };
    };
  }

  deleteTag(e: ChipRemoveEvent): void {
    const indexTag = this.tags.indexOf(e.sender?.label);
    this.tags.splice(indexTag, 1);
  }

  toggleBorder(boolean: boolean): void {
    this.isFocus = boolean;
  }

  clearAllTags(): void {
    this.tags = [];
  }

  addNewTag(): void {
    if (this.tagName.value === '' || this.tagForm.invalid) {
      return;
    }
    this.tags.push(this.tagName.value);
    this.tagName.setValue('');
  }

  saveTags(): void {
    const tags = this.tags;
    this.selectedTagRows = this.selectedTagRows.map((row) => ({
      ...row,
      tags: tags,
    }));
    let saveItems = this.selectedTagRows;

    let $update: Observable<any>;

    switch (this.itemName) {
      case GridName.RestockSuggestion:
        $update = this.updateRestockSuggestionSection(Object.keys(this.selectedKeyTag))
        break;
      case GridName.Item:
        $update = this.updateItemByMultipleIds(saveItems)
        break;
      default:
        $update = this.resourceService
          .updateMultiple(saveItems)
          .pipe(this.autoCleanUp())
    }

    $update.subscribe(() => {
      this.saveTagClicked.emit(saveItems);
      this.activeModal.close();
    })
  }

  updateRestockSuggestionSection(ids: string[]): Observable<any> {
    const tags = this.tags;
    return this.itemService.getByMultipleIds(ids).pipe(
      this.autoCleanUp(),
      map((dataItems) => dataItems.map(dataItem => ({...dataItem, tags:tags}))),
      switchMap((dataItems) => this.updateItemByMultipleIds(dataItems))
    );
  }

  updateItemByMultipleIds(dataItems: Item[]): Observable<any> {
    return this.itemService.updateMultiple(dataItems).pipe(this.autoCleanUp());
  }
}
