<div>
  <kendo-chart>
    <!-- Chart area, includes title, legends and data -->
    <kendo-chart-area height="300"></kendo-chart-area>

    <!-- Chart legend -->
    <kendo-chart-legend position="bottom" orientation="horizontal">
    </kendo-chart-legend>

    <!-- Chart data -->
    <kendo-chart-series>
      <kendo-chart-series-item
        *ngFor="let legend of legends; let i = index"
        [name]="legend"
        type="line"
        field="qty"
        categoryField="month"
        [data]="chartData[i]"
      >
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value">
            {{legend}}: {{value}}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>

  <kendo-grid
    class="grid-history-data-table-reduced"
    [data]="gridItems$ | async"
    [sortable]="true"
    [sort]="sort"
    [height]="200"
    (sortChange)="sortChangeFunc($event)"
    (cellClick)="cellClickFunc($event)"
    (cellClose)="cellCloseFunc($event)"
    [resizable]="true"
    [navigatable]="true"
  >
    <kendo-grid-column
      *ngFor="let col of columns"
      [field]="col.field"
      [title]="col.displayName"
      [width]="col.field === 'legend' ? 120 : 70"
      class="text-center px-1 py-1"
    >
      <ng-template
        kendoGridEditTemplate
        let-formGroup="formGroup"
        let-column="column"
        let-isNew="isNew"
      >
        <input
          type="number"
          class="k-textbox"
          [formControl]="formGroup.get(col.field)"
        />

        <div
          *ngIf="
            formGroup.get(col.field).invalid &&
            !(isNew && formGroup.get(col.field).untouched)
          "
          class="text-danger text-small"
        >
          <span *ngIf="formGroup.get(col.field).hasError('min')">
            <small>Min is 0.</small>
          </span>
        </div>
      </ng-template>

      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
        let-columnIndex="columnIndex"
      >
        <span
          class="text-center d-block"
          [style.backgroundColor]="
            col.field === 'legend'
              ? 'transparent'
              : processColor(colorMatrix[rowIndex][columnIndex - 1])
          "
          [style.color]="col.field === 'legend' ? 'black' : 'white'"
          [style.fontWeight]="col.field === 'legend' ? 400 : 600"
        >
          {{ dataItem[col.field] }}
        </span>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
