
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource-service';
import { ItemSalesVelocitySettings } from '../models/item'
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';

@Injectable ({
  providedIn: 'root',
})

export class ItemSalesVelocitySettingsService extends ResourceService<ItemSalesVelocitySettings>{
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/item-sales-velocity-settings');
  }

  getByIdWithType(
    itemKey: string,
    salesVelocitySettingsType: SalesVelocitySettingsType = SalesVelocitySettingsType.restockAMZ
  ): Observable<ItemSalesVelocitySettings> {
    return this.httpClient.get<ItemSalesVelocitySettings>(
      `${this.apiUrl}/${encodeURIComponent(itemKey)}/${salesVelocitySettingsType}`
    );
  }
}
