// Headers
export enum TimePeriod {
  _2_day = '2-day',
  _7_day = '7-day',
  _14_day = '14-day',
  _30_day = '30-day',
  _60_day = '60-day',
  _90_day = '90-day',
  _180_day = '180-day',
  forecastedDemand = 'ForecastRx Demand',
  totalUnitsDay = 'Units/day'
}

export enum CoverageRequired {
  orderInterval = 'Order Interval',
  targetQty = 'Ideal FBA Inventory',
  supplierLeadTime = 'Supplier/Item Lead Time',
  localLeadTime = 'Warehouse Lead Time',
  additionalInventory = 'Additional Inventory',
  total = 'Total',
}

export enum CoverageAvailable {
  onHand = 'Amazon Inventory',
  onHandAlt = 'On Hand Qty',
  local = 'Warehouse Inventory',
  awdInventory = 'AWD Inventory',
  order = 'On Order',
  total = 'Total',
}

export enum AdjustSwitchType {
  adjustedDailySalesRate = 'adjustedDailySalesRate',
  autoAdjustForOutOfStock = 'autoAdjustForOutOfStock'
}
