<div
  #salveVelocity
  class="sale-velocity-cal"
  *ngIf="choosenItem"
  [class.disable-view]="isLoadingAdd"
>
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5 class="text-white sale-velocity-modal-title">{{ modalTitle }}</h5>
      <h5 class="text-white sale-velocity-modal-des">{{ itemName }}</h5>

      <button
        *ngIf="isPurchasing && isChildItem"
        type="button"
        class="btn mx-1 my-3"
        [ngClass]="
          isTotalComponentDataOn ? 'btn-total-component' : 'btn-total-component--off'
        "
        aria-label="Total Component Data"
        (click)="handleTotalComponentDataClick($event)"
        ngbTooltip="Toggle on for sales/inventory to reflect total usage of the component and turn off for SKU only data"
        placement="bottom"
        container="body"
      >
        Total Component Data: {{ isTotalComponentDataOn ? 'ON' : 'OFF' }}
      </button>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.close(updatedDailySalesRate)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="showForecastPopup" class="text-center">
      <h3 class="text-white">
        Calculation information is not available for this Item.
      </h3>
      <h5 class="text-white">
        Please run a FORECAST to summarize all available information.
      </h5>
      <button class="btn btn-danger" (click)="openForecastModal()">
        Open Forecast Modal
      </button>
    </div>

    <div *ngIf="restockSuggestion" class="card p-4">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="accordion accordion-flush" id="accordionAvg">
            <div class="accordion-item">
              <h2 class="accordion-header bg-white" id="flush-headingAvg">
                <button
                  class="accordion-button accordion-item-toggle border-0 collapsed text-decoration-none bg-white text-center py-2 px-2 w-100"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseAvg"
                  aria-expanded="false"
                  aria-controls="flush-collapseAvg"
                  (click)="toogleAverageDailySalesRate()"
                >
                  <div
                    class="d-flex justify-content-between align-items-center w-100 text-decoration-none"
                  >
                    <span class="toogle-title"> Average Daily Sales Rate </span>
                    <span
                      class="d-flex justify-end align-center align-items-center"
                    >
                      <div>
                        <i
                          *ngIf="isOpenToogleAverageDailySalesRate"
                          class="fas fa-angle-up"
                        ></i>
                        <i
                          *ngIf="!isOpenToogleAverageDailySalesRate"
                          class="fas fa-angle-down"
                        ></i>
                      </div>
                    </span>
                  </div>
                </button>
                <hr *ngIf="isOpenToogleAverageDailySalesRate" class="mt-0" />
              </h2>
              <div
                id="flush-collapseAvg"
                class="accordion-collapse collapse mt-2"
                aria-labelledby="flush-headingAvg"
                data-bs-parent="#accordionAvg"
              >
                <div
                  *ngIf="SALES_VELOCITY_TABLE_DATA"
                  class="accordion-body bg-white pe-0 ps-0 d-flex justify-center"
                >
                  <div class="d-flex justify-center">
                    <table
                      class="table table-bordered bg-white table-responsive w-100 bg-white"
                      aria-describedby
                    >
                      <thead class="text-center bg-white">
                        <tr>
                          <th
                            *ngFor="
                              let header of SALES_VELOCITY_TABLE_DATA.headers;
                              index as i
                            "
                            class="text-start position-relative text-secondary bg-white"
                          >
                            <div class="text-center w-100">
                              <div *ngIf="i === 0 && restockSuggestion.salesVelocityType !== SALES_VELOCITY_SETTINGS.dateRange && isCompanyTypeASC && !isTotalComponentDataOn" class="position-relative mb-1">
                                <div>Use adj. daily sales rate for calculation</div>

                                <div class="switch switch-success d-inline">
                                  <input
                                    type="checkbox"
                                    id="switch-p-1"
                                    [checked]="isUsingAdjustedDailySalesRate"
                                    [disabled]="isSaving"
                                    [dataTestID]="'adjusted-daily-sales-rate-switch'"
                                    (click)="handleAdjSwitchClick(AdjustSwitchType.adjustedDailySalesRate)"
                                  />
                                  <label for="switch-p-1" class="cr sale-velocity-cal__switch"></label>
                                </div>
                              </div>

                              <div *ngIf="i === 3 && restockSuggestion.salesVelocityType !== SALES_VELOCITY_SETTINGS.dateRange && isCompanyTypeASC && !isTotalComponentDataOn" class="position-relative mb-1">
                                <div>Distribute daily sales rate weights to in stock periods</div>

                                <div class="d-flex justify-content-center align-items-center">
                                  <div class="switch switch-success d-inline mr-1">
                                    <input
                                      type="checkbox"
                                      id="switch-p-2"
                                      [checked]="isAdjustingDailySalesRateForOutOfStock"
                                      [dataTestID]="'adjusting-daily-sales-rate-for-out-of-stock-switch'"
                                      [disabled]="isAdjustingOutOfStockSwitchDisabled || isSaving"
                                      (click)="handleAdjSwitchClick(AdjustSwitchType.autoAdjustForOutOfStock)"
                                    />
                                    <label for="switch-p-2" class="cr sale-velocity-cal__switch"></label>
                                  </div>
                                  <span
                                    class="m-r-2"
                                    ngbTooltip="Adjust daily sales rate weights automatically for out-of-stock periods"
                                    placement="bottom"
                                  >
                                    <i
                                      class="fas fa-info-circle cursor-pointer"
                                    ></i>
                                  </span>
                                </div>
                              </div>

                              <span class="text-center align-center">
                                {{ header }}
                              </span>
                              <span *ngIf="i === 0 && isCompanyTypeASC && !isTotalComponentDataOn" placement="bottom" ngbTooltip="Days in this period out of stock" container="body" class="text-danger">
                                (Days OOS)
                              </span>
                              <span
                                class="m-r-2"
                                ngbTooltip="Daily sales rate adjusted to exclude days out of stock"
                                [placement]="restockSuggestion.salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange ? 'left' : 'bottom'"
                                [tooltipClass]="restockSuggestion.salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange ? 'adjusted-tool-tip' : null"
                                container="body"
                                *ngIf="header === 'Adj. Daily Sales Rate'"
                              >
                                <i
                                  class="fas fa-info-circle cursor-pointer"
                                ></i>
                              </span>
                              <div
                                *ngIf="
                                  restockSuggestion.salesVelocityType !== SALES_VELOCITY_SETTINGS.dateRange
                                "
                              >
                                <span
                                  *ngIf="isCompanyTypeASC && !isTotalComponentDataOn ? i === 2 : i === 1"
                                  class="position-absolute fs-2 text-primary sale-velocity-cal__operator"
                                  [class.bottom-0]="isCompanyTypeASC && !isTotalComponentDataOn"
                                  [class.sale-velocity-cal__operator--compact]="!isCompanyTypeASC || isTotalComponentDataOn"
                                  >&times;</span
                                >
                                <span
                                  *ngIf="isCompanyTypeASC && !isTotalComponentDataOn ? i === 3 : i === 2"
                                  class="position-absolute fs-2 text-primary sale-velocity-cal__operator"
                                  [class.bottom-0]="isCompanyTypeASC && !isTotalComponentDataOn"
                                  [class.sale-velocity-cal__operator--compact]="!isCompanyTypeASC || isTotalComponentDataOn"
                                  >=</span
                                >
                              </div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let data of SALES_VELOCITY_TABLE_DATA.data;
                            index as i
                          "
                        >
                          <td>
                            <div class="text-center">
                              {{ data.displayName }}

                              <span *ngIf="isCompanyTypeASC && !isTotalComponentDataOn" placement="bottom" class="text-danger" ngbTooltip="Days in this period out of stock">
                                {{data.outOfStockDays}}
                              </span>
                            </div>
                          </td>

                          <ng-template [ngIf]="isUsingAdjustedDailySalesRate && !isTotalComponentDataOn">
                            <td class="text-right ng-binding">
                              {{ data.salesVelo }}
                            </td>

                            <td class="text-right ng-binding">
                              {{ data.adjVelo }}
                            </td>
                          </ng-template>

                          <ng-template [ngIf]="!isUsingAdjustedDailySalesRate || isTotalComponentDataOn">
                            <td *ngIf="isCompanyTypeASC && !isTotalComponentDataOn" class="text-right ng-binding">
                              {{ data.adjVelo }}
                            </td>

                            <td class="text-right ng-binding">
                              {{ data.salesVelo }}
                            </td>
                          </ng-template>

                          <td
                            *ngIf="
                              SALES_VELOCITY_TABLE_DATA.headers?.length > 3
                            "
                            class="text-center ng-binding"
                          >
                            {{ data.weight }}
                          </td>

                          <td
                            *ngIf="
                              SALES_VELOCITY_TABLE_DATA.headers?.length > 3
                            "
                            class="text-center ng-binding"
                            [class.custom-border-top]="
                              i == SALES_VELOCITY_TABLE_DATA.data.length - 1
                            "
                          >
                            {{ data.weighted }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-0" />
          <p class="text-center mb-1 fs-2 text-secondary">
            {{ getRestockSuggestionValue('demand') }}
            {{ isMany(getRestockSuggestionValue('demand')) ? "units/day" : "unit/day" }}
          </p>
          <p class="text-center text-secondary">based on Average or Date Range</p>
        </div>
        <div class="col-md-3 col-sm-10">
          <div class="accordion accordion-flush" id="accordionReq">
            <div class="accordion-item">
              <h2 class="accordion-header bg-white" id="flush-headingReq">
                <button
                  class="accordion-button accordion-item-toggle text-decoration-none collapsed bg-white text-center py-2 px-2 border-0 w-100"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseReq"
                  aria-expanded="false"
                  aria-controls="flush-collapseReq"
                  (click)="toogleRequiredInventory()"
                >
                  <div
                    class="d-flex justify-content-between align-items-center w-100"
                  >
                    <span class="toogle-title"> Required Inventory </span>
                    <span
                      class="d-flex justify-end align-center align-items-center"
                    >
                      <div>
                        <i
                          *ngIf="isOpenToogleRequiredInventory"
                          class="fas fa-angle-up"
                        ></i>
                        <i
                          *ngIf="!isOpenToogleRequiredInventory"
                          class="fas fa-angle-down"
                        ></i>
                      </div>
                    </span>
                  </div>
                </button>
                <hr *ngIf="isOpenToogleRequiredInventory" class="mt-0" />
              </h2>
              <div
                id="flush-collapseReq"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingReq"
                data-bs-parent="#accordionReq"
              >
                <div
                  *ngIf="COVERAGE_REQUIRED_TABLE_DATA"
                  class="accordion-body bg-white pe-0 ps-0 w-100"
                >
                  <table class="table table-bordered table-responsive w-100 bg-white" aria-describedby>
                    <thead>
                      <tr>
                        <th
                          *ngFor="
                            let header of COVERAGE_REQUIRED_TABLE_DATA.headers
                          "
                          class="text-secondary text-center bg-white"
                        >
                          {{ header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let data of COVERAGE_REQUIRED_TABLE_DATA.data"
                      >
                        <td *ngIf="!data.isTotal">
                          {{ data.displayName }}
                        </td>
                        <td *ngIf="!data.isTotal" class="text-right ng-binding text-center">
                          {{ data.days }}
                        </td>
                        <td *ngIf="!data.isTotal" class="text-right ng-binding text-center">
                          {{ data.units }}
                        </td>

                        <ng-container *ngIf="data.isTotal">
                          <td class="font-weight-bold text-uppercase text-secondary">
                            {{ data.displayName }}
                          </td>
                          <td class="ng-binding font-weight-bold text-center text-secondary">
                            {{
                              getTotal(
                                COVERAGE_REQUIRED_TABLE_DATA.data,
                                "days"
                              )
                            }}
                          </td>
                          <td class="ng-binding font-weight-bold text-center text-secondary">
                            {{
                              getTotal(
                                COVERAGE_REQUIRED_TABLE_DATA.data,
                                "units"
                              )
                            }}
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-0" />
          <p class="text-center mb-1 fs-2 text-secondary">
            {{ getTotal(COVERAGE_REQUIRED_TABLE_DATA.data, "units") }}
            {{
              isMany(getTotal(COVERAGE_REQUIRED_TABLE_DATA.data, "units"))
                ? "units"
                : "unit"
            }}
          </p>
          <p class="text-center text-secondary">
            {{ getTotal(COVERAGE_REQUIRED_TABLE_DATA.data, "days") }}
            {{
              isMany(getTotal(COVERAGE_REQUIRED_TABLE_DATA.data, "days"))
                ? "days'"
                : "day's"
            }}
            worth
          </p>
        </div>
        <div class="col-md-3 col-sm-10">
          <div class="accordion accordion-flush" id="accordionInv">
            <div class="accordion-item">
              <h2 class="accordion-header bg-white" id="flush-headingInv">
                <button
                  class="accordion-button accordion-item-toggle text-decoration-none collapsed bg-white text-center py-2 px-2 border-0 w-100"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseInv"
                  aria-expanded="false"
                  aria-controls="flush-collapseInv"
                  (click)="toogleInventoryAvailable()"
                >
                  <div
                    class="d-flex justify-content-between align-items-center w-100"
                  >
                    <span class="toogle-title"> Inventory Available </span>
                    <span
                      class="d-flex justify-end align-center align-items-center"
                    >
                      <div>
                        <i
                          *ngIf="isOpenToogleInventoryAvailable"
                          class="fas fa-angle-up"
                        ></i>
                        <i
                          *ngIf="!isOpenToogleInventoryAvailable"
                          class="fas fa-angle-down"
                        ></i>
                      </div>
                    </span>
                  </div>
                </button>
                <hr *ngIf="isOpenToogleInventoryAvailable" class="mt-0" />
              </h2>
              <div
                id="flush-collapseInv"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingInv"
                data-bs-parent="#accordionInv"
              >
                <div
                  *ngIf="COVERAGE_AVAILABLE_TABLE_DATA"
                  class="accordion-body bg-white pe-0 ps-0"
                >
                  <table class="table table-bordered table-responsive bg-white" aria-describedby>
                    <thead>
                      <tr>
                        <th
                          *ngFor="
                            let header of COVERAGE_AVAILABLE_TABLE_DATA.headers
                          "
                          class="text-secondary bg-white"
                        >
                          {{ header }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let data of COVERAGE_AVAILABLE_TABLE_DATA.data"
                      >
                        <ng-container *ngIf="!data.isHidden">
                          <td class="d-flex justify-content-between align-items-center" *ngIf="!data.isTotal">
                            {{ data.displayName }}
                            <span
                              *ngIf="data.tooltip"
                              class="m-r-2"
                              [ngbTooltip]="data.tooltip"
                              placement="bottom"
                            >
                              <i
                                class="fas fa-info-circle cursor-pointer"
                              ></i>
                            </span>
                          </td>
                          <td *ngIf="!data.isTotal" class="ng-binding text-center">
                            {{ data.days }}
                          </td>
                          <td *ngIf="!data.isTotal" class="ng-binding text-center">
                            {{ data.units }}
                          </td>
                        </ng-container>
                        <ng-container *ngIf="data.isTotal">
                          <td class="font-weight-bold text-uppercase">
                            <span class="text-secondary">
                              {{ data.displayName }}
                            </span>
                          </td>
                          <td class="text-center ng-binding font-weight-bold">
                            <span class="text-secondary">
                              {{
                                getTotal(
                                  COVERAGE_AVAILABLE_TABLE_DATA.data,
                                  "days"
                                )
                              }}
                            </span>
                          </td>
                          <td class="text-center ng-binding font-weight-bold">
                            <span class="text-secondary">
                              {{
                                getTotal(
                                  COVERAGE_AVAILABLE_TABLE_DATA.data,
                                  "units"
                                )
                              }}
                            </span>
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <hr class="mt-0" />
          <p class="text-center mb-1 fs-2 text-secondary">
            {{ getTotal(COVERAGE_AVAILABLE_TABLE_DATA.data, "units") }}
            {{
              isMany(getTotal(COVERAGE_AVAILABLE_TABLE_DATA.data, "units"))
                ? "units"
                : "unit"
            }}
          </p>
          <p class="text-center text-secondary">
            {{ getTotal(COVERAGE_AVAILABLE_TABLE_DATA.data, "days") }}
            {{
              isMany(getTotal(COVERAGE_AVAILABLE_TABLE_DATA.data, "days"))
                ? "days'"
                : "day's"
            }}
            worth
          </p>
        </div>
      </div>

      <div *ngIf="SUGGESTED_DATA" class="row mt-4 d-flex justify-around">
        <div
          *ngFor="let suggested of SUGGESTED_DATA"
          class="col-md-6 col-sm-12"
          [class.w-100]="SUGGESTED_DATA.length === 1"
        >
          <div class="card h-100 bg-light shadow-none">
            <div class="card-header pb-0 bg-transparent">
              <h2 class="card-title text-center">
                {{ suggested.title }}
              </h2>
            </div>
            <div class="card-body">
              <h3 class="text-center text-secondary">
                {{ suggested.unitNumber }}
                {{ isMany(suggested.unitNumber) ? "units" : "unit" }}
              </h3>
              <div
                class="text-center fw-light pt-0 pb-3 text-secondary"
                *ngIf="getRestockSuggestionValue('suggReorder') > 0"
              >
                {{ suggested.textUnder }}
              </div>
              <table class="table table-borderless table-responsive w-100" aria-describedby>
                <th hidden="hidden"></th>
                <tbody class="w-100">
                  <tr
                    class="w-100"
                    *ngFor="let line of suggested?.data"
                    [class.sale-velocity-cal__formula__suggested__body__row--line]="
                      line.hasUnderLine
                    "
                    [class.sale-velocity-cal__formula__suggested__body__row--space]="
                      line.hasSpacing
                    "
                  >
                    <td class="w-100 py-0">
                      <span class="custom-text">
                        {{ line.field }}
                        <span
                          *ngIf="line.tooltip"
                          class="m-r-2"
                          [ngbTooltip]="line.tooltip"
                        >
                          <i
                            class="fas fa-info-circle cursor-pointer"
                          ></i>
                        </span>
                      </span>
                    </td>
                    <td class="text-center py-0">
                      <span class="custom-text">
                        {{ line.operator }}
                      </span>
                    </td>
                    <td
                      class="text-end"
                      [class.negative-unit]="checkNegativeNumber(line.value)"
                    >
                      <span
                        class="custom-text d-block"
                        style="min-width: 120px"
                        [class.text-secondary]="!checkNegativeNumber(line.value)"
                      >
                        {{ checkNegativeNumber(line.value) ? "(" : ""
                        }}{{ getPositiveNumBer(line.value) }}
                        {{
                          isMany(getPositiveNumBer(line.value))
                            ? "units"
                            : "unit"
                        }}{{ checkNegativeNumber(line.value) ? ")" : "" }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
