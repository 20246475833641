export class Demand {
  docType: string = '';
  orderKey: string = '';
  rowKey: string = '';
  refNum: string = '';
  itemKey: string = '';
  itemName: string = '';
  fnsku: string = '';
  imageUrl: string ='';
  asin: string = '';
  dueDate: Date = null;
  orderQty: number = 1;
  openQty: number = 0;

  constructor(init?: Partial<Demand>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
