import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { shareReplay } from 'rxjs/operators';
import { StockaidResponse } from '../misc/stockaid-response';
import { ResourceService } from './resource-service';

export abstract class CacheableResourceService<T> extends ResourceService<T> {
  protected cache: any = {};

  getAllCacheStockAid$: Observable<StockaidResponse<T[]>>;

  constructor(protected httpClient: HttpClient, protected apiUrl: string) {
    super(httpClient, apiUrl);
  }

  getStockAid(): Observable<StockaidResponse<T[]>> {
    if (!this.getAllCacheStockAid$) {
      this.getAllCacheStockAid$ = super.getStockAid().pipe(shareReplay(1));
    }

    return this.getAllCacheStockAid$;
  }

  getAll(reloadCache: boolean = false): Observable<T[]> {
    const key = this.apiUrl;

    if (reloadCache) {
      return super.getAll();
    }

    if (!this.cache[key]) {
      this.cache[key] = super.getAll().pipe(shareReplay(1));
    }

    return this.cache[key];
  }

  getById(id: any, reloadCache: boolean = false): Observable<T> {
    const key = `${this.apiUrl}/${id}`;

    if (reloadCache) {
      return super.getById(id);
    }

    if (!this.cache[key]) {
      this.cache[key] = super.getById(id).pipe(shareReplay(1));
    }

    return this.cache[key];
  }

  clearCache(key: string = null): void {
    if (key) {
      delete this.cache[key];
    }

    this.cache = {};
  }
}
