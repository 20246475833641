import { Component, OnInit, Input } from '@angular/core';
import { ProcessingService } from 'src/app/core/services/processing.service';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { PusherService } from 'src/app/core/services/pusher.service';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  selector: 'app-nav-search',
  templateUrl: './nav-search.component.html',
  styleUrls: ['./nav-search.component.scss'],
})
export class NavSearchComponent extends BaseComponent implements OnInit {
  @Input() navBarHeight: number;
  @Input() isSubscriptionExpired: boolean = false;

  isNavbarCollapse: boolean = false;

  set searchOn(value: string) {
    this._searchOn = value;
    const statusSearch = Boolean(value);
    this.headerService.setSearchStatus(statusSearch);
  }
  get searchOn(): string {
    return this._searchOn;
  }

  private _searchOn = '';

  isSyncInProcess = false;

  constructor(
    private pusherService: PusherService,
    private processingService: ProcessingService,
    private headerService: HeaderService
  ) {
    super();
  }

  ngOnInit() {
    this.pusherService.userChannel.bind('notification', (data) => {
      if (data.type === 'close') {
          this.isSyncInProcess = false;
      }
    });

    this.processingService.processingStartedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.processingService.processingStoppedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.headerService
      .getCompany()
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = !!data?.jobInitiator;
      });

    this.headerService.navbarCollapseSubject$.subscribe(value => {
      if (!value) {
        this.isNavbarCollapse = true;
        return;
      }

      this.isNavbarCollapse = !this.isNavbarCollapse;
    });
  }

  getTransform(): string {
    return `translateY(${this.navBarHeight}px)`;
  }

  search(term) {
    this.searchOn = term;
  }
}
