<div *ngIf="alreadyChoosenItem" class="copy-history-modal">
  <div class="modal-header">
    <div class="modal-title w-100">
      <h5 class="text-white text-center">Copy Sales History</h5>
      <h4 class="text-white text-center">{{ copyInfo }}{{ itemSourceInfo }}</h4>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- Change source item button -->
    <button
      *ngIf="isHistoryVisible"
      (click)="changeSourceItem()"
      class="btn btn-warning mb-3"
    >
      Change Source Item
    </button>

    <!-- Item Lookup -->
    <div
      *ngIf="isLookupVisible"
      class="d-flex justify-content-center flex-column my-1 copy-history-modal__lookup"
    >
      <h5 class="text-white text-center">Please Select A Source Item:</h5>

      <h6 class="text-center">
        <input
          #hiddenToggle
          type="checkbox"
          id="hiddenToggle"
          (change)="showHidden(hiddenToggle.checked)"
        />&nbsp;
        <label for="hiddenToggle" class="text-white">Show Hidden Items</label>
      </h6>

      <app-lookup-item
        [alreadyChoosenItem]="alreadyChoosenItem"
        [shouldShowHiddenItems]="shouldShowHiddenItems"
        [currentCompany]="currentCompany"
        (itemChoosen)="itemChoosen($event)"
      ></app-lookup-item>
    </div>

    <!-- History Data to copy -->
    <div
      *ngIf="isHistoryVisible && choosenItem"
      class="d-flex justify-content-center flex-column"
    >
      <h5 class="text-white text-center">The following sales history overrides will be applied to {{alreadyChoosenItem.name}}. Make any desired adjustments to the grid below.</h5>

      <app-history-data-table
        [itemObject]="choosenItem"
        [copyFeature]="true"
        [currentCompany]="currentCompany"
        (giveCopySource)="getCopySource($event)"
      ></app-history-data-table>
    </div>

    <div class="d-flex justify-content-center my-2">
      <button
        type="button"
        (click)="activeModal.dismiss('Cross click')"
        class="btn btn-danger mx-1"
      >
        Cancel
      </button>
      <button
        type="button"
        [disabled]="!isHistoryVisible"
        (click)="completeCopyHistory()"
        class="btn btn-success mx-1"
      >
        Copy History
      </button>
    </div>
  </div>
</div>
