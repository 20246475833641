export function getAdditionalDemandSources(): any {
  return [
    {
      displayName: 'Name',
      field: 'name',
    },
    {
      displayName: 'Description',
      field: 'description',
    },
    {
      displayName: 'Purchase As',
      field: 'lotMultipleItemName',
    },
    {
      displayName: 'Case Pack Qty',
      field: 'lotMultipleQty',
    },
  ];
}

export enum HistoryTabs {
  DirectSales = 'direct',
  KitSales = 'kit',
  TotalSales = 'total',
}

export const REFRESH_TOOLTIP = 'Clear the selection to export table(s)'