<app-navigation
  class="pcoded-navbar"
  [topPadding]="searchOn ? bannerHeight + navBarHeight : bannerHeight"
  [id]="navHidden ? 'apcoded-navbar-hiden' : ''"
  [ngClass]="{
    'navbar-collapsed': navCollapsed,
    'theme-horizontal': this.gradientConfig['layout'] === 'horizontal',
    'mob-open': navCollapsedMob
  }"
  (onNavMobCollapse)="navMobClick()"
></app-navigation>

<app-nav-bar
  class="navbar pcoded-header navbar-expand-lg navbar-light p-0"
  (onNavCollapse)="navCollapseClick()"
  (onNavHeaderMobCollapse)="navMobClick()"
  (afterViewInit)="navBarHeight = $event ? $event : 0"
  [isSubscriptionExpired]="isSubscriptionExpired"
  [companyId]="companyId"
  [navCollapsed]="navCollapsed"
></app-nav-bar>


<div
  class="pcoded-main-container h-100"
  [id]="navHidden ? 'pcoded-full' : ''"
  [style.top.px]="containerTop"
>
  <div
    class="pcoded-wrapper container page-stretched d-flex flex-column justify-content-between"
    [id]="navHidden ? 'pcoded-wrapper-cover' : ''"
    [ngClass]="{
      container:
        this.gradientConfig.layout === 'horizontal' &&
        this.gradientConfig.subLayout === 'horizontal-2',
      'pcoded-content-collapsed': navCollapsed
    }"
  >
    <div [class.has-notification-banner]="!!bannerType" class="pcoded-content">
      <div>
        <div class="pcoded-inner-content">
          <div class="main-body">
            <div class="page-wrapper">
              <app-breadcrumb></app-breadcrumb>
              <router-outlet></router-outlet>
              <ng-snotify ngClass="material"></ng-snotify>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <footer class="footer card">
        <div>
          <div class="row text-muted">
            <div class="col-md-6 col-sm-12 text-start">
              <p class="mb-0">
                <a
                  href="https://www.forecastrx.com/"
                  target="_blank"
                  class="text-muted"
                  rel=noopener
                  ><strong>&copy;&nbsp;ForecastRx</strong></a
                >
              </p>
            </div>
            <div class="col-md-6 col-sm-12 text-md-end text-sm-start">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a
                    class="text-muted"
                    href="https://forecastrx.zendesk.com"
                    target="_blank"
                    rel=noopener
                    >Support</a
                  >
                </li>
                <li class="list-inline-item">
                  <a
                    class="text-muted"
                    href="https://www.forecastrx.com/privacy-policy/"
                    target="_blank"
                    rel=noopener
                    >Privacy</a
                  >
                </li>
                <li class="list-inline-item">
                  <a
                    class="text-muted"
                    href="https://www.forecastrx.com/terms/"
                    target="_blank"
                    rel=noopener
                    >Terms</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>

<app-configuration></app-configuration>

<div class="version" (click)="onOpenReleastNotes()">v{{ version }}</div>
