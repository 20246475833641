import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { NgbDropdownModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { CardModule } from '../card/card.module';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    CardModule,
    NgbProgressbarModule,
    /*AnimatorModule*/
  ],
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  providers: [/*AnimationService*/]
})
export class LoaderModule { }
