export const FORMAT_PATTERN = {
  year: 'yyyy',
  month: 'mm',
  day: 'dd',
  hour: 'h',
  minute: 'm',
  second: 's',
};

export const COMMON_FORMAT_DATE = "MM/dd/yyyy"
