import {Injectable} from '@angular/core';
import {PreloadingStrategy, Route, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {
    constructor(private router: Router){}
    preload(route: Route, fn: () => Observable<any>): Observable<any> {
        if (route.data?.preload && this.router.url.includes(route.path)) {
            return fn();
        }
        return of(null);
    }
}