export const ServiceLevelBgColor = [
  '#f8696b',
  '#f19366',
  '#eea865',
  '#eabd64',
  '#e6d063',
  '#e2e262',
  '#c9de62',
  '#b1da61',
  '#9ad561',
  '#7fce62',
  '#6bc962',
];

export const ServiceLevelDescription = {
  0: 'No Safety Stock',
  7: 'Recommended Safety Stock',
  10: 'Max Safety Stock',
};
/**
 * - { display: '0', val: 50 }
 * - { display: '1', val: 55 }
 * - { display: '2', val: 60 }
 * - ...
 */
export const ServiceLevelSimplifiedList = Array.from(Array(11).keys()).map(
  (num) => ({
    display: `${num}`,
    val: 50 + num * 5 - (num === 10 ? 1 : 0),
    description: ServiceLevelDescription[num],
    bgColor: ServiceLevelBgColor[num]
  })
);

/**
 * - { display: '0 (50%)', val: 50 }
 * - { display: '1 (55%)', val: 55 }
 * - { display: '2 (60%)', val: 60 }
 * - ...
 */
export const ServiceLevelList = ServiceLevelSimplifiedList.map((s) => ({
  display: s.description ? `${s.display} (${s.val}% ${s.description})` : `${s.display} (${s.val}%)`,
  val: s.val,
  bgColor: s.bgColor
}));

class ServiceLevelMap<K, V> extends Map<K, V> {
  get(key) {
    if (!key) {
      return {
        val: '7 (85%)',
        numOnly: '7'
      } as unknown as V;
    }
    return super.get(key);
  }
}

/**
 * - 50: { val: '0 (50%)', numOnly: '0' }
 * - 55: { val: '1 (55%)', numOnly: '1' }
 * - 60: { val: '2 (60%)', numOnly: '2' }
 * - ...
 */
export const ServiceLevelListDisplay = new ServiceLevelMap<
  number,
  { val: string; numOnly: string }
>(
  ServiceLevelSimplifiedList.map((s) => [
    s.val,
    {
      val: `${s.display} (${s.val}%)`,
      numOnly: s.display
    }
  ])
)
