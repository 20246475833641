export class Bom {
  parentKey: string = '';
  parentName: string = '';
  childKey: string = '';
  childName: string = '';
  qty: number = 1;

  constructor(init?: Partial<Bom>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
