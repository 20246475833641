export class FileDetails {

  fileName: string = "";
  fileType: string = "";
  append: boolean = false;
  zero: boolean = false;
  userId: string = "";
  isCreateNew: boolean = false;
  isInitialUpload: boolean = false;

  constructor(init?: Partial<FileDetails>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }

}
