import {Pipe, PipeTransform} from '@angular/core';
import {MetaDataField} from '../../../../core/infrastructure/classes/meta-data-field';
import { GridName } from '../../forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';
import { SummaryByVendorField } from '../../../../core/infrastructure/enums/meta-data-field.enum';
import { SummaryByVendor } from '../../../../core/models/vendor';

@Pipe({
  name: 'dataIsSummaryByVendor'
})

export class DataIsSummaryByVendorPipe implements PipeTransform {

  transform(
    dataItem: SummaryByVendor,
    metaDataField: MetaDataField,
    itemName: string
  ): boolean {
    if (itemName === GridName.SuggestedPos) {
      switch (metaDataField.field) {
        case SummaryByVendorField.vendorName:
          return (
            metaDataField.field === SummaryByVendorField.vendorName &&
            dataItem[metaDataField.field] === 'no-supplier'
          );

        case SummaryByVendorField.earliestDueDate:
          const currentDate = new Date();
          const earliestDueDate = new Date(dataItem[metaDataField.field]);
          return earliestDueDate <= currentDate;

        default:
          return false
      }
    }

    return false;
  }
}
