<div class="create-shipment-modal">
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h4 class="text-white">
        Create new shipment
      </h4>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card create-shipment-modal__card">
      <form *ngIf="shipmentForm" [formGroup]="shipmentForm">
        <div class="form-group">
          <!-- Shipment Name -->
          <label class="create-shipment-modal__label--large" for="shipmentName"
            >Name of shipment</label
          >
          <input
            type="text"
            class="form-control"
            placeholder="Name of Shipment..."
            formControlName="shipmentName"
            [ngClass]="{
              'is-invalid':
                !shipmentName?.valid &&
                (shipmentName?.dirty || shipmentName?.touched)
            }"
          />

          <div
            *ngIf="shipmentName?.errors && shipmentName?.touched"
            class="text-danger mt-1"
          >
            <span *ngIf="shipmentName.hasError('required')">
              <b>Shipment name required.</b>
            </span>
            <span *ngIf="shipmentName.hasError('unique')">
              <b>Shipment name already exists.</b>
            </span>
          </div>
          <hr />

          <!-- Location -->
          <label class="create-shipment-modal__label--large">
            Where will you send this shipment?
          </label>
          <div
            class="row text-center create-shipment-modal__shipment-location justify-content-center"
          >
            <div class="col-6">
              <input
                type="radio"
                id="amazon"
                [value]="ShipmentLocation.AMAZON"
              />
              <label
                for="amazon"
                class="create-shipment-modal__shipment-location__option"
              >
                <img [src]="AMAZON_LOGO_SOURCE" alt="Amazon" />
                <div>Direct to Amazon</div>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer p-10">
    <button
      type="button"
      class="btn btn-danger mr-2"
      [disabled]="isLoading"
      (click)="activeModal.dismiss('Cross click')"
    >
      Close
    </button>
    <button
      type="button"
      class="btn btn-success"
      [disabled]="shipmentForm.invalid || isLoading"
      (click)="saveShipment()"
    >
      Create
    </button>
  </div>
</div>
