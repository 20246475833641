<div
  #navBar
  class="container"
  *ngIf="
    this.gradientConfig.layout === 'horizontal' &&
      this.gradientConfig.subLayout === 'horizontal-2';
    else mainHeader
  "
>
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <div [ngClass]="{ 'navbar-collapsed': navCollapsed }" class="m-header">
    <a
      *ngIf="!isHiddenIconCollapse"
      href="javascript:"
      class="mobile-menu"
      id="mobile-collapse"
      (click)="navCollapse()"
      ><span></span
    ></a>
    <a
      [routerLink]="
        isSubscriptionExpired ? ['/subscriptions', companyId] : ['/dashboard']
      "
      class="b-brand"
    >
      <img class="position-absolute" src="assets/images/logoTextWhite.svg" alt="ForecastRx" style="max-width: 170px;">
      <img src="assets/images/logo-icon.png" alt="" class="logo-thumb" />
    </a>
    <a
      class="mob-toggler"
      [ngClass]="{ on: this.menuClass }"
      href="javascript:"
      (click)="toggleMobOption()"
      ><i class="feather icon-more-vertical"></i
    ></a>
  </div>
  <div class="collapse navbar-collapse px-4" [style.display]="this.collapseStyle">
    <app-nav-left
      [isSubscriptionExpired]="isSubscriptionExpired"
      class="mr-auto"
    ></app-nav-left>
    <app-nav-right
      [navBarHeight]="navBarHeight"
      [isSubscriptionExpired]="isSubscriptionExpired"
      class="ml-auto"
    ></app-nav-right>
  </div>
</ng-template>
