import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Observable, Subject, asapScheduler } from 'rxjs';
import {
  map,
  switchMap,
  distinctUntilChanged,
  debounceTime,
  finalize,
  tap,
} from 'rxjs/operators';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import {
  ColumnComponent,
  ColumnReorderEvent,
  ColumnVisibilityChangeEvent,
} from '@progress/kendo-angular-grid';
import {
  conditionalOperator,
  fillingMissingItems,
  mapColumnStateToField,
  reorderColumnsByFields,
  reorderFieldsByColumns,
  sortColumnsByField,
  updateColumnsVisible,
  updateFieldsVisible,
} from '@stockaid/utils';
import Swal from 'sweetalert2';
import { SnotifyService } from 'ng-snotify';
import _ from 'lodash';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { GridView } from 'src/app/core/models/grid-view';
import { GridState } from 'src/app/core/infrastructure/classes/grid-state';
import { GridStateColumn } from 'src/app/core/infrastructure/classes/grid-state-column';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { GridStateColumnSort } from 'src/app/core/infrastructure/classes/grid-state-column-sort';
import { GridStatePagination } from 'src/app/core/infrastructure/classes/grid-state-pagination';
import { GridViewService } from 'src/app/core/services/grid-view.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { itemMetricIncludeFields } from 'src/app/core/models/item';
import { Logic } from 'src/app/core/infrastructure/enums/logic.enum';
import { GridName } from '../forecast-rxdata-table/forcast-rxdata-table.constant';
import { GridType } from 'src/app/core/infrastructure/enums/grid-type.enum';

@Component({
  selector: 'app-grid-view-combobox',
  templateUrl: './grid-view-combobox.component.html',
})
export class GridViewComboboxComponent extends BaseComponent implements OnInit {
  @ViewChild('comboBox') private comboBox: ComboBoxComponent;

  @Input() modelType: <T>() => T;
  @Input() isCustom: boolean;
  @Input() userSelectedGridView: GridView;
  @Input() set itemName(value: string) {
    if (value === 'Suggested POs') {
        this._itemName = 'suggested-pos';
    } else {
        this._itemName = _.kebabCase(value);
    }

    if (this.isCustom) {
      this._itemName = `custom-${this._itemName}`;
    }
  }
  get itemName(): string {
    return this._itemName;
  }
  @Input() set metadataFields(value: MetaDataField[]) {
    if (!value?.length) {
      return;
    }

    this._metadataFields = value;

    if (!this.gridState) {
      this.gridState = new GridState();
      value?.forEach((metadataField) => {
        const column = new GridStateColumn({
          name: metadataField.field,
          visible: metadataField.initialUpload,
          locked: metadataField.locked
        });

        this.gridState.columns.push(column);
      });
    }
  }
  get metadataFields(): MetaDataField[] {
    return this._metadataFields;
  }
  @Input() set state(value: any) {
    this.gridState?.columns?.forEach((column) => {
      const currentSort = value.sort?.find(
        (sortCol) => sortCol.field === column?.name
      );
      const currentFilter = value.filter?.filters.find(
        (fltr) => {
          if(!fltr.filters) {
            return fltr?.field === column.name;
          }

          return fltr?.filters[0]?.field === column.name;
        }
      );

      if (currentFilter) {
        column.filters = currentFilter;
      } else {
        delete column?.filters;
      }

      if (currentSort) {
        column.sort = new GridStateColumnSort({
          direction: currentSort.dir as string,
        });
      } else {
        delete column.sort;
      }
    });

    if (!this.gridState) {
      return;
    }

    this.gridState.pagination = new GridStatePagination({
      paginationCurrentPage: value?.skip || 0,
      paginationPageSize: value?.take || 100,
    });

    this.gridState.filter = value.filter;
    this.gridState.sort = value.sort;
  }
  @Input() set columnReorderArgs(value: ColumnReorderEvent & { isLocked?: boolean, isResettingLockedColumns: boolean }) {
    if (!value || (value.newIndex === 0 && !['suggested-pos'].includes(this.itemName))) {
      return;
    }

    asapScheduler.schedule(() => {
      this.gridState.columns = reorderColumnsByFields(
        this.metadataFields,
        this.gridState?.columns
      );

      if (value.isResettingLockedColumns) {
        this.gridState.columns.forEach((i) => i.locked = false);
      }
      if (value.isLocked === undefined || value.isLocked === null) {
        return;
      }
      const lockingColumn = this.gridState.columns
        .find((i) => i.name === (value?.column as any)?.field);
      if (lockingColumn) {
        lockingColumn.locked = value.isLocked;
      }
    });
  }

  @Input() set columnResizeArgs(value) {
    if (!value) {
      return;
    }

    this.gridState.columns.forEach((col) => {
      if (col.name === value.field) {
        col.width = value.newWidth;
      }
    });
  }

  @Input() set columnVisibilityArgs(value: ColumnVisibilityChangeEvent) {
    if (!value) {
      return;
    }

    const changedColumns = value.columns as ColumnComponent[];
    updateColumnsVisible(this.gridState?.columns, changedColumns);

    updateFieldsVisible(this.metadataFields, changedColumns);
  }
  @Input() clearClicked$: Subject<any>;
  @Input() set searchTerm(value: string) {
    if (!this.gridState) {
      return;
    }
    this.gridState.searchTerm = value;
  }
  @Input() set removalOrdersFilter(value: CompositeFilterDescriptor) {
    this._removalOrdersFilter = value;
    if (!this.gridState) {
      return;
    }
    this.gridState.removalOrdersFilter = value;
  }
  get removalOrdersFilter(): CompositeFilterDescriptor {
    return this._removalOrdersFilter;
  }

  @Output() gridViewChange = new EventEmitter();
  @Output() isSavedGridView = new EventEmitter()
  isLoading = false;
  searchTerm$: Subject<string> = new Subject<string>();
  gridViews: GridView[];
  selectedGridView: GridView;
  oldGridView: GridView;
  gridState: GridState;
  kendoGridState: State = {
    skip: 0,
    take: 100,
    sort: [],
    filter: {
      logic: Logic.and,
      filters: [],
    },
  };

  private _itemName: string;
  private _metadataFields: MetaDataField[];
  private _removalOrdersFilter: CompositeFilterDescriptor;

  constructor(
    private gridViewService: GridViewService,
    private snotifyService: SnotifyService,
    private headerService: HeaderService
  ) {
    super();
  }

  ngOnInit(): void {
    if(this.gridState) {
      this.gridState.removalOrdersFilter = this.removalOrdersFilter;
    }
    this.getFirstFewGridViews().subscribe();

    this.headerService
      .getChange()
      .pipe(
        this.autoCleanUp(),
        switchMap(() => {
          this.selectedGridView = null;
          this.comboBox.reset();
          return this.getFirstFewGridViews();
        })
      )
      .subscribe();

    this.searchTerm$
      .pipe(
        this.autoCleanUp(),
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => {
          return this.gridViewService.getGridViews(this.itemName, term);
        })
      )
      .subscribe((gvs) => {
        this.isLoading = false;
        this.gridViews = gvs;
      });

    this.clearClicked$
      ?.pipe(
        this.autoCleanUp(),
        tap(() => {
          this.selectedGridView = null;
          this.comboBox.reset();
        }),
        switchMap(() => this.getFirstFewGridViews())
      )
      .subscribe();
  }

  getFirstFewGridViews(): Observable<GridView[]> {
    this.isLoading = true;
    return this.gridViewService.getGridViews(this.itemName).pipe(
      this.autoCleanUp(),
      tap((gvs) => {
        this.gridViews = gvs;
      }),
      finalize(() => this.isLoading = false )
    );
  }

  saveGridView(): void {
    this.selectedGridView.gridState = this.gridState;
    this.selectedGridView.itemType = this.itemName;
    let confirmHtmlText = conditionalOperator(
      !!this.selectedGridView?.key || !!this.userSelectedGridView?.key,
      `<div>The grid view <strong>${this.selectedGridView.name}</strong> will be saved with the current grid layout</div>`,
      `<div>A new grid view named <strong>${this.selectedGridView.name}</strong> will be created with the current grid layout</div>`
    );

    if (this.selectedGridView.name === 'Default') {
      confirmHtmlText = `<div>The grid view <strong>${this.selectedGridView.name}</strong> will be saved with the current grid layout</div>`
    }

    Swal.fire({
      title: 'Are you sure?',
      html: confirmHtmlText,
      type: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willSave) => {
      if (willSave.dismiss) {
        return;
      }

      if (this.oldGridView) {
        this.selectedGridView.key = this.oldGridView.key;
      }
      this.isLoading = true;
      this.handleSaveGridView();
    });
  }

  handleSaveGridView() {
    this.gridViewService
      .saveGridView(this.selectedGridView)
      .pipe(
        this.autoCleanUp(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (gridView) => {
          if (this.oldGridView && this.selectedGridView.key === this.oldGridView?.key) {
            this.gridViews = this.gridViews.map((gv) =>
              conditionalOperator(gv.name === gridView.name, gridView, gv)
            )
            this.selectedGridView = gridView;
            this.oldGridView = gridView;
            this.snotifyService.success('Save grid view successful');
            this.handleValueChange(this.selectedGridView)
            return;
          }

          if(!this.selectedGridView?.key && this.selectedGridView?.isDefault && this.selectedGridView?.name === 'Default') {
            this.gridViews.splice(0,1);
            this.gridViews.unshift(gridView);
            this.selectedGridView = gridView;
            this.snotifyService.success('Save grid view successful');
            this.handleValueChange(this.selectedGridView)
            return
          }

          if(this.userSelectedGridView) {
            this.gridViews = this.gridViews.map((gv) =>
              conditionalOperator(gv.name === gridView.name, gridView, gv)
            )
            this.selectedGridView = gridView;
            this.oldGridView = gridView;
            this.snotifyService.success('Save grid view successful');
            this.handleValueChange(this.selectedGridView)
            return;
          }
          this.gridViews.push(gridView);
          this.selectedGridView = gridView;
          this.handleValueChange(this.selectedGridView)
          this.snotifyService.success('Save grid view successful');
        },
        () => {
          this.snotifyService.error('Save grid view fail');
        }
      );
  }

  deleteGridView(): void {
    Swal.fire({
      title: 'Are you sure?',
      html: `<div>The grid view <strong>${this.selectedGridView.name}</strong> will be deleted</div>`,
      type: 'warning',
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((willDelete) => {
      if (willDelete.dismiss) {
        return;
      }

      this.isLoading = true;
      this.gridViewService
        .delete(this.selectedGridView.key)
        .pipe(
          this.autoCleanUp(),
          finalize(() => (this.isLoading = false))
        )
        .subscribe(
          () => {
            this.gridViews = this.gridViews.filter((gv) => {
              return gv.key !== this.selectedGridView.key;
            });
            this.comboBox.reset();
            this.selectedGridView = null;
            this.resetGridState();
            this.snotifyService.success('Delete grid view successful');
          },
          () => {
            this.snotifyService.error('Delete grid view fail');
          }
        );
    });
  }

  valueNormalizer(text: Observable<string>): Observable<GridView | null> {
    return text.pipe(
      map((t) => {
        return t ? new GridView({ name: t }) : null;
      })
    );
  }

  handleFilterChange(value) {
    if (value) {
      this.oldGridView = this.selectedGridView;
      this.isLoading = true;
      this.searchTerm$.next(value);
    }
  }

  handleValueChange(value: GridView | null) {
    if (!value) {
      this.selectedGridView = null;
      this.resetGridState();
      this.getFirstFewGridViews().subscribe();
      return;
    }
    this.selectedGridView =
      this.gridViews.find((gv) => gv.name === value.name && gv.key === value.key) || value;
    this.oldGridView =
      this.gridViews.find((gv) => gv.name === value.name && gv.key === value.key) ||
      (this.userSelectedGridView?.name === value.name ? this.userSelectedGridView : null);

    if (!this.selectedGridView.key && this.selectedGridView.name !== 'Default') {
      return;
    }
    this.gridState = this.selectedGridView?.gridState;

    this.gridState.columns = fillingMissingItems(
      this.metadataFields,
      this.gridState.columns
    );

    this.gridState.columns = sortColumnsByField(this.gridState.columns, 'visible', 'desc');

    this.metadataFields = mapColumnStateToField(
      this.metadataFields,
      this.gridState?.columns
    );

    this.metadataFields = reorderFieldsByColumns(
      this.metadataFields,
      this.gridState?.columns
    );

    this.kendoGridState = {
      skip: 0,
      take: 100,
      sort: [],
      filter: {
        logic: Logic.and,
        filters: [],
      },
    };

    const filters = [];
    let cols = Object.getOwnPropertyNames(this.modelType);

    if(this.itemName === 'item') {
      cols = cols.concat(itemMetricIncludeFields);
    }

    this.gridState?.columns
      .filter((col) => cols.includes(col.name))
      .forEach((col) => {
        if (col.sort) {
          this.kendoGridState.sort.push({
            field: col.name,
            dir: col.sort.direction as 'asc' | 'desc',
          });
        }

        if (col.filters?.filters?.length) {
          filters.push({
            filters: col.filters.filters,
            logic: col.filters.logic,
          });
        }
      });

    this.kendoGridState.filter = {
      logic: Logic.and,
      filters,
    };

    this.kendoGridState.skip =
      this.gridState?.pagination?.paginationCurrentPage || 0;
    this.kendoGridState.take =
      this.gridState?.pagination?.paginationPageSize || 100;

    if ([GridName.RestockSuggestion, GridType.Inventory].map((i) => _.kebabCase(i)).includes(this.itemName)) {
      this.kendoGridState.sort = this.gridState.sort;
      this.kendoGridState.filter = this.gridState.filter;
    }

    this.kendoGridState.sort = this.gridState.sort;
    this.kendoGridState.filter = this.gridState.filter;

    this.gridViewChange.emit({
      selectedGridView: this.selectedGridView,
      columns: this.metadataFields,
      gridState: this.kendoGridState,
      searchTerm: this.gridState.searchTerm,
      removalOrdersFilter: this.gridState.removalOrdersFilter
    });
  }

  resetGridState(): void {
    this.kendoGridState = {
      skip: 0,
      take: 20,
      sort: [],
      filter: {
        logic: Logic.and,
        filters: [],
      },
    };

    this.gridViewChange.emit({
      columns: this.metadataFields,
      gridState: this.kendoGridState,
    });
  }
}
