import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource-service';
import { StockaidResponse } from '../misc/stockaid-response';
import { Observable } from 'rxjs';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root',
})
export class ForecastService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/forecast');
  }

  runForecast(company: Company, isForecastAll = false) : Observable<StockaidResponse<any>> {
    return this.httpClient.post<StockaidResponse<any>>(`${this.apiUrl}`, {...company, isForecastAll});
  }

  forecastReport() : Observable<Blob> {
    return this.httpClient.get(`${this.apiUrl}/file`, {
      responseType: 'blob'
    });
  }
}
