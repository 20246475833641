import { Component, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DATA } from 'src/app/core/services/modal';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  get titleIconClass(): string {
    return this.data?.titleIconClass;
  }

  get title(): string {
    return this.data?.title;
  }

  get content(): string {
    return this.data?.content;
  }

  get successBtn(): string {
    return this.data?.successBtn;
  }

  get errorBtn(): string {
    return this.data?.errorBtn;
  }

  get theme(): string {
    return this.data?.theme || 'dark';
  }

  constructor(
    @Inject(MODAL_DATA) public data: any,
    public activeModal: NgbActiveModal
  ) {}

}
