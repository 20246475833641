import { RESTOCK_MODELS } from "./restock-model.constant";

export const AVERAGE_VELOCITY_WEIGHTINGS = [
  { percent2Day: '2-Day' },
  { percent7Day: '7-Day' },
  { percent14Day: '14-Day' },
  { percent30Day: '30-Day' },
  { percent60Day: '60-Day' },
  { percent90Day: '90-Day' },
  { percent180Day: '180-Day' },
  { percentForecasted: " ForecastRx Demand" },
];

export enum DATE_RANGE_SALES_VELOCITY {
  startDate = 'startDate',
  endDate = 'endDate',
}

export enum SALES_VELOCITY_SETTINGS {
  average = 'average',
  dateRange = 'dateRange',
  auto = 'auto'
}

export const DEFAULT_SALES_VELOCITY_SETTINGS = {
  salesVelocityType: SALES_VELOCITY_SETTINGS.average,
  localLeadTime: 7,
  restockModel: RESTOCK_MODELS[1].val,
  targetQtyOnHandMin: 30,
  targetQtyOnHandMax: 60,
  salesVelocitySettingData: {
    percent2Day: 0,
    percent7Day: 20,
    percent30Day: 20,
    percent60Day: 10,
    percent90Day: 0,
    percent180Day: 0,
    percentForecasted: 50
  }
}

export const MILISECONDS_OF_YEAR = 3153600000;
export const MILISECONDS_OF_DAY = 86400000;
