import { SalesVelocitySettingData } from './sales-velocity';
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';

export class Item {
  key: string = '';
  name: string = '';
  asin: string = '';
  fnsku: string = '';
  description: string = '';
  vendorKey: string = '';
  vendorName: string = '';
  vendorPrice: number = 0;
  moq: number = 1;
  additionalInventory: number = 0;
  leadTime: number = 1;
  orderInterval: number = 0;
  serviceLevel: number = 85;
  onHand: number = 0;
  onHandMin: number = 0;
  onHandThirdParty: number = 0;
  onHandThirdPartyMin: number = 0;
  growthTrend: number = 0;
  isHidden: boolean = false;
  useHistoryOverride: boolean = false;
  lotMultipleQty: number = 1;
  lotMultipleItemKey: string = '';
  lotMultipleItemName: string = '';
  forecastDirty: boolean = false;
  forecastTags: any[] = [];
  tags: string[] = [];
  useBackfill: boolean = false;
  useLostSalesOverride: boolean = false;
  createdAt: Date = new Date();
  isFbm: boolean = false;
  inventorySourcePreference: string = '';
  imageUrl: string;
  skuNotes: string = '';
  prepNotes: string = '';
  supplierRebate: string = '';
  inboundShippingCost: number = 0;
  reshippingCost: number = 0;
  repackagingMaterialCost: number = 0;
  repackingLaborCost: number = 0;
  inboundShipped: number = 0;
  inboundReceiving: number = 0;
  inbound: number = 0;
  inboundFcTransfer: number = 0;
  referralFee: number = 0;
  fbaFee: number = 0;
  rank: number = 0;
  variableClosingFee: number = 0;
  lowestFba: number = 0;
  newBuyBox: number = 0;
  listPrice: number = 0;
  average7DayPrice: number = 0;
  syncedFields: string[];
  hazmat: boolean;
  itemHistoryLength: number = 0;
  isUsingAdjustedDailySalesRateOption: Record<string, boolean>;
  doNotOrder: boolean = false;
  doNotRestock: boolean = false;
  isAdjustingDailySalesRateForOutOfStockOption: Record<string, boolean>;
  isCustom: boolean;

  constructor(init?: Partial<Item>) {
    if (init) {
      // if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export interface IItemResponse {
  model: Item[];
}

export const itemMetricIncludeFields = ['s7d', 's30d', 's90d', 's365d'];

export class ItemSalesVelocitySettings {
  companyType: string;
  companyKey: string;
  itemKey: string;
  restockModel: string;
  localLeadTime: number;
  targetQtyOnHandMin: number;
  targetQtyOnHandMax: number;
  salesVelocityType?: string;
  salesVelocitySettingData?: SalesVelocitySettingData;
  salesVelocitySettingsType?: SalesVelocitySettingsType;

  constructor(init?: Partial<ItemSalesVelocitySettings>) {
      Object.assign(this, init || {} );
  }
}
