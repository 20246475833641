import { KeyValue } from "@angular/common";

export enum InventorySourcePreference {
  FBA = "FBA",
  FBM = "FBM",
  FBAPlusFBM = "FBA+FBM"
}

export const InventorySourcePreferenceLabel = new Map<string, string>([
  [InventorySourcePreference.FBA, 'Fullfilled By Amazon (FBA)'],
  [InventorySourcePreference.FBM, 'Fullfilled By Merchant (FBM)'],
  [InventorySourcePreference.FBAPlusFBM, 'FBA + FBM'],
]);

//when using the keyvalue pipe in the html with *ngFor it rearranges the entries by key which is an undesired effect.
//This preserves the order of the entries.
export const originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
  return 0;
}
