<div class="data-table">
  <h6 class="py-2 pl-2 data-table__title">{{title}}</h6>
  <table class="table table-striped" aria-describedby>
    <th hidden="hidden"></th>
    <tbody>
      <tr *ngFor="let row of rows">
        <td>
          <span>{{row.displayName}}</span>
          <span *ngIf="row.tooltip" class="ml-1" [ngbTooltip]="row.tooltip">
            <i class="fas fa-info-circle cursor-pointer"></i>
          </span>
        </td>
        <td
          class="data-table__row-data text-right"
          container="body"
          placement="bottom"
        >
          {{row.data}} {{row.additionalData ? '(' + row.additionalData + ')' : ''}}
        </td>
      </tr>
    </tbody>
  </table>
</div>
