import { FieldValidatorType } from "../../../enums/field-validator-type.enum";
import { IFieldValidator } from "../../../interfaces/i-field-validator";

export class EmailValidator implements IFieldValidator  {

  type:FieldValidatorType  = FieldValidatorType.Email;

  //could provid custome email pattern, but for now just using angular built in
  //email validator.

  constructor(init?: Partial<EmailValidator>) {


    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }

}
