import { Component, OnInit, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DATA } from '@stockaid/services';

import { ItemService } from 'src/app/core/services/item.service';

import { Item } from 'src/app/core/models/item';
import { Vendor } from 'src/app/core/models/vendor';

@Component({
  selector: 'app-vendor-hidden-modal',
  templateUrl: './vendor-hidden-modal.component.html',
  styleUrls: ['./vendor-hidden-modal.component.scss'],
})
export class VendorHiddenModalComponent implements OnInit {
  items: Item[];
  doesContainItems: boolean;
  currentVendor: Vendor;
  isLoading: boolean;

  constructor(
    @Inject(MODAL_DATA) private data: any,
    public activeModal: NgbActiveModal,
    private itemService: ItemService
  ) {}

  ngOnInit(): void {
    const { vendor } = this.data;
    this.currentVendor = vendor;

    this.isLoading = true;

    // Create filters to get items with vendorKey and isHidden = false
    const filterItemByVendor = {
      logic: 'and',
      filters: [
        {
          filters: [
            {
              field: 'isHidden',
              operator: 'eq',
              value: false,
            },
          ],
          logic: 'and',
        },
        {
          filters: [
            {
              field: 'vendorKey',
              operator: 'eq',
              value: vendor.key,
            },
          ],
          logic: 'and',
        },
      ],
    };

    this.itemService.getFilteredItem(filterItemByVendor).subscribe((items) => {
      this.items = items;
      this.doesContainItems = this.items.length !== 0;
      this.isLoading = false;
    });
  }

  private hideItemsByVendor(items: Item[]): Observable<Item[]> {
    if (!items.length) {
      return of([]);
    }

    return this.itemService.deleteHideMultiple(items.map((i) => i.key), null, true);
  }

  confirmHidden(): void {
    this.hideItemsByVendor(this.items)?.subscribe((failToHideItems) => {
      this.activeModal.close(failToHideItems);
    });
  }
}
