import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '../models/company';
import { Realm } from '../models/realm';
import { CacheableResourceService } from './cacheable-resource-service';

@Injectable({
  providedIn: 'root'
})
export class RealmService extends CacheableResourceService<Realm> {

  constructor(httpClient: HttpClient) {
    super(httpClient, "/api/realm");
  }

  getLastUsedRealm(realms: Realm[]): Realm {
    if (!realms || realms.length === 0) {
      return null;
    }

    if (realms.length === 1) {
      return realms[0];
    }

    let sortedRealms: Realm[] = realms.concat().sort((a, b) => {
      if (a.updated_at > b.updated_at) return -1;
      if (a.updated_at < b.updated_at) return 1;
      return 0;
    });

    return sortedRealms[0];
  }

  getAllRealmsOfUser(userId: any): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.apiUrl}/${userId}`);
  }

  updateCompanyName(company: Company): Observable<any> {
    return this.httpClient.post<any>(`${this.apiUrl}`, { company });
  }
}
