import { HttpClient, HttpParams } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { SummaryByVendor } from '../models/vendor';

import { ResourceService } from './resource-service';
import { StockaidResponse } from '../misc/stockaid-response';
import { Observable } from 'rxjs';
import { ISummaryByVendorsResponse } from '../models/summary';
import { SortDescriptor } from '@progress/kendo-data-query';

@Injectable({
  providedIn: 'root',
})
export class SummaryByVendorService extends ResourceService<SummaryByVendor> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/summarybyvendor');
  }

  public stockaidPaginated(
    offset: number,
    limit: number,
    sortAttributes: SortDescriptor[] = [],
    filters: any = []
  ): Observable<StockaidResponse<SummaryByVendor[]>> {
    const body: any = {
      offset,
      limit,
      sort: sortAttributes,
      filters,
      operation: 'paginate',
    };

    return this.httpClient.post<StockaidResponse<SummaryByVendor[]>>(
      this.apiUrl,
      body
    );
  }

  public getCountSummary(
    offset: number,
    limit: number,
    sortAttributes: SortDescriptor[] = [],
    filters: any = []
  ): Observable<number> {
    const body: any = {
      offset,
      limit,
      sort: sortAttributes,
      filters,
      operation: 'count',
    };

    return this.httpClient.post<number>(this.apiUrl, body);
  }

  getSummaryByVendors(
    companyKey: string,
    companyType: string,
    marketplaceId: string,
    currencyCode: string
  ): Observable<ISummaryByVendorsResponse> {
    let params = new HttpParams();

    params = params.set('companyKey', companyKey);
    params = params.set('companyType', companyType);

    if (currencyCode) {
      params = params.set('marketplaceId', marketplaceId);
      params = params.set('currencyCode', currencyCode);
    }

    return this.httpClient.get<ISummaryByVendorsResponse>(this.apiUrl, {
      params,
    });
  }
}
