import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { ResourceService } from './resource-service';
import { CreateSubscriptionResponse } from './../models/create-subscription-response';
import { IGetPlansResponse, IPlan } from './../models/plans';
import {
  ISubscription,
  ISubscriptionRequest,
  ISubscriptionUpdateResponse,
  IPaymentMethod,
  IPaymentIntent,
} from '../models/subscription';
import { IBillingCheckoutRequest } from './../models/billing-checkout';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root',
})
export class BillingService extends ResourceService<any> {
  private plansCache$: Observable<IGetPlansResponse>;
  private subscriptionCache$: Observable<ISubscription>;
  private currentCompanyId: string;
  private subscriptionTime: number;

  constructor(httpClient: HttpClient) {
    super(httpClient, '/api/billing');
  }

  createTrailSubscription(
    userId: string,
    companyName: string
  ): Observable<CreateSubscriptionResponse> {
    return this.httpClient.post<CreateSubscriptionResponse>(
      `${this.apiUrl}/trial`,
      { userId, companyName }
    );
  }

  getSubscriptions(): Observable<ISubscription[]> {
    return this.httpClient.get<ISubscription[]>(`${this.apiUrl}/subscriptions`);
  }

  getSubscriptionByCompanyId(
    companyId: string,
    forcedRefresh = false
  ): Observable<ISubscription> {
    const cacheExpiration = 300000;
    const currentTime = new Date().getTime();
    if (
      !this.subscriptionCache$ ||
      forcedRefresh ||
      this.currentCompanyId !== companyId ||
      currentTime - this.subscriptionTime > cacheExpiration
    ) {
      this.currentCompanyId = companyId;
      this.subscriptionCache$ = this.httpClient
        .get<ISubscription>(`${this.apiUrl}/subscription/${companyId}`)
        .pipe(shareReplay(1, cacheExpiration));

      this.subscriptionTime = currentTime;
    }

    return this.subscriptionCache$;
  }

  getPlans(): Observable<IGetPlansResponse> {
    if (!this.plansCache$) {
      this.plansCache$ = this.httpClient
        .get<IGetPlansResponse>(`${this.apiUrl}/plans`)
        .pipe(
          map((res: any) => ({
            ...res,
            plans: res.plans.map((plan) => ({
              ...plan,
              allowOrders:
                plan.allowOrders === 'Infinity' ? Infinity : plan.allowOrders,
            })),
          })),
          shareReplay(1)
        );
    }

    return this.plansCache$;
  }

  getAltPlan(planId): Observable<IPlan> {
    return this.httpClient.get<IPlan>(`${this.apiUrl}/plans/${planId}`).pipe(
      map((plan: any) =>
        plan
          ? {
              ...plan,
              allowOrders:
                plan.allowOrders === 'Infinity' ? Infinity : plan.allowOrders,
            }
          : null
      )
    );
  }

  checkout(
    payload: IBillingCheckoutRequest,
    company: Partial<Company>,
    referralId: string
  ): Observable<string> {
    return this.httpClient
      .post<string>(`${this.apiUrl}/checkout`, {
        payload,
        company,
        referralId
      })
      .pipe(
        tap((sessionUrl) => {
          if (sessionUrl?.length) {
            window.location.href = sessionUrl;
          }
        })
      );
  }

  changeCard(payload: {
    customerId?: string;
    companyKey?: string;
    companyType?: string;
    oldPaymentMethodId?: string;
    referralId?: string;
  }): Observable<string> {
    return this.httpClient
      .post<string>(`${this.apiUrl}/change-card`, payload)
      .pipe(
        tap((sessionUrl) => {
          if (sessionUrl?.length) {
            window.location.href = sessionUrl;
          }
        })
      );
  }

  updateSubscription(
    payload: ISubscriptionRequest
  ): Observable<ISubscriptionUpdateResponse> {
    const { priceId } = payload;

    return this.httpClient.post<ISubscriptionUpdateResponse>(
      `${this.apiUrl}/plan/${priceId}`,
      payload
    );
  }

  cancelSubscription(
    companyId: string,
    nextPlanSubscriptionId?: string
  ): Observable<ISubscription> {
    let params = new HttpParams();
    if (nextPlanSubscriptionId) {
      params = params.set('nextPlanSubscriptionId', nextPlanSubscriptionId);
    }

    return this.httpClient.delete<ISubscription>(
      `${this.apiUrl}/subscription/${companyId}`,
      { params }
    );
  }

  getPaymentMethods(customerId: string): Observable<IPaymentMethod[]> {
    if (!customerId) {
      return of([]);
    }

    return this.httpClient.get<IPaymentMethod[]>(
      `${this.apiUrl}/payment-method/${customerId}`
    );
  }

  getPendingSubscription(
    customerId: string,
    subscriptionId: string
  ): Observable<ISubscription> {
    if (!subscriptionId) {
      return of(null);
    }

    return this.httpClient.get<ISubscription>(
      `${this.apiUrl}/pending-subscription/${customerId}/${subscriptionId}`
    );
  }

  getPaymentIntent(
    paymentIntentId: string,
    subscriptionId: string
  ): Observable<IPaymentIntent> {
    if (!paymentIntentId || !subscriptionId) {
      return of(null);
    }

    return this.httpClient.get<IPaymentIntent>(
      `${this.apiUrl}/payment-intent/${subscriptionId}/${paymentIntentId}`
    );
  }

  confirmPaymentIntent(
    selectedCompanyId: string,
    subscriptionId: string,
    paymentMethodId: string
  ): Observable<IPaymentIntent> {
    return this.httpClient.post<IPaymentIntent>(
      `${this.apiUrl}/payment-intent`,
      {
        selectedCompanyId,
        subscriptionId,
        paymentMethodId,
      }
    );
  }

  extendTrial(customerId: string, payload): Observable<ISubscription> {
    const { subscriptionId, companyKey, companyType, companyName } = payload;
    return this.httpClient.post<ISubscription>(
      `${this.apiUrl}/extend-trial/${customerId}`,
      { subscriptionId, companyKey, companyType, companyName }
    );
  }

  updatePrice(
    subscriptionId: string,
    customerId: string,
    updatedPrice: number,
    updatedOrder: number
  ): Observable<ISubscription> {
    return this.httpClient.put<ISubscription>(
      `${this.apiUrl}/change-price/${subscriptionId}`,
      { customerId, updatedPrice, updatedOrder }
    );
  }
}
