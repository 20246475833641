import { Component, DoCheck, OnInit, Input } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { GradientConfig } from '../../../../../app-config';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { User } from 'src/app/core/models/user';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ForecastService } from 'src/app/core/services/forecast.service';
import { saveAs } from '@progress/kendo-file-saver';
import { formatDate } from '@angular/common';
import { CompanyService } from 'src/app/core/services/company.service';
import { RealmService } from 'src/app/core/services/realm.service';
import { ProcessingService } from 'src/app/core/services/processing.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { HeaderService } from 'src/app/core/services/header.service';
import { Company } from 'src/app/core/models/company';
import { fullScreenAllPage } from 'src/app/theme/shared/full-screen/toggle-full-screen';
import { NotificationService } from 'src/app/core/services/notification.service';
import { NOTIFICATIONS_DISPLAY } from 'src/app/routes/notification/notification.constant';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class NavRightComponent
  extends BaseComponent
  implements OnInit, DoCheck
{
  @Input() navBarHeight: number;
  @Input() isSubscriptionExpired: boolean;

  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public gradientConfig: any;
  isSyncInProcess = false;
  company: Company;
  isExporting = false;
  isFullScreen: boolean = false;
  isShowIconNotification: boolean = true;
  notifications: any;
  isQuicksBookSupport= {
    showIcon: false,
    showDialog:false,
    showMinorIcon:false
  }

  constructor(
    private authService: AuthenticationService,
    private companyService: CompanyService,
    private realmService: RealmService,
    private router: Router,
    private forecastService: ForecastService,
    private pusherService: PusherService,
    private processingService: ProcessingService,
    private headerService: HeaderService,
    private notificationService: NotificationService
  ) {
    super();
    this.visibleUserList = false;
    this.chatMessage = false;
    this.gradientConfig = GradientConfig.config;
  }

  ngOnInit() {
    this.pusherService.userChannel.bind('notification', (data) => {
      if (data.type === 'close') {
          this.isSyncInProcess = false;
        }
      }
    );

    this.processingService.processingStartedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.processingService.processingStoppedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.companyService
      .getCurrentCompany()
      .pipe(this.autoCleanUp())
      .subscribe((company) => {
        this.company = company;
        if(company?.companyType === CompanyType.QBFS){
          this.isQuicksBookSupport.showIcon = true;
          this.isQuicksBookSupport.showDialog = true;
          this.isQuicksBookSupport.showMinorIcon = true;
        } else {
          this.isQuicksBookSupport.showIcon = false;
          this.isQuicksBookSupport.showDialog = false;
          this.isQuicksBookSupport.showMinorIcon = false;
        };
        this.isSyncInProcess = !!company?.jobInitiator;

        if(this.company.companyKey === '0') {
          return;
        }

        this.notificationService
          .get()
          .pipe(this.autoCleanUp())
          .subscribe((notifications) => {
            this.notifications = notifications;
          });
      });

    this.headerService.getCompany().subscribe((company) => {
      this.company = company;
      this.isSyncInProcess = !!company?.jobInitiator;
    });
  }

  get user(): User {
    return this.authService.currentUser;
  }

  logout(): void {
    this.authService
      .logout()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.router.navigate(['/account/signin']);
        },
        (err) => {
          this.router.navigate(['/account/signin']);
          sessionStorage.removeItem('userInfo');
          this.companyService.clearCache();
          this.realmService.clearCache();
        },
        () => {
          sessionStorage.removeItem('userInfo');
          this.companyService.clearCache();
          this.realmService.clearCache();
        }
      );
  }

  getObjectKeys(obj: any): string[] {
    const data = obj ? Object.keys(obj) : [];
    const order = [
      "MISSING_NOTIFICATION",
      "METRIC",
      "PURCHASING_STATUS_CHANGE",
      "PURCHASING_SUGGESTION",
      "RESTOCK_AMZ_STATUS_CHANGE",
      "RESTOCK_AMZ_SUGGESTION"
    ];
    return  data.sort((a, b) => order.indexOf(a) - order.indexOf(b));
  }

  getNotificationName(s: string, notificationAmount: number): string {
    let name;
    switch (s) {
      case NOTIFICATIONS_DISPLAY.ACCOUNT_ALERTS.field: {
        name = NOTIFICATIONS_DISPLAY.ACCOUNT_ALERTS.name;
        break;
      }
      case NOTIFICATIONS_DISPLAY.MISSING_NOTIFICATION.field: {
        name = NOTIFICATIONS_DISPLAY.MISSING_NOTIFICATION.name;
        break;
      }
      case NOTIFICATIONS_DISPLAY.PURCHASING_STATUS_CHANGE.field: {
        name = NOTIFICATIONS_DISPLAY.PURCHASING_STATUS_CHANGE.name;
        break;
      }
      case NOTIFICATIONS_DISPLAY.PURCHASING_SUGGESTION.field: {
        name = NOTIFICATIONS_DISPLAY.PURCHASING_SUGGESTION.name;
        break;
      }
      case NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_STATUS_CHANGE.field: {
        name = NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_STATUS_CHANGE.name;
        break;
      }
      case NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_SUGGESTION.field: {
        name = NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_SUGGESTION.name;
        break;
      }
      case NOTIFICATIONS_DISPLAY.METRIC.field: {
        name = NOTIFICATIONS_DISPLAY.METRIC.name;
        break;
      }
    }

    return notificationAmount > 1 && name !== NOTIFICATIONS_DISPLAY.MISSING_NOTIFICATION.name ? `${name}s` : name;
  }

  getBackgroundIcon(s: string) {
    switch (s) {
      case NOTIFICATIONS_DISPLAY.MISSING_NOTIFICATION.field: {
        return NOTIFICATIONS_DISPLAY.MISSING_NOTIFICATION.bgIcon;
      }
      case NOTIFICATIONS_DISPLAY.PURCHASING_STATUS_CHANGE.field: {
        return NOTIFICATIONS_DISPLAY.PURCHASING_STATUS_CHANGE.bgIcon;
      }
      case NOTIFICATIONS_DISPLAY.PURCHASING_SUGGESTION.field: {
        return NOTIFICATIONS_DISPLAY.PURCHASING_SUGGESTION.bgIcon;
      }
      case NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_STATUS_CHANGE.field: {
        return NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_STATUS_CHANGE.bgIcon;
      }
      case NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_SUGGESTION.field: {
        return NOTIFICATIONS_DISPLAY.RESTOCK_AMZ_SUGGESTION.bgIcon;
      }
      case NOTIFICATIONS_DISPLAY.METRIC.field: {
        return NOTIFICATIONS_DISPLAY.METRIC.bgIcon;
      }
    }
  }

  navigateToProfile(): void {
    this.router.navigate(['/profile']);
  }

  navigateToNotification(): void {
    this.router.navigate(['/notification']);
  }

  onClickNotification(notification: any): void {
    this.router.navigate(['/notification'], {
      queryParams: {
        notification: notification
      },
    });
  }

  navigateToSubscription(): void {
    this.router.navigate(['/subscriptions']);
  }

  onChatToggle(friendID) {
    this.friendId = friendID;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('elite-rtl')) {
      this.gradientConfig['rtl-layout'] = true;
    } else {
      this.gradientConfig['rtl-layout'] = false;
    }
  }

  forecastReport(): void {
    if (this.isSyncInProcess || this.isExporting) {
      return;
    }

    this.isExporting = true;

    this.forecastService
      .forecastReport()
      .pipe(this.autoCleanUp())
      .subscribe((res) => {
        const blob = new Blob(['\ufeff', res]);
        const fileName = this.generateFileName();
        this.isExporting = false;
        saveAs(blob, fileName);
      });
  }

  generateFileName(): string {
    const date = formatDate(new Date(), "MM-dd-yyyy_HH-mm-aaaaa'm'", 'en');
    const fileName = `Forecast_${date}.csv`;
    return fileName;
  }

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;

    if (this.isFullScreen) {
      fullScreenAllPage(true);
    } else {
      fullScreenAllPage(false);
    }
  }

  onClickQBSupport() {
    this.isQuicksBookSupport.showDialog = false;
    this.isQuicksBookSupport.showMinorIcon = false;
    return true;
  }

  redirectToHelpCenter() {
    window.open(
      'https://support.forecastrx.com/hc/en-us',
      '_blank',
      'noopener'
    );
  }
}
