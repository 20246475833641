import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { SnotifyService } from 'ng-snotify';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {

  constructor(
    private authService: AuthenticationService,
    private route: Router,
    private snotifyService: SnotifyService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(state.url.includes("administration")) {
        return this.authService.getLoggedInUserFromSession(true).pipe(
          catchError(err => {
            return of(null);
          }),
          map((user) => {
            const [currentUser] = user?.model || [];
            if(!currentUser) {
              this.authService.redirectUrl = {url: state.url.split('?')[0], queryParams: route.queryParams};
              this.route.navigate(['/account/signin']);
              return false;
            }
  
            if(currentUser.isAdmin) {
              return true;
            }
  
            this.route.navigate(['/dashboard/home']).then(() => {
              this.snotifyService.error("Access denied.");
            });          
            return false;
          })
        );
      }
  
      if (this.authService?.currentUser?.isAdmin) {
        return true;
      }
  
      this.route.navigate(['/dashboard/home']).then(() => {
        this.snotifyService.error("Access denied.");
      });
  
      return false;
  }

}
