import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';

import { SortDescriptor } from '@progress/kendo-data-query';

import { GridComponent } from '@progress/kendo-angular-grid';

import 'hammerjs';

@Component({
  selector: 'app-history-data-table-reduced',
  templateUrl: './history-data-table-reduced.component.html',
  styleUrls: ['./history-data-table-reduced.component.scss'],
})
export class HistoryDataTableReducedComponent implements OnInit {
  @ViewChild(GridComponent) private grid: GridComponent;

  @Input() set isLoading(value: boolean) {
    this._isLoading = value;

    if (this.grid) {
      this.grid.loading = value;
    }
  }
  get isLoading(): boolean {
    return this._isLoading;
  }
  @Input() columns: any[];
  @Input() legends: any[];
  @Input() sort: SortDescriptor[];
  @Input() colorMatrix: string[][];
  @Input() gridItems$: Observable<any>;
  @Input() chartData: any[];

  @Output() sortChange = new EventEmitter();
  @Output() cellClick = new EventEmitter();
  @Output() cellClose = new EventEmitter();

  private _isLoading: boolean;

  ngOnInit(): void {
    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];
  }

  sortChangeFunc(sort: SortDescriptor[]): void {
    this.sortChange.emit(sort);
  }

  cellClickFunc(args: any[]): void {
    this.cellClick.emit(args);
  }

  cellCloseFunc(args: any[]): void {
    this.cellClose.emit(args);
  }

  processColor(feature: string = null): string {
    switch (feature) {
      case 'override':
        return '#F6C05E';
      case 'backfill':
        return '#92C5E6';
      case 'copyedit':
        return '#E13129';
      case 'lostSales':
        return '#E0324FE1'
      default:
        return '#3498DB';
    }
  }
}
