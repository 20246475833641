<ul class="navbar-nav mr-auto ml-0">
  <li class="nav-item cursor-pointer pl-0" (click)="navCollapse()">
    <span class="collapse-btn pr-3" *ngIf="!isHiddenIconCollapse">
      <i class="hamburger" [class.icon-visible]="isNavBarCollapsed || isSubscriptionExpired"></i>
      <i
        class="fa fa-chevron-left chevron"
        [class.icon-visible]="!isNavBarCollapsed && !isSubscriptionExpired"
      ></i>
    </span>
  </li>

  <li class="nav-item">
    <app-nav-search
      [isSubscriptionExpired]="isSubscriptionExpired"
    ></app-nav-search>
  </li>

  <li class="nav-item">
    <app-company-dropdown></app-company-dropdown>
  </li>
</ul>
