<div #grid>
  <!-- Toolbar -->
  <div *ngIf="!copyFeature" class="d-flex justify-content-end btn-zone">
    <!-- Copy button -->
    <button
      class="btn btn-outline-secondary btn-copy mx-1 my-3"
      (click)="copyHistory()"
      [disabled]="isCopyDisabled"
    >
      <i class="feather icon-copy"></i> Copy
    </button>

    <!-- Backfill button -->
    <div
      class="mx-1 my-3"
      [ngbTooltip]="backfillInfo"
      triggers="mouseenter:mouseleave"
      placement="bottom"
      closeDelay="300"
    >
      <button
        class="btn"
        [ngClass]="
          itemObject.useBackfill ? 'btn-backfill' : 'btn-backfill--off'
        "
        [disabled]="isBackfillDisabled && !itemObject.useBackfill"
        (click)="toggleBackfill()"
      >
        Backfill: {{ itemObject.useBackfill ? "ON" : "OFF" }}
      </button>
    </div>

    <!-- Overrides Button -->
    <button
      class="btn mx-1 my-3"
      [ngClass]="
        itemObject.useHistoryOverride ? 'btn-override' : 'btn-override--off'
      "
      (click)="toggleOverrides()"
      [disabled]="isOverrideDisabled"
    >
      Overrides: {{ itemObject.useHistoryOverride ? "ON" : "OFF" }}
    </button>

    <!-- Lost Sales Button -->
    <div
      *ngIf="isAscCompany"
      class="mx-1 my-3"
      [ngbTooltip]="lostSalesInfo"
      triggers="mouseenter:mouseleave"
      placement="bottom-right"
      closeDelay="300"
    >
      <button
        class="btn"
        [ngClass]="
          itemObject.useLostSalesOverride ? 'btn-lostsales' : 'btn-lostsales--off'
        "
        [disabled]="isLostSalesButtonDisabled"
        (click)="toggleLostSales()"
      >
        Lost Sales: {{ itemObject.useLostSalesOverride ? "ON" : "OFF" }}
      </button>
    </div>

    <div ngbDropdown class="d-inline-block" dropdownClass="custom-dropdown" container="body">
      <button
        class="mx-1 my-3 btn btn-outline-primary"
        id="dropdownBasic1"
        ngbDropdownToggle
      >
        <span
          *ngIf="grid?.loading"
          title="Please wait while the application is uploading"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        >
        </span>
        Bulk Management
      </button>

      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button class="sub-menu" ngbDropdownItem (click)="downloadInitialTemplate()">
          Download Template
        </button>

        <div ngbDropdown container="body" class="my-1">
          <button
            class="btn btn-outline-primary d-flex justify-content-between align-items-center sub-menu"
            id="dropdownBasic2"
            ngbDropdownToggle
          >
            Edit as overrides
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
            <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Override, UploadHistoryAmountType.Single)">
              Single Item
            </button>
            <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Override, UploadHistoryAmountType.Multiple)">
              Multiple Items
            </button>
          </div>
        </div>

        <div ngbDropdown container="body" class="my-1">
          <button
            class="btn btn-outline-primary d-flex justify-content-between align-items-center sub-menu"
            id="dropdownBasic3"
            ngbDropdownToggle
          >
            Add to existing history
          </button>

          <div ngbDropdownMenu aria-labelledby="dropdownBasic3">
            <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Add, UploadHistoryAmountType.Single)">
              Single Item
            </button>
            <button class="sub-menu" ngbDropdownItem (click)="openImporter(UploadHistoryBehaviorType.Add, UploadHistoryAmountType.Multiple)">
              Multiple Items
            </button>
          </div>
        </div>

        <button
          *ngIf="remainingUndoTime > 0"
          class="sub-menu"
          ngbDropdownItem
          (click)="undoLastUpload()"
        >
          Undo Last Upload ({{remainingUndoTime}} hrs left)
        </button>
      </div>
    </div>

    <img alt="" class="fullScreen" src="{{ iconFullScreen }}" (click)="fullScreen()"/>
  </div>

  <!-- History Chart -->
  <kendo-chart>
    <!-- Chart title -->
    <kendo-chart-title
      [text]="itemObject ? itemObject.name : 'Choose an item'"
    ></kendo-chart-title>

    <!-- Chart area, includes title, legends and data -->
    <kendo-chart-area height="300"></kendo-chart-area>

    <!-- Chart legend -->
    <kendo-chart-legend position="bottom" orientation="horizontal">
    </kendo-chart-legend>

    <!-- Chart data -->
    <kendo-chart-series>
      <kendo-chart-series-item
        *ngFor="let legend of legends; let i = index"
        [name]="legend"
        type="line"
        field="qty"
        categoryField="month"
        [data]="chartData[i]"
      >
        <kendo-chart-series-item-tooltip>
          <ng-template kendoChartSeriesTooltipTemplate let-value="value">
            {{ legend }}: {{ value }}
          </ng-template>
        </kendo-chart-series-item-tooltip>
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>

  <!-- History Table -->
  <kendo-grid
    #grid
    class="grid-history-data-table"
    [data]="gridItems$ | async"
    [sortable]="true"
    [sort]="sort"
    (cellClick)="cellClickHandler($event)"
    (cellClose)="cellCloseHandler($event)"
    (sortChange)="sortChange($event)"
    [resizable]="true"
    [navigatable]="true"
    [height]="190"
  >
    <kendo-grid-column
      *ngFor="let col of columns"
      [field]="col.field"
      [title]="col.displayName"
      [width]="col.field === 'legend' ? 130 : 60"
      class="text-center px-1 py-1"
    >
      <ng-template
        kendoGridEditTemplate
        let-formGroup="formGroup"
        let-column="column"
        let-isNew="isNew"
      >
        <input
          type="number"
          class="k-textbox"
          [formControl]="formGroup.get(col.field)"
        />
        <div
          *ngIf="
            formGroup.get(col.field).invalid &&
            !(isNew && formGroup.get(col.field).untouched)
          "
          class="text-danger text-small"
        >
          <span *ngIf="formGroup.get(col.field).hasError('min')">
            <small>Min is 0.</small>
          </span>
        </div>
      </ng-template>

      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
        let-columnIndex="columnIndex"
      >
        <span
          class="text-center d-block"
          [style.backgroundColor]="
            col.field === 'legend'
              ? 'transparent'
              : processColor(colorMatrix[rowIndex][columnIndex - 1])
          "
          [style.color]="col.field === 'legend' ? 'black' : 'white'"
          [style.fontWeight]="col.field === 'legend' ? 400 : 600"
          >{{ dataItem[col.field] }}</span
        >
      </ng-template>
    </kendo-grid-column>
    <ng-template kendoGridNoRecordsTemplate>
      There are no item(s) to show.
    </ng-template>
  </kendo-grid>
</div>
