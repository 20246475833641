import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
  BreadcrumbModule,
  CardModule,
  CompanyInprogressModule,
  LoaderModule,
  ModalModule,
} from './components';

import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

import { SpinnerComponent } from './components/spinner/spinner.component';
import { ToastComponent } from './components/toast/toast.component';
import { ToastService } from './components/toast/toast.service';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { CoreModule } from 'src/app/core/core.module';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    CompanyInprogressModule,
    BreadcrumbModule,
    ModalModule,
    LoaderModule,
  ],
  exports: [
    CoreModule,
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    CardModule,
    CompanyInprogressModule,
    BreadcrumbModule,
    ModalModule,
    SpinnerComponent,
    ToastComponent,
    LoadingSpinnerComponent,
    LoaderModule,
    NgbCollapseModule
  ],
  declarations: [
    SpinnerComponent,
    ToastComponent,
    LoadingSpinnerComponent,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ToastService,
  ],
})
export class SharedModule {}
