export interface HeaderModel {
  key: string;
  name: string;
  total: number;
  isMoney?: boolean;
}

export enum SummaryItemEnum {
  onHandCount = 'onHandCount',
  onHandThirdPartyCount = 'onHandThirdPartyCount',
  olderThan30DaysCount = 'olderThan30DaysCount',
  missingVendorCount = 'missingVendorCount',
  totalEstimatedStorageFees = 'totalEstimatedStorageFees'
}

export enum SummaryByVendorsEnum {
  count = 'count',
  totalPrice = 'totalPrice',
  uniqueItems = 'uniqueItems',
  totalQty = 'totalQty',
}

export enum SummaryItemNameEnum {
  AmazonItems = 'Amazon Items',
  WarehouseItems = 'Warehouse Items',
  NewItems = 'New Items',
  NoSuppliersAssigned = 'No Suppliers Assigned',
  ItemsOutOfStock = 'Items Out of Stock',
  SuggestedPurchaseOrders = 'Suggested Purchase Orders',
  TotalCostOfSuggestedPurchaseOrders = 'Total Cost of Suggested Purchase Orders',
  TotalItemsOnSuggestedPurchaseOrders = 'Total Items on Suggested Purchase Orders',
  TotalUnitsOnSuggestedPurchaseOrders = 'Total Units on Suggested Purchase Orders',
  TotalEstimatedStorageFees = 'Total Estimated Storage Fees',
}

export enum SummaryItemStatusEnum {
  OutOfStock = 'Out of Stock',
  Lats30Days = '(Last 30 Days)',
}
