import { ValidationErrors } from '@angular/forms';
import { Controls, FormGroup } from 'ngx-strongly-typed-forms';
import { defer, EMPTY, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class Form<T> {
  defaultValue: T;
  isSubmitted = false;
  isSubmitting = false;

  constructor(
    public formGroup: FormGroup<T>,
    private onSubmit: (formValue: T) => Observable<T>
  ) {
    this.defaultValue = this.formGroup?.value;
  }

  get formValue(): T {
    return this.formGroup.value;
  }

  get rawValue(): T {
    return this.formGroup.getRawValue();
  }

  get controls(): Controls<T> {
    return this.formGroup.controls;
  }

  get valid(): boolean {
    return this.formGroup.valid;
  }

  get invalid(): boolean {
    return this.formGroup.invalid;
  }

  get dirty(): boolean {
    return this.formGroup.dirty;
  }

  submit(): Observable<T> {
    this.isSubmitted = true;

    return defer(() => {
      if (this.isSubmitting || this.invalid) {
        return EMPTY;
      }

      this.isSubmitting = true;

      return this.onSubmit(this.formValue).pipe(
        finalize(() => (this.isSubmitting = false))
      );
    });
  }

  update(value: Partial<T>) {
    this.formGroup.patchValue(value);
  }

  enable(controlNames?: (keyof T)[]) {
    if (!controlNames?.length) {
      return this.formGroup.enable();
    }

    controlNames.forEach((controlName) => {
      const control = this.formGroup.controls[controlName];

      control?.enable();
    });
  }

  disable(controlNames?: (keyof T)[]) {
    if (!controlNames?.length) {
      return this.formGroup.disable();
    }

    controlNames.forEach((controlName) => {
      const control = this.formGroup.controls[controlName];

      control?.disable();
    });
  }

  reset(value: T = this.defaultValue) {
    this.isSubmitted = false;
    this.isSubmitting = false;
    this.formGroup.reset(value);
  }

  setError(controlName: keyof T, error: ValidationErrors) {
    const control = this.formGroup.controls[controlName];

    control.setErrors(error);
  }

  hasError(controlName: keyof T): ValidationErrors {
    const control = this.formGroup.controls[controlName];
    const isErrorShown =
      (control?.invalid && control?.dirty) || this.isSubmitted;

    return isErrorShown && control?.errors;
  }
}
