import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[decimalNumber]',
})
export class DecimalNumberDirective {
  readonly VALID_KEYS = ['ArrowLeft', 'ArrowRight', 'Delete', 'Backspace'];

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    const isValid =
      /^\d*\.?\d*$/g.test(event?.key) || this.VALID_KEYS.includes(event?.key); // NOSONAR

    if (!isValid) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent): void {
    const isValid = /^\d*\.?\d*$/g.test(event.clipboardData.getData("text")); // NOSONAR

    if(!isValid) {
      event.preventDefault();
    }
  }
}
