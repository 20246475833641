import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ForecastRXDataTableComponent } from './forecast-rxdata-table/forecast-rxdata-table.component';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { NgbProgressbarModule, NgbPopoverModule, NgbCollapseModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LookupInputComponent } from './lookup-input/lookup-input.component';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ItemCardDetailComponent } from './item-card-detail/item-card-detail.component';
import { GenericDataTableComponent } from './generic-data-table/generic-data-table.component';
import { GenericKendoGridComponent } from './generic-kendo-grid/generic-kendo-grid.component';
import { HistoryDataTableReducedComponent } from './history-data-table-reduced/history-data-table-reduced.component';
import { LookupItemComponent } from './lookup-item/lookup-item.component';
import { HistoryDataTableComponent } from './history-data-table/history-data-table.component';
import { CopyHistoryModalComponent } from './copy-history-modal/copy-history-modal.component';
import { DecimalNumberDirective } from '../components/decimal-number/decimal-number.directive';
import { ImportFailModalComponent } from './import-fail-modal/import-fail-modal.component';
import { VendorHiddenModalComponent } from './vendor-hidden-modal/vendor-hidden-modal.component';
import { GridViewComboboxComponent } from './grid-view-combobox/grid-view-combobox.component';
import { ListInputComponent } from './list-input/list-input.component';
import { KendoSelectFilterComponent } from './kendo-select-filter/kendo-select-filter.component';
import { NoticeToResyncModalComponent } from './notice-to-resync-modal/notice-to-resync-modal.component';
import { ViewOfHistoryComponent } from './view-of-history/view-of-history.component';
import { SaleVelocityCalculationComponent } from './sale-velocity-calculation/sale-velocity-calculation.component';
import {ShipmentPickListViewComponent} from '../../../routes/dashboard/shipments/manage-shipments/shipment-pick-list-view/shipment-pick-list-view.component'
import { PrintStickersModalComponent } from './print-stickers-modal/print-stickers-modal.component';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridItemLookupComponent } from './grid-item-lookup/grid-item-lookup.component';
import { FieldGroupDataTableComponent } from './field-group-data-table/field-group-data-table.component';
import { SalesVelocityModalComponent } from './restock-settings/restock-settings.component';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { EstimatedMarginCalculationModalComponent } from './estimated-margin-calculation-modal/estimated-margin-calculation-modal.component';
import { GenericItemModalComponent } from './generic-item-modal/generic-item-modal.component';
import { EditTagsModalComponent } from './edit-tags-modal/edit-tags-modal.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PopoverComponent } from './popover/popover.component';
import { InboundAnalyzerModalComponent } from './inbound-analyzer-modal/inbound-analyzer-modal.component';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { FilterModule } from '@progress/kendo-angular-filter';
import { CoreModule } from 'src/app/core/core.module';
import { DataOutputPipe } from '../components/data-table/data-output.pipe';
import { DataAddTextUnitToNumberPipe } from '../components/data-table/data-add-text-to-number.pipe';
import { DataTransformOversizePipe } from '../components/data-table/data-transform-oversize.pipe';
import { DataTransformBooleanPipe } from '../components/data-table/data-transform-boolean.pipe';
import { DataResolveFilterTypePipe } from '../components/data-table/data-resolve-filter-type.pipe';
import { DataGetMaxPipe } from '../components/data-table/data-get-max.pipe';
import { DataGetMinPipe } from '../components/data-table/data-get-min.pipe';
import { DataIsSummaryByVendorPipe } from '../components/data-table/data-is-summary-by-vendor.pipe';
import { DataTooltipPipe } from '../components/data-table/data-tooltip.pipe';
import { DataFlagClassPipe } from '../components/data-table/data-flag-class.pipe';
import { DataIsSortMenuColumnsPipe } from '../components/data-table/data-is-sort-menu-columns.pipe';
import { DataIsFilterMenuColumnsPipe } from '../components/data-table/data-is-filter-menu-columns.pipe';
import { DataSetWidthColumnPipe } from '../components/data-table/data-set-width-column.pipe';
import { DataGridClassPipe } from '../components/data-table/data-grid-class.pipe';
import { DataReorderColumnPipe } from '../components/data-table/data-reorder-column.pipe';
import { CompanyDropdownComponent } from './company-dropdown/company-dropdown.component';
import { InputFormModalComponent } from 'src/app/core/services/modal/input-form-modal/input-form-modal.component';
import { CreatePoWizardModalComponent } from 'src/app/core/services/modal/create-po-wizard-modal/create-po-wizard-modal.component';
import { CreateSupplierModalComponent } from 'src/app/core/services/modal/create-supplier-modal/create-supplier-modal.component';
import { CreateAddressModalComponent } from 'src/app/core/services/modal/create-address-modal/create-address-modal.component';
import { UploadImageModalComponent } from 'src/app/core/services/modal/upload-image-modal/upload-image-modal.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CompleteComponent } from 'src/app/routes/dashboard/shipments/steps/complete/complete.component';

@NgModule({
  declarations: [
    CreateSupplierModalComponent,
    CreatePoWizardModalComponent,
    InputFormModalComponent,
    DataOutputPipe,
    DataAddTextUnitToNumberPipe,
    DataTransformOversizePipe,
    DataResolveFilterTypePipe,
    DataTransformBooleanPipe,
    DataGetMaxPipe,
    DataIsSummaryByVendorPipe,
    DataTooltipPipe,
    DataFlagClassPipe,
    DataGetMinPipe,
    DataIsSortMenuColumnsPipe,
    DataIsFilterMenuColumnsPipe,
    DataSetWidthColumnPipe,
    DataGridClassPipe,
    DataReorderColumnPipe,
    ForecastRXDataTableComponent,
    FileUploadModalComponent,
    LookupInputComponent,
    ItemCardDetailComponent,
    GenericDataTableComponent,
    GenericKendoGridComponent,
    HistoryDataTableReducedComponent,
    LookupItemComponent,
    HistoryDataTableComponent,
    CopyHistoryModalComponent,
    DecimalNumberDirective,
    ImportFailModalComponent,
    VendorHiddenModalComponent,
    GridViewComboboxComponent,
    ListInputComponent,
    KendoSelectFilterComponent,
    NoticeToResyncModalComponent,
    ViewOfHistoryComponent,
    PrintStickersModalComponent,
    GridItemLookupComponent,
    ShipmentPickListViewComponent,
    FieldGroupDataTableComponent,
    SalesVelocityModalComponent,
    SaleVelocityCalculationComponent,
    EstimatedMarginCalculationModalComponent,
    GenericItemModalComponent,
    EditTagsModalComponent,
    PopoverComponent,
    InboundAnalyzerModalComponent,
    CompanyDropdownComponent,
    CreateAddressModalComponent,
    UploadImageModalComponent,
    UploadImageComponent,
    CompleteComponent
  ],
  exports: [
    CreateAddressModalComponent,
    CreateSupplierModalComponent,
    CreatePoWizardModalComponent,
    InputFormModalComponent,
    ForecastRXDataTableComponent,
    FieldGroupDataTableComponent,
    LookupInputComponent,
    ItemCardDetailComponent,
    LookupItemComponent,
    HistoryDataTableComponent,
    DecimalNumberDirective,
    ListInputComponent,
    KendoSelectFilterComponent,
    GridItemLookupComponent,
    SalesVelocityModalComponent,
    GridViewComboboxComponent,
    CompanyDropdownComponent,
    CoreModule,
    UploadImageComponent,
    CompleteComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    GridModule,
    ComboBoxModule,
    DropDownsModule,
    NgbPopoverModule,
    NgbProgressbarModule,
    NgbCollapseModule,
    NgbTooltipModule,
    NgbModule,
    ExcelModule,
    ChartsModule,
    ButtonsModule,
    DateInputsModule,
    IntlModule,
    LabelModule,
    InputsModule,
    WindowModule,
    FilterModule,
    ImageCropperModule,
  ],
  bootstrap: [
    CreateAddressModalComponent,
    CreateSupplierModalComponent,
    CreatePoWizardModalComponent,
    InputFormModalComponent,
    ForecastRXDataTableComponent,
    FieldGroupDataTableComponent
  ]
})
export class ForecastuiModule {}
