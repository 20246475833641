export class Address {
  addressType: string = '';
  addressName: string = '';
  address1: string = '';
  addressCity: string = '';
  addressState: string = '';
  addressCountry: string = '';
  addressPostalCode: string = '';
  phone: string = '';
  addressUuid?: string = '';
  addressTableKey?: string = '';
  addressKey?: string = '';
  address2?: string = '';
  address3?: string = '';
  address4?: string = '';
  address5?: string = '';
  email?: string = '';
  isDefault?: boolean;

  constructor(init?: Partial<Address>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}