// Headers
export const PRICE_DISPLAY_NAMES = {
  average7DayPrice: 'Average 7-day Sales Price',
  newBuyBox: 'New buy Box Price',
  listPrice: 'Listing Price',
  lowestFba: 'Lowest FBA Offer Price'
}

export const COST_DISPLAY_NAMES = {
  currentSupplierCost: 'Current Supplier Cost',
  inboundShippingCost: 'Inbound Shipping Cost',
  reshippingCost: 'Reshipping Cost',
  repackagingMaterialCost: 'Repackaging Material Cost',
  repackagingLaborCost: 'Repackaging Labor Cost',
  supplierRebate: 'Supplier Rebate',
  total: 'TOTAL COSTS'
}

export const AMAZON_FEES_DISPLAY_NAMES = {
  fbaFees: 'FBA Fees',
  referralFee: 'Referral Fee',
  monthlyStorageFee: 'Monthly Storage Fee',
  variableClosingFee: 'Variable Closing Fee',
  total: 'TOTAL AMAZON FEES'
}

export const ESTIMATED_HEADERS = {
  price: 'Price',
  totalCosts: 'Total Costs',
  amazonFees: 'Amazon Fees',
  estimatedMargin: 'Estimated Margin',
  estimatedMarkup: 'Estimated Markup'
}

export enum Price {
  average7DayPrice = 'average7DayPrice',
  newBuyBox = 'newBuyBox',
  listPrice = 'listPrice',
  lowestFba = 'lowestFba'
}
