import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Logic } from 'src/app/core/infrastructure/enums/logic.enum';

@Component({
  selector: 'app-grid-item-lookup',
  templateUrl: './grid-item-lookup.component.html',
})
export class GridItemLookupComponent implements OnInit, AfterViewInit {
  @ViewChild('searchBar') private searchBar: ElementRef;

  @Input() fieldsToLookUp: string[] = [];
  @Input() customPlaceholder: string;
  @Input() itemName: any;
  @Input() set defaultSearchTerm(value: string) {
    this._defaultSearchTerm = value;
    if (!this.searchBar) {
      return;
    }

    this.searchBar.nativeElement.value =
      value && value !== 'undefined' ? value : '';
  }
  get defaultSearchTerm(): string {
    return this._defaultSearchTerm;
  }
  private _defaultSearchTerm: string;

  @Output() onSearch: EventEmitter<any> = new EventEmitter<any>();

  searchTerm$: Subject<string> = new Subject<string>();

  ngOnInit(): void {
    this.searchTerm$
      .pipe(
        // wait 500ms after each keystroke before considering the term
        debounceTime(500),
        // ignore new term if same as previous term
        distinctUntilChanged()
      )
      .subscribe((searchTerm) => {
        this.onSearch.emit({
          filters: this.constructFilters(searchTerm),
          searchTerm,
        });
      });
  }

  ngAfterViewInit(): void {
    if (this.defaultSearchTerm && this.defaultSearchTerm !== 'undefined') {
      this.searchBar.nativeElement.value =
        this.defaultSearchTerm && this.defaultSearchTerm !== 'undefined'
          ? this.defaultSearchTerm
          : '';
    }
  }

  search(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  constructFilters(searchTerm: string) {
    return searchTerm
      ? [
          {
            logic: Logic.or,
            filters: this.fieldsToLookUp.map((f) => ({
              field: f,
              operator: 'contains',
              value: searchTerm,
            })),
          },
        ]
      : [];
  }

  extractFilters(filters): string {
    let searchTerm = '';
    const [filter] = filters || [];

    if (filters?.length) {
      searchTerm = filter?.filters?.find((item) => item?.value && item).value;
    }

    return searchTerm;
  }
}
