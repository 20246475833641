<app-input-form-modal
  *ngIf="modalConfig"
  [inputFields]="purchaseOrderMetadataFields"
  [modalConfig]="modalConfig"
  [selectedVendor]="createdVendor"
  (addNewSupplier)="openAddNewSupplier()"
  (cancel)="onCancel()"
  (save)="onSave($event)"
>
</app-input-form-modal>
