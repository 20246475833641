import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationItem } from '../navigation';
import { GradientConfig } from '../../../../../app-config';
import { Location } from '@angular/common';
import { AuthenticationService } from '@stockaid/services';
import { HeaderService } from 'src/app/core/services/header.service';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { Company } from 'src/app/core/models/company';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { WarehouseService } from 'src/app/core/services/warehouse.service';

@Component({
  selector: 'app-nav-content',
  templateUrl: './nav-content.component.html',
  styleUrls: ['./nav-content.component.scss']
})
export class NavContentComponent extends BaseComponent implements OnInit, AfterViewInit {
  public gradientConfig: any;
  public navigation: any;
  public prevDisabled: string;
  public nextDisabled: string;
  public contentWidth: number;
  public wrapperWidth: any;
  public scrollWidth: any;
  public windowWidth: number;
  public DEFAULT_COMPANY_KEY = '0';

  @Output() onNavMobCollapse = new EventEmitter();

  @ViewChild('navbarContent', { static: false }) navbarContent: ElementRef;
  @ViewChild('navbarWrapper', { static: false }) navbarWrapper: ElementRef;

  constructor(
    public nav: NavigationItem,
    private location: Location,
    private authService: AuthenticationService,
    private headerService: HeaderService,
    private warehouseService: WarehouseService
  ) {
    super();

    this.gradientConfig = GradientConfig.config;
    this.windowWidth = window.innerWidth;

    this.navigation = this.nav.get();

    this.prevDisabled = 'disabled';
    this.nextDisabled = '';
    this.scrollWidth = 0;
    this.contentWidth = 0;
  }

  private warehouseItemCheck(warehouses, companyType) {
    let advancedNavItem = (this.navigation as NavigationItem[])[0]
      .children[0]?.children.find((item) => item.id === 'advanced');

    if (advancedNavItem) {
      const warehouseNavItem = advancedNavItem.children.find((item) => item.id === 'warehouses');
      warehouseNavItem.hidden = !warehouses?.length;
      warehouseNavItem.hidden = companyType === CompanyType.CSV
        ? false
        : warehouseNavItem.hidden;
    }
  }

  private adminNavItemCheck() {
    //hard coded to the first "navigation group"
    let adminNavItem = (this.navigation as NavigationItem[])[0].children.find((item) => item.id === 'administration');

    if (adminNavItem) {
      adminNavItem.hidden = !this.authService.currentUser?.isAdmin ?? true;
    }
  }

  private shipmentNavItemCheck(company: Company) {
    const shipmentNavItem = (this.navigation as NavigationItem[])[0].children.find((item) => item.id === 'shipments');

    if (shipmentNavItem) {
      shipmentNavItem.hidden = company?.companyType !== CompanyType.ASC
      || (!this.authService.currentUser?.isAdmin && !company?.displayRestockAMZ);
    }
  }

  ngOnInit() {
    if (this.windowWidth < 992) {
      this.gradientConfig['layout'] = 'vertical';
      setTimeout(() => {
        document.querySelector('.pcoded-navbar').classList.add('menupos-static');
        document.querySelector<HTMLElement>('#nav-ps-gradient-able').style.maxHeight = '100%';
      }, 500);
    }

    this.headerService.getAdminStatusChange()
      .pipe(this.autoCleanUp())
      .subscribe((change) => {
        if (change) {
          // show/hide admin nav item.
          this.adminNavItemCheck();
        }
      });

    this.headerService.getCompany()
      .pipe(this.autoCleanUp())
      .subscribe((company) => {
        this.shipmentNavItemCheck(company);

        // Check warehouse is existed.
        if(company?.companyKey && company?.companyKey !== this.DEFAULT_COMPANY_KEY ){
          this.warehouseService.getAll(true).pipe(this.autoCleanUp())
            .subscribe((warehouses) => {
              this.warehouseItemCheck(warehouses, company?.companyType);
          })
        }
      })
  }

  ngAfterViewInit() {
    if (this.gradientConfig['layout'] === 'horizontal') {
      this.contentWidth = this.navbarContent.nativeElement.clientWidth;
      this.wrapperWidth = this.navbarWrapper.nativeElement.clientWidth;
    }
  }

  scrollPlus() {
    this.scrollWidth = this.scrollWidth + (this.wrapperWidth - 80);
    if (this.scrollWidth > (this.contentWidth - this.wrapperWidth)) {
      this.scrollWidth = this.contentWidth - this.wrapperWidth + 80;
      this.nextDisabled = 'disabled';
    }
    this.prevDisabled = '';
    if (this.gradientConfig.rtlLayout) {
      document.querySelector<HTMLElement>('#side-nav-horizontal').style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      document.querySelector<HTMLElement>('#side-nav-horizontal').style.marginLeft = '-' + this.scrollWidth + 'px';
    }

    console.log('window width - ', this.windowWidth);
    console.log('content width - ', this.contentWidth);
    console.log('wrapper width - ', this.wrapperWidth);
    console.log('scroll width - ', this.scrollWidth);
  }

  scrollMinus() {
    this.scrollWidth = this.scrollWidth - this.wrapperWidth;
    if (this.scrollWidth < 0) {
      this.scrollWidth = 0;
      this.prevDisabled = 'disabled';
    }
    this.nextDisabled = '';
    if (this.gradientConfig.rtlLayout) {
      document.querySelector<HTMLElement>('#side-nav-horizontal').style.marginRight = '-' + this.scrollWidth + 'px';
    } else {
      document.querySelector<HTMLElement>('#side-nav-horizontal').style.marginLeft = '-' + this.scrollWidth + 'px';
    }
  }

  fireLeave() {
    const sections = document.querySelectorAll('.pcoded-hasmenu');
    sections.forEach((section) => {
      section.classList.remove('active');
      section.classList.remove('pcoded-trigger');
    });

    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (ele !== null && ele !== undefined) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        last_parent.classList.add('active');
      }
    }
  }

  navMob() {
    if (this.windowWidth < 992 && document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
      this.onNavMobCollapse.emit();
    }
  }

  fireOutClick() {
    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }
    const link = "a.nav-link[ href='" + current_url + "' ]";
    const ele = document.querySelector(link);
    if (![null, undefined].includes(ele)) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      if (parent.classList.contains('pcoded-hasmenu')) {
        if (this.gradientConfig['layout'] === 'vertical') {
          parent.classList.add('pcoded-trigger');
        }
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        if (this.gradientConfig['layout'] === 'vertical') {
          up_parent.classList.add('pcoded-trigger');
        }
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        if (this.gradientConfig['layout'] === 'vertical') {
          last_parent.classList.add('pcoded-trigger');
        }
        last_parent.classList.add('active');
      }
    }
  }

}
