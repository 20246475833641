import { GridState } from './../infrastructure/classes/grid-state';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';

export class GridView {
  key: string;
  name: string;
  itemType: string;
  gridState: GridState = new GridState();
  isDefault: boolean;
  constructor(init?: Partial<GridView>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export interface IGridViewChangeEvent {
  selectedGridView: GridView;
  columns: MetaDataField[];
  gridState: State;
  searchTerm: string;
  removalOrdersFilter: CompositeFilterDescriptor;
}
