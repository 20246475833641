import { Injectable } from '@angular/core';
import { ResourceService } from './resource-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { State } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { Pagination } from '../infrastructure/classes/pagination';
import { stringifyJSONWithDates } from '@stockaid/utils';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderItemService extends ResourceService<any> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/purchase-order-item');
  }

  getPurchaseOrderItemsByPOKey(
    key: string,
    state: State = {},
    marketplaceId?: string,
    currencyCode?: string,
    shouldIncludeSupplierSku?: boolean
  ): Observable<any[]> {
    const { skip, take, sort, filter } = state;
    const pagination = new Pagination({ offset: skip || 0, limit: take });
    const sortArray = this.parseSortT(sort || []);
    let params = new HttpParams();

    if (pagination) {
      params = params.set('offset', pagination.offset?.toString());
      params = params.set('limit', pagination.limit?.toString());
    }

    if (sortArray.length) {
      params = params.set('sort', JSON.stringify(sortArray));
    }

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (key) {
      params = params.set('key', key);
    }

    if (currencyCode && marketplaceId) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    if (shouldIncludeSupplierSku) {
      params = params.set('shouldIncludeSupplierSku', String(shouldIncludeSupplierSku))
    }

    return this.httpClient.get<any[]>(this.apiUrl, { params });
  }

  getPurchaseOrderItemCountByPOKey(
    key: string,
    filter = null
  ): Observable<number> {
    let params = new HttpParams();

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (key) {
      params = params.set('key', key);
    }

    return this.httpClient.get<number>(`${this.apiUrl}/count`, { params });
  }
}
