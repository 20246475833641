import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Result, IResultResponse } from '../models/result';
import { map } from 'rxjs/operators';
import { ResourceService } from './resource-service';

@Injectable({
  providedIn: 'root',
})
export class ResultService extends ResourceService<Result> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/result');
  }

  getResult(itemKey: string): Observable<Result> {
    return this.httpClient
      .get<IResultResponse>(`${this.apiUrl}/${encodeURIComponent(itemKey)}`)
      .pipe(map((Iresult) => Iresult.model));
  }

  getResultByMultipleItemIds(itemKeys: string[]): Observable<Result[]> {
    return this.httpClient.post<Result[]>(`${this.apiUrl}`, { itemKeys });
  }
}
