
import { transformOversizeToYesNo } from '@stockaid/shared-utils';

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dataTransformOversize1'
})

export class DataTransformOversizePipe implements PipeTransform {
  transform(value: string): any {
    return transformOversizeToYesNo(value);
  }
}
