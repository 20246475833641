import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { debounce } from 'underscore';
import { isString } from 'lodash';

///this causes in infinite loop of requests.  Research.
// const isServerError = (err : HttpErrorResponse) => err.status >= 400;
// const retryServerErrors = <T>() => {
//   let tryCount: number = 2;
//   return pipe(
//     retryWhen<T>(errors => errors.pipe(
//       mergeMap(
//         err => {
//           if (isServerError(err) && tryCount > 0){
//             tryCount--;
//             return interval(2000).pipe(take(1));
//           }
//           return throwError(err);
//         }
//       )
//     )),
//     tap(_ => tryCount = 2)
//   );
// }

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private route: Router,
    private snotifyService: SnotifyService
  ) {}

  private debouncedFireError = debounce(
    (message) => {
      this.snotifyService.error(message);
    },
    500,
    true
  );

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 200) {
          return next.handle(request);
        }

        if (error.status === 401) {
          this.authService.clearUserInfo();
          this.route.navigate(['/account/signin']);
        } else {
          const { errorMessage } = error.error || {};

          if (isString(errorMessage)) {
            switch (true) {
              case [500, 503].includes(error.status):
                this.debouncedFireError(errorMessage);
                break;

              case !error?.error?.errors?.length:
                this.snotifyService.error(errorMessage)
                break;
            }
          }

          return throwError(error);
        }
      })
    ) as Observable<HttpEvent<any>>;
  }
}
