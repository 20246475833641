import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Summary, ISummaryResponse } from '../models/summary';
import { map, shareReplay } from 'rxjs/operators';
import { ResourceService } from './resource-service';
import { POItem } from '../models/po-items';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Pagination } from '../infrastructure/classes/pagination';
import { SummaryOnHandType } from '../infrastructure/enums/summary-on-hand-type.enum';
import { stringifyJSONWithDates } from 'src/app/core/utils';
import _ from 'lodash';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root',
})
export class SummaryService extends ResourceService<POItem> {
  protected dataCached = {
    poItems: [],
    state: new BehaviorSubject<POItem[]>([]),
  };

  private allSummariesCache$: Observable<any>;
  private summaryCountCache$: Observable<number>;
  private summaryFilter: any;
  private summaryByVendorCountCache$: Observable<number>;
  private summaryByVendorFilter: any;
  private currentVendorKey: string;

  constructor(protected httpClient: HttpClient, private companyService: CompanyService) {
    super(httpClient, '/api/summary');
  }

  getSummary(itemKey: string): Observable<Summary> {
    return this.httpClient
      .get<ISummaryResponse>(`${this.apiUrl}?id=${encodeURIComponent(itemKey)}`)
      .pipe(
        map((summaryRes) => {
          return summaryRes.model?.[0];
        })
      );
  }

  getAllSummaries(reloadCache: boolean = false): Observable<Summary[]> {
    const httpRes$ = this.httpClient
      .get<ISummaryResponse>(`${this.apiUrl}?type=all`)
      .pipe(map((Isummary) => Isummary.model));

    if (!this.allSummariesCache$ || reloadCache) {
      this.allSummariesCache$ = httpRes$.pipe(shareReplay(1));
    }

    return this.allSummariesCache$;
  }

  getCount(filter: any = null, altApiUrl = ''): Observable<number> {
    let params = new HttpParams();
    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    const httpRes$ = this.httpClient.get<number>(`${altApiUrl || this.apiUrl}/count`, {
      params,
    });
    if (
      !this.summaryCountCache$ ||
      !_.isEqual(this.summaryFilter, filter) ||
      this.currentCompanyKey !== this.companyService.currentCompany()?.companyKey
    ) {
      this.summaryFilter = filter;
      this.summaryCountCache$ = httpRes$.pipe(shareReplay(1));
      this.currentCompanyKey = this.companyService.currentCompany()?.companyKey;
    }

    return this.summaryCountCache$;
  }

  getSummariesAdvanced(state: {
    type: string;
    skip: number;
    take: number;
    filter: any;
    sort: SortDescriptor[];
    marketplaceId: string;
    currencyCode: string;
  }): Observable<{ model: Summary[]; count: number }> {
    const { type, skip, take, filter, sort, marketplaceId, currencyCode } = state;

    let params = this.setupParams({
      skip,
      take,
      filter,
      sort,
    });

    params = params.set('type', type);

    if (currencyCode) {
      params = params.set('marketplaceId', marketplaceId);
      params = params.set('currencyCode', currencyCode);
    }

    return this.httpClient.get<{ model: Summary[]; count: number }>(
      `${this.apiUrl}/advanced`,
      { params }
    );
  }

  getItemsBySummaryVendor(state: {
    vendorKey: string;
    removedItemKeys: string[];
    skip: number;
    take: number;
    filter: any;
    sort: SortDescriptor[];
    marketplaceId?: string;
    currencyCode?: string
  }): Observable<ISummaryResponse> {
    const { vendorKey, removedItemKeys, skip, take, filter, sort, marketplaceId, currencyCode} = state;

    let params = this.setupParams({
      skip,
      take,
      filter,
      sort,
    });

    params = params.set('vendorKey', vendorKey);

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    return this.httpClient.post<ISummaryResponse>(`${this.apiUrl}/vendor`, {removedItemKeys}, { params });
  }

  getUnitTransits(): Observable<any> {
    return this.httpClient.get<any>(`${this.apiUrl}/unit-transits`);
  }

  getTopSellingItems(totalDay: number, summaryOnHandType: SummaryOnHandType): Observable<any> {
    const params = new HttpParams()
      .set('summaryOnHandType', encodeURIComponent(summaryOnHandType));

    return this.httpClient.get<any>(`${this.apiUrl}/top-selling-items/${totalDay}`, { params });
  }

  getProjectedSellingItems(summaryOnHandType: SummaryOnHandType): Observable<any> {
    const params = new HttpParams()
      .set('summaryOnHandType', encodeURIComponent(summaryOnHandType));

    return this.httpClient.get<any>(`${this.apiUrl}/projected-selling-items`, { params });
  }

  getItemsRequiringOrdering(summaryOnHandType: SummaryOnHandType): Observable<any> {
    const params = new HttpParams()
      .set('summaryOnHandType', encodeURIComponent(summaryOnHandType));

    return this.httpClient.get<any>(`${this.apiUrl}/items-requiring-ordering`, { params });
  }

  getItemsRequiringRestock(summaryOnHandType: SummaryOnHandType): Observable<any> {
    const params = new HttpParams()
      .set('summaryOnHandType', encodeURIComponent(summaryOnHandType));

    return this.httpClient.get<any>(`${this.apiUrl}/items-requiring-restock`, { params });
  }

  getItemsWithHighestStorageFees(summaryOnHandType: SummaryOnHandType, marketplaceId?: string, currencyCode?: string): Observable<any> {
    const params = new HttpParams()
      .set('summaryOnHandType', encodeURIComponent(summaryOnHandType))
      .set('marketplaceId', marketplaceId)
      .set('currencyCode', currencyCode);

    return this.httpClient.get<any>(`${this.apiUrl}/items-with-highest-storage-fees`, { params });
  }

  getCountByVendorKey(
    filter: any,
    vendorKey: string
  ): Observable<number> {
    let params = new HttpParams();
    filter = filter || null;

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }
    // Null vendor key means getting count of items without vendor
    params = params.set('vendorKey', vendorKey);

    const httpRes$ = this.httpClient.get<number>(`${this.apiUrl}/count`, { params });
    if (
      !this.summaryByVendorCountCache$ ||
      !_.isEqual(this.summaryByVendorFilter, filter) ||
      this.currentVendorKey !== vendorKey ||
      this.currentCompanyKey !== this.companyService.currentCompany()?.companyKey
    ) {
      this.summaryByVendorFilter = filter;
      this.currentVendorKey = vendorKey;
      this.summaryByVendorCountCache$ = httpRes$.pipe(shareReplay(1));
      this.currentCompanyKey = this.companyService.currentCompany()?.companyKey;
    }

    return this.summaryByVendorCountCache$
  }

  saveItemsToCache(items: POItem[] = []): void {
    if (!items.length) {
      this.dataCached.poItems = items;
    }

    this.dataCached.poItems = this.dataCached.poItems.concat(items);
    this.dataCached.state.next(this.dataCached.poItems);
  }

  saveCachedPOItem(item: POItem): void {
    this.dataCached.poItems.push(item);
    this.dataCached.state.next(this.dataCached.poItems);
  }

  removeCachedPOItem(itemKey: string): void {
    const deleteIndex = this.dataCached.poItems.findIndex(
      (item) => item.itemKey === itemKey
    );
    this.dataCached.poItems.splice(deleteIndex, 1);
    this.dataCached.state.next(this.dataCached.poItems);
  }

  getCachedPOItems(): Observable<POItem[]> {
    return this.dataCached.state;
  }

  getTotalItems(): number {
    return this.dataCached.poItems.length;
  }

  private setupParams(state: {
    skip: number;
    take: number;
    filter: any;
    sort: SortDescriptor[];
  }): HttpParams {
    const { skip, take, filter, sort } = state;

    const pagination = new Pagination({ offset: skip, limit: take });

    const sortArray = this.parseSortT(sort);

    let params = new HttpParams();

    if (pagination) {
      params = params.set('offset', pagination.offset?.toString());
      params = params.set('limit', pagination.limit?.toString());
    }

    if (sortArray.length) {
      params = params.set('sort', JSON.stringify(sortArray));
    }

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    return params;
  }
}
