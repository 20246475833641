import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { Observable, asapScheduler } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { State } from '@progress/kendo-data-query';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { ColumnWidth } from 'src/app/core/infrastructure/enums/column-width.enum';
import { ShipmentField, ShipmentDetailField } from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { ShipmentStatus } from 'src/app/core/infrastructure/enums/shipment-status.enum';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { MetadataService } from 'src/app/core/services/metadata.service';
import { amazonShipmentDetailLink } from 'src/app/core/constants/amazon-link.constant';
import { DonutChart, DonutChartField, DonutChartName, STATUS_CHART } from './inbound-analyzer.constant';
import { COMMON_FORMAT_DATE } from 'src/app/core/constants/format-date.constant';

@Component({
  selector: 'app-inbound-analyzer-modal',
  templateUrl: './inbound-analyzer-modal.component.html',
  styleUrls: ['./inbound-analyzer-modal.component.scss']
})
export class InboundAnalyzerModalComponent
  extends BaseComponent
  implements OnInit {
  @Input() itemName: string;
  @Input() itemKey: string;

  @ViewChild(GridComponent) grid: GridComponent;

  readonly INBOUND_STATUS_TABLE_DATA = [
    ShipmentStatus.WORKING, ShipmentStatus.IN_TRANSIT, ShipmentStatus.RECEIVING, 'TOTAL'
  ];

  readonly INBOUND_SUMMARY_TABLE_DATA = [
    ShipmentStatus.WORKING,
    ShipmentStatus.SHIPPED,
    ShipmentStatus.IN_TRANSIT,
    ShipmentStatus.RECEIVING,
    ShipmentStatus.DELIVERED,
    ShipmentStatus.CHECKED_IN,
    ShipmentStatus.ERROR,
    'TOTAL'
  ];

  readonly ShipmentField = ShipmentField;
  gridState: State = {
    skip: 0,
    take: 20,
    sort: [],
  };

  readonly FORMAT_DATE = COMMON_FORMAT_DATE;
  
  gridItems$: Observable<GridDataResult>;
  gridHeight: number;
  DEFAULT_MINIMUM_GRID_HEIGHT = 430;

  countStatus: number[];
  summaries: string[];
  summaryTotal: string = '';
  pageSizes = [
    { text: '20', value: 20 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
  ];

  fields = [
    ShipmentField.shipmentId,
    ShipmentField.shipmentName,
    ShipmentDetailField.shipmentQty,
    ShipmentDetailField.receivedQty,
    ShipmentDetailField.receiveDate,
    ShipmentDetailField.difference,
    ShipmentField.shipmentSource,
    ShipmentField.destinationFulfillmentCenterId,
    ShipmentField.status,
    ShipmentField.orderNotes,
    ShipmentField.updatedAt,
  ];
  metadataFields: MetaDataField[];

  differenceByStatusChart: any;
  statusCharts: any;
  totalStatus: number = 0;
  totalShipmentQty: number = 0;
  totalReceived: number = 0;
  totalDifference: number = 0;

  readonly DonutChartName = DonutChartName;
  readonly STATUS_CHART = STATUS_CHART;
  DIFFERENCE_BY_STATUS_CHART = [
    new DonutChart(DonutChartField.working.displayName, 0, DonutChartField.working.colorField),
    new DonutChart(DonutChartField.inTransit.displayName, 0, DonutChartField.inTransit.colorField),
    new DonutChart(DonutChartField.receiving.displayName, 0, DonutChartField.receiving.colorField),
  ];

  STATUS_CHARTS = [
    {
      name: DonutChartName.ShipmentQty,
      field: [
        new DonutChart(DonutChartField.working.displayName, 0, DonutChartField.working.colorField),
        new DonutChart(DonutChartField.shipped.displayName, 0, DonutChartField.shipped.colorField),
        new DonutChart(DonutChartField.inTransit.displayName, 0, DonutChartField.inTransit.colorField),
        new DonutChart(DonutChartField.receiving.displayName, 0, DonutChartField.receiving.colorField),
        new DonutChart(DonutChartField.delivered.displayName, 0, DonutChartField.delivered.colorField),
        new DonutChart(DonutChartField.checkedIn.displayName, 0, DonutChartField.checkedIn.colorField),
        new DonutChart(DonutChartField.error.displayName, 0, DonutChartField.error.colorField)
      ],
      isShowToolTip: true
    },
    {
      name: DonutChartName.Received,
      field: [
        new DonutChart(DonutChartField.working.displayName, 0, DonutChartField.working.colorField),
        new DonutChart(DonutChartField.shipped.displayName, 0, DonutChartField.shipped.colorField),
        new DonutChart(DonutChartField.inTransit.displayName, 0, DonutChartField.inTransit.colorField),
        new DonutChart(DonutChartField.receiving.displayName, 0, DonutChartField.receiving.colorField),
        new DonutChart(DonutChartField.delivered.displayName, 0, DonutChartField.delivered.colorField),
        new DonutChart(DonutChartField.checkedIn.displayName, 0, DonutChartField.checkedIn.colorField),
        new DonutChart(DonutChartField.error.displayName, 0, DonutChartField.error.colorField)
      ],
      isShowToolTip: true
    },
    {
      name: DonutChartName.Difference,
      field: [
        new DonutChart(DonutChartField.working.displayName, 0, DonutChartField.working.colorField),
        new DonutChart(DonutChartField.shipped.displayName, 0, DonutChartField.shipped.colorField),
        new DonutChart(DonutChartField.inTransit.displayName, 0, DonutChartField.inTransit.colorField),
        new DonutChart(DonutChartField.receiving.displayName, 0, DonutChartField.receiving.colorField),
        new DonutChart(DonutChartField.delivered.displayName, 0, DonutChartField.delivered.colorField),
        new DonutChart(DonutChartField.checkedIn.displayName, 0, DonutChartField.checkedIn.colorField),
        new DonutChart(DonutChartField.error.displayName, 0, DonutChartField.error.colorField)
      ],
      isShowToolTip: true
    },
  ]

  constructor(
    public activeModal: NgbActiveModal,
    private shipmentService: ShipmentService,
    private metaDataService: MetadataService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadMetaDataFields();
    this.loadGridItems();
    this.setDefaultMinimumGridHeight();
  }

  private loadMetaDataFields(): void {
    this.metaDataService.getShipmentMetaData().subscribe((metaData) => {
      this.metadataFields = metaData.fields;
    })
  }

  loadGridItems(): void {
    this.setIsLoading(true);
    this.gridItems$ = this.shipmentService.getInboundAnalyzerByItemKey(this.itemKey).pipe(
      this.autoCleanUp(),
      map((results) => {
        // total amount of shipments by status
        this.countStatus = results?.countStatus;
        this.getDifferenceByStatusChart();

        // summary amount of items by status
        this.summaries = results?.summaries;
        this.getStatusCharts();
        this.setIsLoading(false);

        return { total: results.count, data: results.shipments } as GridDataResult;
      })
    );
  }

  formatTotal(totalStatus: number) {
    let result = totalStatus.toString();
    return result.length > 4 ? result.substring(0, 4) + '...' : result;
  }

  getDifferenceByStatusChart() {
    this.totalStatus = Number(this.countStatus[this.countStatus.length - 1]);
    if (this.totalStatus <= 0) {
      this.differenceByStatusChart = [new DonutChart(DonutChartField.null.displayName, 1, DonutChartField.null.colorField)];
    }
    else {
      this.differenceByStatusChart = this.DIFFERENCE_BY_STATUS_CHART;
      this.differenceByStatusChart.map((s: any, index: number) => {
        s.value = this.countStatus[index];
      })

    }
  }

  getStatusCharts() {
    this.statusCharts = this.STATUS_CHARTS;
    this.summaries.forEach((summary: any, index: number) => {
      let s = summary.split("/").map(Number);

      if (this.statusCharts[0].field[index]
        && this.statusCharts[1].field[index]
        && this.statusCharts[2].field[index]) {
        this.statusCharts[0].field[index].value = s[0];
        this.statusCharts[1].field[index].value = s[1];
        this.statusCharts[2].field[index].value = s[2];
      }

      this.totalShipmentQty = s[0];
      this.totalReceived = s[1];
      this.totalDifference = s[2];
    });

    this.handleNotValueChart();

  }

  handleNotValueChart() {
    if (this.totalShipmentQty <= 0) {
      // Shipment Qty
      this.statusCharts[0].field = [new DonutChart(DonutChartField.null.displayName, 1, DonutChartField.null.colorField)];
      this.statusCharts[0].isShowToolTip = false;
    }

    if (this.totalReceived <= 0) {
      // Received
      this.statusCharts[1].field = [new DonutChart(DonutChartField.null.displayName, 1, DonutChartField.null.colorField)];
      this.statusCharts[1].isShowToolTip = false;
    }

    if (this.totalDifference <= 0) {
      // Difference
      this.statusCharts[2].field = [new DonutChart(DonutChartField.null.displayName, 1, DonutChartField.null.colorField)];
      this.statusCharts[2].isShowToolTip = false;
    }
  }

  isMany(number: number) {
    return number > 0 && number !== 1;
  }

  getAmazonShipmentDetailLink(shipmentId: string): string {
    return amazonShipmentDetailLink(shipmentId);
  }

  setDefaultMinimumGridHeight() {
    this.gridHeight = this.DEFAULT_MINIMUM_GRID_HEIGHT;
  }

  getDisplayName(field: string): string {
    return (
      this.metadataFields?.find((metadataField) => {
        return field === metadataField.field;
      })?.displayName || _.startCase(field)
    );
  }

  getColumnWidth(fieldGroup: string[]): number {
    if (fieldGroup.includes(ShipmentField.shipmentName)) {
        return ColumnWidth.shipmentName;
    }
    return ColumnWidth.default;
  }

  private setIsLoading(isLoading: boolean) {
    asapScheduler.schedule(() => {
      if (this.grid) {
        this.grid.loading = isLoading;
      }
    })
  }
}
