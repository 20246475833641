import { PeriodType } from 'src/app/routes/subscriptions/subscriptions-detail';

export class Subscription {
  customerId: string;
  subscriptionId: string;
  subscriptionStatus: string;

  constructor(init?: Partial<Subscription>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export enum SubscriptionStatus {
  'trialing' = 'trialing',
  'active' = 'active',
  'canceled' = 'canceled',
  'expired' = 'expired',
  'unpaid' = 'unpaid',
  'incomplete' = 'incomplete',
  'incomplete_expired' = 'incomplete_expired',
  'past_due' = 'past_due'
}

export interface ISubscription {
  id: string;
  customer: string;
  status: SubscriptionStatus;
  trial_start: number;
  trial_end: number;
  current_period_start: number;
  current_period_end: number;
  cancel_at_period_end: boolean;
  plan: IPlan;
  company?: ICompany;
  metadata: any;
  default_payment_method: string;
}

export interface ICompany {
  companyKey: string;
  companyName: string;
}

export interface IPlan {
  id: string;
  object: string;
  active: boolean;
  aggregate_usage: null;
  amount: number;
  amount_decimal: string;
  billing_scheme: string;
  created: number;
  currency: string;
  interval: PeriodType;
  interval_count: number;
  livemode: boolean;
  metadata: IMetadata;
  name: string;
  nickname: null;
  product: string;
  statement_descriptor: null;
  tiers: null;
  tiers_mode: null;
  transform_usage: null;
  trial_period_days: number;
  usage_type: string;
}
export interface ISubscriptionRequest {
  subscriptionId: string;
  priceId: string;
  isRevert: boolean;
}

export interface ISubscriptionUpdateResponse {
  message: string;
  err: string;
}

export interface IMetadata {}

export interface IPaymentMethod {
  id: string;
  billingDetails: any;
}

export interface IPaymentIntent {
  id: string;
  status: PaymentIntentStatus;
  next_action: any;
  custom_action: string;
}

export enum PaymentIntentStatus {
  requires_source = 'requires_source',
  requires_source_action = 'requires_source_action',
  succeeded = 'succeeded'
}

export enum PaymentIntentCustomAction {
  change_card = 'change_card',
  refresh_page = 'refresh_page'
}
