import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { filter } from 'rxjs/operators';

declare const gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private get isGoogleTagEnabled() {
    return (environment as any).isGoogleTagEnabled;
  }

  constructor(private router: Router) {}

  ngOnInit() {
    if(this.isGoogleTagEnabled) {
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          switch (event.urlAfterRedirects) {
            case '/account/registration':
              try {
                gtag('event', 'conversion', {'send_to': 'AW-871663586/21j7CJ_Ig6wYEOKP0p8D'});
              } catch (_) {}

              break;

            case '/onboarding/create':
              try {
                gtag('event', 'conversion', {'send_to': 'AW-871663586/ND-mCLXd8qsYEOKP0p8D'});
              } catch (_) {}
              break;
          }
        });
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
