import { Component, OnInit, Inject } from '@angular/core';
import { take } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DATA, AuthenticationService } from '@stockaid/services';
import { UserService } from 'src/app/core/services/user.service';
import { RealmService } from 'src/app/core/services/realm.service';
import { Router } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { IAddRequest } from 'src/app/core/infrastructure/interfaces/add-request.interface';

@Component({
  selector: 'app-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  styleUrls: ['./agreement-modal.component.scss'],
})
export class AgreementModalComponent implements OnInit {
  private addRequest: IAddRequest;

  constructor(
    @Inject(MODAL_DATA) private data: any,
    public activeModal: NgbActiveModal,
    private authService: AuthenticationService,
    private userService: UserService,
    private snotifyService: SnotifyService,
    private router: Router,
    private realmService: RealmService
  ) {}

  ngOnInit(): void {
    const {email, company, type} = this.data?.addRequest || {};
    this.addRequest = {
      type,
      existingEmail: email,
      companyName: company
    }
  }

  get isAddedByCompanyOwner(): boolean {
    return Boolean(
      this.addRequest?.existingEmail && this.addRequest?.companyName
    );
  }

  get isQboSSO(): boolean {
    return this.addRequest?.type === 'sso';
  }

  get isAmazonSSO(): boolean {
    return this.addRequest?.type === 'amz';
  }

  logOut(): void {
    this.authService
      .logout()
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.router.navigate(['/account/signin']);
          sessionStorage.removeItem('userInfo');
          sessionStorage.removeItem('metadata');
          this.activeModal.dismiss();
          this.realmService.clearCache();
        },
        (err) => {
          this.router.navigate(['/account/signin']);
          sessionStorage.removeItem('metadata');
          sessionStorage.removeItem('userInfo');
          this.activeModal.dismiss();
          this.realmService.clearCache();
        },
        () => {
          this.activeModal.dismiss();
          sessionStorage.removeItem('userInfo');
          sessionStorage.removeItem('metadata');
          this.activeModal.dismiss();
          this.realmService.clearCache();
        }
      );
  }

  agree(): void {
    this.userService
      .editUser({
        ...this.authService.currentUser,
        acceptedPrivacyPolicy: true,
      })
      .subscribe((res) => {
        if (res.err) {
          this.snotifyService.error(
            'Unable to update user info. Please try again later!'
          );
          return;
        }

        this.authService.setUserInfo({
          ...this.authService.currentUser,
          acceptedPrivacyPolicy: true,
        });
        this.router.navigate(['/onboarding', 'create']);
        this.activeModal.close();
      });
  }
}
