export class HistoryOverride {
  itemKey: string = '';
  itemName: string = '';
  grid: string = '';
  forecastKey: string = '';
  start: number;
  orderQty: number;

  constructor(init?: Partial<HistoryOverride>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export interface IHistoryOverrideResponse {
  err: string;
  model: HistoryOverride[];
  key: string;
}
