import {
  Component,
  Input,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { conditionalOperator } from '@stockaid/utils';
import { Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { LookUpConfig } from 'src/app/core/infrastructure/classes/metadataDisplayControls/support/look-up-config';
import {
  ItemField,
  PurchaseOrderField,
  SupplyField,
} from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { GridName } from '../forecast-rxdata-table/forcast-rxdata-table.constant';
import { BaseComponent } from '@stockaid/core';
import { Vendor } from 'src/app/core/models/vendor';

@Component({
  selector: 'app-lookup-input',
  templateUrl: './lookup-input.component.html',
})
export class LookupInputComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() itemName;
  @Input() lookService: Observable<any>;
  @Input() lookUpConfig: LookUpConfig;
  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() isDisabled: boolean = false;
  @Input() placeholder = '';
  @Input() resetItemLookUp$: Subject<any>;
  @Input() set parentKey(value: string) {
    this._parentKey = value;

    this.handleFilter('');
    if (this.combo) {
      this.combo.value = null;
    }
    this.valueChange('');
  }

  @Input() newVendor: Vendor;
  @Input() autofillVendor: any;

  @Input() isAllowAddLookUp: boolean = false;
  @Output() shouldEnableChild = new EventEmitter();
  @Output() chooseItem = new EventEmitter();
  @Output() selectedVendor = new EventEmitter();
  @Output() addNewSupplier = new EventEmitter();
  @ViewChild('combo') public combo: ComboBoxComponent;
  @ViewChild('addNew') public addNew: ElementRef;
  //view: Observable<any[]>; // = of([{name: "This is Modifed  4"}, {name:"This is Modifed  10"}]);
  view: any[]; // = of([{name: "This is Modifed  4"}, {name:"This is Modifed  10"}]);
  loading: boolean = true;
  searchField: string;

  private _parentKey: string;

  get parentKey(): string {
    return this._parentKey;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['newVendor']?.currentValue) {
      // handle created Vendor
      this.view.push({ name: this.newVendor.name, key: this.newVendor.key });
      this.combo.value = { name: this.newVendor.name, key: this.newVendor.key };
    }
  }

  ngOnInit(): void {
    this.searchField = this.itemName === 'Result' ? 'itemName' : 'name';
    if (this.lookUpConfig.searchField) {
      this.searchField = this.lookUpConfig.searchField;
    }

    this.loading = true;

    this.getData().subscribe();

    this.resetItemLookUp$
      ?.pipe(
        tap(() => this.combo.reset()),
        switchMap(() => this.getData(false))
      )
      ?.subscribe();
  }

  getData(shouldSetDefaultValue = true): Observable<any> {
    return this.lookUpConfig.service.getFiltered().pipe(
      map((data: any) => this.filterView(data, this.controlName)),
      tap((data: any) => {
        this.view = data;
        // Exclude Parent Name from Component Name lookup, only apply for Modify Kits
        if (this.parentKey) {
          this.view = data.filter((item) => this.parentKey !== item.key);
        }

        if (shouldSetDefaultValue && this.combo) {
          this.combo.value = this.defaultValue();
        }
      }),
      finalize(() => {
        this.loading = false;
        if (this.isAllowAddLookUp && this.itemName === GridName.PurchaseOrder) {
          const autofillVendor = this.autofillVendor
            ? [this.autofillVendor]
            : [];
          this.view = [
            { name: '+ Add Supplier' },
            ...autofillVendor,
            ...this.view,
          ];
        }
      })
    );
  }

  defaultValue() {
    let defaultValueItem = {};
    this.lookUpConfig.mapping.forEach((m) => {
      defaultValueItem[m.from] = this.group.controls[m.to].value;
    });

    return defaultValueItem;
  }

  valueChange(value): void {
    if (value && value.name === '+ Add Supplier') {
      this.addNewSupplier.emit(true);
      this.view.splice(
        this.view.findIndex((f) => f.name === '+ Add Supplier'),
        1
      );
      this.view = [{ name: '+ Add Supplier' }, ...this.view];
      this.combo.reset();
      return;
    }

    this.group.markAsDirty();
    this.lookUpConfig.mapping.forEach((mapping) => {
      if (value) {
        this.group.controls[mapping.to].setValue(value[mapping.from]);
        if (this.itemName === 'Result') {
          this.group.controls.asin.setValue(value.asin);
          this.group.controls.restockHistorySnapshot.setValue(
            value.restockHistorySnapshot
          );
          this.group.controls.originalMeanForecast.setValue(
            value.originalMeanForecast
          );
          this.group.controls.updatedAt.setValue(value.updatedAt);
        }
        return;
      }

      //empty the  form control
      this.group.controls[mapping.to].setValue('');
      if (this.itemName === 'Result') {
        this.group.controls.asin.setValue('');
        this.group.controls.restockHistorySnapshot.setValue(null);
        this.group.controls.originalMeanForecast.setValue(null);
      }
    });

    if (this.controlName === 'parentName') {
      if (!value) {
        this.shouldEnableChild.emit({ should: false });
      } else {
        this.shouldEnableChild.emit({ should: true, parentKey: value.key });
      }
    }

    if (this.controlName === 'itemName' && value) {
      this.chooseItem.emit(value.asin);
    }

    if (
      this.itemName === GridName.Item &&
      this.controlName === ItemField.vendorName
    ) {
      this.selectedVendor.emit(value);
    }
  }

  handleFilter(value): void {
    if (!value) {
      this.lookUpConfig.service
        .getFiltered()
        .pipe(
          map((data: any) => this.filterView(data, this.controlName)),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe((data: any[]) => {
          this.view = data;

          // Exclude Parent Name from Component Name lookup, only apply for Modify Kits
          if (this.parentKey) {
            this.view = data.filter((item) => this.parentKey !== item.key);
          }

          if (
            this.isAllowAddLookUp &&
            this.itemName === GridName.PurchaseOrder
          ) {
            this.view = [{ name: '+ Add Supplier' }, ...this.view];
          }
        });

      return;
    }

    this.handleAnotherFilter(value);
  }

  handleAnotherFilter(value: any): void {
    if (value.length >= 3) {
      const handleFilterValue = conditionalOperator(
        this.itemName === 'Result',
        {
          filters: [
            {
              filters: [
                { field: this.searchField, operator: 'contains', value: value },
                { field: 'asin', operator: 'contains', value: value },
              ],
              logic: 'or',
            },
            {
              filters: [
                { field: 'lotMultipleItemKey', operator: 'eq', value: null },
              ],
              logic: 'and',
            },
          ],
          logic: 'and',
        },
        {
          filters: [
            { field: this.searchField, operator: 'startswith', value: value },
          ],
          logic: 'and',
        }
      );

      this.loading = true;
      let filter: any = {
        filters: [handleFilterValue],
        logic: 'and',
      };
      this.lookUpConfig.service
        .getFiltered(null, [], filter)
        .pipe(
          map((data: any) => this.filterView(data, this.controlName)),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe((data: any[]) => {
          this.view = data;

          // Exclude Parent Name from Component Name lookup, only apply for Modify Kits
          if (this.parentKey) {
            this.view = data.filter((item) => this.parentKey !== item.key);
          }
        });
    } else {
      this.combo.toggle(false);
    }
  }

  private filterView(data: any, controlName: string) {
    switch (controlName) {
      case ItemField.vendorName:
      case SupplyField.itemName:
        return data.filter((item) => !item.isHidden);

      case PurchaseOrderField.shipmentId:
        return data.filter((item) => {
          if (!item.shipmentId) {
            item.shipmentId = item.shipmentName;
          }

          return item.shipmentId;
        });

      default:
        return data;
    }
  }
}
