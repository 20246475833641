import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { iif, of } from 'rxjs';
import { filter, first, switchMap, tap } from 'rxjs/operators';
import { formatTimestamp } from 'src/app/core/utils';
import { GradientConfig } from '../../../app-config';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import { Company } from 'src/app/core/models/company';
import {
  ISubscription,
  SubscriptionStatus,
} from 'src/app/core/models/subscription';

import { PusherService } from 'src/app/core/services/pusher.service';
import { AuthenticationService } from 'src/app/core/services/authentication/authentication.service';
import { BillingService } from 'src/app/core/services/billing.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { HeaderService } from 'src/app/core/services/header.service';

import intervalToDuration from 'date-fns/intervalToDuration';
import { ModalService } from '@stockaid/services';
import { UpdateSPOCompanyModalComponent } from '../../shared/components/modal/spo-company-modal/update-spo-company-modal.component';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { SyncService } from 'src/app/core/services/sync.service';
import { GenericModalComponent } from '../../shared/components/modal/generic-modal/generic-modal.component';
import { ManuallyImportDataModalComponent } from '../../shared/components/modal/manually-import-data-modal/manually-import-data-modal.component';
import moment from 'moment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent extends BaseComponent implements OnInit {
  readonly version = environment.version;
  public gradientConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;
  navHidden = false;

  subscription: ISubscription;
  currentCompany: Company;
  companyId: string;
  bannerType: string;
  navBarHeight = 0;
  bannerHeight = 0;
  backgroundColor: string;
  timeRemaining: string;
  searchOn = false;
  isSubscriptionExpired = false;
  isMob: boolean = false;
  isCompanyDifferent = false;

  get isTrialing(): boolean {
    return (
      this.subscription?.trial_end &&
      Date.now() < this.subscription?.trial_end * 1000
    );
  }

  get isSubNearExpired(): boolean {
    const timeLeft = this.subscription?.current_period_end * 1000 - Date.now();

    if (timeLeft <= 0 || isNaN(timeLeft)) {
      return false;
    }

    const dayLeft = timeLeft / 1000 / 3600 / 24;

    const duration = intervalToDuration({ start: 0, end: timeLeft });

    this.timeRemaining = `${duration.days}d:${duration.hours}h:${duration.minutes}m:${duration.seconds}s`;

    // Near expired = time remaining less than 7 days
    return (
      this.subscription?.current_period_end && timeLeft > 0 && dayLeft <= 7
    );
  }

  get containerTop(): number {
    return this.bannerHeight;
  }

  constructor(
    private location: Location,
    private pusherService: PusherService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private billingService: BillingService,
    private companyService: CompanyService,
    private headerService: HeaderService,
    private modalService: ModalService,
    private syncService: SyncService
  ) {
    super();

    this.gradientConfig = GradientConfig.config;
    let currentURL = this.location.path();
    const baseHerf = this.location['_baseHref'];
    if (baseHerf) {
      currentURL = baseHerf + this.location.path();
    }

    this.windowWidth = window.innerWidth;
    this.isMob = this.windowWidth < 992;

    if (
      currentURL === baseHerf + '/layout/collapse-menu' ||
      currentURL === baseHerf + '/layout/box' ||
      (this.windowWidth >= 992 && this.windowWidth <= 1024)
    ) {
      this.gradientConfig.collapseMenu = true;
    }

    this.navCollapsed =
      this.windowWidth >= 992 ? this.gradientConfig.collapseMenu : false;
    this.navCollapsedMob = false;
  }

  ngOnInit() {
    if (this.windowWidth < 992) {
      this.gradientConfig.layout = 'vertical';
      setTimeout(() => {
        document
          .querySelector('.pcoded-navbar')
          .classList.add('menupos-static');
        document.querySelector<HTMLElement>(
          '#nav-ps-gradient-able'
        ).style.maxHeight = '100%'; // 100% amit
      }, 500);
    }

    this.connectToPusher();

    this.getCurrentCompany()
      .pipe(
        this.autoCleanUp(),
        switchMap(() => this.getSubscription(this.router.url))
      )
      .subscribe();

    // Track search bar visibility to position sections below it
    this.headerService
      .getSearchStatus()
      .pipe(this.autoCleanUp())
      .subscribe((searchOn) => (this.searchOn = searchOn));

    // Detect company changing to reset navigation, banner, main-container position
    this.headerService
      .getLatestRealm()
      .pipe(this.autoCleanUp())
      .subscribe(() => (this.searchOn = false));

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        switchMap((s: NavigationEnd) =>
          iif(() => !!this.companyId, this.getSubscription(s.url), of(null))
        )
      )
      .subscribe();

    this.headerService
      .getRevertSubscription()
      .pipe(
        this.autoCleanUp(),
        tap((subscription) => {
          if (subscription) {
            this.setupSubscription(subscription, this.router.url);
          }
        })
      )
      .subscribe();

    this.headerService.isFullScreen
      .pipe(this.autoCleanUp())
      .subscribe((isFullScreen) => {
        const container = document.querySelector<HTMLElement>(
          '.pcoded-main-container'
        );

        container.style.top = isFullScreen ? '0' : `${this.containerTop}px`;
      });
  }

  checkDisplayBanner(routeUrl): boolean {
    return routeUrl?.includes('subscriptions/');
  }

  private connectToPusher() {
    this.pusherService.subScribeToChannel(
      this.authenticationService.currentUser.userId,
      ['notification'],
      (data) => {
        if (!environment.production) {
          console.log(data, 'pusher called');
        }
      }
    );
  }

  navCollapseClick() {
    this.navCollapsed = !this.navCollapsed;
    this.headerService.setMenuCollapse(this.navCollapsed);
  }

  navMobClick() {
    if (this.windowWidth < 992) {
      if (
        this.navCollapsedMob &&
        !document
          .querySelector('app-navigation.pcoded-navbar')
          .classList.contains('mob-open')
      ) {
        this.navCollapsedMob = !this.navCollapsedMob;
        setTimeout(() => {
          this.navCollapsedMob = !this.navCollapsedMob;
        }, 100);
      } else {
        this.navCollapsedMob = !this.navCollapsedMob;
      }
    }
  }

  formatDate(timestamp: number): string {
    if (!timestamp) {
      return '';
    }

    return formatTimestamp(timestamp, `EEEE, MMMM do yyyy, 'at' hh:mm:ss aaa`);
  }

  private getCurrentCompany() {
    return this.companyService.getCurrentCompany().pipe(
      tap((company) => {
        this.isCompanyDifferent = this.companyId !== company?.companyKey;
        if (this.isCompanyDifferent) {
          localStorage.removeItem('hasPoppedUp');
        }
        if (company.companyType !== CompanyType.ASC) {
          localStorage.setItem('hasPoppedUp', 'true');
        }

        this.currentCompany = company;
        this.companyId = company?.companyKey;
      }),
      switchMap(
        () =>
          this.currentCompany.companyKey !== '0' &&
          this.syncService.getAscShouldMaintain()
      ),
      tap(({ shouldShowMaintenanceMessage }) => {
        if (
          shouldShowMaintenanceMessage &&
          this.isCompanyDifferent &&
          !moment(this.currentCompany.lastSyncDate).isSame(moment(), 'day')
        ) {
          const self = this;
          this.modalService.open(GenericModalComponent, {
            data: {
              title: '🛠️ Amazon API Maintenance Notice 🚧',
              content: `
                <p class="generic-modal__content">Attention Amazon Users!</p>
                <p class="generic-modal__content">We apologize for any inconvenience caused. The Amazon API is currently undergoing unscheduled maintenance. Fear not! Our expert team is working diligently to get it back up and running as soon as possible. The issue is only impacting Amazon syncs and the creation of shipments; forecasts and restocking recommendations are still available.</p>
                <p class="generic-modal__content">You can attempt manually importing the data to avoid affecting and interrupting your forecasting and restocking recommendations. Doing it is pretty simple.</p>
                <div class="generic-modal__content">Thank you for your patience and understanding.</div>
              `,
              successBtn: 'Manual Import Data',
            },
            size: 'lg',
            backdrop: 'static',
            onSuccess() {
              self.modalService.open(ManuallyImportDataModalComponent, {
                size: 'lg',
                backdrop: 'static',
                onSuccess() {
                  self.headerService.processManualImportCompleted$.next(true);
                },
              });
            },
            onError() {
              self.headerService.amazonMaintenanceNotfiy$.next(true);
            },
          });
          return;
        }

        let routeUrl = this.router.url;
        if (
          this.currentCompany?.companyKey !== '0' &&
          this.currentCompany.companyType === CompanyType.ASC &&
          !(
            routeUrl.includes('/onboarding/processing') &&
            !this.currentCompany.lastForecastDate
          ) &&
          (!this.currentCompany.isAuthorized ||
            this.currentCompany.isPromptedOnLogin) &&
          !shouldShowMaintenanceMessage
        ) {
          this.modalService.dismissAll();
          this.modalService.open(UpdateSPOCompanyModalComponent, {
            data: {
              company: this.currentCompany,
            },
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        }
      })
    );
  }

  private setupSubscription(subscription, routeUrl) {
    // Reset banner type when switching company
    this.bannerType = null;

    this.isSubscriptionExpired = false;

    if (
      this.currentCompany?.lastForecastDate &&
      (!subscription ||
        ![SubscriptionStatus.active, SubscriptionStatus.trialing].includes(
          subscription.status
        ))
    ) {
      this.bannerType = 'expired';
      this.backgroundColor = 'danger';

      if (this.authenticationService.currentUser?.isAdmin === false) {
        this.isSubscriptionExpired = true;
        this.headerService.setNavbarCollapse(false);
        this.navCollapsed = true;
        this.headerService.setMenuCollapse(this.navCollapsed);

        // User can still access the subscription, profile, onboarding page
        if (
          !['subscriptions', 'profile', 'onboarding'].some((urlSegment) => {
            return routeUrl.includes(urlSegment);
          })
        ) {
          this.getCurrentCompany()
            .pipe(this.autoCleanUp(), first())
            .subscribe(() => {
              this.router.navigate([
                '/subscriptions',
                this.currentCompany.companyKey,
              ]);
            });
        }
      }
    }

    if (this.checkDisplayBanner(routeUrl)) {
      this.bannerType = null;
      return;
    }

    this.subscription = subscription;

    if (this.isTrialing) {
      this.bannerType = 'trialing';
      this.backgroundColor = 'normal';
    }
    if (this.isSubNearExpired) {
      this.bannerType = 'nearExpired';
      this.backgroundColor = 'danger';
    }

    this.headerService.setShowBanner(true);
  }

  private getSubscription(routeUrl: string = null) {
    return this.billingService.getSubscriptionByCompanyId(this.companyId).pipe(
      this.autoCleanUp(),
      tap((subscription) => {
        this.setupSubscription(subscription, routeUrl);
      })
    );
  }

  onOpenReleastNotes() {
    window.open(
      'https://docs.google.com/spreadsheets/d/1UvRWgy6KKKpdnolvvEq65cyPxl54K6vDEY2_qMLLdLg/edit#gid=1402618586',
      '_blank',
      'noopener'
    );
  }
}
