import { Component, Input, OnInit } from '@angular/core';
import { asapScheduler } from 'rxjs';
import { NavigationItem } from '../../navigation';
import { GradientConfig } from '../../../../../../app-config';
import { Location } from '@angular/common';
import { HeaderService } from 'src/app/core/services/header.service';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss'],
})
export class NavItemComponent extends BaseComponent implements OnInit {
  @Input() item: NavigationItem;
  public gradientConfig: any;
  public themeLayout: string;

  constructor(
    private location: Location,
    private headerService: HeaderService
  ) {
    super();
    this.gradientConfig = GradientConfig.config;
    this.themeLayout = this.gradientConfig['layout'];
  }

  ngOnInit() {
    this.headerService
      .getCompany()
      .pipe(this.autoCleanUp())
      .subscribe((company) => {
        asapScheduler.schedule(() => this.checkItem(company));
      });
  }

  checkItem(company) {
    if (
      this.item.id === 'editKits'
      && [CompanyType.QBO, CompanyType.QBFS].includes(company?.companyType)
    ) {
      this.item.hidden = true;
    } else {
      this.item.hidden = false;
    }
  }

  closeOtherMenuByVerticalLayout(event: any): void {
    const ele = event.target;
    if (![null, undefined].includes(ele)) {
      const parent = ele.parentElement;
      const up_parent = parent.parentElement.parentElement;
      const last_parent = up_parent.parentElement;
      const sections = document.querySelectorAll('.pcoded-hasmenu');
      sections.forEach((section) => {
        section.classList.remove('active');
        section.classList.remove('pcoded-trigger');
      })

      if (parent.classList.contains('pcoded-hasmenu')) {
        parent.classList.add('pcoded-trigger');
        parent.classList.add('active');
      } else if (up_parent.classList.contains('pcoded-hasmenu')) {
        up_parent.classList.add('pcoded-trigger');
        up_parent.classList.add('active');
      } else if (last_parent.classList.contains('pcoded-hasmenu')) {
        last_parent.classList.add('pcoded-trigger');
        last_parent.classList.add('active');
        const getParent = last_parent.parentElement.parentElement.parentElement;

        if (!getParent.classList.contains('pcoded-inner-navbar')) {
          getParent.classList.add('pcoded-trigger');
          getParent.classList.add('active');
        }
      }
    }

    if (
      document
        .querySelector('app-navigation.pcoded-navbar')
        .classList.contains('mob-open')
    ) {
      document
        .querySelector('app-navigation.pcoded-navbar')
        .classList.remove('mob-open');
    }
  }

  closeOtherMenu(event) {
    if (this.gradientConfig['layout'] === 'vertical') {
      this.closeOtherMenuByVerticalLayout(event);
      return;
    }

    setTimeout(() => {
      const sections = document.querySelectorAll('.pcoded-hasmenu');
      sections.forEach((section) => {
        section.classList.remove('active');
        section.classList.remove('pcoded-trigger');
      })

      let current_url = this.location.path();
      if (this.location['_baseHref']) {
        current_url = this.location['_baseHref'] + this.location.path();
      }
      const link = "a.nav-link[ href='" + current_url + "' ]";
      const ele = document.querySelector(link);
      if (![null, undefined].includes(ele)) {
        const parent = ele.parentElement;
        const up_parent = parent.parentElement.parentElement;
        const last_parent = up_parent.parentElement;
        if (parent.classList.contains('pcoded-hasmenu')) {
          parent.classList.add('active');
        } else if (up_parent.classList.contains('pcoded-hasmenu')) {
          up_parent.classList.add('active');
        } else if (last_parent.classList.contains('pcoded-hasmenu')) {
          last_parent.classList.add('active');
        }
      }
    }, 500);
  }

  getTitle(title:string){
    return title==='Supply'?'Purchase Orders': title;
  }
}
