import { Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/core/base';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';

@Component({
  selector: 'app-create-supplier-modal',
  templateUrl: './create-supplier-modal.component.html',
  styleUrls: ['./create-supplier-modal.component.scss'],
})
export class CreateSupplierModalComponent extends BaseComponent {
  @Input() fields: MetaDataField[];
  @Input() modalConfig: any;

  constructor(
    private activeModal: NgbActiveModal
  ) {
    super();
  }

  public onCancel() {
    this.activeModal.dismiss();
  }

  public onSave(e: Event) {
    this.activeModal.close(e);
  }
}
