<div #gridView>
  <kendo-grid
    class="field-group-table"
    #grid
    [data]="gridItems"
    [pageable]="{ pageSizes: pageSizes }"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [columnMenu]="true"
    [reorderable]="true"
    [height]="gridHeight"
    [selectable]="{ checkboxOnly: true, mode: 'multiple', drag: false }"
    kendoGridSelectBy="key"
    [(selectedKeys)]="selectedItemKeys"
    (selectionChange)="onSelectionChange($event)"
    (dataStateChange)="onStateChange($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    (columnReorder)="onColumnReorder($event)"
    (columnResize)="onResizeColumn($event)"
    (columnLockedChange)="onColumnLocked($event)"
    [navigable]="true"
    [resizable]="true"
  >
    <ng-template kendoGridToolbarTemplate position="top">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class="d-flex flex-row gap-2 align-items-center">
          <kendo-dropdownlist
            class="field-group-table__shipment-list"
            [data]="shipmentsData"
            [filterable]="true"
            [defaultItem]="shipmentDefault"
            [(ngModel)]="currentShipmentOption"
            textField="text"
            valueField="value"
            (filterChange)="handleShipmentsFilter($event)"
            (valueChange)="onShipmentChange($event)"
          >
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <strong>{{ dataItem?.text }}</strong>
            </ng-template>

            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <strong>{{ dataItem?.text }}</strong>
            </ng-template>
          </kendo-dropdownlist>

          <div *ngIf="currentShipmentOption?.value">
            <button
              type="button"
              class="btn btn-sm btn-danger rounded"
              (click)="onDeleteShipment()"
            >
              <i class="feather icon-x"></i>
              Delete shipment
            </button>
          </div>
        </div>

        <span
          class="cursor-pointer mr-2"
          [class.text-selected]="!isToolbarCollapsed"
          (click)="isToolbarCollapsed = !isToolbarCollapsed"
        >
          <span *ngIf="isToolbarCollapsed">Expand</span>
          <span *ngIf="!isToolbarCollapsed">Collapse</span>
          <span class="ml-2">
            <i *ngIf="!isToolbarCollapsed" class="fas fa-angle-up"></i>
            <i *ngIf="isToolbarCollapsed" class="fas fa-angle-down"></i>
          </span>
        </span>
      </div>

      <div [ngbCollapse]="isToolbarCollapsed" class="field-group-table__header">
        <!-- Filter options -->
        <div class="field-group-table__header__filter">
          <!-- Flag filter: -->
          <kendo-multiselect
            class="field-group-table__header__filter__flag"
            [class.hide-input]="currentFlagFilter?.length"
            [checkboxes]="{ checkOnClick: false }"
            [autoClose]="false"
            [data]="flagOptions"
            [(ngModel)]="currentFlagFilter"
            textField="text"
            valueField="value"
            placeholder="Select Flag"
            (valueChange)="onFlagChange($event)"
          >
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
              <span class="template">
                <i [class]="getFlagClass(dataItem.value)"></i>
              </span>
            </ng-template>

            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <!-- Render flag icon with choices in dropdown  -->
              <span class="template">
                <i [class]="getFlagClass(dataItem.value)"></i>
              </span>
              &nbsp;&nbsp;{{ dataItem.text }}
            </ng-template>
          </kendo-multiselect>

          <div
            class="field-group-table__header__filter__condition"
            (click)="changeOperator()"
          >
            {{ currentOperator ? ConditionOperator.AND : ConditionOperator.OR }}
          </div>

          <!-- Status filter: -->
          <kendo-multiselect
            class="field-group-table__header__filter__status"
            [class.hide-input]="currentStatusFilter?.length"
            [checkboxes]="{ checkOnClick: false }"
            [autoClose]="false"
            [data]="statusOptions"
            [(ngModel)]="currentStatusFilter"
            textField="text"
            valueField="value"
            placeholder="Select Status"
            (valueChange)="onStatusChange($event)"
          >
            <ng-template kendoMultiSelectTagTemplate let-dataItem>
              <i [class]="getStatusIcon(dataItem.value)"></i>
            </ng-template>

            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span class="template">
                <i [class]="getStatusIcon(dataItem.value)"></i>
              </span>
              &nbsp;&nbsp;{{ dataItem.text }}
            </ng-template>
          </kendo-multiselect>

          <button
            kendoButton
            class="ml-2"
            (selectedChange)="applyExpediteFilter($event)"
            [themeColor]="havingExpeditedItems ? 'error' : 'primary'"
            [toggleable]="true"
            [selected]="isFilteringExpeditedQty"
            ngbTooltip="If red, items will go out of stock before next shipment arrival. Click for expedite shipment recommendations"
            container="body"
          >
            Expedite Recommendation
          </button>
        </div>

        <div class="field-group-table__header__toolbar">
          <ul class="nav justify-content-end">
            <!-- Grid Item Lookups -->
            <li *ngIf="fieldsToLookUp?.length > 0" class="nav-item active">
              <app-grid-item-lookup
                [fieldsToLookUp]="fieldsToLookUp"
                [itemName]="itemName"
                [defaultSearchTerm]="searchTerm"
                (onSearch)="onSearch($event)"
              >
              </app-grid-item-lookup>
            </li>
          </ul>
        </div>
      </div>

      <div
        [ngbCollapse]="isToolbarCollapsed"
        class="w-100 field-group-table__second-toolbar"
      >
        <div class="d-flex justify-content-end align-items-center gap-2">
          <!-- Grid layout views -->
          <app-grid-view-combobox
            [itemName]="itemName"
            [modelType]="modelType"
            [metadataFields]="groupMetaDataFields"
            [state]="gridState"
            [columnReorderArgs]="columnReorderArgs"
            [columnVisibilityArgs]="columnVisibilityArgs"
            [searchTerm]="searchTerm"
            (gridViewChange)="handleGridViewChange($event)"
            [userSelectedGridView]="userSelectedGridView"
          ></app-grid-view-combobox>

          <!-- Export Data -->
          <div
            ngbDropdown
            dropdownClass="custom-dropdown"
            class="d-inline-block"
            container="body"
          >
            <button
              class="btn btn-sm btn-outline-primary"
              id="dropdownBasic1"
              ngbDropdownToggle
              [disable]="isSyncFile"
            >
              <span
                *ngIf="isSyncFile"
                title="Please wait while the application is exporting"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              >
              </span>
              Bulk Management
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button
                class="sub-menu"
                ngbDropdownItem
                (click)="downloadInitialTemplate()"
              >
                Download Template
              </button>
              <button class="sub-menu" ngbDropdownItem (click)="downloadFile()">
                Export Data
              </button>
              <button
                class="sub-menu"
                ngbDropdownItem
                (click)="uploadShipmentItems()"
                [disable]="currentShipmentOption?.value"
              >
                Upload Shipment Items
              </button>
            </div>
          </div>

          <!-- Item Chooser -->
          <kendo-grid-column-chooser
            id="columnChooser"
            class="btn btn-primary field-group-table__header__toolbar__chooser"
            ><i class="fas fa-bars"></i
          ></kendo-grid-column-chooser>

          <!-- Toggle Fullscreen -->
          <img
            alt=""
            class="field-group-table__header__toolbar__full-screen"
            src="{{ iconFullScreen }}"
            (click)="fullScreen()"
          />
        </div>

        <div class="field-group-table__header__suppliers d-flex">
          <!-- Filter suppliers -->
          <kendo-multiselect
            class="field-group-table__header__filter__supplier"
            [class.hide-input]="currentSupplierFilters?.length"
            [checkboxes]="{ checkOnClick: false }"
            [autoClose]="false"
            [data]="supplierOptions"
            [(ngModel)]="currentSupplierFilters"
            [disabled]="isDisableSupplierFilter"
            textField="text"
            valueField="value"
            placeholder="Select Supplier"
            (valueChange)="onSupplierChange($event)"
          >
          </kendo-multiselect>

          <div class="d-flex ml-2">
            <button
              kendoButton
              class="mr-2"
              (click)="openFilterWindow()"
              icon="filter"
              [selected]="filterValue?.filters?.length"
              rounded="full"
            >
              Filter
            </button>
            <button
              kendoButton
              ngbTooltip="The ability to quick filter out items with 0 Warehouse Qty"
              container="body"
              (selectedChange)="filterLocalQty($event)"
              [toggleable]="true"
              [selected]="isFilteringLocalQty"
              class="p-2 mx-1"
            >
              <img
                alt=""
                [class.icon-white]="localQtyFilterStatus"
                [width]="17"
                [height]="17"
                class="inventory-selection__grid-toolbar__warehouse-icon"
                src="assets/images/warehouse-alt.png"
              />
            </button>

            <button
              kendoButton
              ngbTooltip="The ability to quick filter out items with 0 or negative quantity recommendations."
              container="body"
              (selectedChange)="filterRecommendedQty($event)"
              [toggleable]="true"
              [selected]="isFilteringRecommendedQty"
              class="p-2 mx-1"
            >
              <i class="fa fa-search-minus" aria-hidden="true"></i>
            </button>

            <button
              *ngIf="isDefaultLockVisible"
              kendoButton
              class="btn btn-sm btn-primary mx-1 tack-reset"
              ngbTooltip="Reset to default pinned column"
              container="body"
              (click)="resetLockedColumns()"
            >
              <img
                src="assets/images/tack-reset-icon.svg"
                alt="tack-reset-icon"
                width="20px"
                height="20px"
              />
            </button>

            <div
              *ngIf="filterValue.filters?.length || advancedFilter === AdvancedFilter.warehouseRestockRecommendation"
              class="field-group-table__header__extra-filter-wrapper d-flex align-items-center"
            >
              <kendo-chip
              *ngIf="advancedFilter === AdvancedFilter.warehouseRestockRecommendation"
              class="ml-1 mr-1"
              themeColor="info"
              rounded="large"
              [label]="'Warehouse Restock Recommendation Greater Than Warehouse Inventory'"
              [removable]="true"
              removeIcon="k-icon k-i-close"
              (remove)="deleteAdvancedFilter()"
              >
              </kendo-chip>
              <kendo-chip
                *ngFor="let filter of filterValue.filters"
                class="ml-1 mr-1"
                themeColor="info"
                rounded="large"
                [label]="getExtraFilterChipLabel(filter)"
                [removable]="true"
                removeIcon="k-icon k-i-close"
                (remove)="deleteExtraFilter(filter)"
              >
              </kendo-chip>
            </div>


          </div>
        </div>
      </div>
    </ng-template>

    <kendo-grid-column
      [columnMenu]="false"
      [title]="fieldGroup.title"
      [headerStyle]="{ 'vertical-align': 'top' }"
      [style]="{ 'vertical-align': 'top' }"
      [field]="fieldGroup.group ? fieldGroup.group[0] : null"
      [width]="getWidthColumn(fieldGroup.group && fieldGroup.metaDatas[0])"
      [hidden]="!fieldGroup?.initialUpload"
      [locked]="lockedColumns.includes(fieldGroup.group[0])"
      *ngFor="let fieldGroup of metaDataFieldsGroup"
    >
      <ng-template
        kendoGridHeaderTemplate
        let-column
        let-columnIndex="columnIndex"
      >
        <ng-container *ngFor="let field of fieldGroup.group; index as i">
          <div
            *ngIf="shouldFieldDisplayed(field)"
            [class]="getSpecialClasses(field)"
          >
            <div *ngIf="field === 's2d'">
              Total Orders
              <span
                class="pin-btn ml-1"
                [class.pin-btn--unlocked]="!lockedColumns.includes(field)"
                (click)="lockColumnWithButton($event, field)"
              >
                <img
                  src="assets/images/tack-icon.svg"
                  alt="tack-icon"
                  width="20px"
                  height="20px"
                />
              </span>
            </div>
            <div
              *ngIf="
                field ===
                RestockSuggestionItemField.casePackForecastRecommendedQty
              "
            >
              Recommendation by Case Pack
              <span
                class="pin-btn ml-1"
                [class.pin-btn--unlocked]="!lockedColumns.includes(field)"
                (click)="lockColumnWithButton($event, field)"
              >
                <img
                  src="assets/images/tack-icon.svg"
                  alt="tack-icon"
                  width="20px"
                  height="20px"
                />
              </span>
            </div>
            <div *ngIf="field === RestockSuggestionItemField.boxLength">
              Box Weight & Dimensions
              <span
                class="pin-btn ml-1"
                [class.pin-btn--unlocked]="!lockedColumns.includes(field)"
                (click)="lockColumnWithButton($event, field)"
              >
                <img
                  src="assets/images/tack-icon.svg"
                  alt="tack-icon"
                  width="20px"
                  height="20px"
                />
              </span>
            </div>
            <span
              class="field-group-table__shorten-text"
              [class.field-group-table__sortable-field]="isColumnSortable(field)"
              (click)="isColumnSortable(field) && sortField(field)"
            >
              <span
                *ngIf="
                  [
                    RestockSuggestionItemField.forecastRecommendedQty,
                    RestockSuggestionItemField.casePackForecastRecommendedQty
                  ].includes(field)
                "
              >
                <img
                  alt=""
                  src="assets/images/forecast_icon.svg"
                  class="field-group-table__image-header"
                />
              </span>
              <span
                *ngIf="
                  [
                    RestockSuggestionItemField.recommendedSupplierQty,
                    RestockSuggestionItemField.casePackSuggReorder
                  ].includes(field)
                "
              >
                <span>
                  <img
                    alt=""
                    class="field-group-table__image-header"
                    src="assets/images/factory.svg"
                  />
                </span>
              </span>
              <span
                *ngIf="
                  [
                    RestockSuggestionItemField.recommendedWarehouseQty,
                    RestockSuggestionItemField.casePackSuggShip
                  ].includes(field)
                "
              >
                <span>
                  <img
                    alt=""
                    class="field-group-table__image-header"
                    src="assets/images/warehouse.svg"
                  />
                </span>
              </span>
              <span
                *ngIf="
                  field ===
                    RestockSuggestionItemField.expeditedRecommendationQty &&
                  isFilteringExpeditedQty
                "
              >
                <span>
                  <img
                    class="field-group-table__image-header"
                    src="assets/images/expedited.svg"
                    alt=""
                  />
                </span>
              </span>
              <ng-template #inventoryAgeSortPopupContent>
                <div class="m-3">
                  <table>
                    <tbody>
                      <tr *ngFor="let iaf of InventoryAgeField">
                        <td
                          class="field-group-table__sortable-field"
                          (click)="sortInventoryAgeField(iaf)"
                        >
                          <span>{{ InventoryAgeDisplayField[iaf] }}</span>
                        </td>
                        <td>
                          <i
                            *ngIf="iaf === currentInventoryAgeSortField"
                            [class]="getSortIconClass(field)"
                            class="ml-2"
                          ></i>
                          <i
                            *ngIf="iaf !== currentInventoryAgeSortField"
                            class="ml-2 invisible"
                          >x</i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
              <span
                *ngIf="
                  ![
                    RestockSuggestionItemField.expeditedRecommendationQty,
                    RestockSuggestionItemField.useExpeditedRecommendationQty,
                    RestockSuggestionItemField.useReCasePackQty
                  ].includes(field) || isFilteringExpeditedQty
                "
                [title]="getTooltip(field)"
                [style.width.%]="100"
                [ngbPopover]="
                  field === RestockSuggestionItemField.inventoryAges &&
                  inventoryAgeSortPopupContent
                "
                container="body"
                autoClose="outside"
                triggers="mouseenter"
                placement="bottom"
                #inventoryAgeSortPopover="ngbPopover"
                (mouseenter)="
                  field === RestockSuggestionItemField.inventoryAges &&
                    handlePopoverOpen(inventoryAgeSortPopover)
                "
                >{{ getDisplayName(field) }}
                <span
                  *ngIf="
                    fieldGroup?.metaDatas[i]?.displayControl?.output ===
                    DisplayControlOutput.Currency
                  "
                >
                  ({{ displayCurrency.symbol }})
                </span>
              </span>
              <span
                *ngIf="i === 0 && shouldDisplayPinIconForIsolateColumn(field)"
              >
                <span
                  class="pin-btn ml-1"
                  [class.pin-btn--unlocked]="!lockedColumns.includes(field)"
                  (click)="lockColumnWithButton($event, field)"
                >
                  <img
                    src="assets/images/tack-icon.svg"
                    alt="tack-icon"
                    width="20px"
                    height="20px"
                  />
                </span>
              </span>
              &nbsp;
              <i [class]="getSortIconClass(field)"></i>
            </span>
            <!-- Use recommend Qty -->
            <span *ngIf="field === RestockSuggestionItemField.useReForecastQty">
              <input
                #useReForecastCheckBox
                type="checkbox"
                kendoCheckBox
                class="mr-2"
                (change)="onUseOrderQty($event, field)"
              />
            </span>
            <span *ngIf="field === RestockSuggestionItemField.useReSupplierQty">
              <input
                #useReSupplierCheckBox
                type="checkbox"
                kendoCheckBox
                class="mr-2"
                (change)="onUseOrderQty($event, field)"
              />
            </span>
            <span *ngIf="field === RestockSuggestionItemField.useReWarehouseQty">
              <input
                #useReWarehouseCheckBox
                type="checkbox"
                kendoCheckBox
                class="mr-2"
                (change)="onUseOrderQty($event, field)"
              />
            </span>
            <span
              ngbDropdown
              dropdownClass="custom-dropdown"
              container="body"
              placement="bottom"
              *ngIf="field === RestockSuggestionItemField.useReCasePackQty"
            >
              <span ngbDropdownToggle>
                <input
                  #useReCasePackQtyCheckBox
                  data-toggle="dropdown"
                  type="checkbox"
                  kendoCheckBox
                  class="mr-2"
                  (change)="onUseReCasePackQty($event, field)"
                />{{ getDisplayName(field) }}
              </span>
              <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
                <button
                  ngbDropdownItem
                  (click)="
                    onUseReCasePackQty(
                      $event,
                      RestockSuggestionItemField.useReForecastQtyByCasePackQty
                    )
                  "
                >
                  Use Forecast Recommended Qty
                </button>
                <button
                  ngbDropdownItem
                  (click)="
                    onUseReCasePackQty(
                      $event,
                      RestockSuggestionItemField.useReSupplierQtyByCasePackQty
                    )
                  "
                >
                  Use Supplier Restock Recommendation
                </button>
                <button
                  ngbDropdownItem
                  (click)="
                    onUseReCasePackQty(
                      $event,
                      RestockSuggestionItemField.useReWarehouseQtyByCasePackQty
                    )
                  "
                >
                  Use Warehouse Restock Recommendation
                </button>
              </div>
            </span>
            <span
              *ngIf="
                field ===
                  RestockSuggestionItemField.useExpeditedRecommendationQty &&
                isFilteringExpeditedQty
              "
            >
              <input
                #useReExpeditedCheckBox
                type="checkbox"
                kendoCheckBox
                class="mr-2"
                [disabled]="disableCheckAll()"
                (change)="onUseOrderQty($event, field)"
              />
            </span>
            <input
              type="checkbox"
              *ngIf="field === RestockSuggestionItemField.tags"
              class="mr-1"
              #checkboxSelectAllTag
              (change)="selectAllTagRows($event.target?.checked)"
              kendoCheckBox
            />
          </div>
        </ng-container>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngFor="let field of fieldGroup.group; let idx = index">
          <div *ngIf="shouldFieldDisplayed(field)">
            <ng-container
              [ngSwitch]="fieldGroup?.metaDatas[idx]?.displayControl?.output"
            >
              <ng-container *ngSwitchCase="DisplayControlOutput.Flag">
                <i
                  *ngIf="dataItem.flag; else noFlag"
                  [class]="getFlagClass(dataItem[field])"
                ></i>
                <ng-template #noFlag>&nbsp;</ng-template>
              </ng-container>
              <div
                *ngSwitchCase="DisplayControlOutput.Image"
                class="text-center d-flex justify-content-around"
              >
                <img
                  alt=""
                  *ngIf="dataItem[field]"
                  width="50"
                  height="75"
                  [src]="dataItem[field]"
                  loading="lazy"
                />
              </div>
              <a
                *ngSwitchCase="DisplayControlOutput.Link"
                class="field-group-table__shorten-text field-group-table__link"
                [title]="dataItem[field] || ''"
                [href]="getLink(fieldGroup?.metaDatas[idx], dataItem)"
                target="_blank"
                rel="noopener"
                [class.disabled]="!dataItem[field]"
              >
                {{ dataItem[field] || '&nbsp;' }}
              </a>
              <span *ngSwitchCase="DisplayControlOutput.Currency">
                <span
                  *ngIf="field === RestockSuggestionItemField.totalSurcharge"
                  class="field-group-table__sub-text"
                  >Total:
                </span>
                <span
                  *ngIf="
                    field === RestockSuggestionItemField.totalSurcharge;
                    else otherCurrencyOutputs
                  "
                  class="field-group-table__inbound-btn"
                >
                  <ng-template #surchargePopupContent>
                    <div class="m-3 mt-4" (mouseleave)="surchargePopup.close()">
                      <div class="popup-header font-weight-bold">
                        Estimated aged inventory surcharge
                      </div>
                      <p class="mt-1">
                        The below table provides details about the surcharge based
                        on inventory age. ASINs with age greater than 180 days
                        have a surcharge added based on the storage volume.
                      </p>
                      <span>
                        Aged inventory surcharge:
                        <strong>{{
                          dataItem[field]
                            | currency
                              : displayCurrency.code
                              : displayCurrency.symbol
                              : "1.2"
                        }}</strong>
                      </span>
                      <table class="mt-2 table table-sm table-bordered">
                        <thead class="thead-light">
                          <tr>
                            <th class="text">
                              <strong>Surcharge segments</strong>
                            </th>
                            <th class="col=1">181-<br />210</th>
                            <th class="col=1">211-<br />240</th>
                            <th class="col=1">241-<br />270</th>
                            <th class="col=1">271-<br />300</th>
                            <th class="col=1">301-<br />330</th>
                            <th class="col=1">331-<br />365</th>
                            <th class="col=1">365+</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><strong>Total Units</strong></td>
                            <td *ngFor="let unitField of SurchargeQuantityField">
                              {{
                                getDeepValueOfItem(
                                  dataItem,
                                  RestockSuggestionItemField.estimatedAgedInventorySurcharges,
                                  unitField
                                )
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Aged inventory<br />surcharge</strong>
                            </td>
                            <td *ngFor="let priceField of SurchargePriceField">
                              <strong>{{
                                getDeepValueOfItem(
                                  dataItem,
                                  RestockSuggestionItemField.estimatedAgedInventorySurcharges,
                                  priceField
                                )
                                  | currency
                                    : displayCurrency.code
                                    : displayCurrency.symbol
                                    : "1.2"
                              }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-template>
                  <a
                    #surchargePopup="ngbPopover"
                    [ngbPopover]="surchargePopupContent"
                    container="body"
                    autoClose="outside"
                    popoverClass="custom-popover"
                    triggers="mouseenter"
                    (mouseenter)="handlePopoverOpen(surchargePopup)"
                  >
                    {{
                      dataItem[field]
                        | currency
                          : displayCurrency.code
                          : displayCurrency.symbol
                          : "1.2"
                    }}
                  </a>
                </span>
                <ng-template #otherCurrencyOutputs>
                  {{
                    dataItem[field]
                      | currency
                        : displayCurrency.code
                        : displayCurrency.symbol
                        : "1.2"
                  }}
                </ng-template>
              </span>
              <div
                *ngSwitchCase="DisplayControlOutput.Dropdown"
                class="field-group-table__dropdown"
              >
                <kendo-dropdownlist
                  [data]="statusOptions"
                  (valueChange)="onSelectStatus($event, dataItem)"
                  [textField]="'text'"
                  [value]="dataItem.status"
                  [valueField]="'value'"
                  [valuePrimitive]="true"
                >
                  <!-- Custom dropdown -->
                  <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <i
                      class="field-group-table__drop-icon"
                      [class]="getStatusIcon(dataItem.value)"
                    ></i>
                    <span>{{ dataItem.value }}</span>
                  </ng-template>
                </kendo-dropdownlist>
                <div class="field-group-table__value-icon">
                  <i [class]="getStatusIcon(dataItem?.status)"></i>
                </div>
              </div>
              <div
                *ngSwitchCase="DisplayControlOutput.Icon"
                class="d-flex align-items-start"
                [class.justify-content-between]="
                  field === RestockSuggestionItemField.tags &&
                  dataItem[field]?.length > 0
                "
                [class.justify-content-end]="
                  field === RestockSuggestionItemField.tags &&
                  dataItem[field]?.length === 0
                "
                [class.justify-content-center]="
                  field === RestockSuggestionItemField.prepGuide
                "
                style="gap: 0px 16px"
              >
                <ng-container
                  *ngIf="field === RestockSuggestionItemField.prepGuide"
                >
                  <app-popover [content]="dataItem[field]"></app-popover>
                </ng-container>
                <ng-container *ngIf="field === RestockSuggestionItemField.tags">
                  <div
                    *ngIf="dataItem[field]?.length > 0"
                    class="d-flex flex-wrap field-group-table__shorten-text"
                    style="gap: 6px 10px"
                  >
                    <kendo-chip
                      *ngFor="let tag of dataItem[field]"
                      [label]="tag"
                      [title]="tag"
                    ></kendo-chip>
                  </div>
                  <div
                    class="d-flex flex-column justify-content-between"
                    style="gap: 20px 0"
                  >
                    <button
                      class="forecast-icon btn field-group-table__tags__btn p-0"
                      [title]="'Edit Tags'"
                      (click)="openEditTagsModal(dataItem)"
                    >
                      <i class="fas fa-tags"></i>
                    </button>
                    <input
                      type="checkbox"
                      (change)="selectTagRows(dataItem, $event.target?.checked)"
                      [checked]="!!selectedKeyTag[dataItem?.key]"
                      kendoCheckBox
                    />
                  </div>
                </ng-container>
              </div>
              <ng-container
                *ngIf="
                  itemName === GridName.RestockSuggestion &&
                  checkSpecialTextFields(field)
                "
              >
                <div
                  *ngSwitchDefault
                  class="field-group-table__shorten-text"
                  [title]="
                    field !== RestockSuggestionItemField.historicalDaysOfSupply
                      ? dataItem[field] || ''
                      : ''
                  "
                >
                  <ng-container
                    *ngIf="field === RestockSuggestionItemField.dimensionalWeight"
                  >
                    <button
                      type="button"
                      class="p-0 size-tier"
                      [class.size-tier--disabled]="
                        !!!dataItem[RestockSuggestionItemField.oversized]
                      "
                    >
                      <div
                        [class.size-tier__trigger]="
                          !!dataItem[RestockSuggestionItemField.oversized]
                        "
                      >
                        {{ dataItem[field] }}
                      </div>
                      <div
                        *ngIf="!!dataItem[RestockSuggestionItemField.oversized]"
                        class="size-tier__content"
                      >
                        <div class="size-tier__content-heading">
                          <strong> Size Tier </strong>
                        </div>
                        <hr />
                        <div class="size-tier__content-body">
                          {{ getSizeTier(dataItem) }}
                        </div>
                      </div>
                    </button>
                  </ng-container>
                  <div
                    *ngIf="field !== RestockSuggestionItemField.dimensionalWeight"
                    [class.mt-4]="
                      field === RestockSuggestionItemField.casePackQuantity
                    "
                    [class.text-truncate]="
                      field === RestockSuggestionItemField.supplierSku
                    "
                  >
                    <ng-container [ngSwitch]="field">
                      <ng-container
                        *ngSwitchCase="RestockSuggestionItemField.oversized"
                      >
                        {{ transformOversizeToYesNo(dataItem[field]) }}
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="RestockSuggestionItemField.restockModel"
                      >
                        {{
                          RESTOCK_MODELS_SETTINGS[dataItem[field]] ||
                            dataItem[field]
                        }}
                      </ng-container>
                      <ng-container
                        *ngIf="itemName === GridName.RestockSuggestion"
                      >
                        <ng-container
                          *ngSwitchCase="RestockSuggestionItemField.orderQty"
                        >
                          <input
                            type="checkbox"
                            name="isInShipment"
                            [value]="!!dataItem?.restockKey"
                            [checked]="!!dataItem?.restockKey"
                            (click)="
                              handleOrderQtyCheckboxClick($event, dataItem)
                            "
                          />
                          <input
                            type="number"
                            class="form-control shadow-none"
                            name="orderQty"
                            min="0"
                            oninput="validity.valid || (value='');"
                            [value]="dataItem?.orderQty || 0"
                            (blur)="handleOrderQtyInputBlur($event, dataItem)"
                          />
                        </ng-container>
                        <ng-container
                          *ngSwitchCase="RestockSuggestionItemField.doNotRestock"
                          class="d-flex justify-content-center"
                        >
                          <input
                            type="checkbox"
                            name="doNotRestock"
                            class="w-100 k-cursor-pointer"
                            [checked]="dataItem[field]"
                            (click)="
                              handleDoNotRestockCheckboxClick($event, dataItem)
                            "
                          />
                        </ng-container>
                      </ng-container>
                      <span
                        *ngSwitchCase="RestockSuggestionItemField.inbound"
                        class="field-group-table__inbound-btn"
                      >
                        <a (click)="onInboundAnalyzer(dataItem)">
                          {{ dataItem[field] }}
                        </a>
                      </span>
                      <ng-container
                        *ngSwitchCase="RestockSuggestionItemField.demand"
                      >
                        <a
                          *ngIf="
                            dataItem[RestockSuggestionItemField.alertAutoAdjust]
                          "
                          title="Click to adjust daily sales rate for out of stock"
                          class="field-group-table__alert-link"
                          (click)="
                            onDetailPO({
                              dataItem: dataItem,
                              modalType: ModalType.SalesVelocity,
                              field:
                                RestockSuggestionItemField.recommendedSupplierQty
                            })
                          "
                        >
                          {{ dataItem[field] }}
                        </a>
                        <ng-container
                          *ngIf="
                            !dataItem[RestockSuggestionItemField.alertAutoAdjust]
                          "
                        >
                          {{ dataItem[field] }}
                        </ng-container>
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="RestockSuggestionItemField.localQty"
                      >
                        {{ dataItem[field] }}
                        {{ getWarehouseQtyUpdatedDate(dataItem) }}
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="
                          RestockSuggestionItemField.historicalDaysOfSupply
                        "
                      >
                        <span
                          *ngIf="dataItem[field] || dataItem[field] === 0"
                          [class.text-danger]="dataItem[field] <= 28"
                          [class.field-group-table__using-tooltip]="
                            dataItem[field] <= 28
                          "
                          [ngbTooltip]="
                            dataItem[field] <= 28
                              ? 'May incur low inventory level fee'
                              : ''
                          "
                          container="body"
                        >
                          {{ dataItem[field] }}
                        </span>
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="RestockSuggestionItemField.itemVolume"
                      >
                        <span>
                          {{ dataItem[field] }}
                          {{
                            getUnitFromText(
                              dataItem[
                                RestockSuggestionItemField.volumeUnitMeasurement
                              ]
                            )
                          }}<sup>{{
                            dataItem[
                              RestockSuggestionItemField.volumeUnitMeasurement
                            ]
                              ? "3"
                              : ""
                          }}</sup>
                        </span>
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="RestockSuggestionItemField.storageVolume"
                      >
                        <span>
                          {{ dataItem[field] }}
                          {{
                            getUnitFromText(
                              dataItem[
                                RestockSuggestionItemField.volumeUnitMeasurement
                              ]
                            )
                          }}<sup>{{
                            dataItem[
                              RestockSuggestionItemField.volumeUnitMeasurement
                            ]
                              ? "3"
                              : ""
                          }}</sup>
                        </span>
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="RestockSuggestionItemField.inventoryAges"
                      >
                        <div *ngFor="let inventoryAgeField of InventoryAgeField">
                          <span class="field-group-table__sub-text">{{
                            InventoryAgeDisplayField[inventoryAgeField]
                          }}</span
                          >:
                          {{
                            getDeepValueOfItem(
                              dataItem,
                              RestockSuggestionItemField.inventoryAges,
                              inventoryAgeField
                            )
                          }}
                        </div>
                        <div
                          *ngIf="
                            dataItem[RestockSuggestionItemField.inventoryAges]
                          "
                          class="field-group-table__inbound-btn"
                        >
                          <ng-template #inventoryAgePopupContent>
                            <div
                              class="m-3 mt-4"
                              (mouseleave)="
                                inventoryAgeMouseLeaveHandle(inventoryAgePopover)
                              "
                            >
                              <div class="popup-header font-weight-bold">
                                Inventory age
                              </div>
                              <div class="mt-2">
                                <div
                                  *ngFor="let iad of InventoryAgeDetail(dataItem)"
                                  (click)="
                                    inventoryAgeCollapses[iad.field] =
                                      !inventoryAgeCollapses[iad.field]
                                  "
                                >
                                  <div
                                    class="row row-bordered py-1"
                                    [class.row-collapsable]="iad.children"
                                    [class.row-total]="iad.isTotal"
                                  >
                                    <div class="col-1 font-weight-bold">
                                      <i
                                        *ngIf="
                                          inventoryAgeCollapses[iad.field] &&
                                          iad.children
                                        "
                                        class="fas fa-angle-right"
                                      ></i>
                                      <i
                                        *ngIf="
                                          !inventoryAgeCollapses[iad.field] &&
                                          iad.children
                                        "
                                        class="fas fa-angle-down"
                                      ></i>
                                    </div>
                                    <div class="col-5">
                                      {{ iad.displayName }}
                                    </div>
                                    <div class="col-4 ml-auto">
                                      {{ iad.value }} units
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="iad.children"
                                    [ngbCollapse]="
                                      inventoryAgeCollapses[iad.field]
                                    "
                                  >
                                    <div *ngFor="let iadChild of iad.children">
                                      <div class="row row-bordered py-1">
                                        <div class="col-1">&nbsp;</div>
                                        <div class="col-5 pl-4">
                                          {{ iadChild.displayName }}
                                        </div>
                                        <div class="col-4 ml-auto">
                                          {{ iadChild.value }} units
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <a
                            #inventoryAgePopover="ngbPopover"
                            [ngbPopover]="inventoryAgePopupContent"
                            container="body"
                            autoClose="outside"
                            popoverClass="custom-popover"
                            triggers="mouseenter"
                            (mouseenter)="handlePopoverOpen(inventoryAgePopover)"
                          >
                            Details
                          </a>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        {{ dataItem[field] || "&nbsp;"
                        }}{{
                          field.includes("Percentage") &&
                          (dataItem[field] || dataItem[field] === 0)
                            ? "%"
                            : ""
                        }}
                        <span
                          title="Days in this period out of stock"
                          *ngIf="
                            field.includes('Percentage') &&
                            dataItem['outOfStockTargetDay']
                          "
                        >
                          ({{ dataItem["outOfStockTargetDay"] }} days)
                        </span>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                  field === RestockSuggestionItemField.estimatedMarkupPercentage
                "
              >
                <button
                  type="button"
                  class="forecast-icon btn p-0 icon-btn"
                  (click)="openEstimatedMarginCalcModal(dataItem)"
                  title="Click for detailed margin estimated calculations"
                >
                  <i class="fa fa-calculator"></i>
                </button>
              </ng-container>
              <ng-container *ngIf="field === RestockSuggestionItemField.type">
                <button
                  type="button"
                  class="forecast-icon btn p-0 icon-btn"
                  (click)="openEditItemModal(dataItem)"
                  title="Change Item Information"
                >
                  <i class="fa fa-search"></i>
                </button>
              </ng-container>
              <ng-container
                *ngIf="field === RestockSuggestionItemField.alertDate"
              >
              <span>{{ dataItem[field] | date: FORMAT_DATE || "&nbsp;" }}</span>
              </ng-container>
              <ng-container
                *ngIf="field === RestockSuggestionItemField.createdAt"
              >
                <span>{{ dataItem[field] | date : FORMAT_DATE }}</span>
              </ng-container>
              <!-- Recommended Qty cells-->
              <div
                *ngIf="checkCellHasIcon(field)"
                class="d-flex justify-content-between align-items-center"
              >
                <div class="field-group-table__shorten-text d-flex">
                  <button
                    *ngIf="
                      [
                        RestockSuggestionItemField.forecastRecommendedQty,
                        RestockSuggestionItemField.casePackForecastRecommendedQty
                      ].includes(field)
                    "
                    class="forecast-icon btn p-0 icon-btn"
                    title="Item Card"
                    (click)="
                      onDetailPO({
                        dataItem: dataItem,
                        modalType: ModalType.ItemCard
                      })
                    "
                  >
                    <img
                      class="field-group-table__image-header field-group-table__image-header--popup"
                      src="assets/images/forecast_icon.svg"
                      alt=""
                    />
                  </button>
                  <button
                    class="forecast-icon btn p-0 icon-btn"
                    title="Average Daily Sales Rate Calculation"
                    (click)="
                      onDetailPO({
                        dataItem: dataItem,
                        modalType: ModalType.SalesVelocity,
                        field: field
                      })
                    "
                  >
                    <ng-container
                      *ngIf="
                        [
                          RestockSuggestionItemField.recommendedSupplierQty,
                          RestockSuggestionItemField.casePackSuggReorder
                        ].includes(field)
                      "
                    >
                      <img
                        alt=""
                        class="field-group-table__image-header field-group-table__image-header--popup"
                        src="assets/images/factory.svg"
                      />
                    </ng-container>
                    <ng-container
                      *ngIf="
                        [
                          RestockSuggestionItemField.recommendedWarehouseQty,
                          RestockSuggestionItemField.casePackSuggShip
                        ].includes(field)
                      "
                    >
                      <img
                        alt=""
                        class="field-group-table__image-header field-group-table__image-header--popup"
                        src="assets/images/warehouse.svg"
                      />
                    </ng-container>
                  </button>
                  <span
                    class="mt-1"
                    *ngIf="
                      field ===
                        RestockSuggestionItemField.expeditedRecommendationQty &&
                      isFilteringExpeditedQty
                    "
                  >
                    <img
                      alt=""
                      class="field-group-table__image-header field-group-table__image-header--popup"
                      src="assets/images/expedited.svg"
                    />
                  </span>
                  <span
                    class="p-t-5"
                    [class]="
                      dataItem?.isCustom &&
                      [
                        RestockSuggestionItemField.forecastRecommendedQty,
                        RestockSuggestionItemField.casePackForecastRecommendedQty
                      ].includes(field)
                        ? 'text-c-red'
                        : ''
                    "
                    [title]="
                      dataItem?.isCustom &&
                      [
                        RestockSuggestionItemField.forecastRecommendedQty,
                        RestockSuggestionItemField.casePackForecastRecommendedQty
                      ].includes(field)
                        ? 'Modified Forecast in use, safety stock not included'
                        : ''
                    "
                    *ngIf="
                      field !==
                        RestockSuggestionItemField.expeditedRecommendationQty ||
                      isFilteringExpeditedQty
                    "
                  >
                    {{ dataItem[field] }}
                  </span>
                </div>
                <span
                  *ngIf="
                    [
                      RestockSuggestionItemField.recommendedWarehouseQty,
                      RestockSuggestionItemField.casePackSuggShip
                    ].includes(field)
                  "
                  class="text-danger"
                >
                  ({{ dataItem[RestockSuggestionItemField.localQty] || 0 }}
                  Available)
                </span>
                <span
                  [title]="
                    getIsOnOrderDisabled(dataItem, field) === 'warehouse'
                      ? 'Warehouse quantity is empty'
                      : 'Average Daily Sales Rate Calculation'
                  "
                >
                  <button
                    *ngIf="
                      field !==
                        RestockSuggestionItemField.expeditedRecommendationQty ||
                      isFilteringExpeditedQty
                    "
                    class="forecast-icon btn p-0 d-flex"
                    [class.icon-btn]="dataItem[field] > 0"
                    (click)="onMapDataToOrderQty(dataItem, field)"
                    [disabled]="!!getIsOnOrderDisabled(dataItem, field)"
                  >
                    <i
                      class="fa fa-caret-right field-group-table__icon-right"
                      aria-hidden="true"
                    ></i>
                  </button>
                </span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>

  <ng-template let-currentItem #filterSelectTemplate>
    <kendo-dropdownlist
      [data]="RESTOCK_MODEL_SELECTIONS"
      textField="displayName"
      valueField="value"
      (valueChange)="editorValueChange($event, currentItem)"
    >
    </kendo-dropdownlist>
  </ng-template>

  <kendo-window
    *ngIf="opened && filterWindowLeft && filterWindowTop"
    class="mb-2"
    (close)="opened = false"
    [width]="FILTER_WINDOW_SIZE.WIDTH"
    [height]="FILTER_WINDOW_SIZE.HEIGHT"
    [left]="filterWindowLeft"
    [top]="filterWindowTop"
  >
    <div class="window-content">
      <div class="d-flex flex-column">
        <kendo-filter #filter
        [filters]="filters"
        [value]="filterValue?.filters?.length === 0 ? defaultFilterValue : filterValue"
        (valueChange)="onFilterChange($event)">
        </kendo-filter>

        <button
        kendoButton
        icon="filter-add-expression"
        class="custom-add-filter"
        (click)="onAddFilter(filter.value)"

        >
          Add Filter
        </button>
      </div>
      <button kendoButton
      (click)="applyExtraFilters(filter?.value)">
        Apply
      </button>
    </div>
  </kendo-window>
</div>
