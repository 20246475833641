
import {Pipe, PipeTransform} from '@angular/core';
import { getTip, GridName } from '../../forecastui/forecast-rxdata-table/forcast-rxdata-table.constant';

@Pipe({
  name: 'dataTooltip'
})

export class DataTooltipPipe implements PipeTransform {
  transform(field: string, companyType: string, gridName: GridName | '' = ''): string {
    return getTip(field, companyType, gridName)
  }
}
