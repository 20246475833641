<form [formGroup]="companyForm" class="card">
  <div class="card-title text-center">
    <h4>Re-connect to Amazon</h4>
    <h5 *ngIf="currentCompany?.isPromptedOnLogin">
      We've just deployed the AWD Inventory feature! To start using the latest
      updates, please help us by reconnecting to your AMZ account.
    </h5>
  </div>
  <hr />

  <div *ngIf="!data.company?.sellerId" formGroupName="companyInfo">
    <ng-container>
      <div class="form-group">
        <label>Company Name</label>
        <input
          type="text"
          class="form-control"
          formControlName="companyName"
          placeholder="Company Name"
        />
      </div>
      <div class="form-group">
        <label>Marketplace ID</label>
        <div>
          <kendo-dropdownlist
            isRequired="true"
            formControlName="marketplaceId"
            [data]="marketplaceIds"
            [textField]="'display'"
            [valueField]="'val'"
          >
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="form-group">
        <label>Earliest ASC Sale</label>
        <div class="">
          <kendo-datepicker
            isRequired="true"
            placeholder="mm/dd/yyyy"
            format="{{FORMAT_DATE}}"
            [formatPlaceholder]="FORMAT_PATTERN"
            formControlName="initialSyncDate"
            [animateCalendarNavigation]="true"
            [min]="ascInitialDateRange?.min"
            [max]="ascInitialDateRange?.max"
          >
          </kendo-datepicker>
        </div>
      </div>
    </ng-container>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-secondary mx-1"
        (click)="activeModal.dismiss(); deleteCompany(data.company)"
      >
        Cancel
      </button>
      <button
        class="btn btn-success mx-1"
        [disabled]="!companyForm.valid"
        [ngStyle]="{
          cursor:
            companyForm.valid || !savingCompany ? 'pointer' : 'not-allowed'
        }"
        (click)="updateSPOCompany()"
      >
        <span
          *ngIf="savingCompany"
          class="spinner-border spinner-border-sm"
        ></span
        >&nbsp;
        <span *ngIf="savingCompany" class="load-text">Updating Company</span>
        <span *ngIf="!savingCompany"> Save and login </span>
      </button>
    </div>
  </div>

  <div *ngIf="data.company?.sellerId" formGroupName="companyInfo">
    <ng-container>
      <div class="form-group">
        <label>Company Name</label>
        <input
          disabled
          type="text"
          class="form-control"
          formControlName="companyName"
          placeholder="Company Name"
        />
      </div>
      <div class="form-group">
        <label>Marketplace ID</label>
        <div>
          <kendo-dropdownlist
            disabled
            isRequired="true"
            formControlName="marketplaceId"
            [data]="marketplaceIds"
            [textField]="'display'"
            [valueField]="'val'"
          >
          </kendo-dropdownlist>
        </div>
      </div>
    </ng-container>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-secondary mx-1"
        (click)="activeModal.dismiss(); deleteCompany(data.company)"
      >
        Cancel
      </button>
      <button
        class="btn btn-success mx-1"
        [disabled]="!companyForm.valid"
        [ngStyle]="{
          cursor:
            companyForm.valid || !savingCompany ? 'pointer' : 'not-allowed'
        }"
        (click)="updateSPOCompany()"
      >
        <ng-container *ngIf="savingCompany"
        >
          <span
            class="spinner-border spinner-border-sm"
          ></span>&nbsp;
        </ng-container>
        <span *ngIf="!savingCompany"> Login </span>
      </button>
    </div>
  </div>
</form>
