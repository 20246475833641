<form class="print-stickers-modal">
  <div class="modal-header">
    <h5 class="modal-title">Item Stickers</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body print-stickers-modal-body">
    <div>
      <p>
        Here is a preview of your label. Please select any additional line items
        to be printed, the format and output option. For thermal printers,
        choose the Custom Size format and enter your sticker size in millimeters.
      </p>
      <p>
        For best result, we suggest using the Download button and printing from
        Adobe reader.
      </p>
    </div>

    <span class="print-stickers-modal-body-modify" (click)="handleModify()">{{
      modifySticker
    }}</span>
    <form *ngIf="stickerForm" [formGroup]="stickerForm">
      <!-- Optional Data -->
      <div
        *ngIf="isModifySticker"
        class="d-flex flex-column justify-content-center my-5 barcode"
      >
        <div id="test" class="barcode__content" [style]="generateBarcode()">
          <svg id="barcode"></svg>
          <div>FNSKU &nbsp;&nbsp;&nbsp; Condition</div>
          <div>Title/Description</div>
          <div>
            <div *ngIf="optionalBarcodeText1 !== OptionalDatas.blank">{{
              optionalBarcodeText1
            }}</div>
            <div
              *ngIf="
                optionalBarcodeText1 !== OptionalDatas.blank &&
                optionalBarcodeText2 !== OptionalDatas.blank &&
                stickerFormat.value !== '_27_1' && stickerFormat.value !== '_30_66'
              "
              >{{ optionalBarcodeText2 }}</div
            >
          </div>
        </div>
        <!-- Optional Data 1 -->
        <div class="form-group row barcode__options">
          <label class="col-2 col-form-label"></label>
          <div class="col-10">
            <select
              class="form-select"
              formControlName="optionalData1"
              (change)="handleOptionalData1Change()"
            >
              <option
                *ngFor="let item of OptionalDatas | keyvalue"
                [value]="item.key"
              >
                {{ item.value }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="shouldShowCustomText1" class="form-group row">
          <label
            class="col-3 col-form-label barcode__options-label"
            for="customText1"
            >Custom Text 1:</label
          >
          <div class="barcode__options-input">
            <input
              type="text"
              class="form-control"
              id="customText1"
              formControlName="customText1"
              (change)="generateBarcode()"
              [ngClass]="{
                'is-invalid':
                  !customText1?.valid &&
                  (customText1?.dirty || customText1?.touched)
              }"
            />

            <div
              *ngIf="customText1?.errors && customText1?.touched"
              class="text-danger mt-2"
            >
              <span *ngIf="customText1.hasError('required')">
                <b>Custom text is required.</b>
              </span>
            </div>
          </div>
        </div>
        <!-- Optional Data 2 -->
        <div *ngIf="stickerFormat.value !== '_27_1' && stickerFormat.value !== '_30_66'" class="form-group row barcode__options">
          <label class="col-2 col-form-label"></label>
          <div class="col-10">
            <select
              class="form-select"
              formControlName="optionalData2"
              [attr.disabled]="
                optionalBarcodeText1 === OptionalDatas.blank ? true : null
              "
              (change)="handleOptionalData2Change()"
            >
              <option
                *ngFor="let item of OptionalDatas | keyvalue"
                [value]="item.key"
              >
                {{ item.value }}
              </option>
            </select>
          </div>
        </div>
        <div *ngIf="shouldShowCustomText2 && stickerFormat.value !== '_27_1' && stickerFormat.value !== '_30_66'" class="form-group row">
          <label
            class="col-3 col-form-label barcode__options-label"
            for="customText2"
            >Custom Text 2:</label
          >
          <div class="barcode__options-input">
            <input
              type="text"
              class="form-control"
              id="customText2"
              formControlName="customText2"
              (change)="generateBarcode()"
              [ngClass]="{
                'is-invalid':
                  !customText2?.valid &&
                  (customText2?.dirty || customText2?.touched)
              }"
            />

            <div
              *ngIf="customText1?.errors && customText1?.touched"
              class="text-danger mt-2"
            >
              <span *ngIf="customText1.hasError('required')">
                <b>Custom text is required.</b>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        [style.display]="isModifySticker ? 'none' : 'block'"
        class="my-5 preview"
      >
        <div class="input-group mb-2">
          <input
            #searchBar
            class="form-control border"
            type="search"
            placeholder="Search items by SKU"
            [disabled]="isLoading"
            (input)="clearSearch(searchBar.value)"
          />
          <span class="input-group-append">
            <div class="input-group-append">
              <button class="btn btn-primary" (click)="search(searchBar.value)">
                <i class="fa fa-search"></i>
              </button>
            </div>
          </span>
        </div>

        <ng-container *ngIf="purchaseOrder; else itemGrid">
          <app-forecast-rxdata-table
            #grid
            *ngIf="metadataFields?.length"
            [purchaseOrderKey]="purchaseOrder.key"
            [itemName]="GridName.PurchaseOrderItem"
            [modelType]="supplyRef"
            [resourceService]="supplyService"
            [columns]="metadataFields"
            [userMetaDataFields]="userMetaDataFields"
            [shouldLoadGrid]="shouldLoadGrid"
            [addIsVisible]="false"
            [deleteIsVisible]="false"
            [bulkMngtIsVisible]="false"
            [isExtraExportVisible]="false"
            [initGridState]="gridState"
            [saveGridStateIsVisible]="false"
            [fullScreenIconIsVisible]="false"
            [isSelectable]="false"
            [isSticker]="true"
          ></app-forecast-rxdata-table>
        </ng-container>

        <ng-template #itemGrid>
          <app-forecast-rxdata-table
            #grid
            *ngIf="metadataFields?.length"
            itemName="Item Stickers"
            [modelType]="itemRef"
            [resourceService]="itemService"
            [initGridState]="gridState"
            [columns]="metadataFields"
            [addIsVisible]="false"
            [deleteIsVisible]="false"
            [bulkMngtIsVisible]="false"
            keyName="rowId"
            [notiDisplayName]="name"
            [toogleFullScreenIsVisible]="false"
            [additionalReadOnlyCols]="additionalReadOnlyCols"
            [itemsKeySelected]="itemsKeySelected"
            [itemsSticker]="itemsSticker"
            [saveGridStateIsVisible]="false"
            [isSelectable]="false"
            [isSticker]="true"
            (itemsStickerChange)="itemsStickerChange($event)"
          ></app-forecast-rxdata-table>
        </ng-template>
      </div>

      <div class="form-check mb-4 required-sticker">
        <div>
          <input
            class="form-check-input"
            type="checkbox"
            id="printRequired"
            formControlName="printRequiredStickerOnly"
          />
          <label class="form-check-label" for="printRequired">
            Print Required Sticker Only
          </label>
        </div>
      </div>

      <!-- Sticker Format -->
      <div class="form-group row">
        <label class="col-2 col-form-label" for="stickerFormat">Format:</label>
        <div class="col-8">
          <select
            class="form-select"
            id="stickerFormat"
            formControlName="stickerFormat"
            (change)="handleStickerFormatChange()"
          >
            <option
              *ngFor="let item of StickerFormats | keyvalue"
              [value]="item.key"
            >
              {{ item.value }}
            </option>
          </select>

          <div *ngIf="shouldShowCustomSize" class="form-row mt-3">
            <div class="form-group col-6">
              <label for="customWidth">Width (mm)</label>
              <input
                class="form-control"
                type="number"
                id="customWidth"
                formControlName="customWidth"
                [ngClass]="{
                  'is-invalid':
                    !customWidth?.valid &&
                    (customWidth?.dirty || customWidth?.touched)
                }"
              />

              <div
                *ngIf="customWidth?.errors && customWidth?.touched"
                class="text-danger mt-2"
              >
                <span *ngIf="customWidth.hasError('required')">
                  <b>Custom width is required.</b>
                </span>
              </div>

              <small
                class="form-text text-muted"
                [ngClass]="{ 'text-danger': customWidth.hasError('min') }"
              >
                Minumum width is 45.00mm
              </small>
            </div>
            <div class="form-group col-6">
              <label for="customHeight">Height (mm)</label>
              <input
                class="form-control"
                type="number"
                id="customHeight"
                formControlName="customHeight"
                [ngClass]="{
                  'is-invalid':
                    !customHeight?.valid &&
                    (customHeight?.dirty || customHeight?.touched)
                }"
              />

              <div
                *ngIf="customHeight?.errors && customHeight?.touched"
                class="text-danger mt-2"
              >
                <span *ngIf="customHeight.hasError('required')">
                  <b>Custom height is required.</b>
                </span>
              </div>

              <small
                class="form-text text-muted"
                [ngClass]="{ 'text-danger': customHeight.hasError('min') }"
              >
                Minumum width is 25.40mm
              </small>
            </div>
          </div>
        </div>
        <div class="col-2">
          <button
            class="btn btn-primary btn-block"
            (click)="downloadPDF()"
            [disabled]="isDownloadPrintButtonDisabled"
          >
            <span
              class="spinner-border spinner-border-sm text-light alig"
              *ngIf="isLoading"
            ></span>
            Download
          </button>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-2 col-form-label" for="printOption">Option:</label>
        <div class="col-8">
          <select
            class="form-select"
            id="printOption"
            formControlName="printOption"
          >
            <option
              *ngFor="let item of PrintOptions | keyvalue"
              [value]="item.key"
            >
              {{ item.value }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <button
            class="btn btn-primary btn-block"
            (click)="downloadPDF(false)"
            [disabled]="isDownloadPrintButtonDisabled"
          >
            <span
              class="spinner-border spinner-border-sm text-light alig"
              *ngIf="isPrintLoading"
            ></span>
            Print
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="modal-footer pr-2 print-stickers-modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark print-stickers-modal-footer-btn-close"
      (click)="activeModal.close('Close click')"
    >
      Close
    </button>
  </div>

  <div id="barcode-wrapper" class="hidden" style="display: none">
    <img alt="" id="temp-barcode" />
  </div>
</form>
