<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">
      Add
      {{
        modalConfig.itemName === "Supply"
          ? "Purchase Order"
          : modalConfig.displayName
      }}
    </h5>
  </div>
  <div class="modal-body">
    <form class="row" [formGroup]="inputForm">
      <div
        class="col-4 p-2"
        *ngFor="let metaDataField of inputFields"
        [ngClass]="{
          'd-none': modalConfig.forbiddenFields?.includes(metaDataField.field)
        }"
      >
        <label
          class="m-r-5 m-b-1"
          *ngIf="!modalConfig.forbiddenFields?.includes(metaDataField.field)"
          >{{ metaDataField.displayName }}:
        </label>
        <ng-container
          *ngIf="!modalConfig.forbiddenFields?.includes(metaDataField.field)"
          [ngSwitch]="metaDataField.displayControl.input"
        >
          <input
            *ngSwitchCase="inputTypeEnum.Text"
            type="text"
            class="form-control"
            [formControl]="inputForm.get(metaDataField.field)"
          />
          <input
            *ngSwitchCase="inputTypeEnum.Email"
            type="email"
            class="form-control"
            [formControl]="inputForm.get(metaDataField.field)"
          />
          <input
            *ngSwitchCase="inputTypeEnum.Integer"
            decimalNumber
            type="number"
            class="form-control"
            [formControl]="inputForm.get(metaDataField.field)"
          />
          <input
            *ngSwitchCase="inputTypeEnum.Decimal"
            decimalNumber
            type="number"
            class="form-control"
            [formControl]="inputForm.get(metaDataField.field)"
          />
          <div *ngSwitchCase="inputTypeEnum.Date"
            >
            <kendo-datepicker
              [style.width.%]="100"
              placeholder="mm/dd/yyyy"
              calendarType="classic"
              [formControl]="inputForm.get(metaDataField.field)"
              format="{{FORMAT_DATE}}"
              [formatPlaceholder]="FORMAT_PATTERN"
            >
            </kendo-datepicker>
          </div>
          <app-list-input
            *ngSwitchCase="inputTypeEnum.List"
            [group]="inputForm"
            [control]="inputForm.get(metaDataField.field)"
            [controlName]="metaDataField.field"
            [source]="metaDataField.displayControl.source"
            [textField]="metaDataField.displayControl.textField"
            [itemName]="modalConfig.itemName"
            [valueField]="metaDataField.displayControl.valueField"
          >
          </app-list-input>

          <app-lookup-input
            [itemName]="modalConfig.itemName"
            [group]="inputForm"
            *ngSwitchCase="inputTypeEnum.LookUp"
            [lookUpConfig]="metaDataField.displayControl.lookUpConfig"
            [control]="inputForm.get(metaDataField.field)"
            [controlName]="metaDataField.field"
            [isAllowAddLookUp]="modalConfig.isAllowAddLookUp"
            (addNewSupplier)="openAddNewSupplier()"
            (selectedVendor)="handleSelectedVendor($event)"
            [newVendor]="selectedVendor"
            [autofillVendor]="autofillVendor"
          ></app-lookup-input>

          <input
            *ngSwitchCase="inputTypeEnum.Checkbox"
            type="checkbox"
            [formControl]="inputForm.get(metaDataField.field)"
          />

          <div *ngSwitchCase="inputTypeEnum.Select">
            <kendo-dropdownlist
              *ngIf="
                metaDataField.field !== ItemField.serviceLevel;
                else serviceLevelDropDown
              "
              [data]="metaDataField.displayControl.selectSource"
              [formControl]="inputForm.get(metaDataField.field)"
              textField="display"
              valueField="val"
              [valuePrimitive]="true"
            ></kendo-dropdownlist>

            <ng-template #serviceLevelDropDown>
              <kendo-dropdownlist
                [popupSettings]="{ height: 200, width: 320 }"
                [data]="metaDataField.displayControl.selectSource"
                [formControl]="inputForm.get(metaDataField.field)"
                textField="display"
                valueField="val"
                [valuePrimitive]="true"
              >
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  <div
                    class="dropdown-list-item"
                    [style]="{
                      'background-color': dataItem.bgColor,
                      color: getContrastYIQ(dataItem.bgColor)
                    }"
                  >
                    <span class="pl-2">{{ dataItem.display }}</span>
                  </div>
                </ng-template>
              </kendo-dropdownlist>
            </ng-template>
          </div>

          <input
            *ngSwitchDefault
            type="text"
            class="form-control"
            [formControl]="inputForm.get(metaDataField.field)"
          />
        </ng-container>
        <div
          *ngIf="
            inputForm.get(metaDataField.field)?.invalid &&
            !(isNew && inputForm.get(metaDataField.field).untouched) &&
            !modalConfig.forbiddenFields?.includes(metaDataField.field)
          "
          class="text-danger text-small"
        >
          <span *ngIf="inputForm.get(metaDataField.field).hasError('required')">
            <small>{{ metaDataField.displayName }} is required. </small>
          </span>
          <span *ngIf="inputForm.get(metaDataField.field).hasError('max')">
            <div [ngSwitch]="metaDataField.field">
              <div *ngSwitchCase="ItemField.leadTime">
                <small *ngIf="!leadTimeAndOrderIntervalTotalError"
                  >Maximum is
                  {{ inputForm.get(metaDataField.field).getError("max").max }}.
                </small>
                <small *ngIf="leadTimeAndOrderIntervalTotalError"
                  >Max total Lead time and Order interval must be less than or
                  equal to 360</small
                >
              </div>
              <div *ngSwitchCase="ItemField.orderInterval">
                <small *ngIf="!leadTimeAndOrderIntervalTotalError"
                  >Maximum is
                  {{ inputForm.get(metaDataField.field).getError("max").max }}.
                </small>
                <small *ngIf="leadTimeAndOrderIntervalTotalError"
                  >Max total Lead time and Order interval must be less than or
                  equal to 360</small
                >
              </div>
              <div *ngSwitchDefault>
                <small>Maximum is {{ metaDataField | dataGetMax }}. </small>
              </div>
            </div>
          </span>
          <span *ngIf="inputForm.get(metaDataField.field).hasError('min')">
            <small>Minimum is {{ metaDataField | dataGetMin }}. </small>
          </span>
          <span *ngIf="inputForm.get(metaDataField.field).hasError('email')">
            <small>Invalid email address. </small>
          </span>
          <span
            *ngIf="inputForm.get(metaDataField.field).hasError('isANumber')"
          >
            <small>{{ metaDataField.displayName }} must be a number.</small>
          </span>
          <span
            *ngIf="inputForm.get(metaDataField.field)?.hasError('divisibleBy')"
          >
            <small>{{
              inputForm.get(metaDataField.field)?.getError("divisibleBy")
            }}</small>
          </span>
          <span
            *ngIf="
              inputForm.get(metaDataField.field)?.hasError('lessThanOrEqual')
            "
          >
            <small>{{
              inputForm.get(metaDataField.field)?.getError("lessThanOrEqual")
            }}</small>
          </span>
          <span
            *ngIf="
              inputForm.get(metaDataField.field)?.hasError('greaterThanOrEqual')
            "
          >
            <small>{{
              inputForm.get(metaDataField.field)?.getError("greaterThanOrEqual")
            }}</small>
          </span>
        </div>
        <span
          *ngIf="metaDataField.field === 'dueDate' && isDateConflict"
          class="text-danger text-small"
        >
          <small>The Receive Date must be greater than the PO Date</small>
        </span>
      </div>
    </form>
    <div
      *ngIf="
        modalConfig.itemName === GridName.Supplier &&
        modalConfig.isShowRestockAMZ
      "
    >
      <span class="row">
        <hr class="col-7" />
        <button
          class="col-3 border-0 p-0 bg-transparent"
          [style]="{ 'font-size': '18px' }"
          (click)="openAddressModal(addNewAddress)"
          data-dismiss="modal"
        >
          <span> Add address </span>
          <span
            [style]="{ 'font-size': '22px' }"
            class="k-icon mb-md-3 k-i-plus-circle"
          ></span>
        </button>
        <hr class="col" />
      </span>
      <div class="row">
        <kendo-grid
          [data]="addressData"
          [height]="200"
          [selectable]="true"
          [reorderable]="true"
          (cellClick)="cellAddressClick(addNewAddress, $event)"
        >
          <kendo-grid-column
            field="countryCode"
            title="Country Code"
            editor="text"
          ></kendo-grid-column>
          <kendo-grid-column
            field="addressLine1"
            title="Street address"
          ></kendo-grid-column>
          <kendo-grid-column
            field="city"
            title="City"
            [width]="100"
          ></kendo-grid-column>
          <kendo-grid-column
            field="stateOrProvinceCode"
            title="State/Province"
          ></kendo-grid-column>
          <kendo-grid-column
            field="postalCode"
            title="Zip/Postal code"
          ></kendo-grid-column>
          <kendo-grid-column
            field="phoneNumber"
            title="Phone Number"
          ></kendo-grid-column>
        </kendo-grid>
      </div>
    </div>
  </div>
  <div class="modal-footer p-10">
    <a
      href="javascript://"
      (click)="modal.dismiss('Cross click'); cancel.emit(true)"
      >Cancel</a
    >
    <button
      type="button"
      class="btn btn-success"
      (click)="saveItem(modal)"
      [disabled]="inputForm.invalid || loading"
      [ngStyle]="{ cursor: inputForm.valid ? 'pointer' : 'not-allowed' }"
    >
      Save
    </button>
  </div>
</ng-template>

<ng-template class="supplierForm" #addNewAddress let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ modalAddressTitle }}</h5>
  </div>
  <div class="modal-body supplierForm">
    <div class="supplier-form">
      <div class="row">
        <div class="col-sm-12">
          <form *ngIf="supplierForm" [formGroup]="supplierForm">
            <div class="form-group">
              <label for="country">Country</label>
              <div>
                <kendo-combobox
                  #Combobox
                  [style.width]="'100%'"
                  [data]="countryDropdownItem"
                  [loading]="loading"
                  [textField]="'name'"
                  [valueField]="'countryCode'"
                  [searchField]="'name'"
                  [filterable]="true"
                  (filterChange)="handleCountryFilter($event)"
                  (valueChange)="valueCountryChange($event)"
                  [placeholder]="placeholder"
                  formControlName="country"
                >
                </kendo-combobox>
              </div>
            </div>

            <div class="form-group">
              <label for="addressLine1">Street address</label>
              <input
                id="addressLine1"
                type="text"
                class="form-control"
                formControlName="addressLine1"
                placeholder="Street and number, P.O. box, c/o."
                [ngClass]="{
                  'is-invalid':
                    !addressLine1?.valid &&
                    (addressLine1?.dirty || addressLine1?.touched)
                }"
              />
              <div
                *ngIf="addressLine1.errors && addressLine1.dirty"
                class="error jquery-validation-error small form-text invalid-feedback"
              >
                <label *ngIf="addressLine1.hasError('required')">
                  <b>Street address is required.</b>
                </label>
                <label *ngIf="addressLine1.hasError('maxLength')">
                  <b>The maximum length is 180.</b>
                </label>
              </div>
            </div>

            <div class="form-group">
              <label for="city">City</label>
              <input
                id="city"
                type="text"
                class="form-control"
                formControlName="city"
                [ngClass]="{
                  'is-invalid': !city?.valid && (city?.dirty || city?.touched)
                }"
              />
              <div
                *ngIf="city.errors && city.dirty"
                class="error jquery-validation-error small form-text invalid-feedback"
              >
                <label *ngIf="city.hasError('required')">
                  <b>City is required.</b>
                </label>
                <label *ngIf="city.hasError('maxLength')">
                  <b>The maximum length is 30.</b>
                </label>
              </div>
            </div>

            <div class="form-group">
              <label for="stateOrProvince">State/Province</label>
              <input
                id="stateOrProvince"
                type="text"
                class="form-control"
                formControlName="stateOrProvince"
                [ngClass]="{
                  'is-invalid':
                    !stateOrProvince?.valid &&
                    (stateOrProvince?.dirty || stateOrProvince?.touched)
                }"
              />
              <div
                *ngIf="stateOrProvince.errors && stateOrProvince.dirty"
                class="error jquery-validation-error small form-text invalid-feedback"
              >
                <label *ngIf="stateOrProvince.hasError('required')">
                  <b>State/Province is required.</b>
                </label>
              </div>

              <!-- will be handled in future -->
              <!-- <div *ngIf="false">
                <kendo-dropdownlist
                  id="usState"
                  isRequired="true"
                  formControlName="usState"
                  [data]="states"
                  textField="name"
                  valueField="stateCode"
                  [style.width.%]="100"
                  [ngClass]="{
                    'is-invalid':
                      !usState?.valid && (usState?.dirty || usState?.touched)
                  }"
                >
                </kendo-dropdownlist>
              </div> -->
            </div>

            <div class="form-group">
              <label for="postalCode">Zip/Postal Code</label>
              <input
                id="postalCode"
                class="form-control"
                type="text"
                formControlName="postalCode"
                [ngClass]="{
                  'is-invalid':
                    !postalCode?.valid &&
                    (postalCode?.dirty || postalCode?.touched)
                }"
              />
              <div
                *ngIf="postalCode.errors && postalCode.dirty"
                class="error jquery-validation-error small form-text invalid-feedback"
              >
                <label *ngIf="postalCode.hasError('required')">
                  <b>Postal code is required.</b>
                </label>
                <label *ngIf="postalCode.hasError('maxLength')">
                  <b>The maximum length is 30.</b>
                </label>
              </div>
            </div>

            <div class="form-group">
              <label for="phoneNumber">Phone number (optional)</label>
              <input
                id="phoneNumber"
                class="form-control"
                type="tel"
                formControlName="phoneNumber"
                [ngClass]="{
                  'is-invalid':
                    !phoneNumber?.valid &&
                    (phoneNumber?.dirty || phoneNumber?.touched)
                }"
              />
              <div
                *ngIf="phoneNumber.errors && phoneNumber.dirty"
                class="error jquery-validation-error small form-text invalid-feedback"
              >
                <label *ngIf="phoneNumber.hasError('pattern')"
                  ><b>Enter a valid phone number.</b></label
                >
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer p-10">
    <div>
      <a href="javascript://" (click)="onAddressCancel(modal)">Cancel</a>
    </div>
    <div *ngIf="modalAddressTitle === 'Edit Address'">
      <button
        type="button"
        class="btn btn-danger mx-1"
        (click)="deleteAddress()"
      >
        Delete
      </button>
    </div>
    <button
      type="button"
      class="btn btn-success"
      [disabled]="supplierForm.invalid"
      (click)="handleAddress()"
      [ngStyle]="{ cursor: supplierForm.valid ? 'pointer' : 'not-allowed' }"
    >
      Save
    </button>
  </div>
</ng-template>
