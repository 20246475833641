import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  switchMap,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import { Item } from 'src/app/core/models/item';
import { Company } from 'src/app/core/models/company';

import { ItemService } from 'src/app/core/services/item.service';
import { CompanyService } from 'src/app/core/services/company.service';

import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-lookup-item',
  templateUrl: './lookup-item.component.html',
  styleUrls: ['./lookup-item.component.scss'],
})
export class LookupItemComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('searchBox') searchBox;

  @Input() alreadyChoosenItem: Item = null;
  @Input() set shouldShowHiddenItems(value: boolean) {
    this._shouldShowHiddenItems = value;
    this.loadAllItems();
  }
  @Input() set currentCompany(value: Company) {
    this.isFirstTime = true;
    this.loadAllItems();
  }
  @Input() reloadLookUpGrid$: Subject<any>;

  @Output() itemChoosen = new EventEmitter();

  readonly CompanyType = CompanyType;
  @Input() itemName: string

  get isModifyForecastGrid() {
    return  this.itemName === 'Result';
  }

  gridItems: GridDataResult;
  currentOffset = 0;
  itemCount: number;
  columns = [];
  searchTerm = '';
  choosenItem: Item;
  hiddenStyle = {
    opacity: 0.5,
    'font-style': 'italic',
  };
  isFirstTime = true;
  private searchTerms = new Subject<string>();
  private _shouldShowHiddenItems = false;

  get currentCompany(): Company {
    return this.companyService.currentCompany();
  }

  get shouldShowHiddenItems(): boolean {
    return this._shouldShowHiddenItems;
  }

  constructor(
    private itemService: ItemService,
    private companyService: CompanyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.companyService
      .getCurrentCompany()
      .pipe(this.autoCleanUp())
      .subscribe((company) => {
        if (this.currentCompany.companyType === CompanyType.ASC) {
          this.columns = [
            {
              field: 'name',
              displayName: 'SKU',
            },
            {
              field: 'asin',
              displayName: 'ASIN',
            },
          ];
        } else {
          this.columns = [
            {
              field: 'name',
              displayName: 'Item Name',
            },
          ];
        }
      });

    this.searchTerms
      .pipe(
        // wait 300ms after each keystroke before considering the term
        debounceTime(300),

        // ignore new term if same as previous term
        distinctUntilChanged(),

        // switch to new search observable each time the term changes
        switchMap((term: string) => {
          return this.itemService.getFilteredUserTelerikEvent(
            0,
            30,
            this.searchFilter(term)
          );
        })
      )
      .subscribe((items) => {
        this.currentOffset = 0;

        this.loadItemCount(this.searchTerm).subscribe((count) => {
          this.itemCount = count;
          this.gridItems = {
            total: count,
            data: this.isModifyForecastGrid ? items
            : items.filter(
              (item) => this.alreadyChoosenItem?.key !== item.key
            ),
          } as GridDataResult;
        });
      });

    this.reloadLookUpGrid$
      .pipe(
        this.autoCleanUp(),
        tap(() => {
          this.isFirstTime = false;
          this.loadAllItems();
        })
      )
      .subscribe();
  }

  searchFilter(term: string): any {
    const filter = {
      logic: 'and',
      filters: [] as any[],
    };

    if (!this.shouldShowHiddenItems) {
      filter.filters.push({
        logic: 'and',
        filters: [
          {
            field: 'isHidden',
            operator: 'eq',
            value: false,
          },
        ],
      });
    }

    if (term) {
      filter.filters.push({
        logic: 'or',
        filters: [
          {
            field: 'name',
            operator: 'contains',
            value: term,
          },
          {
            field: 'asin',
            operator: 'contains',
            value: term,
          },
        ],
      });
    }

    if(this.isModifyForecastGrid) {
      const extendsFilter = {
        logic: 'and',
        filters: [
          {
            field: 'lotMultipleItemKey',
            operator: 'eq',
            value: null,
          },
        ],
      };
      filter.filters.push(extendsFilter)
    }

    if (filter.filters.length === 0) {
      return null;
    }

    return filter;
  }

  search(term: string): void {
    if (term.length === 0) {
      this.searchTerm = '';
      this.loadAllItems();

      return;
    }

    if (term.length === 1) {
      return;
    }

    this.searchTerm = term;
    this.searchTerms.next(term);
  }

  loadItemCount(term: string): Observable<number> {
    return this.itemService.getCount(
      this.searchFilter(this.searchTerm),
      '',
      this.currentCompany?.companyKey
    );
  }

  loadItems(): void {
    this.itemService
      .getFilteredUserTelerikEvent(
        this.currentOffset,
        30,
        this.searchFilter(this.searchTerm)
      )
      .subscribe((items) => {
        this.gridItems = {
          total: this.itemCount,
          data: this.isModifyForecastGrid ? items
          : items.filter(
            (item) => this.alreadyChoosenItem?.key !== item.key
          ),
        } as GridDataResult;
      });
  }

  loadAllItems(): void {
    this.itemService
      .getFilteredUserTelerikEvent(0, 30, this.searchFilter(this.searchTerm))
      .subscribe((items) => {
        this.currentOffset = 0;

        if (this.isFirstTime && !this.alreadyChoosenItem) {
          this.isFirstTime = false;
          if(!this.isModifyForecastGrid) {
            this.chooseItem({ dataItem: items[0] });
          }
        }

        this.loadItemCount(this.searchTerm).subscribe((count) => {
          this.itemCount = count;
          this.gridItems = {
            total: count,
            data: this.isModifyForecastGrid ? items
            : items.filter(
              (item) => this.alreadyChoosenItem?.key !== item.key
            )
          } as GridDataResult;
        });
      });
  }

  pageChange(event: any): void {
    this.currentOffset = event.skip;
    this.loadItems();
  }

  chooseItem({ dataItem }): void {
    this.itemChoosen.emit(dataItem as Item);
  }
}
