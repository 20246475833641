import { Component, Inject } from '@angular/core';
import { MODAL_DATA } from '@stockaid/services';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IConfirmationModalData } from './confirmation-modal.model';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  constructor(
    @Inject(MODAL_DATA) public data: IConfirmationModalData,
    private activeModal: NgbActiveModal
  ) {}

  confirm() {
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
