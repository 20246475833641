<div class="estimated-margin-cal">
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5 class="text-white estimated-modal-title">Estimated Margin</h5>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.close(restockSuggestion)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card px-4 pt-4">
      <div class="row">
        <div class="col-md-6 col-sm-4">
          <div class="card p-2" [class.accordion-price]="isOpenTooglePrice">
            <div
              *ngIf="
                PRICE_TABLE_DATA && COST_TABLE_DATA && AMAZON_FEES_TABLE_DATA
              "
              class="card-body py-0 px-4"
            >
              <button
                class="accordion-button collapsed bg-white btn w-100 pt-1 py-0 px-2 border-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
                [dataTestID]="'price-toggle'"
                (click)="onClickTooglePrice()"
              >
                <div class="d-flex justify-content-between">
                  <h4 class="text-success">
                    <strong> Price </strong>
                  </h4>
                  <span class="float-end">
                    <span class="mr-2 h4">
                      <strong class="text-success">
                        {{ currentPrice | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}
                      </strong>
                    </span>
                    <span>
                      <i
                        *ngIf="!isOpenTooglePrice"
                        class="fas fa-chevron-down"
                      ></i>
                      <i
                        *ngIf="isOpenTooglePrice"
                        class="fas fa-chevron-up"
                      ></i>
                    </span>
                  </span>
                </div>
                <h6 class="float-left text-muted">
                  {{ selectedPrice?.displayName }}
                </h6>
              </button>

              <div *ngIf="isOpenTooglePrice" class="dropdown-content card">
                <div
                  class="mt-0"
                  *ngFor="let data of PRICE_TABLE_DATA.data; index as i"
                >
                  <span class="mx-1 pr-4 toogle-price">
                    <span class="d-flex align-items-center">
                      <input
                        type="radio"
                        name="price"
                        [id]="data.field"
                        [value]="data.field"
                        [checked]="
                          data.field === restockSuggestion?.estimatedPriceType
                        "
                        [disabled]="getIsRadioDisabled(data.value) || isLoading"
                        class="cursor-pointer"
                        [class.cursor-not-allowed]="getIsRadioDisabled(data.value) || isLoading"
                        (change)="onPriceChange($event)"
                      />
                      <div>
                        <span class="ml-2" [for]="data.field">
                          {{ data.displayName }}
                        </span>
                      </div>
                    </span>

                    <span class="ml-4"> {{ data.value || 0 | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }} </span>
                  </span>
                  <hr *ngIf="i < PRICE_TABLE_DATA.data.length - 1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-flex align-items-stretch">
        <div *ngIf="COST_TABLE_DATA" class="col-md-6 col-sm-12">
          <div class="card h-100">
            <div class="card-body pb-0 bg-white">
              <table class="table table-borderless mb-0 bg-white" aria-describedby>
                <thead *ngFor="let header of COST_TABLE_DATA.headers">
                  <tr>
                    <th class="pt-2 pb-2 border-0">
                      <span class="h4 text-warning">
                        <strong> {{ header }} </strong>
                      </span>
                    </th>
                    <th class="pt-2 pb-2 align-right border-0 text-end">
                      <span class="h4 text-warning">
                        <strong> {{ totalCosts | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }} </strong>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody *ngFor="let data of COST_TABLE_DATA.data">
                  <tr
                    class="border-top"
                    *ngIf="data.displayName != COST_DISPLAY_NAMES.total"
                  >
                    <td class="pt-2 pb-2">{{ data.displayName }}</td>
                    <td class="pt-2 pb-2 text-end">
                      {{
                        (data.isTotal
                          ? getTotalFromDataTable(COST_TABLE_DATA.data)
                          : data.value) || 0 | currency: displayCurrency.code : displayCurrency.symbol : '1.2'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="AMAZON_FEES_TABLE_DATA" class="col-md-6 col-sm-12">
          <div class="card h-100">
            <div class="card-body pb-0">
              <table class="table table-borderless mb-0 bg-white" aria-describedby>
                <thead *ngFor="let header of AMAZON_FEES_TABLE_DATA.headers">
                  <th class="pt-2 pb-2 text-primary border-0">
                    <span class="px-0 h4 text-primary">
                      <strong>{{ header }}</strong>
                    </span>
                  </th>
                  <th class="pt-2 pb-2 text-primary border-0 text-end">
                    <span class="px-0 h4 text-primary">
                      <strong>{{ amazonFees | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</strong>
                    </span>
                  </th>
                </thead>
                <tbody *ngFor="let data of AMAZON_FEES_TABLE_DATA.data">
                  <tr
                    *ngIf="data.displayName != AMAZON_FEES_DISPLAY_NAMES.total"
                    class="border-top"
                  >
                    <td class="pt-2 pb-2">{{ data.displayName }}</td>
                    <td class="pt-2 pb-2 text-end">
                      {{
                        (data.isTotal
                          ? getTotalFromDataTable(AMAZON_FEES_TABLE_DATA.data)
                          : data.value) || 0 | currency: displayCurrency.code : displayCurrency.symbol : '1.2'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-4 px-2">
        <div class="card-body">
          <div class="row w-100">
            <div class="col-md-4 col-sm-16">
              <table class="w-100 text-cal text-secondary" aria-describedby>
                <th hidden="hidden"></th>
                <tbody class="w-100">
                  <tr>
                    <td class="py-2">
                      <strong class="text-success">Price</strong>
                    </td>
                    <td class="text-center">&nbsp;</td>
                    <td class="text-end">{{ currentPrice | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</td>
                  </tr>
                  <tr>
                    <td><strong class="text-warning">Cost</strong></td>
                    <td class="text-center">-</td>
                    <td class="text-end">{{ totalCosts | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</td>
                  </tr>
                  <tr>
                    <td class="py-2">
                      <strong class="text-primary">Amazon Fees</strong>
                    </td>
                    <td class="text-center">-</td>
                    <td class="text-end">{{ amazonFees | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</td>
                  </tr>
                  <tr class="border-top">
                    <td class="py-2">Estimated Margin ({{ displayCurrency.symbol }})</td>
                    <td class="text-center">=</td>
                    <td class="text-end">{{ estimatedMargin | currency: displayCurrency.code : displayCurrency.symbol : '1.2'}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-4 col-sm-16">
              <table class="w-100 text-cal text-secondary" aria-describedby>
                <th hidden="hidden"></th>
                <tbody>
                  <tr>
                    <td class="py-2">Estimated Margin ({{ displayCurrency.symbol }})</td>
                    <td class="text-center">&nbsp;</td>
                    <td class="text-end">{{ estimatedMargin | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</td>
                  </tr>
                  <tr>
                    <td><strong class="text-success">Price</strong></td>
                    <td class="text-center">/</td>
                    <td class="text-end">{{ currentPrice | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</td>
                  </tr>
                  <tr>
                    <td class="py-2">&nbsp;</td>
                    <td class="text-center">&nbsp;</td>
                    <td class="text-end">&nbsp;</td>
                  </tr>
                  <tr class="border-top">
                    <td class="py-2">Estimated Margin (%)</td>
                    <td class="text-center">=</td>
                    <td class="text-end">{{ estimatedMarginPercentage }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-4 col-sm-16">
              <table class="w-100 text-cal text-secondary" aria-describedby>
                <th hidden="hidden"></th>
                <tbody>
                  <tr>
                    <td class="py-2">Estimated Margin ({{ displayCurrency.symbol }})</td>
                    <td>&nbsp;</td>
                    <td>{{ estimatedMargin | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</td>
                  </tr>
                  <tr>
                    <td><strong class="text-warning">Cost</strong></td>
                    <td>/</td>
                    <td>{{ totalCosts | currency: displayCurrency.code : displayCurrency.symbol : '1.2' }}</td>
                  </tr>
                  <tr>
                    <td class="py-2">&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr class="border-top">
                    <td class="py-2">Estimated Margin (%)</td>
                    <td>=</td>
                    <td>{{ estimatedMarkup }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
