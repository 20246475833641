export const MARKET_PLACE_IDS = [
  { display: 'North American Integration', val: 'NA' },
  { display: 'European Integration', val: 'EU' },
  { display: '(CA) A2EUQ1WTGCTBG2', val: 'A2EUQ1WTGCTBG2' },
  { display: '(DE) A1PA6795UKMFR9', val: 'A1PA6795UKMFR9' },
  { display: '(ES) A1RKKUPIHCS9HS', val: 'A1RKKUPIHCS9HS' },
  { display: '(FR) A13V1IB3VIYZZH', val: 'A13V1IB3VIYZZH' },
  { display: '(IT) APJ6JRA9NG5V4', val: 'APJ6JRA9NG5V4' },
  { display: '(MX) A1AM78C64UM0Y8', val: 'A1AM78C64UM0Y8' },
  { display: '(UK) A1F83G8C2ARO7P', val: 'A1F83G8C2ARO7P' },
  { display: '(US) ATVPDKIKX0DER', val: 'ATVPDKIKX0DER' },
];

export const EU_MARKET_PLACE_IDS = [
  { display: 'Spain', val: 'A1RKKUPIHCS9HS' },
  { display: 'France', val: 'A13V1IB3VIYZZH' },
  { display: 'Netherlands', val: 'A1805IZSGTT6HS' },
  { display: 'Germany', val: 'A1PA6795UKMFR9' },
  { display: 'Italy', val: 'APJ6JRA9NG5V4' },
  { display: 'Sweden', val: 'A2NODRKZP88ZB9' },
  { display: 'Poland', val: 'A1C3SOZRARQ6R3' },
  { display: 'Turkey', val: 'A33AVAJ2PDY3EV' },
];
