export enum PurchaseOrderStatus {
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  PENDING = 'PENDING',
  RECEIVING = 'RECEIVING',
  RECEIVED = 'RECEIVED',
  SHIPPED = 'SHIPPED',
  IN_TRANSIT = 'IN_TRANSIT',
  // DELIVERED = 'DELIVERED',
  // CHECKED_IN = 'CHECKED_IN',
  // PO_SENT = 'PO_SENT',
  WORKING = 'WORKING',
  OPEN = 'OPEN'
}
