import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { ForecastuiModule } from '../../forecastui/forecastui.module';
import { AgreementModalComponent } from './agreement-modal';
import { AnimationModalComponent } from './animation-modal/animation-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal';
import { CreateShipmentModalComponent } from './create-shipment-modal/create-shipment-modal.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { ManuallyImportDataModalComponent } from './manually-import-data-modal/manually-import-data-modal.component';
import { SkipMeetingModalComponent } from './skip-meeting-modal';
import { SoftwareModalComponent } from './software-modal/software-modal.component';
import { UpdateSPOCompanyModalComponent } from './spo-company-modal/update-spo-company-modal.component';
import { SyncShipmentModalComponent } from './sync-shipment-modal/sync-shipment-modal.component';
import { UiModalComponent } from './ui-modal/ui-modal.component';

const MODALS = [
  UiModalComponent,
  AnimationModalComponent,
  ConfirmationModalComponent,
  AgreementModalComponent,
  UpdateSPOCompanyModalComponent,
  SyncShipmentModalComponent,
  CreateShipmentModalComponent,
  SkipMeetingModalComponent,
  GenericModalComponent,
  ManuallyImportDataModalComponent,
  SoftwareModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    IntlModule,
    DateInputsModule,
    LabelModule,
    ForecastuiModule,
    NgbTooltipModule,
    NgbProgressbarModule
  ],
  declarations: MODALS,
  exports: MODALS,
})
export class ModalModule {}
