import { HttpErrorResponse } from '@angular/common/http';
import {
  ErrorHandler,
  Injectable,
  Injector,
} from '@angular/core';
import { LoggingService } from './../services/logging.service';
import { environment } from './../../../environments/environment';
import { ErrorService } from './../services/error.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: Error | HttpErrorResponse): void {
    const loggerService = this.injector.get(LoggingService);
    const errorService = this.injector.get(ErrorService);
    //potentially add notifier service

    let message;

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = errorService.getServerMessage(error);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
    }

    // Only logging on ENV production
    if (environment.production) {
      loggerService.error(message, error);
    }
    
    console.error(error);
  }
}
