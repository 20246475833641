<div
  class="navbar-wrapper container"
  [ngClass]="{
    container:
      this.gradientConfig.layout === 'horizontal' &&
      this.gradientConfig.subLayout === 'horizontal-2'
  }"
>
  <app-nav-content (onNavMobCollapse)="navMobCollapse()"></app-nav-content>
</div>
