<div class="generic-modal">
  <div class="modal-header text-center" [class]="theme">
    <div class="modal-title w-100">
      <i *ngIf="titleIconClass" [class]="titleIconClass" class="text-white"></i>
      <h3 class="text-white">{{ title }}</h3>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [class]="theme">
    <div class="card p-4 content">
      <span [innerHtml]="content"></span>
    </div>

    <div class="d-flex justify-content-center gap-2">
      <div *ngIf="errorBtn" class="d-flex justify-content-center modal-button">
        <button class="btn" (click)="activeModal.dismiss()">
          {{ errorBtn }}
        </button>
      </div>
      <div *ngIf="successBtn" class="d-flex justify-content-center modal-button">
        <button class="btn" (click)="activeModal.close()">
          {{ successBtn }}
        </button>
      </div>
    </div>
  </div>
</div>
