import { DisplayControl } from "./display-control";

//this list is for simple dropdowns that don't require special look ups.
export class ListDisplayControl extends DisplayControl {

  textField:string;
  valueField:string;
  source: any;

  constructor(init?: Partial<ListDisplayControl>) {
    super(init);
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }
}
