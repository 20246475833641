export enum ColumnWidth {
  itemHistory = 100,
  itemLinks = 140,
  itemIsHidden = 130,
  itemDesc = 220,
  itemMetric = 260,
  itemImage = 100,
  poDetails = 120,
  poDesc = 240,
  poAsin = 140,
  fnsku = 120,
  skuDescAsin = 320,
  shipmentDetailMedium = 140,
  shipmentDetailLarge = 220,
  shipmentName = 220,
  shipmentMedium = 145,
  shipmentStatus = 130,
  shipmentNextStep = 170,
  shipmentOptions = 110,
  shipmentOrderNotes = 240,
  restockModel = 220,
  recommendedQty = 220,
  default = 180,
}
