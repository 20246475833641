import { RestockSuggestionSupplierField } from '../../../../core/infrastructure/enums/meta-data-field.enum';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFlagClass'
})
export class DataFlagClassPipe implements PipeTransform {
  transform(field: string): string | null {
    let flagClass =
      'fa fa-flag forecast-table__flag-icon forecast-table__flag-icon';

    switch (field) {
      case RestockSuggestionSupplierField.orangeAlerts:
        flagClass += '--restock-now';
        break;

      case RestockSuggestionSupplierField.yellowAlerts:
        flagClass += '--restock-soon';
        break;

      case RestockSuggestionSupplierField.redAlerts:
        flagClass += '--out-of-stock';
        break;

      case RestockSuggestionSupplierField.tealAlerts:
        flagClass += '--new';
        break;

      case RestockSuggestionSupplierField.greenAlerts:
        flagClass += '--well-stocked';
        break;

      default:
        flagClass = null;
    }

    return flagClass;
  }
}
