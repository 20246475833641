import { GridStateColumnSort } from './grid-state-column-sort';
import { GridStateColumnFilter } from './grid-state-column-filter';

export class GridStateColumn {
  name: string;
  field: string;
  visible: boolean = true;
  width: string = '*';
  sort = new GridStateColumnSort();
  filters = new GridStateColumnFilter();
  locked: boolean;

  constructor(init?: Partial<GridStateColumn>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
