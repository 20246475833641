import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource-service';
import { GroupNotification, Notification } from '../models/notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends ResourceService<Notification> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/notification');
  }

  get(): Observable<GroupNotification> {
    return this.httpClient.get<GroupNotification>(`${this.apiUrl}`);
  }

  delete(key: string): Observable<Notification> {
    return this.httpClient.delete<Notification>(`${this.apiUrl}/${key}`);
  }

  deleteAll(): Observable<Notification> {
    return this.httpClient.delete<Notification>(`${this.apiUrl}`);
  }
}
