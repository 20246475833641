//used in the metafield to control how to display the data.
export enum DisplayControlOutput {
  Text,
  Currency,
  Checkbox,
  Icon,
  Button,
  Image,
  Flag,
  Link,
  Dropdown,
  Date
}
