import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import { RestockSuggestionService } from 'src/app/core/services/restock-suggestion.service';
import { RestockSuggestion } from 'src/app/core/models/restock-suggestion';
import {
  AMAZON_FEES_DISPLAY_NAMES,
  COST_DISPLAY_NAMES,
  Price,
  PRICE_DISPLAY_NAMES,
} from './estimated-margin.constant';
import _ from 'lodash';
import { finalize } from 'rxjs/operators';
import { getCurrencyCodeByMarketplaceId } from 'src/app/core/constants/amazon-link.constant';
import { CompanyService } from 'src/app/core/services/company.service';
import { Company } from 'src/app/core/models/company';
import { Currencies } from 'src/app/core/constants/currency.constant';

@Component({
  selector: 'app-estimated-margin-calculation-modal',
  templateUrl: './estimated-margin-calculation-modal.component.html',
  styleUrls: ['./estimated-margin-calculation-modal.component.scss'],
})
export class EstimatedMarginCalculationModalComponent
  extends BaseComponent
  implements OnInit
{
  @Input() restockSuggestion: RestockSuggestion;

  PRICE_TABLE_DATA;
  COST_TABLE_DATA;
  AMAZON_FEES_TABLE_DATA;
  totalCosts: number = 0;
  amazonFees: number = 0;
  isLoading: boolean = false;
  estimatedMargin: number;
  estimatedMarkup: string = '';
  estimatedMarginPercentage: number = 0;
  currentPrice: number = 0;
  isOpenTooglePrice: boolean = false;
  selectedPrice;

  readonly Price = Price;
  readonly COST_DISPLAY_NAMES = COST_DISPLAY_NAMES;
  readonly AMAZON_FEES_DISPLAY_NAMES = AMAZON_FEES_DISPLAY_NAMES;

  get currentCompany(): Company {
    return this.companyService.currentCompany();
  }

  get displayCurrency() {
    const currencyCodeByMarketPlaceId = getCurrencyCodeByMarketplaceId(this.currentCompany?.marketplaceId);
    const currencyCodeSetting = this.currentCompany?.currencyCode;
    const code = currencyCodeSetting || currencyCodeByMarketPlaceId || 'USD';
    const currency = Currencies.find(currency => currency.code === code);
    return { code:currency.code, symbol:currency.symbol };
  }

  constructor(
    public activeModal: NgbActiveModal,
    private restockSuggestionService: RestockSuggestionService,
    private companyService: CompanyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadPriceData(this.restockSuggestion);
    this.loadCostData(this.restockSuggestion);
    this.loadAmazonFeesData(this.restockSuggestion);
    this.loadEstimatedData(this.restockSuggestion);
  }

  loadPriceData(restockSuggestion: RestockSuggestion): void {
    this.PRICE_TABLE_DATA = {
      headers: ['Price', ''],
      data: [
        {
          field: Price.listPrice,
          displayName: PRICE_DISPLAY_NAMES.listPrice,
          value: restockSuggestion?.listPrice,
        },
        {
          field: Price.newBuyBox,
          displayName: PRICE_DISPLAY_NAMES.newBuyBox,
          value: restockSuggestion?.newBuyBox,
        },
        {
          field: Price.average7DayPrice,
          displayName: PRICE_DISPLAY_NAMES.average7DayPrice,
          value: restockSuggestion?.average7DayPrice,
        },
        {
          field: Price.lowestFba,
          displayName: PRICE_DISPLAY_NAMES.lowestFba,
          value: restockSuggestion?.lowestFba,
        },
      ],
    };
    this.selectedPrice = this.PRICE_TABLE_DATA.data.find(data => data.field === restockSuggestion?.estimatedPriceType);
  }

  loadCostData(restockSuggestion: RestockSuggestion): void {
    this.COST_TABLE_DATA = {
      headers: ['Cost'],
      data: [
        {
          displayName: COST_DISPLAY_NAMES.currentSupplierCost,
          value: restockSuggestion?.supplierCost,
        },
        {
          displayName: COST_DISPLAY_NAMES.supplierRebate,
          value: restockSuggestion?.supplierRebate,
        },
        {
          displayName: COST_DISPLAY_NAMES.reshippingCost,
          value: restockSuggestion?.reshippingCost,
        },
        {
          displayName: COST_DISPLAY_NAMES.repackagingLaborCost,
          value: restockSuggestion?.repackingLaborCost,
        },
        {
          displayName: COST_DISPLAY_NAMES.repackagingMaterialCost,
          value: restockSuggestion?.repackagingMaterialCost,
        },
        {
          displayName: COST_DISPLAY_NAMES.inboundShippingCost,
          value: restockSuggestion?.inboundShippingCost,
        },
        {
          displayName: COST_DISPLAY_NAMES.total,
          value: 0,
          isTotal: true,
        },
      ],
    };

    this.totalCosts = this.getTotalFromDataTable(this.COST_TABLE_DATA.data);
  }

  loadAmazonFeesData(restockSuggestion: RestockSuggestion): void {
    this.AMAZON_FEES_TABLE_DATA = {
      headers: ['Amazon Fees'],
      data: [
        {
          displayName: AMAZON_FEES_DISPLAY_NAMES.fbaFees,
          value: restockSuggestion?.fbaFee,
        },
        {
          displayName: AMAZON_FEES_DISPLAY_NAMES.variableClosingFee,
          value: restockSuggestion?.variableClosingFee,
        },
        {
          displayName: AMAZON_FEES_DISPLAY_NAMES.monthlyStorageFee,
          value: restockSuggestion?.monthlyStorageFee,
        },
        {
          displayName: AMAZON_FEES_DISPLAY_NAMES.referralFee,
          value: restockSuggestion?.referralFee,
        },
        {
          displayName: AMAZON_FEES_DISPLAY_NAMES.total,
          value: 0,
          isTotal: true,
        },
      ],
    };

    this.amazonFees = this.getTotalFromDataTable(
      this.AMAZON_FEES_TABLE_DATA.data
    );
  }

  loadEstimatedData(
    restockSuggestion: RestockSuggestion,
    currentPrice: number = restockSuggestion?.[
      restockSuggestion?.estimatedPriceType || Price.listPrice
    ] || 0
  ): void {
    const unroundedEstimatedMargin =
      currentPrice - this.totalCosts - this.amazonFees;
    const estimatedMargin = _.round(unroundedEstimatedMargin, 2);
    const estimatedMarkup = this.totalCosts
      ? _.round((unroundedEstimatedMargin / this.totalCosts) * 100, 2)
      : 0;

    this.estimatedMargin =
      this.parseNumber(estimatedMargin) < 0
        ? this.parseNumber(estimatedMargin) * -1
        : this.parseNumber(estimatedMargin)

    this.estimatedMarginPercentage = currentPrice ? this.parseNumber(_.round((unroundedEstimatedMargin / currentPrice) * 100, 2)) : 0;
    this.estimatedMarkup = `${this.parseNumber(estimatedMarkup)}%`
    this.currentPrice = currentPrice;
    this.selectedPrice = this.PRICE_TABLE_DATA.data.find(data => data.field === restockSuggestion?.estimatedPriceType);
  }

  onClickTooglePrice() {
    this.isOpenTooglePrice = !this.isOpenTooglePrice;
  }

  parseNumber(number: number): number {
    return isNaN(number) ? 0 : number;
  }

  getTotalFromDataTable(data): number {
    const sum = data.reduce((a, cur) => {
      if (cur.isTotal) {
        return a;
      }

      return a + Number(cur.value) || 0;
    }, 0);

    return _.round(sum, 2);
  }

  getIsRadioDisabled(value): boolean {
    if (!value) {
      return true;
    }

    return !Number(value) || Number(value) === 0 || Number(value) < this.totalCosts + this.amazonFees;
  }

  onPriceChange(event) {
    this.isLoading = true;
    const priceType = event.target.value as Price;
    let price: number;

    switch (priceType) {
      case Price.average7DayPrice:
        price = this.restockSuggestion?.average7DayPrice;
        break;

      case Price.newBuyBox:
        price = this.restockSuggestion?.newBuyBox;
        break;

      case Price.listPrice:
        price = this.restockSuggestion?.listPrice;
        break;

      case Price.lowestFba:
        price = this.restockSuggestion?.lowestFba;
        break;
    }

    const unroundedEstimatedMargin = price - this.totalCosts - this.amazonFees;
    const estimatedMargin = _.round(unroundedEstimatedMargin, 2);

    const estimatedMarginPercentage = price
      ? _.round((unroundedEstimatedMargin / price) * 100, 2)
      : 0;
    const estimatedMarkupPercentage = this.totalCosts
      ? _.round((unroundedEstimatedMargin / this.totalCosts) * 100, 2)
      : 0;

    this.restockSuggestion = {
      ...this.restockSuggestion,
      estimatedPriceType: priceType,
      estimatedMargin,
      estimatedMarginPercentage,
      estimatedMarkupPercentage,
    };

    this.loadEstimatedData(this.restockSuggestion, price || 0);
    this.restockSuggestionService
      .save(this.restockSuggestion, 'key')
      .pipe((this.autoCleanUp(), finalize(() => (this.isLoading = false))))
      .subscribe();
  }
}
