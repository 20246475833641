import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { IFieldValidator } from 'src/app/core/infrastructure/interfaces/i-field-validator';
import { FieldValidatorType } from 'src/app/core/infrastructure/enums/field-validator-type.enum';
import { MinMaxValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/min-max-validator';
import { CustomPatternValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/custom-pattern-validator';
import { LessMoreValidator } from 'src/app/core/infrastructure/classes/metadataDisplayControls/validators/less-more-validator';
import { DisplayControlInput } from 'src/app/core/infrastructure/enums/display-control-input.enum';
import { formatDate } from '@angular/common';
import { LookUpDisplayControl } from 'src/app/core/infrastructure/classes/metadataDisplayControls/controls/look-up-display-control';
import { LookUpMappingType } from 'src/app/core/infrastructure/enums/look-up-mapping-type.enum';
import { ResourceService } from 'src/app/core/services/resource-service';
import { SnotifyService } from 'ng-snotify';
import { asapScheduler, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RestockSuggestionService } from 'src/app/core/services/restock-suggestion.service';
import { ItemField } from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { Company } from 'src/app/core/models/company';
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';
import { getContrastYIQ } from '@stockaid/utils';
import { COMMON_FORMAT_DATE, FORMAT_PATTERN } from 'src/app/core/constants/format-date.constant';

@Component({
  selector: 'app-generic-item-modal',
  templateUrl: './generic-item-modal.component.html',
  styleUrls: ['./generic-item-modal.component.scss'],
})
export class GenericItemModalComponent<T>
  extends BaseComponent
  implements OnInit
{
  @Input() metadataFields: MetaDataField[];
  @Input() forbiddenFields: string[] = [];
  @Input() item: any;
  @Input() currentCompany: Company;
  @Input() modalTitle: string;
  @Input() resourceService: ResourceService<T>;
  @Input() keyName: string = 'key';

  readonly DisplayControlInput = DisplayControlInput;
  readonly getContrastYIQ = getContrastYIQ;
  readonly FORMAT_DATE = COMMON_FORMAT_DATE;
  readonly FORMAT_PATTERN = FORMAT_PATTERN;

  genericItemForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private snotifyService: SnotifyService,
    private restockSuggestionService: RestockSuggestionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.genericItemForm = new FormGroup({});
    let syncedFields = [];

    this.metadataFields.forEach((m) => {
      if (this.forbiddenFields.includes(m.field)) {
        return;
      }

      const validators = this.extractValidators(m);
      if (m.displayControl?.input === DisplayControlInput.Date) {
          this.genericItemForm.addControl(
            m.field,
            this.fb.control(formatDate(null, this.FORMAT_DATE, 'en'), validators)
          );
      } else {
          this.genericItemForm.addControl(
            m.field,
            this.fb.control('', validators)
          );
      }

      //lookup fields are very special, and need special attention.
      if (m.displayControl?.input === DisplayControlInput.LookUp) {
        let lookupControl = m.displayControl as LookUpDisplayControl;
        //get the field that will hold the key
        let mapping = lookupControl.lookUpConfig.mapping.find((x) => {
          return x.type === LookUpMappingType.Key;
        });

        if (mapping) {
          this.genericItemForm.addControl(mapping.to, this.fb.control(''));
        }
      }

      if (this.item?.syncedFields?.includes(m?.field)) {
        this.genericItemForm.controls[m?.field].disable();

        syncedFields.push(m);
      }

      if([ItemField.itemHistoryLength.toString(),
        ItemField.onHandFbm.toString(),
        ItemField.onHand.toString()
      ].includes(m?.field)) {
        this.genericItemForm.controls[m?.field].disable();
      }
    });

    this.metadataFields = this.metadataFields
      .filter((item) => !syncedFields.find((m) => m.field === item.field))
      .concat(syncedFields);

    asapScheduler.schedule(() => {
      this.genericItemForm.patchValue(this.item);
    });
  }

  saveItem() {
    this.item.doNotRestock = this.genericItemForm.value.doNotRestock;
    this.item.doNotOrder = this.genericItemForm.value.doNotOrder;
    this.item.isHidden = this.item.doNotRestock && this.item.doNotOrder;
    this.resourceService
      .save(
        { ...this.item, ...this.genericItemForm.getRawValue() },
        this.keyName
      )
      .pipe(
        this.autoCleanUp(),
        switchMap(() => {
          if(!this.item.doNotRestock) {
            return this.restockSuggestionService.getByIdWithType(
              this.item.key,
              SalesVelocitySettingsType.restockAMZ,
              this.currentCompany?.marketplaceId,
              this.currentCompany?.currencyCode
            );
          }

          return of(null);
        })
      )
      .subscribe((restockSuggestion) => {
        this.snotifyService.success('Save item successfully');
        this.activeModal.close(restockSuggestion);
      });
  }

  private extractValidators(mdf: MetaDataField): ValidatorFn[] {
    const validators: ValidatorFn[] = [];

    if (!mdf.validators || mdf.validators.length === 0) {
      return validators;
    }

    //loop through validators array, and add to form field.
    mdf.validators.forEach((validator: IFieldValidator) => {
      switch (validator.type) {
        case FieldValidatorType.MinMax:
          let minMax = validator as MinMaxValidator;

          validators.push(Validators.min(minMax.min));
          validators.push(Validators.max(minMax.max));
          break;
        case FieldValidatorType.Pattern:
          let pattern = validator as CustomPatternValidator;
          validators.push(Validators.pattern(pattern.pattern));
          break;
        case FieldValidatorType.Email:
          validators.push(Validators.email);
          break;
        case FieldValidatorType.LessMore:
          const lessMoreVali = validator as LessMoreValidator;
          validators.push(lessMoreVali.condition);
          break;
      }
    });

    return validators;
  }
}
