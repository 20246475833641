import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-generic-data-table',
  templateUrl: './generic-data-table.component.html',
  styleUrls: ['./generic-data-table.component.scss']
})
export class GenericDataTableComponent {

  @Input() title: string;
  @Input() rows: any[] = [];

}
