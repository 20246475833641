<div class="sales-velocity" [class.sales-velocity-dark]="isModal">
  <div class="modal-header text-center" [class.hidden]="!isModal">
    <div class="modal-title w-100">
      <h5 class="modal-title">{{ modalTitle }}</h5>
      <h5 class="text-white">{{ itemName }}</h5>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div *ngIf="isModal" class="modal-body sales-velocity-modal-body">
    <div
      class="restock-settings__container"
    >
      <form
        *ngIf="!isSupplier && settingFormGroup"
        [formGroup]="settingFormGroup"
      >
        <div class="restock-settings__container__item">
          <ngb-accordion activeIds="panel-3">
            <!-- Velocity Estimation Rule -->
            <ngb-panel id="panel-3">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="container-fluid d-flex justify-content-left">
                  <button
                    ngbPanelToggle
                    class="btn btn-link pl-0 pr-0 mr-1"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    Average Daily Sales Rate
                  </button>
                  <button
                    title="Edit"
                    *ngIf="opened"
                    class="btn btn-link pl-0 pr-0 mr-1"
                    (click)="changeSetting('AverageSaleRate')"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <div *ngIf="opened && !disableSettings.isNotAverageDailySaleRate" ngbDropdown class="dropdown drp-user">
                    <button
                      title="Change recently modified values to global"
                      href="javascript:"
                      class="btn btn-link pl-0 pr-0 mr-1"
                      ngbDropdownToggle
                    >
                      <i class="fas fa-undo"></i>
                    </button>
                    <div ngbDropdownMenu class="top-100 k-shadow">
                      <button ngbDropdownItem (click)="resetSetting('AverageSaleRate')">USE {{disableSettings.averageDailySaleRateFrom}}</button>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <select
                  #salesVelocityInput
                  [(ngModel)]="salesVelocityType"
                  [ngModelOptions]="{ standalone: true }"
                  id="sales-velocity-settings"
                  class="form-select form-control-default w-100 sales-velocity-settings"
                  [ngClass]="{
                    'sales-velocity-settings--disable': disableSettings.isNotAverageDailySaleRate
                  }"
                  [disabled]="disableSettings.isNotAverageDailySaleRate"
                  (change)="createVelocityForm($event.target.value)"
                >
                  <option selected value="average">Average</option>
                  <option *ngIf="isCompanyTypeASC" value="dateRange">Date Range</option>
                  <option value="auto">
                    Automatically Adjusted Weightings
                  </option>
                </select>
                <form
                  *ngIf="salesVelocityForm"
                  class="sales-velocity"
                  [formGroup]="salesVelocityForm"
                  (input)="onChangeData()"
                  [ngClass]="{
                    'sales-velocity-settings--disable': disableSettings.isNotAverageDailySaleRate
                  }"
                >
                  <ng-container
                    *ngIf="
                      [SALES_VELOCITY_SETTINGS.average, SALES_VELOCITY_SETTINGS.auto].includes(salesVelocityType)
                    "
                  >
                    <span>Daily Sales Rate Weightings</span>
                    <div class="sales-velocity-weightings">
                      <div
                        *ngFor="let item of AVERAGE_VELOCITY_WEIGHTINGS"
                        class="sales-velocity-weightings__item"
                      >
                        <div class="sales-velocity-weightings__item__input">
                          <span>{{ (item | keyvalue)[0].value }}</span>
                          <div>
                            <input
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              type="number"
                              maxlength="3"
                              class="form-control col-1 sales-velocity-weightings__item__input--number"
                              [formControlName]="(item | keyvalue)[0].key"
                            />
                            <span
                              class="sales-velocity-weightings__item__input--percentage"
                              >%</span
                            >
                          </div>
                        </div>
                        <div
                          *ngIf="
                            salesVelocityForm
                              ?.get((item | keyvalue)[0].key)
                              ?.hasError('min') ||
                            salesVelocityForm
                              .get((item | keyvalue)[0].key)
                              ?.hasError('required')
                          "
                          class="text-danger sales-velocity-weightings__item--text-small"
                        >
                          Min percentage is 0
                        </div>
                        <div
                          *ngIf="
                            salesVelocityForm
                              ?.get((item | keyvalue)[0].key)
                              ?.hasError('max')
                          "
                          class="text-danger sales-velocity-weightings__item--text-small"
                        >
                          Max percentage is 100
                        </div>
                      </div>
                      <div class="sales-velocity-weightings__item">
                        <div class="sales-velocity-weightings__item__input">
                          <span
                            class="sales-velocity-weightings__item__total-label"
                            >Total</span
                          >
                          <div>
                            <span
                              class="form-control col-1 sales-velocity-weightings__item__total-field"
                              >{{ totalWeightings }}</span
                            >
                            <span
                              class="sales-velocity-weightings__item__input--percentage"
                              >%</span
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        *ngIf="totalWeightings !== 100"
                        class="text-danger sales-velocity-weightings__item--text-small"
                      >
                        The total percentage must be 100%
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="
                      salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange
                    "
                  >
                    <span
                      >Select past date range to be used to calculate sales
                      rate</span
                    >
                    <div class="sales-velocity-weightings__item">
                      <div class="sales-velocity-weightings__item__input--date">
                        <span>Start Date: </span>
                        <kendo-datepicker
                          required
                          placeholder="mm/dd/yyyy"
                          format="{{FORMAT_DATE}}"
                          [formatPlaceholder]="FORMAT_PATTERN"
                          [formControlName]="
                            DATE_RANGE_SALES_VELOCITY.startDate
                          "
                          [min]="ascInitialDateRange?.min"
                          [max]="ascInitialDateRange?.max"
                          [disabledDates]="disabledDate"
                        >
                        </kendo-datepicker>
                      </div>
                      <div class="sales-velocity-weightings__item__input--date">
                        <span>End Date: </span>
                        <kendo-datepicker
                          required
                          format="{{FORMAT_DATE}}"
                          placeholder="mm/dd/yyyy"
                          [formatPlaceholder]="FORMAT_PATTERN"
                          [formControlName]="DATE_RANGE_SALES_VELOCITY.endDate"
                          [min]="ascInitialDateRange?.min"
                          [max]="ascInitialDateRange?.max"
                          [disabledDates]="disabledDate"
                        >
                        </kendo-datepicker>
                      </div>
                      <div
                        *ngIf="errorsDateRange?.invalidDate"
                        class="text-danger sales-velocity-weightings__item--text-small"
                      >
                        Start Date must be before End Date
                      </div>
                    </div>
                  </ng-container>
                </form>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div *ngIf="!isPurchasing" class="restock-settings__container__item">
          <ngb-accordion>
            <!-- FBA Replenishment Model -->
            <ngb-panel id="panel-0">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="container-fluid d-flex justify-content-left">
                  <button
                    ngbPanelToggle
                    class="btn btn-link pl-0 pr-0 mr-1"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    FBA Replenishment Model
                  </button>
                  <button
                    *ngIf="opened"
                    class="btn btn-link pl-0 pr-0 mr-1"
                    (click)="changeSetting('RestockModel')"
                    title="Edit"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <div *ngIf="opened && !disableSettings.isNotRestockModel" ngbDropdown class="dropdown drp-user">
                    <button
                      href="javascript:"
                      class="btn btn-link pl-0 pr-0 mr-1"
                      ngbDropdownToggle
                      title="Change recently modified values to global"
                    >
                      <i class="fas fa-undo"></i>
                    </button>
                    <div ngbDropdownMenu class="top-100 k-shadow">
                      <button ngbDropdownItem (click)="resetSetting('RestockModel')">USE {{disableSettings.restockModelFrom}}</button>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="restock-settings__container__item-content" [ngClass]="{
                  'sales-velocity-settings--disable': disableSettings.isNotRestockModel
                }">
                  <div class="restock-settings__container__item-description">
                    <p>
                      Select how you typically replenish products so your
                      forecast will account for the correct lead times when
                      calculating your restock suggestions.
                    </p>
                    <p>
                      <strong>Supplier Ships to FBA</strong> - My supplier ships
                      my products directly to Amazon.
                    </p>
                    <p>
                      <strong>Warehouse Ships to FBA</strong> - I keep warehouse
                      inventory in my warehouse and ship products to Amazon.
                    </p>
                  </div>
                  <div class="input-space">
                    <select class="form-select" formControlName="restockModel">
                      <option
                        *ngFor="let rm of RESTOCK_MODELS"
                        [value]="rm.val"
                      >
                        {{ rm.display }}
                      </option>
                    </select>
                  </div>
                  <p class="restock-settings__container__item-tips">
                    <strong>Tip:</strong> Global Default Value, this can also be
                    set on the Item or Supplier Level.
                  </p>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div *ngIf="!isPurchasing" class="restock-settings__container__item">
          <ngb-accordion>
            <!-- Warehouse Lead Time -->
            <ngb-panel id="panel-1">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="container-fluid d-flex justify-content-left">
                  <button
                    ngbPanelToggle
                    class="btn btn-link pl-0 pr-0 mr-1"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    Warehouse Lead Time
                  </button>
                  <button
                    *ngIf="opened"
                    class="btn btn-link pl-0 pr-0 mr-1"
                    (click)="changeSetting('WarehouseLeadTime')"
                    title="Edit"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <div *ngIf="opened && !disableSettings.isNotWarehouseLeadTime" ngbDropdown class="dropdown drp-user">
                    <button
                      href="javascript:"
                      class="btn btn-link pl-0 pr-0 mr-1"
                      ngbDropdownToggle
                      title="Change recently modified values to global"
                    >
                      <i class="fas fa-undo"></i>
                    </button>
                    <div ngbDropdownMenu class="top-100 k-shadow">
                      <button ngbDropdownItem (click)="resetSetting('WarehouseLeadTime')">USE {{disableSettings.warehouseLeadTimeFrom}}</button>
                    </div>
                  </div>
                </div>

              </ng-template>
              <ng-template ngbPanelContent>
                <div class="restock-settings__container__item-description" [ngClass]="{
                  'sales-velocity-settings--disable': disableSettings.isNotWarehouseLeadTime
                }">
                  <p>
                    The average # of days it takes from creating a shipment to
                    arrival at Amazon.
                  </p>
                </div>
                <div class="input-space" [ngClass]="{
                  'sales-velocity-settings--disable': disableSettings.isNotWarehouseLeadTime
                }">
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      id="localLeadTime"
                      formControlName="localLeadTime"
                      [ngClass]="{
                        'is-invalid':
                          !localLeadTime?.valid &&
                          (localLeadTime?.dirty || localLeadTime?.touched)
                      }"
                    />
                    <span>days</span>
                  </div>

                  <div
                    *ngIf="
                      localLeadTime?.errors &&
                      (localLeadTime?.dirty || localLeadTime?.touched)
                    "
                    class="text-danger mt-2"
                  >
                    <span *ngIf="localLeadTime?.hasError('required')"
                      ><b>Warehouse Lead Time is required.</b></span
                    >
                    <span *ngIf="localLeadTime.hasError('min')"
                      ><b
                        >Warehouse Lead Time must be greater than or equal to
                        7.</b
                      ></span
                    >
                    <span *ngIf="localLeadTime.hasError('max')"
                      ><b
                        >Warehouse Lead Time must be less than or equal to
                        45.</b
                      ></span
                    >
                  </div>
                </div>
                <p class="restock-settings__container__item-tips" [ngClass]="{
                  'sales-velocity-settings--disable': disableSettings.isNotWarehouseLeadTime
                }">
                  <strong>Tip:</strong> This setting can be changed at any time
                  and is often good to increase during peak seasons, e.g.,
                  fourth quarter, to account for longer shipment processing
                  times at Amazon.
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <div *ngIf="!isPurchasing" class="restock-settings__container__item">
          <ngb-accordion>
            <!-- Ideal FBA Inventory -->
            <ngb-panel id="panel-2">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="container-fluid d-flex justify-content-left">
                  <button
                    ngbPanelToggle
                    class="btn btn-link pl-0 pr-0 mr-1"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    Ideal FBA Inventory
                  </button>
                  <button
                    *ngIf="opened"
                    class="btn btn-link pl-0 pr-0 mr-1"
                    (click)="changeSetting('IdealFbaInventory')"
                    title="Edit"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <div *ngIf="opened && !disableSettings.isNotFbaInventory" ngbDropdown class="dropdown drp-user">
                    <button
                      href="javascript:"
                      class="btn btn-link pl-0 pr-0 mr-1"
                      ngbDropdownToggle
                      title="Change recently modified values to global"
                    >
                      <i class="fas fa-undo"></i>
                    </button>
                    <div ngbDropdownMenu class="top-100 k-shadow">
                      <button ngbDropdownItem (click)="resetSetting('IdealFbaInventory')">USE {{disableSettings.fbaInventoryFrom}}</button>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="restock-settings__container__item-description" [ngClass]="{
                  'sales-velocity-settings--disable': disableSettings.isNotFbaInventory
                }">
                  <p>
                    Choose a range for the number of days of inventory coverage
                    you'd like to keep at Amazon.
                  </p>
                  <p>
                    Products with quantities below your minimum will be flagged
                    orange in your Restock Suggestions. Products between your
                    minimum and maximum will be flagged yellow in your Restock
                    Suggestions.
                  </p>
                </div>
                <div class="input-space" [ngClass]="{
                  'sales-velocity-settings--disable': disableSettings.isNotFbaInventory
                }">
                  <div class="input-group mb-3">
                    <input
                      type="number"
                      class="form-control"
                      id="targetQtyOnHandMin"
                      formControlName="targetQtyOnHandMin"
                      [ngClass]="{
                        'is-invalid':
                          !targetQtyOnHandMin?.valid &&
                          (targetQtyOnHandMin?.dirty ||
                            targetQtyOnHandMin?.touched)
                      }"
                    />
                    <span>
                      <i
                        class="fa fa-flag sales-velocity__flag-icon sales-velocity__flag-icon--restock-now"
                      ></i>
                      Minimum Days of FBA Inventory
                    </span>
                  </div>
                  <div
                    *ngIf="
                      targetQtyOnHandMin?.errors &&
                      (targetQtyOnHandMin?.dirty || targetQtyOnHandMin?.touched)
                    "
                    class="text-danger mt-0 mb-2"
                  >
                    <span *ngIf="targetQtyOnHandMin?.hasError('required')"
                      ><b>Minimum days is required.</b></span
                    >
                    <span *ngIf="targetQtyOnHandMin.hasError('min')"
                      ><b
                        >Minimum days must be greater than or equal to 0.</b
                      ></span
                    >
                    <span *ngIf="targetQtyOnHandMin.hasError('max')"
                      ><b
                        >Minimum days must be less than or equal to 365.</b
                      ></span
                    >
                  </div>

                  <div class="input-group mb-3">
                    <input
                      type="number"
                      class="form-control"
                      id="targetQtyOnHandMax"
                      formControlName="targetQtyOnHandMax"
                      [ngClass]="{
                        'is-invalid':
                          (!targetQtyOnHandMax?.valid &&
                            (targetQtyOnHandMax?.dirty ||
                              targetQtyOnHandMax?.touched)) ||
                          targetQtyOnHandMax?.value <= targetQtyOnHandMin?.value
                      }"
                    />
                    <span>
                      <i
                        class="fa fa-flag sales-velocity__flag-icon sales-velocity__flag-icon--restock-soon"
                      ></i>
                      Maximum Days of FBA Inventory</span
                    >
                  </div>
                  <div
                    *ngIf="
                      targetQtyOnHandMax?.value <= targetQtyOnHandMin?.value
                    "
                    class="text-danger mt-0 mb-2"
                  >
                    <span>
                      <b> Maximum Days must be greater than Minimum Days </b>
                    </span>
                  </div>
                  <div
                    *ngIf="
                      targetQtyOnHandMax?.errors &&
                      (targetQtyOnHandMax?.dirty || targetQtyOnHandMax?.touched)
                    "
                    class="text-danger mt-0 mb-2"
                  >
                    <span *ngIf="targetQtyOnHandMax?.hasError('required')"
                      ><b>Maximum days is required.</b></span
                    >
                    <span *ngIf="targetQtyOnHandMax.hasError('min')"
                      ><b
                        >Maximum days must be greater than or equal to 0.</b
                      ></span
                    >
                    <span *ngIf="targetQtyOnHandMax.hasError('max')"
                      ><b
                        >Maximum days must be less than or equal to 365.</b
                      ></span
                    >
                  </div>
                </div>
                <p class="restock-settings__container__item-tips" [ngClass]="{
                  'sales-velocity-settings--disable': disableSettings.isNotFbaInventory
                }">
                  <strong>Tip:</strong> We recommend starting with '30' and '45'
                  days and adjusting the time period up or down if desired.
                </p>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div *ngIf="isPurchasing" class="restock-settings__container__item">
          <ngb-accordion>
            <ngb-panel id="panel-4">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="container-fluid d-flex justify-content-left">
                  <button
                    ngbPanelToggle
                    class="btn btn-link pl-0 pr-0 mr-1"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    Additional Inventory
                  </button>
                  <button
                    title="Edit"
                    *ngIf="opened"
                    class="btn btn-link pl-0 pr-0 mr-1"
                    (click)="changeSetting('AdditionalInventory')"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <div *ngIf="opened && !disableSettings.isNotAdditionalInventory" ngbDropdown class="dropdown drp-user">
                    <button
                      title="Change recently modified values to global"
                      href="javascript:"
                      class="btn btn-link pl-0 pr-0 mr-1"
                      ngbDropdownToggle
                    >
                      <i class="fas fa-undo"></i>
                    </button>
                    <div ngbDropdownMenu class="top-100 k-shadow">
                      <button ngbDropdownItem (click)="resetSetting('AdditionalInventory')">USE {{disableSettings.additionalInventoryFrom}}</button>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p>Enter additional days of safety stock to be factored into Daily Sales Rate Recommendations. This will be in addition to inventory required to cover Lead Time and Order Interval.</p>

                <div class="mb-3">
                  <input
                    class="form-control form-control-lg d-inline align-middle"
                    formControlName="additionalInventory"
                    type="number"
                    [style.width.px]="80"
                    [ngClass]="{
                      'is-invalid':
                        !additionalInventory?.valid &&
                        (additionalInventory?.dirty || additionalInventory?.touched),
                      'sales-velocity-settings--disable': disableSettings.isNotAdditionalInventory
                    }"
                    [disabled]="disableSettings.isNotAdditionalInventory"
                  />
                  days
                </div>

                <div
                  *ngIf="
                    additionalInventory?.errors &&
                    (additionalInventory?.dirty || additionalInventory?.touched)
                  "
                  class="text-danger mt-2"
                >
                  <span *ngIf="additionalInventory?.hasError('required')">
                    <b>Additional Inventory is required.</b>
                  </span>
                  <span *ngIf="additionalInventory.hasError('min')">
                    <b>Additional Inventory must be greater than or equal to 0.</b>
                  </span>
                  <span *ngIf="additionalInventory.hasError('max')">
                    <b>Additional Inventory must be less than or equal to 365.</b>
                  </span>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </form>

      <ng-container *ngIf="isSupplier">
        <div class="restock-settings__container__item">
          <ngb-accordion activeIds="panel-1">
            <ngb-panel id="panel-1">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="container-fluid d-flex justify-content-left">
                  <button
                    ngbPanelToggle
                    class="btn btn-link pl-0 pr-0 mr-1"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    Average Daily Sales Rate
                  </button>
                  <button
                    title="Edit"
                    *ngIf="opened"
                    class="btn btn-link pl-0 pr-0 mr-1"
                    (click)="changeSetting('AverageSaleRate')"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <div *ngIf="opened && !disableSettings.isNotAverageDailySaleRate" ngbDropdown class="dropdown drp-user">
                    <button
                      title="Change recently modified values to global"
                      href="javascript:"
                      class="btn btn-link pl-0 pr-0 mr-1"
                      ngbDropdownToggle
                    >
                      <i class="fas fa-undo"></i>
                    </button>
                    <div ngbDropdownMenu class="top-100 k-shadow">
                      <button ngbDropdownItem (click)="resetSetting('AverageSaleRate')">USE {{disableSettings.averageDailySaleRateFrom}}</button>
                    </div>
                  </div>
                </div>
              </ng-template>

              <ng-template ngbPanelContent>
                <select
                  [disabled]="disableSettings.isNotAverageDailySaleRate"
                  #salesVelocityInput
                  [(ngModel)]="salesVelocityType"
                  id="sales-velocity-settings"
                  class="form-select form-control-default w-100 sales-velocity-settings"
                  (change)="createVelocityForm($event.target.value)"
                  [ngClass]="{
                    'sales-velocity-settings--disable': disableSettings.isNotAverageDailySaleRate
                  }"
                >
                  <option selected value="average">Average</option>
                  <option *ngIf="isCompanyTypeASC" value="dateRange">Date Range</option>
                  <option value="auto">Automatically Adjusted Weightings</option>
                </select>

                <form
                  *ngIf="salesVelocityForm"
                  class="sales-velocity"
                  [formGroup]="salesVelocityForm"
                  (input)="onChangeData()"
                >
                  <ng-container
                    *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.average"
                  >
                    <span>Daily Sales Rate Weightings</span>
                    <div class="sales-velocity-weightings">
                      <div
                        *ngFor="let item of AVERAGE_VELOCITY_WEIGHTINGS"
                        class="sales-velocity-weightings__item"
                      >
                        <div class="sales-velocity-weightings__item__input">
                          <span>{{ (item | keyvalue)[0].value }}</span>
                          <div>
                            <input
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              type="number"
                              maxlength="3"
                              class="form-control col-1 sales-velocity-weightings__item__input--number"
                              [formControlName]="(item | keyvalue)[0].key"
                            />
                            <span
                              class="sales-velocity-weightings__item__input--percentage"
                              >%</span
                            >
                          </div>
                        </div>
                        <div
                          *ngIf="
                            salesVelocityForm
                              ?.get((item | keyvalue)[0].key)
                              ?.hasError('min') ||
                            salesVelocityForm
                              .get((item | keyvalue)[0].key)
                              ?.hasError('required')
                          "
                          class="text-danger sales-velocity-weightings__item--text-small"
                        >
                          Min percentage is 0
                        </div>
                        <div
                          *ngIf="
                            salesVelocityForm
                              ?.get((item | keyvalue)[0].key)
                              ?.hasError('max')
                          "
                          class="text-danger sales-velocity-weightings__item--text-small"
                        >
                          Max percentage is 100
                        </div>
                      </div>
                      <div class="sales-velocity-weightings__item">
                        <div class="sales-velocity-weightings__item__input">
                          <span class="sales-velocity-weightings__item__total-label"
                            >Total</span
                          >
                          <div>
                            <span
                              class="form-control col-1 sales-velocity-weightings__item__total-field"
                              >{{ totalWeightings }}</span
                            >
                            <span
                              class="sales-velocity-weightings__item__input--percentage"
                              >%</span
                            >
                          </div>
                        </div>
                        <div
                          *ngIf="totalWeightings !== 100"
                          class="text-danger sales-velocity-weightings__item--text-small"
                        >
                          The total percentage must be 100%
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container
                    *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange"
                  >
                    <span
                      >Select past date range to be used to calculate sales
                      rate</span
                    >
                    <div class="sales-velocity-weightings__item">
                      <div class="sales-velocity-weightings__item__input">
                        <span>Start Date: </span>
                        <kendo-datepicker
                          required
                          format="{{FORMAT_DATE}}"
                          placeholder="mm/dd/yyyy"
                          [formatPlaceholder]="FORMAT_PATTERN"
                          [formControlName]="DATE_RANGE_SALES_VELOCITY.startDate"
                          [min]="ascInitialDateRange?.min"
                          [max]="ascInitialDateRange?.max"
                          [disabledDates]="disabledDate"
                        >
                        </kendo-datepicker>
                      </div>
                      <div class="sales-velocity-weightings__item__input">
                        <span>End Date: </span>
                        <kendo-datepicker
                          required
                          format="{{FORMAT_DATE}}"
                          placeholder="mm/dd/yyyy"
                          [formatPlaceholder]="FORMAT_PATTERN"
                          [formControlName]="DATE_RANGE_SALES_VELOCITY.endDate"
                          [min]="ascInitialDateRange?.min"
                          [max]="ascInitialDateRange?.max"
                          [disabledDates]="disabledDate"
                        >
                        </kendo-datepicker>
                      </div>
                      <div
                        *ngIf="errorsDateRange?.invalidDate"
                        class="text-danger sales-velocity-weightings__item--text-small"
                      >
                        Start Date must be before End Date
                      </div>
                    </div>
                  </ng-container>

                </form>

                <ng-container
                  *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.auto"
                >
                  This settings will auto-apply weight to calculation.
                </ng-container>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>

        <div *ngIf="isPurchasing" class="restock-settings__container__item">
          <ngb-accordion>
            <ngb-panel id="panel-2">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="container-fluid d-flex justify-content-left">
                  <button
                    ngbPanelToggle
                    class="btn btn-link pl-0 pr-0 mr-1"
                  >
                    <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
                    <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
                    Additional Inventory
                  </button>
                  <button
                    title="Edit"
                    *ngIf="opened"
                    class="btn btn-link pl-0 pr-0 mr-1"
                    (click)="changeSetting('AdditionalInventory')"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <div *ngIf="opened && !disableSettings.isNotAdditionalInventory" ngbDropdown class="dropdown drp-user">
                    <button
                      title="Change recently modified values to global"
                      href="javascript:"
                      class="btn btn-link pl-0 pr-0 mr-1"
                      ngbDropdownToggle
                    >
                      <i class="fas fa-undo"></i>
                    </button>
                    <div ngbDropdownMenu class="top-100 k-shadow">
                      <button ngbDropdownItem (click)="resetSetting('AdditionalInventory')">USE {{disableSettings.additionalInventoryFrom}}</button>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <p>Enter additional days of safety stock to be factored into Daily Sales Rate Recommendations. This will be in addition to inventory required to cover Lead Time and Order Interval.</p>

                <div *ngIf="supplierSettingFormGroup" class="mb-3">
                  <form [formGroup]="supplierSettingFormGroup">
                    <input
                      class="form-control form-control-lg d-inline align-middle"
                      formControlName="additionalInventory"
                      type="number"
                      [style.width.px]="80"
                      [ngClass]="{
                        'is-invalid':
                          !additionalInventory?.valid &&
                          (additionalInventory?.dirty || additionalInventory?.touched),
                        'sales-velocity-settings--disable': disableSettings.isNotAdditionalInventory
                      }"
                      [disabled]="disableSettings.isNotAdditionalInventory"
                    />
                    days
                  </form>

                  <div
                    *ngIf="
                      additionalInventory?.errors &&
                      (additionalInventory?.dirty || additionalInventory?.touched)
                    "
                    class="text-danger mt-2"
                  >
                    <span *ngIf="additionalInventory?.hasError('required')">
                      <b>Additional Inventory is required.</b>
                    </span>
                    <span *ngIf="additionalInventory.hasError('min')">
                      <b>Additional Inventory must be greater than or equal to 0.</b>
                    </span>
                    <span *ngIf="additionalInventory.hasError('max')">
                      <b>Additional Inventory must be less than or equal to 365.</b>
                    </span>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="!isModal && !isNewUI"
    class="modal-body sales-velocity__body sales-velocity__body--non-modal"
    [class.d-flex]="isModal"
    [class.justify-content]="isModal"
  >
    <div class="form-group">
      <select
        [(ngModel)]="salesVelocityType"
        id="sales-velocity-settings"
        class="form-select form-control-default col-lg-8 col-sm-12"
        (change)="createVelocityForm($event.target.value)"
        [style.min-width.px]="isModal ? '200' : '0'"
        [style.margin-right.px]="isModal ? '8' : '0'"
      >
        <option selected value="average">Average</option>
        <option *ngIf="isCompanyTypeASC" value="dateRange">Date Range</option>
        <option value="auto">Automatically Adjusted Weightings</option>
      </select>
    </div>
    <form
      *ngIf="salesVelocityForm"
      class="ml-3"
      [formGroup]="salesVelocityForm"
      (input)="onChangeData()"
    >
      <ng-container
        *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.average"
      >
        <span>Daily Sales Rate Weightings</span>
        <div
          *ngFor="let item of AVERAGE_VELOCITY_WEIGHTINGS"
          class="sales-velocity__body__item"
        >
          <div class="sales-velocity__body__item__input">
            <span>{{ (item | keyvalue)[0].value }}</span>
            <input
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              type="number"
              maxlength="3"
              class="form-control col-1 sales-velocity__body__item__input--number"
              [formControlName]="(item | keyvalue)[0].key"
              [class.is-invalid]="
                salesVelocityForm.get((item | keyvalue)[0].key)?.errors?.length
              "
            />
            <span>%</span>
          </div>
          <div
            *ngIf="
              salesVelocityForm
                .get((item | keyvalue)[0].key)
                ?.hasError('min') ||
              salesVelocityForm
                .get((item | keyvalue)[0].key)
                ?.hasError('required')
            "
            class="text-danger sales-velocity__body__item--text-small"
          >
            Percent min is 0
          </div>
          <div
            *ngIf="
              salesVelocityForm.get((item | keyvalue)[0].key)?.hasError('max')
            "
            class="text-danger sales-velocity__body__item--text-small"
          >
            Percent max is 100
          </div>
        </div>
        <div class="sales-velocity__body__item">
          <div class="sales-velocity__body__item__input">
            <span class="sales-velocity__body__item__total-label">Total</span>
            <span
              class="form-control col-1 sales-velocity__body__item__total-field"
              >{{ totalWeightings }}</span
            >
            <span>%</span>
          </div>
          <div
            *ngIf="
              salesVelocityType === SALES_VELOCITY_SETTINGS.average &&
              totalWeightings !== 100
            "
            class="text-danger sales-velocity__body__item--text-small"
          >
            The total percentage must be 100%
          </div>
        </div>
      </ng-container>
      <ng-container
        *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange"
      >
        <span>Select past date range to be used to calculate sales rate</span>
        <div class="sales-velocity__body__item">
          <div class="sales-velocity__body__item__input">
            <span>Start Date: </span>
            <kendo-datepicker
              required
              format="{{FORMAT_DATE}}"
              placeholder="mm/dd/yyyy"
              [formatPlaceholder]="FORMAT_PATTERN"
              [popupSettings]="{ animate: false }"
              [formControlName]="DATE_RANGE_SALES_VELOCITY.startDate"
              [min]="ascInitialDateRange?.min"
              [max]="ascInitialDateRange?.max"
              [disabledDates]="disabledDate"
            ></kendo-datepicker>
          </div>
          <div class="sales-velocity__body__item__input">
            <span>End Date: </span>
            <kendo-datepicker
              required
              format="{{FORMAT_DATE}}"
              placeholder="mm/dd/yyyy"
              [formatPlaceholder]="FORMAT_PATTERN"
              [popupSettings]="{ animate: false }"
              [formControlName]="DATE_RANGE_SALES_VELOCITY.endDate"
              [min]="ascInitialDateRange?.min"
              [max]="ascInitialDateRange?.max"
              [disabledDates]="disabledDate"
            ></kendo-datepicker>
          </div>
          <div
            *ngIf="errorsDateRange?.invalidDate"
            class="text-danger sales-velocity__body__item--text-small"
          >
            Start Date must be before End Date
          </div>
        </div>
      </ng-container>
    </form>

    <ng-container *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.auto">
      This settings will auto-apply weight to calculation.
    </ng-container>
  </div>
  <div
    class="modal-footer pr-2 sales-velocity-modal-footer"
    [class.hidden]="!isModal"
  >
    <button
      type="button"
      class="btn btn-primary sales-velocity-modal-footer-btn-save"
      (click)="saveSettings()"
      [disabled]="
        !isCanSave ||
        (!isSupplier && targetQtyOnHandMax?.value <= targetQtyOnHandMin?.value)
      "
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-light sales-velocity-modal-footer-btn-close"
      (click)="activeModal.close('Close click')"
    >
      Cancel
    </button>
  </div>

  <div *ngIf="isNewUI">
    <label class="select-subtext w-100" [attr.subtext]="saleVelocityTypeSubtext">
      <select
        class="form-select col-6 w-100 mb-2 font-weight-bold cursor-pointer"
        id="sales-velocity-settings"
        autocomplete="off"
        [(ngModel)]="salesVelocityType"
        (change)="createVelocityForm($event.target.value)"
        [dataTestID]="'sales-velocity-type-select'"
      >
        <!-- select -->
        <option value="average">Average</option>
        <option *ngIf="isCompanyTypeASC" value="dateRange">Date Range</option>
        <option value="auto" selected>
          Automatically Adjusted Weightings
        </option>
      </select>
    </label>

    <form
      *ngIf="salesVelocityForm"
      [formGroup]="salesVelocityForm"
      (input)="onChangeData()"
    >
      <ng-container *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.average">
        <div class="table-responsive mt-3">
          <table class="table table-borderless w-auto mw-100" aria-describedby>
            <th hidden="hidden"></th>
            <tbody>
              <tr class="fw-bold">
                <td class="p-0 pe-3 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">2-Day</td>
                <td class="p-0 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">7-Day</td>
                <td class="p-0 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">14-Day</td>
                <td class="p-0 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">30-Day</td>
                <td class="p-0 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">60-Day</td>
                <td class="p-0 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">90-Day</td>
                <td class="p-0 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">180-Day</td>
                <td class="p-0 pb-2"></td>
                <td class="p-0 pb-2" [style.min-width.px]="80">
                  ForecastRx<br/>Demand
                </td>
                <td class="p-0">&nbsp;</td>
              </tr>

              <tr>
                <td class="p-0 pe-3"><strong>Rate</strong></td>
                <td class="p-0 text-center">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percent2Day"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-2-day-input'"
                    />
                  </label>
                </td>
                <td class="px-1">+</td>
                <td class="p-0 text-center">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percent7Day"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-7-day-input'"
                    />
                  </label>
                </td>
                <td class="px-1">+</td>
                <td class="p-0 text-center">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percent14Day"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-14-day-input'"
                    />
                  </label>
                </td>
                <td class="px-1">+</td>
                <td class="p-0 text-center">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percent30Day"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-30-day-input'"
                    />
                  </label>
                </td>
                <td class="px-1">+</td>
                <td class="p-0 text-center">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percent60Day"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-60-day-input'"
                    />
                  </label>
                </td>
                <td class="px-1">+</td>
                <td class="p-0 text-center">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percent90Day"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-90-day-input'"
                    />
                  </label>
                </td>
                <td class="px-1">+</td>
                <td class="p-0 text-center">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percent180Day"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-180-day-input'"
                    />
                  </label>
                </td>
                <td class="px-1">+</td>
                <td class="p-0">
                  <label class="percentage-input">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      formControlName="percentForecasted"
                      class="form-control form-control-lg d-inline align-middle ps-1 pe-1"
                      [dataTestID]="'percent-forecasted-input'"
                    />
                  </label>
                </td>
                <td class="p-0 ps-3 fs-3">
                  <strong [dataTestID]="'total-weightings'">=&nbsp;{{ totalWeightings }}%</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-danger font-weight-bold">
          <div *ngIf="isMinPercentageError">Min percentage is 0</div>
          <div *ngIf="isMaxPercentageError">Max percentage is 100</div>
          <div *ngIf="totalWeightings !== 100">The total percentage must be 100%</div>
        </div>
      </ng-container>

      <div *ngIf="salesVelocityType === SALES_VELOCITY_SETTINGS.dateRange">
        <div class="mt-3 d-flex justify-content-between" style="max-width: 350px;">
          <div class="mb-3">
            <label class="form-label d-block"><strong>From</strong></label>
            <kendo-datepicker
              required
              placeholder="mm/dd/yyyy"
              format="{{FORMAT_DATE}}"
              [formatPlaceholder]="FORMAT_PATTERN"
              placeholder="Select date..."
              [formControlName]="DATE_RANGE_SALES_VELOCITY.startDate"
              [min]="ascInitialDateRange?.min"
              [max]="ascInitialDateRange?.max"
              [disabledDates]="disabledDate"
              [dataTestID]="'start-date-input'"
            >
            </kendo-datepicker>
          </div>
          <div class="mb-3">
            <label class="form-label d-block"><strong>To</strong></label>
            <kendo-datepicker
              required
              placeholder="mm/dd/yyyy"
              format="{{FORMAT_DATE}}"
              [formatPlaceholder]="FORMAT_PATTERN"
              placeholder="Select date..."
              [formControlName]="DATE_RANGE_SALES_VELOCITY.endDate"
              [min]="ascInitialDateRange?.min"
              [max]="ascInitialDateRange?.max"
              [disabledDates]="disabledDate"
              [dataTestID]="'end-date-input'"
            >
            </kendo-datepicker>
          </div>
          <div
            *ngIf="errorsDateRange?.invalidDate"
            class="text-danger font-weight-bold"
          >
            Start Date must be before End Date
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
