import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessingService {

  private processingStartedSource = new Subject<boolean>();
  private processingMessageSource = new Subject<any>();
  private processingStoppedSource = new Subject<any>();
  private processingStartedSyncSource = new Subject<boolean>();
  private processingStoppedSyncSource = new Subject<boolean>();

  processingStarted = this.processingStartedSource.asObservable();
  processingMessage = this.processingMessageSource.asObservable();
  processingStopped = this.processingStoppedSource.asObservable();
  processingStartedSync = this.processingStartedSyncSource.asObservable();
  processingStoppedSync = this.processingStoppedSyncSource.asObservable();

  start(operation: boolean) : void {
    this.processingStartedSource.next(operation);
  }

  messaging(message: any) : void {
    this.processingMessageSource.next(message);
  }

  stop(results: any) : void{
    this.processingStoppedSource.next(results);
  }

  startSync(operation: boolean) : void {
    this.processingStartedSyncSource.next(operation);
  }

  stopSync(operation: boolean) : void {
    this.processingStoppedSyncSource.next(operation);
  }
}
