import { Component, Input } from '@angular/core';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { MetaDataFieldType } from 'src/app/core/infrastructure/enums/meta-data-field-type.enum';
import { formatDate } from '@angular/common';
import { COMMON_FORMAT_DATE } from 'src/app/core/constants/format-date.constant';

@Component({
  selector: 'app-generic-kendo-grid',
  templateUrl: './generic-kendo-grid.component.html',
})
export class GenericKendoGridComponent {

  @Input() columns: any[] = [];
  @Input() gridItems: any[] = [];

  sort: SortDescriptor[] = [];
  metaDataTypeEnum = MetaDataFieldType;
  readonly FORMAT_DATE = COMMON_FORMAT_DATE

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.gridItems = orderBy(this.gridItems, sort);
  }

  toDateOnly(date: string): string {
    return formatDate(date, this.FORMAT_DATE, 'en');
  }
}
