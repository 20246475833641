import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyService } from 'src/app/core/services/company.service';
import { Company } from 'src/app/core/models/company';
import {
  catchError,
  concatMap,
  filter,
  map,
  tap,
  toArray,
} from 'rxjs/operators';
import { BaseComponent } from '@stockaid/core';
import { SyncService } from 'src/app/core/services/sync.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { concat } from 'rxjs';
import { ReportType } from 'src/app/core/infrastructure/enums/report-type.enum';
import { ReportHeaders } from './report-header.constant';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-manually-import-data-modal',
  templateUrl: './manually-import-data-modal.component.html',
  styleUrls: ['./manually-import-data-modal.component.scss'],
})
export class ManuallyImportDataModalComponent
  extends BaseComponent
  implements OnInit
{
  readonly ReportType = ReportType;

  importForm: FormGroup;
  importFiles: Record<string, File> = {};
  currentCompany: Company;
  isLoading = false;
  wholeUploadProgress = 0;
  uploadProgresses: Record<string, number> = {};
  uploadNumber = 0;

  get marketplaceText() {
    switch (this.currentCompany?.marketplaceId) {
      case 'NA':
        return 'the US Marketplaces';

      case 'EU':
        return 'the Spain Marketplace';

      default:
        return 'the Marketplace';
    }
  }

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private syncService: SyncService,
    private snotifyService: SnotifyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCurrentCompany()
      .pipe(
        this.autoCleanUp(),
        tap(() => {
          this.initImportForm();
        })
      )
      .subscribe();
  }

  getCurrentCompany() {
    return this.companyService
      .getCurrentCompany()
      .pipe(tap((company) => (this.currentCompany = company)));
  }

  initImportForm() {
    this.importForm = this.fb.group({
      [ReportType.FbaInventoryReport]: [
        { value: '', disabled: this.isLoading },
        [Validators.required],
      ],
      [ReportType.FbmReport]: [
        { value: '', disabled: this.isLoading },
        [Validators.required],
      ],
      [ReportType.InventoryHealthReport]: [
        { value: '', disabled: this.isLoading },
        [Validators.required],
      ],
      [ReportType.RestockReport]: [
        { value: '', disabled: this.isLoading },
        [Validators.required],
      ],
      [ReportType.FbaFeesReport]: [
        { value: '', disabled: this.isLoading },
        [Validators.required],
      ],
      [ReportType.AllOrdersDataReport]: [
        { value: '', disabled: this.isLoading },
        [Validators.required],
      ],
    });
  }

  handleFileInputChange(target: HTMLInputElement, reportType: ReportType) {
    this.importFiles[reportType] = target?.files?.[0] || null;

    if (!target?.files?.[0]) {
      if (this.uploadProgresses[reportType] === 0) {
        this.uploadNumber--;
      }
      this.clearReportFileInput(reportType);
      return;
    }

    if (target?.files?.[0]?.type !== 'text/plain') {
      this.snotifyService.error('The report must be a ".txt" file');
      if (this.uploadProgresses[reportType] === 0) {
        this.uploadNumber--;
      }
      this.clearReportFileInput(reportType);
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const headers = (e.target.result as string)
        ?.split('\n')?.[0]
        ?.split('\t')
        ?.map((h) => h?.replace(/(\r\n|\n|\r|\?)/gm, '')?.trim());
      const correctHeaders: string[] = ReportHeaders[reportType];
      if (correctHeaders.some((h) => !headers.includes(h))) {
        this.snotifyService.error(
          'Please choose the correct report when uploading file'
        );
        if (this.uploadProgresses[reportType] === 0) {
          this.uploadNumber--;
        }
        this.clearReportFileInput(reportType);
        return;
      }

      this.uploadNumber++;
      this.uploadProgresses[reportType] = 0;
    };
    reader.readAsText(target?.files[0]);
  }

  importAllData() {
    this.isLoading = true;
    this.importForm.disable();
    const importReports$ = Object.keys(this.importForm.controls)
      .filter((reportType) => this.importFiles[reportType])
      .map((reportType: ReportType) =>
        this.importReport(reportType, this.importFiles[reportType])
      );

    concat(...importReports$)
      .pipe(
        this.autoCleanUp(),
        toArray(),
        tap(() => (this.wholeUploadProgress = 100)),
        concatMap((successfullyUploadedFileNames) =>
          this.syncService.importAmazonData(successfullyUploadedFileNames)
        ),
        tap(() => {
          this.importForm.enable();
          this.activeModal.close();
        })
      )
      .subscribe();
  }

  private clearReportFileInput(reportType: ReportType) {
    delete this.uploadProgresses[reportType];
    this.importForm.controls?.[reportType]?.reset();
  }

  private calculateUploadProgress() {
    if (this.wholeUploadProgress === 100) {
      return;
    }
    this.wholeUploadProgress = Object.keys(this.uploadProgresses).reduce(
      (a, reportType) => a + this.uploadProgresses[reportType],
      0
    );
  }

  private getReportName(reportType: ReportType) {
    switch (reportType) {
      case ReportType.FbaInventoryReport:
        return 'Manage FBA Inventory Report';

      case ReportType.FbmReport:
        return 'Inventory Reports';

      case ReportType.InventoryHealthReport:
        return 'FBA Inventory Report';

      case ReportType.RestockReport:
        return 'Restock Inventory Report';

      case ReportType.FbaFeesReport:
        return 'Fee Preview Report';

      case ReportType.AllOrdersDataReport:
        return 'All Orders Report';
    }
  }

  private importReport(reportType: ReportType, file: File) {
    const fileName = `${reportType}_${this.currentCompany.companyKey}.txt`;
    const finalFile = new File([file], fileName, { type: file.type });

    return this.syncService
      .syncFile(fileName, this.currentCompany.companyKey)
      .pipe(
        concatMap((res) =>
          this.syncService.uploadToAmazon(finalFile, res.signedRequest)
        ),
        catchError((err) => {
          this.snotifyService.error(
            `${this.getReportName(
              reportType
            )} has failed to upload properly. Please try again!`
          );
          this.uploadNumber--;
          this.clearReportFileInput(reportType);
          this.isLoading = false;
          this.wholeUploadProgress = 0;
          this.importForm.enable();
          throw err;
        }),
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.uploadProgresses[reportType] = Math.round(
              (100 * (event.loaded / event.total)) / this.uploadNumber
            );
            this.calculateUploadProgress();
          }
          return event;
        }),
        filter((event) => event.type === HttpEventType.Response),
        map(() => fileName)
      );
  }
}
