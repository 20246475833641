export const DefaultWeightPercentage = {
  percent2Day: 0,
  percent7Day: 20,
  percent14Day: 0,
  percent30Day: 20,
  percent60Day: 10,
  percent90Day: 0,
  percent180Day: 0,
  percentForecasted: 50
}
