<button
  *ngIf="content"
  class="wrapper">
  <div class="wrapper__trigger">
    <i class="fas fa-info-circle"></i>
  </div>
  <div class="wrapper__content">
    {{ content }}
  </div>
</button>
