export const Currencies = [
  {
    name: '€ Euro',
    symbol: '€',
    code: 'EUR',
  },
  {
    name: '$ US Dollar',
    symbol: '$',
    code: 'USD',
  },
  {
    name: '¥ Japanese Yen',
    symbol: '¥',
    code: 'JPY',
  },
  {
    name: '£ British Pound',
    symbol: '£',
    code: 'GBP',
  },
  {
    name: 'zł Polish Zloty',
    symbol: 'zł',
    code: 'PLN',
  },
  {
    name: 'kr Swedish Krona',
    symbol: 'kr',
    code: 'SEK',
  },
  {
    name: '₺ Turkish Lira',
    symbol: '₺',
    code: 'TRY',
  },
  {
    name: 'A$ Australian Dollar',
    symbol: 'A$',
    code: 'AUD',
  },
  {
    name: 'R$ Brazilian Real',
    symbol: 'R$',
    code: 'BRL',
  },
  {
    name: 'CA$ Canadian Dollar',
    symbol: 'CA$',
    code: 'CAD',
  },
  {
    name: 'CN¥ Chinese Yuan',
    symbol: 'CN¥',
    code: 'CNY',
  },
  {
    name: '₹ Indian Rupee',
    symbol: '₹',
    code: 'INR',
  },
  {
    name: 'MXN$ Mexican Peso',
    symbol: 'MXN$',
    code: 'MXN',
  },
  {
    name: 'S$ Singapore Dollar',
    symbol: 'S$',
    code: 'SGD',
  },
];
