import { Injectable } from '@angular/core';
import { ResourceService } from './resource-service';
import { RestockSuggestionSupplier } from '../models/restock-suggestion-supplier';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RestockSuggestionSupplierService extends ResourceService<RestockSuggestionSupplier> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/restock-suggestion-by-vendor');
  }
}
