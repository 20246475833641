<kendo-dropdownlist
        #dropdown
        [defaultItem]="defaultItem"
        [value]="control.value"
        [data]="view"
        [textField]="textField"
        [valueField]="valueField"
        (valueChange)="valueChange($event)"
        [valuePrimitive]="true">
</kendo-dropdownlist>
