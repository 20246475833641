<div class="manually-import-data-modal">
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h3 class="text-white">Manual Import Data</h3>
      <div class="text-white">
        Please login to Amazon Seller Central and select {{ marketplaceText }}
        associated with the company on ForecastRx.
      </div>
      <div class="text-white">
        You have to fill all the reports and choose a ".txt" report file whenever possible.
      </div>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="card p-4">
      <form *ngIf="importForm" [formGroup]="importForm">
        <div>
          <h4>Items</h4>
          <ul>
            <li class="row my-1">
              <a
                class="col-6 align-self-center"
                href="https://sellercentral.amazon.com/reportcentral/FBA_MYI_UNSUPPRESSED_INVENTORY/1"
                target="_blank"
                rel="noopener"
              >
                Manage FBA Inventory Report
              </a>
              <span class="col-6">
                <input
                  class="form-control"
                  type="file"
                  [formControlName]="ReportType.FbaInventoryReport"
                  (change)="
                    handleFileInputChange(
                      $event.target,
                      ReportType.FbaInventoryReport
                    )
                  "
                />
              </span>
            </li>
            <li class="row my-1">
              <a
                class="col-6 align-self-center"
                href="https://sellercentral.amazon.com/listing/reports/ref=xx_invreport_dnav_xx"
                target="_blank"
                rel="noopener"
              >
                Inventory Reports
                <i
                  class="fas fa-info-circle cursor-pointer"
                  ngbTooltip="Please choose the All Listing Report type with all attributes selected when requesting a report"
                  placement="bottom"
                ></i>
              </a>
              <span class="col-6">
                <input
                  class="form-control"
                  type="file"
                  [formControlName]="ReportType.FbmReport"
                  (change)="
                    handleFileInputChange($event.target, ReportType.FbmReport)
                  "
                />
              </span>
            </li>
            <li class="row my-1">
              <a
                class="col-6 align-self-center"
                href="https://sellercentral.amazon.com/reportcentral/MANAGE_INVENTORY_HEALTH/1"
                target="_blank"
                rel="noopener"
              >
                FBA Inventory Report
              </a>
              <span class="col-6">
                <input
                  class="form-control"
                  type="file"
                  [formControlName]="ReportType.InventoryHealthReport"
                  (change)="
                    handleFileInputChange(
                      $event.target,
                      ReportType.InventoryHealthReport
                    )
                  "
                />
              </span>
            </li>
            <li class="row my-1">
              <a
                class="col-6 align-self-center"
                href="https://sellercentral.amazon.com/reportcentral/RestockReport/1"
                target="_blank"
                rel="noopener"
              >
                Restock Inventory Report
              </a>
              <span class="col-6">
                <input
                  class="form-control"
                  type="file"
                  [formControlName]="ReportType.RestockReport"
                  (change)="
                    handleFileInputChange(
                      $event.target,
                      ReportType.RestockReport
                    )
                  "
                />
              </span>
            </li>
            <li class="row my-1">
              <a
                class="col-6 align-self-center"
                href="https://sellercentral.amazon.com/reportcentral/ESTIMATED_FBA_FEES/1"
                target="_blank"
                rel="noopener"
              >
                Fee Preview Report
              </a>
              <span class="col-6">
                <input
                  class="form-control"
                  type="file"
                  [formControlName]="ReportType.FbaFeesReport"
                  (change)="
                    handleFileInputChange(
                      $event.target,
                      ReportType.FbaFeesReport
                    )
                  "
                />
              </span>
            </li>
          </ul>
        </div>
        <div>
          <h4>Demands</h4>
          <ul>
            <li class="row my-1">
              <a
                class="col-6 align-self-center"
                href="https://sellercentral.amazon.com/reportcentral/FlatFileAllOrdersReport/1"
                target="_blank"
                rel="noopener"
              >
                All Orders Report
                <i
                  class="fas fa-info-circle cursor-pointer"
                  ngbTooltip="Please choose Order Date type and we highly recommend choosing the Last 30 days option for Event Date"
                  placement="bottom"
                ></i>
              </a>
              <span class="col-6">
                <input
                  class="form-control"
                  type="file"
                  [formControlName]="ReportType.AllOrdersDataReport"
                  (change)="
                    handleFileInputChange(
                      $event.target,
                      ReportType.AllOrdersDataReport
                    )
                  "
                />
              </span>
            </li>
          </ul>
        </div>
      </form>

      <div *ngIf="wholeUploadProgress">
        <div class="text-center">
          {{
            wholeUploadProgress === 100
              ? 'Completed'
              : 'Processing...'
          }}
        </div>
        <ngb-progressbar
          type="success"
          [showValue]="true"
          [value]="wholeUploadProgress"
        ></ngb-progressbar>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button
        class="btn btn-success"
        [disabled]="isLoading || importForm.invalid"
        (click)="importAllData()"
      >
        Import
      </button>
    </div>
  </div>
</div>
