import { Injectable, OnDestroy } from '@angular/core';

// libs
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject();

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  autoCleanUp<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil<T>(this.destroy$);
  }
}
