import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  switchMap,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import { Item } from 'src/app/core/models/item';

import { ItemService } from 'src/app/core/services/item.service';

import { ItemCardDetailComponent } from 'src/app/theme/shared/forecastui/item-card-detail/item-card-detail.component';
import { CompanyService } from 'src/app/core/services/company.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { Company } from 'src/app/core/models/company';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';

@Component({
  selector: 'app-nav-lookup-item',
  templateUrl: './nav-lookup-item.component.html',
  styleUrls: ['./nav-lookup-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavLookupItemComponent extends BaseComponent implements OnInit {
  @Input() set searchOn(value: string) {
    if (!value) {
      this.gridItems = null;
    }
    this.initSerchTerm();
    this._searchOn = value;
    this.searchOnChange.emit(value);
    this.search(value);
  }

  @Input() isNavbarCollapse: boolean;

  get searchOn(): string {
    return this._searchOn;
  }

  @Output() searchOnChange = new EventEmitter<string>();

  gridItems: GridDataResult;
  currentOffset = 0;
  itemCount: number;
  defaultColumns = [
    {
      field: 'name',
      displayName: 'Item Name',
    },
    {
      field: 'description',
      displayName: 'Description',
    },
  ];
  columns: any[];
  searchTerm = '';
  choosenItem: Item;
  hiddenStyle = {
    opacity: 0.5,
    'font-style': 'italic',
  };
  currentCompany: Company;

  private searchTerms = new Subject<string>();
  private _searchOn: string;

  constructor(
    private itemService: ItemService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private headerService: HeaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCurrentCompany();

    this.initSerchTerm();

    this.getColumns();

    this.headerService
      .getChange()
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.getCurrentCompany();
        this.getColumns();
      });
  }

  initSerchTerm() {
    this.searchTerms
      .pipe(
        // wait 300ms after each keystroke before considering the term
        debounceTime(300),

        // ignore new term if same as previous term
        distinctUntilChanged(),

        // switch to new search observable each time the term changes
        switchMap((term: string) => {
          return this.itemService.getFilteredUserTelerikEvent(
            0,
            30,
            this.searchFilter(term)
          );
        })
      )
      .subscribe((items) => {
        this.currentOffset = 0;

        this.loadItemCount(this.searchTerm).subscribe((count) => {
          this.itemCount = count;
          this.gridItems = { total: count, data: items } as GridDataResult;
        });
      });
  }

  getCurrentCompany() {
    this.currentCompany = this.companyService.currentCompany();
  }

  getColumns() {
    this.columns = this.defaultColumns;
    if (this.currentCompany.companyType === CompanyType.ASC) {
      this.columns.push({
        field: 'asin',
        displayName: 'ASIN',
      });
    }
  }

  searchFilter(term: string): any {
    if (!term) {
      return null;
    }

    return {
      logic: 'and',
      filters: [
        {
          logic: 'and',
          filters: [
            {
              field: 'name',
              operator: 'contains',
              value: term,
            },
          ],
        },
      ],
    };
  }

  search(term: string): void {
    if (term.length <= 1) {
      return;
    }

    this.searchTerm = term;
    this.searchTerms.next(term);
    this.searchTerms.next(term);
  }

  loadItemCount(term: string): Observable<number> {
    return this.itemService.getCount(
      this.searchFilter(term),
      '',
      this.currentCompany?.companyKey
    );
  }

  close() {
    this.searchOn = '';
  }

  loadItems(term: string): void {
    this.itemService
      .getFilteredUserTelerikEvent(
        this.currentOffset,
        30,
        this.searchFilter(term)
      )
      .subscribe((items) => {
        this.gridItems = {
          total: this.itemCount,
          data: items,
        } as GridDataResult;
      });
  }

  pageChange(event: any): void {
    this.currentOffset = event.skip;
    this.loadItems(this.searchTerm);
  }

  chooseItem({ dataItem }): void {
    this.choosenItem = dataItem;

    const modalRef = this.modalService.open(ItemCardDetailComponent, {
      size: 'xl' as string,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.choosenItem = dataItem;
    modalRef.componentInstance.isPurchasing = true;

    this.searchOn = '';
  }
}
