<div
  class="confirmation-modal"
  [dataTestID]="'confirm-popup'"
>
  <!-- Header -->
  <div class="modal-header px-3 py-2 d-flex align-items-center">
    <h5 class="modal-title">{{ data?.header || "Confirmation" }}</h5>

    <!-- Close -->
    <span class="confirmation-modal__close" (click)="dismiss()">&times;</span>
  </div>

  <!-- Body -->
  <div class="modal-body px-3">
    <span [innerHTML]="data?.body"> </span>
  </div>

  <!-- Footer -->
  <div class="modal-footer p-1">
    <button class="btn btn-secondary mr-1" (click)="dismiss()">Cancel</button>
    <button class="btn btn-primary" (click)="confirm()">OK</button>
  </div>
</div>
