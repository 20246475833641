import screenfull from 'screenfull';
import { conditionalOperator } from '@stockaid/utils';

const fullscreenHandle = (
  withoutGrid,
  body,
  lookUp,
  archwizardContainer,
  obCard,
  obCardBody
) => {
  withoutGrid.forEach((element) => {
    if (element) {
      element.style.display = 'none';
    }
  });

  body.style['-ms-overflow-style'] = 'none';
  body.style['scrollbar-width'] = 'none';

  if (lookUp) {
    lookUp.style.height = '80vh';
  }

  if (archwizardContainer) {
    archwizardContainer.style.padding = '0';
    archwizardContainer.style.border = 'none';
  }

  if (obCard) {
    obCard.style.backgroundColor = 'transparent';
    obCard.style.boxShadow = 'none';
  }

  if (obCardBody) {
    obCardBody.style.padding = '0';
  }
};

export function toggleFullScreen(
  el: HTMLElement,
  neededToBeHiddenElements = [],
  cb?: Function,
  isForcedFullScreen?: boolean
) {
  const body = document.querySelector('body');
  const header = document.querySelector<HTMLElement>('.pcoded-header');
  const navBar = document.querySelector<HTMLElement>('.pcoded-navbar');
  const footer = document.querySelector<HTMLElement>('.footer');
  const navBarWrapper = document.querySelector<HTMLElement>(
    '.navbar-wrapper'
  );
  const notificationBanner = document.querySelector<HTMLElement>(
    '.notification-banner'
  );

  const pageHeader = document.querySelector<HTMLElement>('.page-header');
  const cardHeader = document.querySelector<HTMLElement>('.card-header');
  const breadCrumb = document.querySelector<HTMLElement>(
    '.page-header.breadcrumb__name'
  );
  const container = document.querySelector<HTMLElement>(
    '.pcoded-main-container'
  );
  const lookUp = document.querySelector<HTMLElement>('.lookup-item .k-grid');
  const version = document.querySelector<HTMLElement>('.version');

  // Onboarding elements (ob)
  const obHeader = document.querySelector<HTMLElement>('.processing__header');
  const archwizardNavigationBar = document.querySelector<HTMLElement>(
    '.horizontal.small.ng-star-inserted'
  );
  const archwizardContainer = document.querySelector<HTMLElement>(
    '.arc-wizard .wizard-steps'
  );
  const obCard = archwizardContainer?.closest<HTMLElement>('.card');
  const obCardBody = archwizardContainer?.closest<HTMLElement>('.card-body');
  const forecastHeader = document.querySelector<HTMLElement>(
    '.forecast-header'
  );
  const modifyForecastTabs = document.querySelector<HTMLElement>(
    '.modify-forecast__tabs'
  );
  const modifyForecastInstruction = document.querySelector<HTMLElement>(
    '.instruction'
  );

  const withoutGrid = [
    navBar,
    navBarWrapper,
    footer,
    notificationBanner,
    cardHeader,
    pageHeader,
    breadCrumb,
    version,
    obHeader,
    archwizardNavigationBar,
    forecastHeader,
    modifyForecastTabs,
    modifyForecastInstruction
  ].concat(neededToBeHiddenElements);

  let isFullScreen = isForcedFullScreen;
  if ([undefined, null].includes(isForcedFullScreen)) {
    isFullScreen = conditionalOperator(
      !!lookUp,
      window.innerHeight * 0.78 >= lookUp?.offsetHeight,
      window.innerHeight * 0.85 >= el.offsetHeight
    );
  }

  if (isFullScreen) {
    fullscreenHandle(
      withoutGrid,
      body,
      lookUp,
      archwizardContainer,
      obCard,
      obCardBody
    );

    header.style.display = 'none';
    el.style.width = '100%';
    container.style.paddingTop = '0';
    container.style.marginLeft = '0';
    container.style.top = '0';

    if (cb) {
      cb(isFullScreen);
    }

    return;
  }

  withoutGrid.forEach((element) => {
    if (element) {
      element.style.display = '';
    }
  });

  if (lookUp) {
    lookUp.style.height = '500px';
  }

  header.style.display = '';
  if (pageHeader) {
    pageHeader.style.display = 'flex-box';
  }

  if (archwizardContainer) {
    archwizardContainer.style.padding = '';
    archwizardContainer.style.border = '';
  }

  if (obCard) {
    obCard.style.backgroundColor = '';
    obCard.style.boxShadow = '';
  }

  if (obCardBody) {
    obCardBody.style.padding = '';
  }

  navBar.style.width = '';
  el.style.width = 'initial';
  el.style.height = 'initial';
  container.style.paddingTop = '';
  container.style.marginLeft = '';
  container.style.top = '';

  if (cb) {
    cb(isFullScreen);
  }
}

export function fullScreenAllPage(isFullScreen: boolean = false) {
  if (isFullScreen) {
    screenfull.request();
  } else {
    screenfull.exit();
  }
}
