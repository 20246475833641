//used in the metafield to control how input the data.
export enum DisplayControlInput {
  Text,
  Email,
  TextArea,
  Date,
  Integer,
  Decimal,
  List,
  LookUp,
  Currency,
  Checkbox,
  Select,
  Image
}
