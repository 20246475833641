export class GridStatePagination {

  // Initial current page need to be 0
  paginationCurrentPage:number = 0;
  paginationPageSize:number = 100;


  constructor(init?: Partial<GridStatePagination>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }
}
