import { Component, OnDestroy } from '@angular/core';
import { Subject, MonoTypeOperatorFunction} from 'rxjs';

import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-base',
  template: ''
})
export class BaseComponent implements OnDestroy{

  protected unsubscribe : Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  /**
   * Auto cleanup by take until component is destroyed
   *
   * @return {MonoTypeOperatorFunction<T>}
   */
     autoCleanUp<T>(): MonoTypeOperatorFunction<T> {
      return takeUntil<T>(this.unsubscribe);
    }

}
