import { Injectable } from '@angular/core';
import { ResourceService } from './resource-service';
import { Supplier } from '../models/supplier';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SupplierService extends ResourceService<Supplier> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/supplier');
  }

  getBillingAndShippingAddressById(supplierKey: string): Observable<any> {
    return this.httpClient.get(
      `${this.apiUrl}/${supplierKey}/billing-and-shipping-address`
    );
  }

  putBillingAndShippingAddressById(payload): Observable<any> {
    return this.httpClient.put(
      `${this.apiUrl}/${payload.key}/billing-and-shipping-address`,
      payload
    );
  }
}
