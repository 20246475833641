export enum AdvancedFilter {
  active = 'active',
  inactive = 'inactive',
  inWarehouse = 'in-warehouse',
  overStocked = 'over-stocked',
  wellStocked = 'well-stocked',
  lowStock = 'low-stock',
  outOfStock = 'out-of-stock',
  salable = 'salable',
  warehouseRestockRecommendation = 'warehouse-restock-recommendation-exceeding-warehouse-qty'
};
