import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, filter, map } from 'rxjs/operators';
import { MAX_SIZE_UPLOAD } from '../../../../core/constants/max-size-upload.constant';
import { FileDetails } from 'src/app/core/models/file-details';
import { CompanyService } from 'src/app/core/services/company.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { Company } from 'src/app/core/models/company';
import { User } from 'src/app/core/models/user';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss'],
})
export class FileUploadModalComponent implements OnInit {
  @Input() importer: any;
  @Input() currentCompany: Company;
  @Input() currentUser: User;
  @Input() fileType: string;
  @Input() isUsingUnit: boolean;

  @Output() fileUploadCompleted: EventEmitter<FileDetails> = new EventEmitter<FileDetails>();

  uploadProgress: number = 0;
  fileName: string = "";
  uploadFileForm: FormGroup;
  fileUploadHasError: boolean = false;
  fileUploadMaxSizeError: boolean = false;

  private file: File;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private syncService: SyncService) {

  }

  get isNew(): AbstractControl {
    return this.uploadFileForm.controls.isNew;
  }

  ngOnInit(): void {
    this.uploadFileForm = this.fb.group({
      'uploadFile':['', [Validators.required]],
      'isNew': ['', [Validators.required]]
    });

    if (this.importer) {
      this.uploadFileForm.controls.isNew?.valueChanges?.subscribe((isNew) => {
        this.importer.setUser({
          user_id: this.currentUser.userId,
          companyType: this.currentCompany.companyType,
          companyKey: this.currentCompany.companyKey,
          isAdmin: this.currentUser.isAdmin,
          fileOptions: btoa(JSON.stringify({fileType: this.fileType, isCreateNew: isNew === 'true', isUsingUnit: this.isUsingUnit}))
        });
      })
    }
  }

  onFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  startUpload() {
    if (this.uploadFileForm.invalid) {
      return;
    }

    this.fileUploadHasError = false;

    if (this.file.size > MAX_SIZE_UPLOAD) {
      this.fileUploadMaxSizeError = true;
      return;
    }

    this.syncService
    .syncFile(this.fileName, this.companyService.currentCompany().companyKey)
    .pipe(
      concatMap((res) => {
        return this.syncService
          .uploadToAmazon(this.file, res.signedRequest)
          .pipe(
            map((event: HttpEvent<any>) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.uploadProgress = Math.round(
                  100 * (event.loaded / event.total)
                );
              }
              return event;
            }),
            filter((event) => {
              //don't emit until uploading is complete.
              return event.type === HttpEventType.Response;
            })
          );
      })
    )
    .subscribe((res) => {
      const fileDetails = new FileDetails( {
        fileName: this.fileName,
        isCreateNew: this.uploadFileForm.controls["isNew"].value === "true"
      });

      this.fileUploadCompleted.emit(fileDetails);
      this.activeModal.close();
    },
    (err) => {
      this.fileUploadHasError = true;
      this.uploadProgress = 0;
      this.uploadFileForm.reset();
    });
  }

  openImporter(): void {
    if(this.uploadFileForm.controls?.isNew?.value === 'true') {
      Swal.fire({
        html: 'Are you sure you want to create new items? </br>Select Update Existing Entries to modify existing items',
        type: 'warning',
        showCancelButton: true,
        allowOutsideClick: false,
      }).then((confirm) => {
        if (confirm.dismiss) {
          return;
        }
        this.importer.openModal();
        this.activeModal.close(true);
      })
      return
    }

    this.importer.openModal();
    this.activeModal.close(true);
  }
}
