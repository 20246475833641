import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Snapshot, ISnapshotResponse } from '../models/snapshot';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnapshotService {
  apiUrl = '/api/snapshot';

  constructor(private httpClient: HttpClient) {}

  getSnapshots(itemKey: string): Observable<Snapshot[]> {
    const params = new HttpParams({
      fromObject: {
        id: itemKey,
        level: '1',
      },
    });

    return this.httpClient
      .get<ISnapshotResponse>(`${this.apiUrl}`, { params })
      .pipe(map((snapshotRes) => snapshotRes.model || []));
  }

  getSnapshotsByItemKeys(
    itemKeys: string[],
    isPurchasing: boolean
  ): Observable<Snapshot[]> {
    const params = new HttpParams({
      fromObject: {
        isPurchasing: `${isPurchasing}`,
      },
    });

    return this.httpClient
      .post<ISnapshotResponse>(
        `${this.apiUrl}`,
        {
          ids: itemKeys,
        },
        { params }
      )
      .pipe(map((snapshotRes) => snapshotRes.model || []));
  }

  getSnapshotsByGroup(
    itemKey: string,
    isPurchasing: boolean
  ): Observable<Snapshot[]> {
    const params = new HttpParams({
      fromObject: {
        groupKey: itemKey,
        isPurchasing: `${isPurchasing}`,
      },
    });

    return this.httpClient
      .get<ISnapshotResponse>(`${this.apiUrl}`, { params })
      .pipe(map((snapshotRes) => snapshotRes.model || []));
  }
}
