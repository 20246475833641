<div class="skip-meeting-hidden">
  <div class="modal-header">
    <div class="modal-title w-100">
      <h5 class="text-center">Skip Meeting</h5>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- The first few errors -->
    <div class="card p-3">
      <!-- Confirmation and messages -->
      <p>Are you sure?</p>
      <p>
        Scheduling an onboarding meeting with ForecastRx staff is highly
        recommended. If you wish to have your account unlocked now, click
        continue.
      </p>

      <form
        class="shipment-detail__modify"
        *ngIf="skipMeetingForm"
        [formGroup]="skipMeetingForm"
      >
        <p>
          <textarea
            type="text"
            class="form-control"
            placeholder="Comments (Optional)"
            id="skip-meeting__comment"
            aria-describedby="basic-addon3"
            style="resize: none; min-height: 100px"
            formControlName="skipMeetingComment"
          ></textarea>
        </p>
      </form>
    </div>

    <!-- Button -->
    <div class="d-flex justify-content-center">
      <button class="btn btn-secondary mx-1" (click)="activeModal.dismiss()">
        Cancel
      </button>
      <button
        class="btn btn-primary mx-1"
        (click)="confirm()"
      >
        Continue
      </button>
    </div>
  </div>
</div>
