<div class="vendor-hidden">
  <div class="modal-header">
    <div class="modal-title w-100">
      <h5 class="text-center">
        <i class="feather icon-eye-off"></i>
        Hide Supplier
      </h5>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- The first few errors -->
    <div class="card p-3">
      <!-- Confirmation and messages -->
      <p>Are you sure that you would like to hide the following supplier?</p>
      <h6>{{ currentVendor.name }}</h6>
      <p>
        <span *ngIf="doesContainItems"
          >This action will hide the following visible items associated with
          this supplier.</span
        >
        The supplier
        <span *ngIf="doesContainItems">and associated item(s)</span> will remain
        visible in your results until your next forecast.
      </p>

      <!-- Related Items -->
      <ol
        class="vendor-hidden__item-list mt-2 mr-3 text-justify"
        *ngIf="doesContainItems"
      >
        <li class="my-2" *ngFor="let item of items">{{ item.name }}</li>
      </ol>
    </div>

    <!-- Button -->
    <div class="d-flex justify-content-center">
      <button class="btn btn-secondary mx-1" (click)="activeModal.dismiss()">
        Cancel
      </button>
      <button
        class="btn btn-primary mx-1"
        (click)="confirmHidden()"
        [disabled]="isLoading"
      >
        Continue
      </button>
    </div>
  </div>
</div>
