import { SalesVelocitySettingsType } from '@stockaid/shared-enums';
import { RestockModel } from '../infrastructure/enums/restock-model.enum';

export class RestockSuggestion {
  key: string = '';
  salesVelocitySettingsType: SalesVelocitySettingsType = SalesVelocitySettingsType.restockAMZ;
  sum: number = 0;
  inboundTotal: number = 0;
  orderQty: number = 0;
  flag: string = '';
  alertDate: Date;
  status: string = '';
  imageUrl: string = '';
  sku: string = '';
  productName: string = '';
  asin: string = '';
  type: string = '';
  tags: string[] = [];
  prepGuide: string = '';
  skuNotes: string = '';
  prepNotes: string = '';
  supplier: string = '';
  supplierSku: string = '';
  supplierCost: number = 0;
  supplierRebate: number = 0;
  inboundShippingCost: number = 0;
  reshippingCost: number = 0;
  repackagingMaterialCost: number = 0;
  repackingLaborCost: number = 0;
  restockModel: string = '';
  fnsku: string = '';
  upc: string = '';
  ean: string = '';
  fba: number = 0;
  lowestFba: number = 0;
  nonFba: number = 0;
  lowestNonFba: number = 0;
  packageWeight: number = 0;
  dimensionalWeight: number = 0;
  casePackQuantity: number = 0;
  hazmat: string = '';
  oversized: string = '';
  s2d: number = 0;
  s7d: number = 0;
  s30d: number = 0;
  s60d: number = 0;
  s90d: number = 0;
  s180d: number = 0;
  outOfStock2d:number = 0;
  outOfStock7d:number = 0;
  outOfStock14d:number = 0;
  outOfStock30d:number = 0;
  outOfStock60d:number = 0;
  outOfStock90d:number = 0;
  outOfStock180d:number = 0;
  outOfStockDays: number = 0;
  average7DayPrice: number = 0;
  listPrice: number = 0;
  soldBy: string = '';
  newBuyBox: number = 0;
  estimatedMargin: number = 0;
  estimatedMarginPercentage: number = 0;
  estimatedMarkupPercentage: number = 0;
  qoh: number = 0;
  inbound: number = 0;
  inboundWorking: number = 0;
  inboundShipped: number = 0;
  inboundReceiving: number = 0;
  inboundFcTransfer: number = 0;
  targetDays: number = 0;
  remaining: number = 0;
  demand: number = 0;
  outOfStockPercentage: number = 0;
  reserved: number = 0;
  unfulfillable: number = 0;
  pending: number = 0;
  localQty: number = 0;
  maximumShipmentQty: number = 0;
  suggShip: number = 0;
  suggReorder: number = 0;
  onOrder: number = 0;
  restockNeeded: number = 0;
  category: string = '';
  rank: number = 0;
  referralFee: number = 0;
  fbaFee: number = 0;
  sv2d: number = 0;
  sv7d: number = 0;
  sv14d: number = 0;
  sv30d: number = 0;
  sv60d: number = 0;
  sv90d: number = 0;
  sv180d: number = 0;
  svDemand: number = 0;
  targetQtyOnHand: number = 0;
  qtySupplierLeadTime: number = 0;
  currentAmazonInventory: number = 0;
  coverageAvailable: number = 0;
  coverageRequired: number = 0;
  coverageOrderQty: number = 0;
  targetMaxDays: number = 0;
  qtyToLocalLeadTime: number = 0;
  localLeadTimeDays: number = 0;
  amazonInventoryDays: number = 0;
  localInventoryDays: number = 0;
  onOrderDays: number = 0;
  supplierLeadTime: number = 0;
  monthlyStorageFee: number = 0;
  variableClosingFee: number = 0;
  moq: number = 0;
  estimatedPriceType: string;
  svDateRage: number = 0;
  salesVelocityType: string;
  salesVelocitySettingData: any;
  adjSv2d: number = 0;
  adjSv7d: number = 0;
  adjSv14d: number = 0;
  adjSv30d: number = 0;
  adjSv60d: number = 0;
  adjSv90d: number = 0;
  adjSv180d: number = 0;
  percentOutOfStock2d: number = 0;
  percentOutOfStock7d: number = 0;
  percentOutOfStock14d: number = 0;
  percentOutOfStock60d: number = 0;
  percentOutOfStock90d: number = 0;
  percentOutOfStock180d: number = 0;
  adjSvDateRange: number = 0;
  svDateRange: number = 0;
  forecastRecommendedQty: number;
  recommendedSupplierQty: number;
  recommendedWarehouseQty: number;
  warehouseQtyUpdatedDate: Date;
  trueRestockModel: RestockModel;
  leadTime: number = 0;
  isAdjustingDailySalesRateForOutOfStock: boolean;
  expeditedRecommendationQty: number = 0;
  vendorKey: string = '';
  casePackSuggShip: number = 0;
  casePackSuggReorder: number = 0;
  casePackForecastRecommendedQty: number = 0;
  isChild: boolean;
  isolatedValues: RestockSuggestion;
  additionalInventory: number;
  qtyAdditionalInventory: number;
  shipmentItemKey: string;
  restockKey: string;
  shipmentQty: number;
  awdInventoryQty: number;
  awdInventoryDays: number;

  constructor(init?: Partial<RestockSuggestion>) {
    if (init) {
      // if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
