import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ResourceService } from './resource-service';
import { GridView } from '../models/grid-view';

@Injectable({
  providedIn: 'root'
})
export class GridViewService extends ResourceService<GridView> {

  constructor(httpClient: HttpClient) {
    super(httpClient, '/api/grid-view');
  }

  getGridViews(itemType: string, searchTerm: string | null = null): Observable<GridView[]> {
    let params = new HttpParams();
    if (searchTerm) {
      params = params.set('search', searchTerm);
    }

    return this.httpClient.get<GridView[]>(`${this.apiUrl}/${itemType}`, { params })
  }

  saveGridView(gridView: GridView): Observable<GridView> {
    if (gridView.key) {
      return this.httpClient.put<GridView>(`${this.apiUrl}/${gridView.key}`, gridView);
    }

    return this.httpClient.post<GridView>(`${this.apiUrl}`, gridView);
  }
}
