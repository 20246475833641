<div class="nav-search d-block position-relative">
  <a class="mob-toggler" href="javascript:"
    ><i class="feather icon-more-vertical text-success"></i
  ></a>

  <div class="input-group bg-silver">
    <input
      #searchBar
      type="text"
      class="form-control bg-silver input-search-box border-0"
      placeholder="Search..."
      (input)="search(searchBar.value)"
      [disabled]="isSubscriptionExpired"
    />
    <button
      class="btn btn-light bg-silver btn-search border-0"
      type="button"
      (click)="search(searchBar.value)"
      [class.nav-search__disabled-search-btn]="isSubscriptionExpired"
    >
      <i class="fa fa-search"></i>
    </button>
  </div>
</div>

<div *ngIf="searchOn && !isSyncInProcess">
  <app-nav-lookup-item
    [isNavbarCollapse]="isNavbarCollapse"
    [(searchOn)]="searchOn"
  ></app-nav-lookup-item>
</div>
