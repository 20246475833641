import { Component, Input, OnInit } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import {
  FilterService,
  BaseFilterCellComponent,
} from '@progress/kendo-angular-grid';
import { getContrastYIQ } from '@stockaid/utils';
import _ from 'lodash';

@Component({
  selector: 'kendo-select-filter',
  templateUrl: './kendo-select-filter.component.html',
  styleUrls:['./kendo-select-filter.component.scss']
})
export class KendoSelectFilterComponent
  extends BaseFilterCellComponent implements OnInit
{
  @Input() public filter: CompositeFilterDescriptor;
  @Input() public filterService: FilterService;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public field: string;
  @Input() private isMenu = false;
  serviceLevelData: any[];
  readonly getContrastYIQ = getContrastYIQ;

  public get selectedValue(): any {
    const filter = this.filterByField(this.field);
    return filter ? filter.value : null;
  }

  public get defaultItem(): any {
    return {
      [this.textField]: 'Select item...',
      [this.valueField]: null,
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  ngOnInit(): void {
    if(this.field === 'serviceLevel') {
      this.serviceLevelData = _.cloneDeep(this.data);
      this.serviceLevelData.shift();
    }
  }

  public onChange(value: any): void {
    this.filter = value === null
    ? this.removeFilter(this.valueField)
    : this.updateFilter({
        field: this.field,
        operator: 'eq',
        value: value,
      })

    if (!this.isMenu) {
      this.applyFilter(this.filter);
    } else {
      this.filterService.filter(this.filter);
    }
  }
}
