export class Realm {

  userId: string;
  companyName: string;
  companyKey: string;
  companyType: string;
  marketplaceName: string;
  created_at:Date;
  updated_at:Date;



  constructor(init?: Partial<Realm>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }
}
