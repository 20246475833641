import { DisplayControlInput } from "../../../enums/display-control-input.enum";
import { DisplayControlOutput } from "../../../enums/display-control-output.enum";

//used with MetaDataField to control display out;
export class DisplayControl {
  input?: DisplayControlInput = DisplayControlInput.Text;
  output: DisplayControlOutput = DisplayControlOutput.Text;
  lookUpConfig: any = null; //this is the configuration for fields that require lookup functionallity.
  displayWithCompanyType: string[] = []; //controls where or not to display in Grid
  selectSource?: any[] = []; //data source for selection
  linkSource?: string
  willShowTooltip?: boolean = false;

  constructor(init?: Partial<DisplayControl>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }
}
