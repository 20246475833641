<ng-container *ngIf="this.navigationList" class="breadcrumb">
  <div *ngIf="summaryItems?.length && isShowManageHeader">
    <app-header
      [dataRows]="summaryItems"
      [currentCompany]="currentCompany"
      (clickRow)="goToItemsWithFilter($event)"
    ></app-header>
  </div>

  <div *ngIf="summaryByVendors?.length && isShowPurchasesHeader">
    <app-header
      [dataRows]="summaryByVendors"
      [currentCompany]="currentCompany"
      [isInteraction]="false"
      (clickRow)="goToItemsWithFilter($event)"
    ></app-header>
  </div>

  <div *ngIf="(currentUser?.isAdmin || (currentCompany && !currentCompany.jobInitiator && !currentCompany.isLocked)) && !isHideBreadCrumb">
    <ng-container *ngFor="let breadcrumb of navigationList; let last = last">
      <div class="page-header breadcrumb__name" *ngIf="last && breadcrumb.breadcrumbs !== false">
        <div class="page-block">
          <div class="row align-items-center">
            <div class="col-md-12 d-flex">
              <div class="page-header-title">
                <ng-container
                  *ngFor="let breadcrumb of navigationList; let last = last"
                >
                  <h5 class="" *ngIf="last">
                    {{ getBreadcrumbTitle((breadcrumb.mainTitle || breadcrumb.title)) }}
                  </h5>
                </ng-container>
              </div>
              <ul class="breadcrumb align-items-center">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/dashboard/']" *ngIf="type === 'theme2'"
                    ><i class="feather icon-home"></i
                  ></a>
                  <a [routerLink]="['/dashboard/']" *ngIf="type === 'theme1'"
                    >Home</a
                  >
                </li>
                <ng-container *ngFor="let breadcrumb of navigationList">
                  <li class="breadcrumb-item" *ngIf="breadcrumb.url !== false">
                    <a [routerLink]="breadcrumb.url">{{ getBreadcrumbTitle(breadcrumb.title) }}</a>
                  </li>
                  <li class="breadcrumb-item" *ngIf="breadcrumb.url === false">
                    <a href="javascript:">{{ getBreadcrumbTitle(breadcrumb.title) }}</a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
