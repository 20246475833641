<form [formGroup]="uploadFileForm">
  <div class="modal-header">
    <h5 class="modal-title">Upload Your File</h5>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-sm-12">
      <div class="mb-4" [hidden]="!uploadProgress">
        Uploading {{ this.fileName }}...
        <ngb-progressbar
          type="success"
          [showValue]="true"
          [value]="uploadProgress"
        ></ngb-progressbar>
      </div>
      <div [hidden]="uploadProgress">
        <div *ngIf="importer; else normalUpload" class="text-center">
          <button
            class="btn btn-success"
            ngbAutofocus
            data-csvbox
            (click)="openImporter()"
            [disabled]="!isNew.value"
          >
            Import
          </button>
        </div>
        <ng-template #normalUpload>
          <div class="text-center">
            <button
              class="btn btn-success"
              ngbAutofocus
              (click)="hiddenfileinput.click()"
              title="Click to choose file to upload."
            >
              Choose File
            </button>
          </div>
          <div *ngIf="this.fileName" class="text-center mt-3">
            <strong>{{ this.fileName }}</strong>
          </div>
          <div [hidden]="!fileUploadHasError" class="text-danger">
            <strong
              >There was an error while uploading {{ this.fileName }}. Please
              try again. If the problem persist, contact support.</strong
            >
          </div>
          <div [hidden]="!fileUploadMaxSizeError" class="text-danger">
            <strong
              >File size greater than 100MB. Please upload file below
              100MB</strong
            >
          </div>
          <div>
            <input
              type="file"
              accept=".csv"
              style="display: none"
              (change)="onFileSelected($event)"
              id="validatedCustomFile"
              formControlName="uploadFile"
              #hiddenfileinput
              required
            />
          </div>
        </ng-template>
        <hr />
        <div>
          <span>Please select one of the following options:</span>
          <div class="form-check mt-2">
            <input
              type="radio"
              name="isNew"
              id="isNew1"
              value="false"
              class="form-check-input"
              formControlName="isNew"
            />
            <label for="isNew1" class="form-check-lable"
              >Update Existing Entries</label
            >
          </div>
          <div class="form-check">
            <input
              type="radio"
              name="isNew"
              id="isNew2"
              value="true"
              class="form-check-input"
              formControlName="isNew"
            />
            <label for="isNew2" class="form-check-lable"
              >Create New Entries</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer pr-2">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.dismiss('Close click')"
      [disabled]="uploadProgress"
    >
      Close
    </button>
    <button
      *ngIf="!importer"
      type="button"
      class="btn btn-primary"
      [disabled]="uploadFileForm.invalid || uploadProgress"
      [ngStyle]="{ cursor: uploadFileForm.valid ? 'pointer' : 'not-allowed' }"
      (click)="startUpload()"
    >
      Upload File
    </button>
  </div>
</form>
