import { AbstractControl } from 'ngx-strongly-typed-forms';
import {
  AbstractControl as AbstractControlAlt,
  FormArray,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import { MetaDataField } from '../../infrastructure/classes/meta-data-field';

type ValidationErrors = { [key: string]: boolean } | null;

export const CustomValidators = {
  required: (control: AbstractControl<string>): ValidationErrors =>
    !control?.value?.trim()?.length ? { required: true } : null,

  passwordMisMatch: <T>(
    passwordPath: keyof T,
    confirmPasswordPath: keyof T
  ) => {
    return (control: AbstractControl<T>): ValidationErrors => {
      const password = control.get(passwordPath);
      const confirmPassword = control.get(confirmPasswordPath);
      const isMismatched =
        confirmPassword.value &&
        password.value &&
        confirmPassword.value !== password.value;

      if (isMismatched) {
        confirmPassword.setErrors({ passwordMismatch: true });
        return { passwordMismatch: true };
      }

      if (confirmPassword.value) {
        confirmPassword.setErrors(null);
      }
      return null;
    };
  },

  atLeast1SpecialChar: (control: AbstractControl<string>): ValidationErrors =>
    !control?.value?.match(/(?=.*[-+_!@#$%^&*?]).*/) // NOSONAR
      ? { specialChar: true }
      : null,

  lessThanOrEqual: (
    aName: string,
    bName: string,
    metaData: MetaDataField[]
  ) => {
    return (control: AbstractControlAlt): ValidationErrors => {
      const a = control.get(aName);
      const b = control.get(bName);
      const isLessThanOrEqual = Number(a.value) <= Number(b.value);

      if (!isLessThanOrEqual) {
        const aFieldName = metaData.find(
          (item) => item.field === aName
        ).displayName;
        const bFieldName = metaData.find(
          (item) => item.field === bName
        ).displayName;

        a.setErrors({
          lessThanOrEqual: `${aFieldName} must be less than or equal to ${bFieldName}`,
        });
        return { lessThanOrEqual: true };
      }

      if (a.value) {
        a.errors && delete a.errors.lessThanOrEqual;
        a.setErrors(Object.keys(a.errors || {}).length ? a.errors : null);
      }
      return a.errors;
    };
  },

  greaterThanOrEqual: (
    aName: string,
    bName: string,
    metaData: MetaDataField[]
  ) => {
    return (control: AbstractControlAlt): ValidationErrors => {
      const a = control.get(aName);
      const b = control.get(bName);
      const isGreaterThanOrEqual = Number(a.value) >= Number(b.value);

      if (!isGreaterThanOrEqual) {
        const aFieldName = metaData.find(
          (item) => item.field === aName
        ).displayName;
        const bFieldName = metaData.find(
          (item) => item.field === bName
        ).displayName;

        a.setErrors({
          greaterThanOrEqual: `${aFieldName} must be greater than or equal to ${bFieldName}`,
        });
        return { greaterThanOrEqual: true };
      }

      if (a.value) {
        a.errors && delete a.errors.greaterThanOrEqual;
        a.setErrors(Object.keys(a.errors || {}).length ? a.errors : null);
      }
      return a.errors;
    };
  },

  lessThanOrEqualStatic: (
    aName: string,
    bName: string,
    bValue: number,
    metaData: MetaDataField[]
  ) => {
    return (control: AbstractControlAlt): ValidationErrors => {
      const a = control.get(aName);

      if (!a) {
        return null;
      }

      const isLessThanOrEqual = Number(a.value) <= bValue;

      if (!isLessThanOrEqual) {
        const aFieldName = metaData.find(
          (item) => item.field === aName
        ).displayName;
        const bFieldName = metaData.find(
          (item) => item.field === bName
        ).displayName;

        a.setErrors({
          lessThanOrEqualStatic: `${aFieldName} must be less than or equal to ${bFieldName}`,
        });

        return { lessThanOrEqualStatic: true };
      }

      if (a.value) {
        a.errors && delete a.errors.lessThanOrEqualStatic;
        a.setErrors(Object.keys(a.errors || {}).length ? a.errors : null);
      }
      return a.errors;
    };
  },

  divisibleBy: (aName: string, bName: string, metaData: MetaDataField[]) => {
    return (control: AbstractControlAlt): ValidationErrors => {
      const a = control.get(aName);
      const b = control.get(bName);
      const isDivisibleBy =
        Number(a.value) % Number(b.value) === 0 || Number(b.value) === 0;

      if (!isDivisibleBy) {
        const aFieldName = metaData.find(
          (item) => item.field === aName
        ).displayName;
        const bFieldName = metaData.find(
          (item) => item.field === bName
        ).displayName;

        a.setErrors({
          divisibleBy: `${aFieldName} must be divisible by ${bFieldName}`,
        });
        b.setErrors({
          divisibleBy: `${aFieldName} must be divisible by ${bFieldName}`,
        });
        return { divisibleBy: true };
      }

      if (b.value) {
        b.errors && delete b.errors.divisibleBy;
        b.setErrors(Object.keys(b.errors || {}).length ? b.errors : null);
      }
      return b.errors;
    };
  },

  casePackStateUnchanged: () => {
    return (control: AbstractControlAlt): ValidationErrors => {
      const caseQty = control.get('caseQty');
      const isShipByCase = control.get('isShipByCase');
      const shipmentId = control.get('shipmentId');

      if (!shipmentId.value) {
        return null;
      }

      if (
        (caseQty.value > 0 && !isShipByCase.value) ||
        (!caseQty.value && isShipByCase.value)
      ) {
        caseQty.setErrors({
          casePackStateUnchanged:
            'You cannot change the case pack state after shipment had been uploaded to Amazon',
        });
        return { casePackStateUnchanged: true };
      }

      caseQty.errors && delete caseQty.errors.casePackStateUnchanged;
      caseQty.setErrors(
        Object.keys(caseQty.errors || {}).length ? caseQty.errors : null
      );
      return caseQty.errors;
    };
  },

  totalShipmentQtyMustBeEqualTo(expectTotals: number[]): ValidatorFn {
    return (boxesFormGroup: FormArray) => {
      const actualTotals = expectTotals.map(() => 0);
      boxesFormGroup.controls.forEach((boxGroup) => {
        const boxItemsFormGroup = boxGroup.get('boxItems') as FormArray;
        boxItemsFormGroup.controls.forEach((boxItemGroup, itemIdx) => {
          actualTotals[itemIdx] += boxItemGroup.get('shipmentQty').value;
        });
      });

      const isValid = expectTotals
        ?.map((t, idx) => t === actualTotals[idx])
        ?.every((t) => t);

      return isValid
        ? null
        : {
            error:
              'The quantity in each box does amount to the total shipment quantity',
          };
    };
  },

  boxMustHaveAtLeastOneItem() {
    return (boxFormGroup: FormGroup) => {
      let total = 0;
      const boxItemsFormGroup = boxFormGroup.get('boxItems') as FormArray;
      boxItemsFormGroup.controls.forEach((boxItem) => {
        total += boxItem.get('shipmentQty')?.value || 0;
      });

      return !!total ? null : { error: 'Box must have at least one item' };
    };
  },
};
