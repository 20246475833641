<div class="nav-item__forecast">
  <!-- Button -->
  <div
    class="nav-item__button-container"
    [ngClass]="{ 'nav-item__button-container--modified': isSyncInProcess }"
  >
    <div
      class="nav-item__button"
      [ngClass]="{ 'nav-item__button--modified': isSyncInProcess }"
      (click)="togglePopup()"
      (mouseenter)="willShowRemindingDialog = false"
    >
      <i class="nav-item__button-icon icon feather icon-trending-up feather--modified"></i>

      <span
        class="nav-item__badge position-absolute top-0 translate-middle border border-light rounded-circle"
        [class.nav-item__badge--hidden]="!willRemindToRunForecast"
      >
      </span>

      <div
        class="nav-item__dialog"
        [class.nav-item__dialog--hidden]="!willShowRemindingDialog"
        [class.nav-item__dialog--animate]="willShowRemindingDialog"
      >
        Run forecast to ensure<br/>items are updated
      </div>

      <div class="nav-item__timeInfo" *ngIf="lastForecast">
        <p class="nav-item__timeDetail">
          Last Forecast:
          <br />
          {{ lastForecast | date: "fullDate" }}
          {{ lastForecast | date: "shortTime" }}
        </p>
      </div>
    </div>
  </div>

  <!-- Popup Confirmation -->
  <div
    class="nav-item__message-container"
    [class]="isHide ? 'nav-item__message-container--modified' : ''"
  >
    <div class="nav-item__message-box nav-item__message-box--modified">
      <div class="nav-item__message-header">
        <i class="icon feather icon-trending-up"></i>
        <p class="nav-item__message-name">Forecast</p>
      </div>
      <div class="nav-item__message-body">
        <p class="nav-item__message-para">
          You have selected to run a Forecast. This will include your latest
          data in predictive calculations and will incorporate any updated
          values in your data. Please note that this may take some time.
        </p>
        <p class="nav-item__message-question">
          Are you sure that you would like to run a forecast now?
        </p>
      </div>
      <div class="nav-item__message-button">
        <a
          href="javascript:"
          class="nav-item__message-link"
          (click)="togglePopup()"
          >Cancel</a
        >
        <a
          href="javascript:"
          (click)="startForecast()"
          class="nav-item__message-link"
          >Start Forecast</a
        >
      </div>
      <div class="nav-item__message-close">
        <i
          class="fa fa-times fa-times--modified"
          (click)="togglePopup()"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>

  <!-- Spinner -->
  <div class="nav-item__forecast-cover" *ngIf="isProcessing">
    <div class="nav-item__forecast-cover-content">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only"></span>
      </div>
      <p class="nav-item__forecast-cover-message">
        {{ header }}
        <br />
        {{ message }}
      </p>
    </div>
  </div>
</div>
