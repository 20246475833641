export enum ShipmentStatus {
  WORKING = 'WORKING',
  PENDING = 'PENDING',
  RECEIVING = 'RECEIVING',
  RECEIVED = 'RECEIVED',
  SHIPPED = 'SHIPPED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CHECKED_IN = 'CHECKED_IN',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
  PO_SENT = 'PO_SENT',
}
