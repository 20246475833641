import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  openQBOPopup(uri: string): void {
    let parameters = 'location=1,width=800,height=650';
    parameters +=
      ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2;
    const win = window.open(uri, 'connectPopup', parameters);
    const pollOAuth = window.setInterval(function () {
      try {
        if (win?.document?.URL?.indexOf('code') != -1) {
          window.clearInterval(pollOAuth);
          win.close();
          setTimeout(() => location.reload());

        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  openASCOPopup(uri: string): void {
    let parameters = 'location=1,width=800,height=650';
    parameters +=
      ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2;
    const win = window.open(uri, 'connectPopup', parameters);
    const pollOAuth = window.setInterval(function () {
      try {
        if(win?.document?.URL?.indexOf('spapi_oauth_code') != -1) {
          window.clearInterval(pollOAuth);
          win.close();
          setTimeout(() => location.reload());
        }
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }
}
