<div class="lookup-item card" [class.lookup-item--no-header]="columns?.length <= 1">
  <!-- Search bar -->
  <input
    #searchBar
    type="search"
    class="form-control border-0 shadow-none lookup-item__input"
    [placeholder]="isModifyForecastGrid ? 'Select/Search by SKU or ASIN' : 'Search Items...'"
    (input)="search(searchBar.value)"
  />

  <!-- Search result -->
  <div *ngIf="gridItems" class="w-90 m-1 pb-1">
    <kendo-grid
      class="lookup-item__grid"
      [data]="gridItems"
      [pageSize]="30"
      [pageable]="true"
      [skip]="currentOffset"
      height="500"
      (pageChange)="pageChange($event)"
      (cellClick)="chooseItem($event)"
    >
      <kendo-grid-column
        *ngFor="let column of columns"
        [field]="column.field"
        [title]="column.displayName"
      >
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          let-rowIndex="rowIndex"
        >
          <span
            [style]="dataItem['isHidden'] ? hiddenStyle : ''"
          >{{dataItem[column.field]}}</span>
        </ng-template>
      </kendo-grid-column>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <div class="k-pager">
          <div class="k-pager-sm">
            <kendo-pager-prev-buttons class="d-flex"></kendo-pager-prev-buttons>
            <kendo-pager-numeric-buttons
              [buttonCount]="10"
            ></kendo-pager-numeric-buttons>
            <kendo-pager-next-buttons class="d-flex"></kendo-pager-next-buttons>
          </div>
          <div>
            <kendo-pager-info class="k-pager-info"></kendo-pager-info>
          </div>
        </div>
      </ng-template>
    </kendo-grid>
  </div>
</div>
