import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MODAL_DATA } from '@stockaid/services';
import { CompanyService } from 'src/app/core/services/company.service';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-skip-meeting-modal',
  templateUrl: './skip-meeting-modal.component.html',
  styleUrls: ['./skip-meeting-modal.component.scss'],
})
export class SkipMeetingModalComponent implements OnInit {
  skipMeetingForm: FormGroup;

  constructor(
    @Inject(MODAL_DATA) public data: any,
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private snotifyService: SnotifyService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.skipMeetingForm = this.fb.group({
      skipMeetingComment: [''],
    });
  }

  confirm() {
    const company = this.data.company;
    const skipMeetingComment = this.skipMeetingForm.controls['skipMeetingComment'].value;
    company.companyPreferences.config.hasScheduled = true;

    this.companyService
      .updateCompanyInfo({
        companyType: company.companyType,
        companyKey: company.companyKey,
        isSkipOnboarding: true,
        skipOnboardingComment: skipMeetingComment,
      })
      .subscribe(data => {
        this.snotifyService.success(
          "Request submitted successfully. A ForecastRx staff member will email you shortly."
        );
        this.data.callback();
        this.activeModal.close();
      });
  }
}
