export enum SurchargeQuantityField {
  quantity181to210 = 'quantity181to210',
  quantity211to240 = 'quantity211to240',
  quantity241to270 = 'quantity241to270',
  quantity271to300 = 'quantity271to300',
  quantity301to330 = 'quantity301to330',
  quantity331to365 = 'quantity331to365',
  quantity365plus = 'quantity365plus',
}

export enum SurchargePriceField {
  charge181to210 = 'charge181to210',
  charge211to240 = 'charge211to240',
  charge241to270 = 'charge241to270',
  charge271to300 = 'charge271to300',
  charge301to330 = 'charge301to330',
  charge331to365 = 'charge331to365',
  charge365plus = 'charge365plus',
}
