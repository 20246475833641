import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import {
  switchMap,
  map,
  tap,
  finalize,
  first,
  debounceTime,
} from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { CopyHistoryModalComponent } from '../copy-history-modal/copy-history-modal.component';

import { Item } from 'src/app/core/models/item';
import { HistoryOverride } from 'src/app/core/models/history-override';
import { LostSalesOverride } from 'src/app/core/models/lostsales-override';
import { Company } from 'src/app/core/models/company';

import { ItemService } from 'src/app/core/services/item.service';
import { DemandAggregationService } from 'src/app/core/services/demand-aggregation.service';
import { HistoryOverrideService } from 'src/app/core/services/history-override.service';
import { LostSalesOverrideService } from 'src/app/core/services/lostsales-override.service';

import _ from 'underscore';
import lodash from 'lodash';
import 'hammerjs';
import { PERIOD } from 'src/app/core/constants/item-history.constant';
import { toggleFullScreen } from '../../full-screen/toggle-full-screen';
import { getLostSalesDisabled } from 'src/app/core/utils';
import { CompanyService } from 'src/app/core/services/company.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { SyncService } from 'src/app/core/services/sync.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PusherService } from 'src/app/core/services/pusher.service';
import Swal from 'sweetalert2';
import { AuthenticationService, ModalService } from '@stockaid/services';
import { ImportFailModalComponent } from '../import-fail-modal/import-fail-modal.component';
import {
  UploadHistoryAmountType,
  UploadHistoryBehaviorType,
} from 'src/app/core/infrastructure/enums/upload-history-type.enum';
import { DemandAggregation } from 'src/app/core/models/demand-aggregation';
import moment from 'moment';
import {
  NotificationKey,
  NotificationType,
} from 'src/app/core/infrastructure/enums/notification.enum';
import { SnotifyService } from 'ng-snotify';
import { HOURS_IN_DAY } from 'src/app/core/constants/misc.constant';

declare const CSVBoxImporter: any;

@Component({
  selector: 'app-history-data-table',
  templateUrl: './history-data-table.component.html',
  styleUrls: ['./history-data-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HistoryDataTableComponent extends BaseComponent implements OnInit {
  @ViewChild('grid') gridView: HTMLElement;
  @ViewChild(GridComponent) private grid: GridComponent;

  @Input() set itemObject(value: Item) {
    this._itemObject = value;
    this.loadGridItems();
  }
  @Input() copyFeature: boolean = false;
  @Input() set currentCompany(value: Company) {
    this._currentCompany = value;
    this.loadCsvboxImporter();
  }

  @Output() giveCopySource = new EventEmitter();
  @Output() uploadCompleted = new EventEmitter();

  @HostListener('window:popstate', ['$event'])
  onPopState(event: Event) {
    if (this.isFullScreen) {
      this.fullScreen();
      window.history.forward();
    }
  }

  columns: any[] = [];
  gridItems$: Observable<any>;
  lastMonth: number;
  displayName = [
    'Date Range',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  months: any[] = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  sort: SortDescriptor[] = [
    {
      field: 'legend',
      dir: 'desc',
    },
  ];
  chartData: any[] = [];
  historyOverrides$: Observable<HistoryOverride[]>;
  lostSalesOverrides$: Observable<LostSalesOverride[]>;
  historyData: any[];
  trueHistoryData: any[];
  legends: string[] = [];
  prevMonths = [];
  currentMonths = [];
  historyOverrides: HistoryOverride[];
  lostSalesOverrides: LostSalesOverride[];
  isCopyDisabled = false;
  isOverrideDisabled = false;
  isBackfillDisabled = true;
  backfillInfo: string;
  isLostSalesDisabled = true;
  lostSalesInfo: string;
  colorMatrix: string[][] = [];

  ICON_MAXXIMIZE = 'assets/images/full-screen.svg';
  ICON_MINIMIZE = 'assets/images/minimize.svg';
  iconFullScreen: string = this.ICON_MAXXIMIZE;
  isFullScreen = false;
  isSaving: boolean = false;
  saveItemCount: number = 0;
  originalValue: any;
  importer: any;
  isImportFailModalOpen = false;
  saveItem$ = new Subject<any>();
  isSidebarCollapsed = false;
  remainingUndoTime: number = 0;
  isShowUndoToast: boolean = false;
  currentToast: any;

  get isAscCompany(): boolean {
    return this.currentCompany?.companyType === CompanyType.ASC;
  }

  get isLostSalesButtonDisabled(): boolean {
    return getLostSalesDisabled(
      this.isLostSalesDisabled,
      this.itemObject.useLostSalesOverride,
      this.currentCompany.isLostSaleTracking
    );
  }

  get itemObject(): Item {
    return this._itemObject;
  }

  get currentCompany() {
    return this._currentCompany;
  }

  readonly UploadHistoryBehaviorType = UploadHistoryBehaviorType;
  readonly UploadHistoryAmountType = UploadHistoryAmountType;

  private _itemObject: Item;
  private _currentCompany: Company;
  private saveInProgress: boolean = false;

  constructor(
    private snotifyService: SnotifyService,
    private demandAggregationService: DemandAggregationService,
    private formBuilder: FormBuilder,
    private itemService: ItemService,
    private historyOverrideService: HistoryOverrideService,
    private lostSalesOverrideService: LostSalesOverrideService,
    private modalService: NgbModal,
    private companyService: CompanyService,
    private headerService: HeaderService,
    private syncService: SyncService,
    private pusherService: PusherService,
    private altModalService: ModalService,
    private authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.saveItem$
      .pipe(
        this.autoCleanUp(),
        debounceTime(200),
        switchMap(() => this.saveItem()),
        switchMap(() => this.saveAndRemindToRunForecast())
      )
      .subscribe();

    this.calculateUndoLeftTime().subscribe();
    this.handleInitialPusher();

    // Include legend so 0 is 'Date Range', 1 is 'Jan', ...
    const currentMonth = new Date().getMonth();
    let accurateHeaders = [];
    accurateHeaders.push(this.displayName[0]);
    accurateHeaders.push(...this.displayName.slice(currentMonth + 1));
    accurateHeaders.push(...this.displayName.slice(1, currentMonth + 1));
    accurateHeaders.forEach((name, idx) => {
      this.columns.push({
        field: idx === 0 ? 'legend' : name.toLowerCase(),
        displayName: name,
      });
    });

    const thisYear = new Date().getFullYear();
    for (let year = thisYear - PERIOD; year < thisYear; year++) {
      this.legends.push(`${year}-${year + 1}`);
    }

    const lastMonthOfCurrentYear = new Date().getMonth() - 1;

    this.lastMonth = new Date().getMonth() - 1;
    this.lastMonth = this.lastMonth < 0 ? this.lastMonth + 12 : this.lastMonth;

    for (let m = 0; m <= lastMonthOfCurrentYear; m++) {
      this.currentMonths.push(m);
    }
    for (let m = lastMonthOfCurrentYear + 1; m < 12; m++) {
      this.prevMonths.push(m);
    }

    this.headerService.navbarCollapseSubject$
      .pipe(
        this.autoCleanUp(),
        tap((value) => {
          if (!value) {
            return;
          }

          this.isSidebarCollapsed = !this.isSidebarCollapsed;
        })
      )
      .subscribe();
  }

  fullScreen(): void {
    toggleFullScreen(this.gridView['nativeElement'], [], (isFullScreen) => {
      if (isFullScreen && this.isSidebarCollapsed) {
        this.headerService.setNavbarCollapse(true);
      }

      this.isFullScreen = isFullScreen;
      this.iconFullScreen = isFullScreen
        ? this.ICON_MINIMIZE
        : this.ICON_MAXXIMIZE;
    });
  }

  // Load demand aggregation and calculate it into history data, and load it into the chart
  private loadGridItems(): void {
    if (this.grid) {
      // when page first initializes this is false, don't die.
      this.grid.loading = true;
    }

    this.resetAndInitColorMatrix();

    this.isCopyDisabled = false;
    this.isOverrideDisabled = false;

    this.gridItems$ = this.demandAggregationService
      .getDemandAggregation(this.itemObject.key)
      .pipe(
        switchMap((aggregations) => {
          let items = [];
          this.legends.forEach((legend, legendIndex) => {
            const item: any = {};
            const currentYear = Number.parseInt(legend.split('-')[1]);
            const prevYear = Number.parseInt(legend.split('-')[0]);
            item.legend = legend;
            this.months.forEach((month, index) => {
              let { isExist, value } = this.constructHistoryValue(
                aggregations,
                index,
                currentYear,
                prevYear
              );
              if (!isExist) {
                value = null;
              }
              item[month] = value;
            });
            items.push(item);
          });

          if (this.grid) {
            setTimeout(() => (this.grid.loading = false));
          }

          this.loadChartData(items);

          this.historyData = lodash.cloneDeep(items);
          this.trueHistoryData = items;

          this.giveCopySource.emit(this.historyData);

          return this.applyOverlay();
        })
      );
  }

  loadChartData(items: any[]): void {
    this.chartData = items.map((history) => {
      let result = [];
      for (const property in history) {
        if (property === 'legend') {
          continue;
        }
        result.push({
          qty: history[property],
          month: `${property[0].toUpperCase()}${property.slice(1)}`,
        });
      }

      const currentMonth = new Date().getMonth();
      const accurateDataOrder = [];
      accurateDataOrder.push(...result.slice(currentMonth));
      accurateDataOrder.push(...result.slice(0, currentMonth));
      return accurateDataOrder;
    });
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (!sort[0].dir) {
      return;
    }

    this.gridItems$ = of(orderBy(this.trueHistoryData, sort));

    this.colorMatrix.reverse();
  }

  public createFormGroup(dataItem: any): FormGroup {
    const formGroup = new FormGroup({});

    this.columns.forEach((col) => {
      formGroup.addControl(
        col.field,
        this.formBuilder.control('', [
          Validators.min(0),
          Validators.max(Number.MAX_SAFE_INTEGER),
        ])
      );
    });
    formGroup.patchValue(dataItem);
    return formGroup;
  }

  public cellCloseHandler(args: any): void {
    const { formGroup, dataItem, rowIndex, column } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
      return;
    }

    if (formGroup.dirty) {
      Object.assign(dataItem, formGroup.value);
      if (!this.copyFeature) {
        this.handleManipulatingHistoryData(dataItem, column);
        return;
      }

      this.editCopySource(dataItem, rowIndex, column);
    }
  }

  handleManipulatingHistoryData(dataItem: any, column: any): void {
    this.historyOverrideService
      .getHistoryOverride(this.itemObject.key)
      .subscribe((histOverrides) => {
        this.historyOverrides = histOverrides;

        const sameLegendHist = this.historyData.find(
          (history) => dataItem.legend === history.legend
        );
        const sameLegendHistory = lodash.cloneDeep(sameLegendHist);

        const deleteObservable$ = this.deleteOverride(column, dataItem.legend);

        this.grid.loading = true;
        this.saveInProgress = true;

        // add old history overrides
        let overrides: HistoryOverride[] = [];
        this.historyOverrides.forEach((histOverride) => {
          const override = new HistoryOverride();
          override.itemKey = histOverride.itemKey;
          override.itemName = histOverride.itemName;
          override.orderQty = histOverride.orderQty;
          override.grid = histOverride.grid;
          override.forecastKey = histOverride.forecastKey;
          override.start = histOverride.start;

          overrides.push(override);
        });

        // add new history override
        if (
          sameLegendHistory[column.field] !== dataItem[column.field] &&
          (dataItem[column.field] || dataItem[column.field] === 0)
        ) {
          const override = new HistoryOverride();
          override.itemKey = this.itemObject.key;
          override.itemName = this.itemObject.name;
          override.orderQty = dataItem[column.field];
          const thatMonth = this.months.indexOf(column.field);
          let grid: string;
          let gridYear: number;

          if (this.currentMonths.includes(thatMonth)) {
            gridYear = parseInt(dataItem.legend.split('-')[1]);
            grid = `${column.field[0].toUpperCase()}${column.field.slice(
              1
            )}_${gridYear}`;
          } else if (this.prevMonths.includes(thatMonth)) {
            gridYear = parseInt(dataItem.legend.split('-')[0]);
            grid = `${column.field[0].toUpperCase()}${column.field.slice(
              1
            )}_${gridYear}`;
          }
          override.grid = grid;
          override.start = Date.UTC(gridYear, thatMonth, 15);
          override.forecastKey = 'm';
          overrides.push(override);
        }

        deleteObservable$.subscribe(() => {
          this.historyOverrideService
            .save(this.itemObject.key, overrides)
            .subscribe(() => {
              this.grid.loading = false;
              this.saveInProgress = false;

              // If Overrides is turn off, turn it on
              this.toggleOverrides(true);
            });
        });
      });
  }

  editCopySource(dataItem: any, rowIndex, column): void {
    const rowIndexToUpdate = this.historyData.findIndex(
      (hist) => hist.legend === dataItem.legend
    );

    this.historyData[rowIndexToUpdate] = dataItem;

    this.colorMatrix[rowIndex][column.leafIndex - 1] = 'copyedit';

    this.trueHistoryData = this.historyData;

    this.giveCopySource.emit(this.historyData);

    this.loadChartData(this.historyData);

    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];
    this.gridItems$ = of(orderBy(this.historyData, this.sort));
  }

  deleteOverride(column: any, legend: string): Observable<any> {
    let overrideToDelete: HistoryOverride;
    const thatMonth = this.months.indexOf(column.field);
    let grid: string;

    if (this.currentMonths.includes(thatMonth)) {
      grid = `${column.field[0].toUpperCase()}${column.field.slice(1)}_${
        legend.split('-')[1]
      }`;
    } else if (this.prevMonths.includes(thatMonth)) {
      grid = `${column.field[0].toUpperCase()}${column.field.slice(1)}_${
        legend.split('-')[0]
      }`;
    }

    overrideToDelete = this.historyOverrides.find(
      (histOverride) => histOverride.grid === grid
    );

    let overrides: HistoryOverride[];
    if (overrideToDelete) {
      overrides = this.historyOverrides.filter(
        (histOverride) => histOverride.grid !== overrideToDelete.grid
      );
    }

    if (overrides) {
      this.historyOverrides = overrides;
    }

    const overridesToDelete = new HistoryOverride({
      itemKey: this.itemObject.key,
      itemName: this.itemObject.name,
    });

    return this.historyOverrideService.delete(overridesToDelete);
  }

  public cellClickHandler({
    sender,
    rowIndex,
    column,
    columnIndex,
    dataItem,
    isEdited,
  }) {
    if (!isEdited && !this.saveInProgress && column.field !== 'legend') {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      //store the original just in case we need to fall back.
      //Just like object.assign, this copies only properties,
      //arrays and nested objects are copied by reference.
      this.originalValue = _.clone(dataItem);
    }
  }

  public overrideHistory(): Observable<any> {
    if (!this.itemObject.useHistoryOverride) {
      // Ensure that history will override only when the toggle button is on
      return of(this.historyData);
    }

    this.historyOverrides$ = this.historyOverrideService.getHistoryOverride(
      this.itemObject.key
    );

    let items = lodash.cloneDeep(this.historyData);

    return this.historyOverrides$.pipe(
      map((overrides) => {
        overrides?.forEach((override) => {
          const year: string = override.grid.split('_')[1];
          const month: string = override.grid.split('_')[0].toLowerCase();
          const thatMonth: number = this.months.indexOf(month);

          let item: any;

          if (this.currentMonths.includes(thatMonth)) {
            item = items.find((i) => year === i.legend.split('-')[1]);
          } else if (this.prevMonths.includes(thatMonth)) {
            item = items.find((i) => year === i.legend.split('-')[0]);
          }

          if (item && (override?.orderQty || override?.orderQty === 0)) {
            item[month] = override.orderQty;
          }
        });

        return orderBy(items, this.sort);
      })
    );
  }

  toggleOverrides(forcedValue: boolean | null = null): void {
    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];

    if (forcedValue === null) {
      this.itemObject.useHistoryOverride = !this.itemObject.useHistoryOverride;
    } else {
      this.itemObject.useHistoryOverride = forcedValue;
    }
    this.gridItems$ = this.applyOverlay();

    this.itemObject.forecastDirty = true;

    this.saveItem$.next();
  }

  loadBackfillInfo(): void {
    this.backfillInfo = `You already have ${
      (this.historyData.length - 1) * 12 + 1
    } months worth of data. You do not need to backfill this item.`;
    this.isBackfillDisabled = true;

    const tempHistoryData = orderBy(this.trueHistoryData, [
      {
        field: 'legend',
        dir: 'desc',
      },
    ]);

    // Check if any rows has full data to determine whether backfill should be disabled
    let isRowFull = false;
    tempHistoryData.forEach((histories, idx, arr) => {
      if (isRowFull || idx === arr.length - 1) {
        return;
      }

      for (const property in histories) {
        if (!histories[property]) {
          this.backfillInfo =
            'You must have at least one full year worth of data for this item in order to use the Backfill feature.';
          this.isBackfillDisabled = true;
          return;
        }
      }

      isRowFull = true;
    });

    if (!isRowFull) {
      this.toggleBackfill(false);
      return;
    }

    this.handleHistoryData();

    if (this.isBackfillDisabled) {
      this.toggleBackfill(false);
    }
  }

  handleHistoryData(): void {
    // Check if item already has enough history data to determine whether backfill should be disabled
    this.historyData.forEach((history, index) => {
      if (index === 0 && history[this.months[this.lastMonth]]) {
        // Skip to next year
        return;
      }

      for (const property in history) {
        if (!history[property]) {
          this.backfillInfo =
            "Use current year's sales history for previous years to help find seasonality. Use this feature with caution.";
          this.isBackfillDisabled = false;
          return;
        }
      }
    });
  }

  toggleBackfill(forcedValue: boolean | null = null): void {
    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];

    if (forcedValue === null) {
      this.itemObject.useBackfill = !this.itemObject.useBackfill;
      this.gridItems$ = this.applyOverlay();
    } else {
      this.itemObject.useBackfill = forcedValue;
    }

    this.itemObject.forecastDirty = true;

    this.saveItem$.next();
  }

  applyOverlay(): Observable<any> {
    return forkJoin([this.overrideHistory(), this.lostSalesHistory()]).pipe(
      map(([overriddenData, lostSalesData]) => {
        let items = lodash.cloneDeep(this.historyData);

        this.resetAndInitColorMatrix();

        overriddenData = orderBy(overriddenData, [
          {
            field: 'legend',
            dir: 'desc',
          },
        ]);

        lostSalesData = orderBy(lostSalesData, [
          {
            field: 'legend',
            dir: 'desc',
          },
        ]);

        let currentYearHistory = items[items.length - 1];

        if (this.itemObject.useLostSalesOverride) {
          currentYearHistory = lostSalesData[0];
        }
        this.prepareDataForApplyingOverlay(currentYearHistory, overriddenData);

        this.applyOverlayBackfill(items, currentYearHistory);
        this.applyOverlayLostSales(lostSalesData, items);
        this.applyOverlayHistoryOverride(overriddenData, items);

        this.loadChartData(items);

        this.trueHistoryData = items;

        this.giveCopySource.emit(items);

        if (this.copyFeature) {
          this.historyData = items;
        }

        this.loadBackfillInfo();
        this.loadLostSalesInfo();

        return orderBy(items, this.sort);
      })
    );
  }

  loadLostSalesInfo(): void {
    this.lostSalesInfo =
      'You must have at least three months worth of item history for this item in order to use the LostSales feature.';
    this.isLostSalesDisabled = true;

    if (this.currentCompany.isLostSaleTracking) {
      this.lostSalesInfo =
        'Lost Sales can not be turned off while Lost Sales Tracking is on';
      this.toggleLostSales(true);
      return;
    }

    let count = 0;
    this.historyData.forEach((data) => {
      for (const property in data) {
        if (property === 'legend') {
          continue;
        }

        if (data[property]) {
          count++;
        }

        if (count >= 3) {
          break;
        }
      }
    });

    if (count < 3) {
      this.toggleLostSales(false);
      return;
    }

    this.lostSalesInfo =
      'Use item inventory history as well as forecast data to determine lost sales. Use this feature with caution.';
    this.isLostSalesDisabled = false;
  }

  lostSalesHistory(): Observable<any> {
    if (!this.itemObject.useLostSalesOverride) {
      return of(this.historyData);
    }

    this.lostSalesOverrides$ =
      this.lostSalesOverrideService.getLostSalesOverride(this.itemObject.key);

    let items = lodash.cloneDeep(this.historyData);

    return this.lostSalesOverrides$.pipe(
      map((overrides) => {
        overrides?.forEach((override) => {
          const year: string = override.grid.split('_')[1];
          const month: string = override.grid.split('_')[0].toLowerCase();
          const thatMonth: number = this.months.indexOf(month);

          let item: any;

          if (this.currentMonths.includes(thatMonth)) {
            item = items.find((item) => year === item.legend.split('-')[1]);
          } else if (this.prevMonths.includes(thatMonth)) {
            item = items.find((item) => year === item.legend.split('-')[0]);
          }

          if (override?.orderQty) {
            item[month] = override.orderQty;
          }
        });

        return orderBy(items, this.sort);
      })
    );
  }

  toggleLostSales(forcedValue: boolean | null = null): void {
    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];

    if (forcedValue === null) {
      this.itemObject.useLostSalesOverride =
        !this.itemObject.useLostSalesOverride;
      this.gridItems$ = this.applyOverlay();
    } else {
      this.itemObject.useLostSalesOverride = forcedValue;
    }

    this.itemObject.forecastDirty = true;

    this.saveItem$.next();
  }

  copyHistory(): void {
    const modalRef = this.modalService.open(CopyHistoryModalComponent, {
      size: 'xl' as string,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.currentCompany = this.currentCompany;
    modalRef.componentInstance.alreadyChoosenItem = this.itemObject;
    modalRef.componentInstance.copyHistoryCompleted.subscribe((copySource) => {
      this.grid.loading = true;
      this.saveInProgress = true;

      this.historyOverrideService
        .getHistoryOverride(this.itemObject.key)
        .subscribe((histOverride) => {
          this.historyOverrides = histOverride;

          const overridesToDelete = new HistoryOverride({
            itemKey: this.itemObject.key,
            itemName: this.itemObject.name,
          });

          const newOverrides: HistoryOverride[] =
            this.getNewHistoryOverrides(copySource);
          this.historyOverrideService
            .delete(overridesToDelete)
            .subscribe(() => {
              this.historyOverrideService
                .save(this.itemObject.key, newOverrides)
                .subscribe(() => {
                  this.grid.loading = false;
                  this.saveInProgress = false;

                  // If Overrides is turn off, turn it on
                  this.toggleOverrides(true);
                });
            });
        });
    });
  }

  getNewHistoryOverrides(copySource: any): HistoryOverride[] {
    let newOverrides: HistoryOverride[] = [];
    copySource.forEach((hist) => {
      for (const property in hist) {
        if (property === 'legend') {
          continue;
        }
        if (!hist[property] && hist[property] !== 0) {
          continue;
        }

        const override = new HistoryOverride();
        override.itemKey = this.itemObject.key;
        override.itemName = this.itemObject.name;
        override.orderQty = hist[property];
        const thatMonth = this.months.indexOf(property);
        let grid: string;
        let gridYear: number;

        if (this.currentMonths.includes(thatMonth)) {
          gridYear = parseInt(hist.legend.split('-')[1]);
          grid = `${property[0].toUpperCase()}${property.slice(1)}_${gridYear}`;
        } else if (this.prevMonths.includes(thatMonth)) {
          gridYear = parseInt(hist.legend.split('-')[0]);
          grid = `${property[0].toUpperCase()}${property.slice(1)}_${gridYear}`;
        }
        override.grid = grid;
        override.start = Date.UTC(gridYear, thatMonth, 15);
        override.forecastKey = 'm';
        newOverrides.push(override);
      }
    });

    this.historyOverrides.forEach((histOverride) => {
      const sameGridOverride = newOverrides.find(
        (override) => override.grid === histOverride.grid
      );
      if (!sameGridOverride) {
        newOverrides.push(histOverride);
      }
    });

    return newOverrides;
  }

  processColor(feature: string = null): string {
    switch (feature) {
      case 'override':
        return '#F6C05E';
      case 'backfill':
        return '#92C5E6';
      case 'copyedit':
        return '#E13129';
      case 'lostSales':
        return '#E0324FE1';
      default:
        return '#3498DB';
    }
  }

  resetAndInitColorMatrix(): void {
    this.colorMatrix = [];

    for (let i = 0; i < 4; i++) {
      this.colorMatrix.push([]);

      for (let j = 0; j < 12; j++) {
        this.colorMatrix[i].push('normal');
      }
    }
  }

  saveAndRemindToRunForecast(): Observable<any> {
    if (this.isSaving || this.saveItemCount < 2) {
      this.saveItemCount++;
      return of();
    }

    this.isSaving = true;
    return this.companyService
      .updateCompanyInfo({
        ...this.currentCompany,
        willRemindToRunForecast: true,
      })
      .pipe(
        this.autoCleanUp(),
        tap(() => this.headerService.notifyForecastSubject.next(true)),
        finalize(() => (this.isSaving = false))
      );
  }

  saveItem() {
    return this.itemService.save(this.itemObject, 'key');
  }

  downloadInitialTemplate() {
    this.syncService
      .downloadInitialTemplate('history-override')
      .pipe(this.autoCleanUp(), first())
      .subscribe((res) => {
        const headers: HttpHeaders = res.headers;

        const dispostion: string = headers.get('Content-Disposition') || '';
        const matches = /filename=([^;]+)/gi.exec(dispostion);
        const fileName = (matches[1] || 'untitled')
          .replace('"', '')
          .replace('"', '')
          .trim();

        const a = document.createElement('a');
        const blob = res.body;
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  loadCsvboxImporter(): void {
    this.importer = new CSVBoxImporter(
      environment.csvboxLicenseKeys.asc.historyOverride,
      {},
      () => {}
    );

    const dynamicColumns = [];
    this.isAscCompany
      ? dynamicColumns.push({
          column_name: 'SKU',
          matching_keywords: 'SKU',
          type: 'text',
          info_hint:
            'All items are required to be supplied with a SKU for the Multiple Item option, but only one item is needed for the Single Item option',
        })
      : dynamicColumns.push({
          column_name: 'Item Key',
          matching_keywords: 'Item Key',
          type: 'text',
          info_hint:
            'All items are required to be supplied with an Item Key for the Multiple Item option, but only one item is needed for the Single Item option',
        });
    this.importer.setDynamicColumns(dynamicColumns);

    this.importer.listen('onSubmit', () => {
      this.grid.loading = true;
    });
  }

  processFileNotification(data): void {
    if (data.key !== NotificationKey.csvStatus) {
      return;
    }

    switch (data.type) {
      case 'close':
        Swal.fire({
          html: `
            <div >
              Upload file successfully
            </div>
          `,
          type: 'success',
          showCloseButton: false,
          allowOutsideClick: false,
          cancelButtonText: 'Undo Last Upload',
          reverseButtons: true,
          showCancelButton: true,
        }).then((res) => {
          if (res?.value) {
            this.calculateUndoLeftTime()
              .pipe(
                this.autoCleanUp(),
                switchMap(() => this.itemService.getById(this.itemObject.key)),
                tap((item) => {
                  this.itemObject = item;
                  this.uploadCompleted.emit();
                })
              )
              .subscribe();
          }

          if (res?.dismiss) {
            this.undoLastUpload();
          }
        });

        this.grid.loading = false;
        break;
      case 'notice':
        if (!this.isImportFailModalOpen) {
          this.isImportFailModalOpen = true;

          const isCsvFile = data.req.body.fileName.includes('.csv');
          if (!isCsvFile) {
            data.msg = {
              ...data.msg,
              error: ['Invalid file type. Please import the CSV file.'],
            };
          }

          this.modalService.dismissAll();
          this.altModalService.open(ImportFailModalComponent, {
            data: { message: data },
            backdrop: 'static',
            keyboard: false,
            onError: () => {
              this.isImportFailModalOpen = false;
            },
          });
          this.calculateUndoLeftTime()
            .pipe(
              this.autoCleanUp(),
              switchMap(() => this.itemService.getById(this.itemObject.key)),
              tap((item) => {
                this.itemObject = item;
                this.uploadCompleted.emit();
              })
            )
            .subscribe();
        }

        this.grid.loading = false;
        break;
    }
  }

  openImporter(
    behaviorType: UploadHistoryBehaviorType,
    amountType: UploadHistoryAmountType
  ) {
    this.importer.setUser({
      user_id: this.authService.currentUser.userId,
      companyType: this.currentCompany.companyType,
      companyKey: this.currentCompany.companyKey,
      isAdmin: this.authService.currentUser.isAdmin,
      fileOptions: btoa(
        JSON.stringify({
          fileType: 'historyOverride',
          isCreateNew: true,
          uploadHistoryBehaviorType: behaviorType,
          uploadHistoryAmountType: amountType,
        })
      ),
    });
    this.importer.openModal();
  }

  undoLastUpload() {
    this.grid.loading = true;

    this.syncService
      .undoLastUpload('history-override')
      .pipe(
        this.autoCleanUp(),
        tap(
          () => {
            this.remainingUndoTime = 0;
            this.isShowUndoToast = true;
          },
          () => (this.grid.loading = false)
        )
      )
      .subscribe();
  }

  private constructHistoryValue(
    aggregations: DemandAggregation[],
    index: number,
    currentYear: number,
    prevYear: number
  ) {
    let isExist = false;
    const value = aggregations?.reduce((a, current) => {
      const dueDate = new Date(current.dueDate);
      const isCurrentOrPreYear =
        (dueDate.getFullYear() === currentYear &&
          this.currentMonths.includes(index)) ||
        (dueDate.getFullYear() === prevYear && this.prevMonths.includes(index));
      if (dueDate.getMonth() === index && isCurrentOrPreYear) {
        isExist = true;
        return a + current.orderQty;
      }
      return a;
    }, 0);

    return { isExist, value };
  }

  private prepareDataForApplyingOverlay(currentYearHistory, overriddenData) {
    if (this.itemObject.useHistoryOverride) {
      for (const property in currentYearHistory) {
        if (property === 'legend') {
          continue;
        }

        if (
          overriddenData[0][property] !==
          this.historyData[this.historyData.length - 1][property]
        ) {
          currentYearHistory[property] = overriddenData[0][property];
        }
      }
    }
  }

  private applyOverlayBackfill(items, currentYearHistory) {
    if (!this.itemObject.useBackfill) {
      return;
    }

    items.forEach((item, index, arr) => {
      if (index === 0) {
        if (!item[this.months[this.lastMonth]]) {
          item[this.months[this.lastMonth]] =
            currentYearHistory[this.months[this.lastMonth]] || 0;

          this.colorMatrix[this.legends.length - 1 - index][11] = 'backfill';
        }
        return;
      }
      if (index === arr.length - 1) {
        if (!item[this.months[this.lastMonth]]) {
          item[this.months[this.lastMonth]] =
            currentYearHistory[this.months[this.lastMonth]] || 0;

          this.colorMatrix[this.legends.length - 1 - index][11] = 'backfill';
        }
        return;
      }

      this.applyOverlayBackfillSupport(item, currentYearHistory, index);
    });
  }

  private applyOverlayBackfillSupport(item, currentYearHistory, index) {
    for (let m = 0; m < 12; m++) {
      if (item[this.months[m]]) {
        return;
      }

      item[this.months[m]] = currentYearHistory[this.months[m]] || 0;

      let columnIndex = m - (this.lastMonth + 1);
      if (columnIndex < 0) {
        columnIndex += 12;
      }

      this.colorMatrix[this.legends.length - 1 - index][columnIndex] =
        'backfill';
    }
  }

  private applyOverlayLostSales(lostSalesData, items) {
    if (!this.itemObject.useLostSalesOverride) {
      return;
    }

    lostSalesData.forEach((lostSales, index) => {
      for (const property in lostSales) {
        if (property === 'legend') {
          continue;
        }

        if (
          (lostSales[property] || lostSales[property] === 0) &&
          lostSales[property] !==
            this.historyData[this.historyData.length - 1 - index][property]
        ) {
          items[items.length - 1 - index][property] = lostSales[property];

          let columnIndex =
            this.months.indexOf(property) - (this.lastMonth + 1);
          if (columnIndex < 0) {
            columnIndex += 12;
          }
          this.colorMatrix[index][columnIndex] = 'lostSales';
        }
      }
    });
  }

  private applyOverlayHistoryOverride(overriddenData, items) {
    if (!this.itemObject.useHistoryOverride) {
      return;
    }

    overriddenData.forEach((overridden, index) => {
      for (const property in overridden) {
        if (property === 'legend') {
          continue;
        }

        if (
          (overridden[property] || overridden[property] === 0) &&
          overridden[property] !==
            this.historyData[this.historyData.length - 1 - index][property]
        ) {
          items[items.length - 1 - index][property] = overridden[property];

          let columnIndex =
            this.months.indexOf(property) - (this.lastMonth + 1);
          if (columnIndex < 0) {
            columnIndex += 12;
          }
          this.colorMatrix[index][columnIndex] = 'override';
        }
      }
    });
  }

  private calculateUndoLeftTime() {
    return this.companyService.getNewById(this.currentCompany.companyKey).pipe(
      this.autoCleanUp(),
      tap((company) => {
        this.currentCompany = company;
        this.remainingUndoTime =
          HOURS_IN_DAY -
          moment().diff(company.historyOverrideLastUploadDate, 'hours');
      })
    );
  }

  private processUndoNotification(data) {
    if (
      data.type !== NotificationType.close ||
      data.key !== NotificationKey.undoUploadStatus
    ) {
      return;
    }

    if (this.isShowUndoToast) {
      if (this.currentToast) {
        this.snotifyService.remove(this.currentToast.id);
      }

      this.currentToast = this.snotifyService
        .success('Undo upload successfully')
        .on('mounted', () => {
          this.isShowUndoToast = false;
        });
    }

    this.remainingUndoTime = 0;
    this.gridItems$ = this.applyOverlay();
    this.grid.loading = false;
  }

  private handleInitialPusher() {
    this.pusherService.userChannel.bind('notification', (data) => {
      this.processFileNotification(data);
      this.processUndoNotification(data);
    });
  }
}
