import { addTextUnitToNumber } from '../../../../core/utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataAddTextUnitToNumber'
})
export class DataAddTextUnitToNumberPipe implements PipeTransform {
  transform(value: number, unit: string, isItemHistory?: boolean): any {
    return addTextUnitToNumber(value, unit, isItemHistory);
  }
}
