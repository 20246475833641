<div class="grid-view-combobox">
  <button
    class="btn btn-success btn-sm mx-1"
    title="Save Grid View"
    (click)="saveGridView()"
    [disabled]="isLoading || !selectedGridView"
  >
    <i class="feather icon-save"></i>
  </button>
  <button
    class="btn btn-danger btn-sm mx-1"
    title="Delete Grid View"
    (click)="deleteGridView()"
    [disabled]="
      isLoading || !selectedGridView?.key || selectedGridView.isDefault
    "
  >
    <i class="feather icon-trash"></i>
  </button>

  <kendo-combobox
    #comboBox
    [data]="gridViews"
    textField="name"
    valueField="key"
    placeholder="Grid Layout"
    [loading]="isLoading"
    [filterable]="true"
    [allowCustom]="true"
    [valueNormalizer]="valueNormalizer"
    [virtual]="{ itemHeight: 30 }"
    (filterChange)="handleFilterChange($event)"
    (valueChange)="handleValueChange($event)"
    [value]="selectedGridView"
  ></kendo-combobox>
</div>
