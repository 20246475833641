
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource-service';
import { VendorSalesVelocitySettings } from '../models/vendor';
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';

@Injectable ({
  providedIn: 'root',
})

export class VendorSalesVelocitySettingsService extends ResourceService<VendorSalesVelocitySettings>{
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/vendor-sales-velocity-settings');
  }

  getByIdWithType(
    itemKey: string,
    salesVelocitySettingsType: SalesVelocitySettingsType = SalesVelocitySettingsType.restockAMZ
  ): Observable<VendorSalesVelocitySettings> {
    return this.httpClient.get<VendorSalesVelocitySettings>(
      `${this.apiUrl}/${encodeURIComponent(itemKey)}/${salesVelocitySettingsType}`
    );
  }
}
