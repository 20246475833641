export class ItemSite {
  key: string = '';
  itemKey: string = '';
  warehouseName: string = '';
  itemName: string = '';
  onHand: number = 0;

  constructor(init?: Partial<ItemSite>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
