export enum NotificationKey {
  summaryStatus = 'summaryStatus',
  mwsSyncStart = 'mwsSyncStart',
  spReportStart = 'spReportStart',
  csvStart = 'csvStart',
  csvStatus = 'csvStatus',
  undoUploadStart = 'undoUploadStart',
  undoUploadStatus = 'undoUploadStatus',
  downloadPdfStart = 'downloadPdfStart',
  downloadPdfStatus = 'downloadPdfStatus',
}

export enum NotificationType {
  header = 'header',
  message = 'message',
  notice = 'notice',
  close = 'close'
}
