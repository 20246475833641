import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, from, Subject } from 'rxjs';
import {
  switchMap,
  map,
  finalize,
  tap,
  first,
  debounceTime,
} from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';

import { Item } from 'src/app/core/models/item';
import { DemandAggregation } from 'src/app/core/models/demand-aggregation';
import { HistoryOverride } from 'src/app/core/models/history-override';
import { LostSalesOverride } from 'src/app/core/models/lostsales-override';
import { Company } from 'src/app/core/models/company';

import { ItemService } from 'src/app/core/services/item.service';
import { DemandAggregationService } from 'src/app/core/services/demand-aggregation.service';
import { HistoryOverrideService } from 'src/app/core/services/history-override.service';
import { PurchaseAsService } from 'src/app/core/services/purchase-as.service';
import { LostSalesOverrideService } from 'src/app/core/services/lostsales-override.service';

import lodash from 'lodash';
import {
  getAdditionalDemandSources,
  HistoryTabs,
  REFRESH_TOOLTIP,
} from './data-display-name';
import { PERIOD } from 'src/app/core/constants/item-history.constant';
import { getLostSalesDisabled } from 'src/app/core/utils';
import jspdf from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { ResultService } from 'src/app/core/services/result.service';
import { Result } from 'src/app/core/models/result';
import { CompanyService } from 'src/app/core/services/company.service';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { SyncService } from 'src/app/core/services/sync.service';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PusherService } from 'src/app/core/services/pusher.service';
import Swal from 'sweetalert2';
import { AuthenticationService, ModalService } from '@stockaid/services';
import { ImportFailModalComponent } from '../import-fail-modal/import-fail-modal.component';
import {
  UploadHistoryAmountType,
  UploadHistoryBehaviorType,
} from 'src/app/core/infrastructure/enums/upload-history-type.enum';
import moment from 'moment';
import {
  NotificationKey,
  NotificationType,
} from 'src/app/core/infrastructure/enums/notification.enum';
import { SnotifyService } from 'ng-snotify';
import { HOURS_IN_DAY } from 'src/app/core/constants/misc.constant';

declare const CSVBoxImporter: any;

@Component({
  selector: 'app-view-of-history',
  templateUrl: './view-of-history.component.html',
  styleUrls: ['./view-of-history.component.scss'],
})
export class ViewOfHistoryComponent extends BaseComponent implements OnInit {
  @Input() choosenItem: Item;
  @Input() currentCompany: Company;
  @ViewChild('nav') nav;
  @ViewChild('view') view: HTMLElement;
  @ViewChild('historyView') historyView: ElementRef;

  isLoading = false;
  columns: any[] = [];
  lastMonth: number;
  gridItems$: Observable<any>;
  directDemandAggregations$: Observable<DemandAggregation[]>;
  displayName = [
    'Date Range',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  months: any[] = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
  ];
  reversedAccurateMonths: string[] = [];
  sort: SortDescriptor[] = [
    {
      field: 'legend',
      dir: 'desc',
    },
  ];
  result: Result;
  chartData: any[] = [];
  historyOverrides$: Observable<HistoryOverride[]>;
  lostSalesOverrides$: Observable<LostSalesOverride[]>;
  historyData: any[];
  trueHistoryData: any[];
  kitHistoryData: any[] = [];
  totalHistoryData: any[] = [];
  legends: string[] = [];
  prevMonths = [];
  currentMonths = [];
  historyOverrides: HistoryOverride[];
  isOverrideDisabled = false;
  isBackfillDisabled = true;
  isSwitchVisible = true;
  backfillInfo: string;
  overrideInfo =
    'The Apply Override option allows you to modify existing data points or add missing data points to your historic sales data for any given month. When enabled, those overridden data points will be used when performing forecast and purchase calculations moving forward.';
  isLostSalesDisabled: boolean = true;
  lostSalesInfo: string;
  colorMatrix: string[][] = [];
  kitColorMatrix: string[][] = [];
  parentKey: string;
  additionalItems: Item[] = [];
  additionalColumns: any[];
  currentTab = HistoryTabs.DirectSales;

  // Export to PDF variables
  currentTabsExport = [];
  currentImagesExport = [];
  pdf: jspdf;
  canvas: HTMLCanvasElement;
  isLoadingAdd: boolean;
  isDisabledAdd: boolean;
  isSaving: boolean = false;
  saveItemCount: number = 0;
  importer: any;
  isImportFailModalOpen = false;
  isDirectSalesEmpty = false;
  saveItem$ = new Subject<any>();
  remainingUndoTime: number = 0;
  isShowUndoToast: boolean = false;
  currentToast: any;

  readonly HistoryTabs = HistoryTabs;
  readonly REFRESH_TOOLTIP = REFRESH_TOOLTIP;
  readonly UploadHistoryBehaviorType = UploadHistoryBehaviorType;
  readonly UploadHistoryAmountType = UploadHistoryAmountType;

  get isLostSalesSwitchDisabled(): boolean {
    return getLostSalesDisabled(
      this.isLostSalesDisabled,
      this.choosenItem.useLostSalesOverride,
      this.currentCompany.isLostSaleTracking
    );
  }

  get isAscCompany(): boolean {
    return this.currentCompany?.companyType === CompanyType.ASC;
  }

  private saveInProgress: boolean = false;
  public originalValue: any;

  constructor(
    public activeModal: NgbActiveModal,
    private snotifyService: SnotifyService,
    private demandAggregationService: DemandAggregationService,
    private formBuilder: FormBuilder,
    private itemService: ItemService,
    private historyOverrideService: HistoryOverrideService,
    private lostSalesOverrideService: LostSalesOverrideService,
    private purchaseAsService: PurchaseAsService,
    private companyService: CompanyService,
    private resultService: ResultService,
    private syncService: SyncService,
    private pusherService: PusherService,
    private altModalService: ModalService,
    private authService: AuthenticationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.saveItem$
      .pipe(
        this.autoCleanUp(),
        debounceTime(200),
        switchMap(() => this.saveItem()),
        switchMap(() => this.saveAndRemindToRunForecast())
      )
      .subscribe();

    this.loadCsvboxImporter();
    this.calculateUndoLeftTime().subscribe();
    this.handleInitialPusher();

    // Include legend so 0 is 'Legend', 1 is 'Jan', ...
    const currentMonth = new Date().getMonth();
    let accurateHeaders = [];
    accurateHeaders.push(this.displayName[0]);
    accurateHeaders.push(...this.displayName.slice(currentMonth + 1));
    accurateHeaders.push(...this.displayName.slice(1, currentMonth + 1));
    accurateHeaders.forEach((name, idx) => {
      this.columns.push({
        field: idx === 0 ? 'legend' : name.toLowerCase(),
        displayName: name,
      });
      this.reversedAccurateMonths.push(name.toLowerCase());
    });

    this.reversedAccurateMonths.shift();
    this.reversedAccurateMonths.reverse();

    const thisYear = new Date().getFullYear();
    for (let year = thisYear - PERIOD; year < thisYear; year++) {
      this.legends.push(`${year}-${year + 1}`);
    }

    const lastMonthOfCurrentYear = new Date().getMonth() - 1;

    this.lastMonth = new Date().getMonth() - 1;
    this.lastMonth = this.lastMonth < 0 ? this.lastMonth + 12 : this.lastMonth;

    for (let m = 0; m <= lastMonthOfCurrentYear; m++) {
      this.currentMonths.push(m);
    }
    for (let m = lastMonthOfCurrentYear + 1; m < 12; m++) {
      this.prevMonths.push(m);
    }

    this.resetAndInitColorMatrix();

    this.additionalColumns = getAdditionalDemandSources();

    this.directDemandAggregations$ =
      this.demandAggregationService.getDemandAggregation(this.choosenItem.key);

    forkJoin([
      this.purchaseAsService.getItems(this.choosenItem.key),
      this.resultService.getResult(this.choosenItem.key),
    ])
      .pipe(this.autoCleanUp())
      .subscribe(([additionalItems, result]) => {
        this.additionalItems = additionalItems;
        this.result = result;

        this.loadDirectAggregations();
      });
  }

  changeTab(tab: HistoryTabs): void {
    if (tab === this.currentTab) {
      return;
    }

    this.currentTab = tab;

    this.isSwitchVisible = true;

    if (tab !== HistoryTabs.DirectSales) {
      this.isOverrideDisabled = true;
      this.overrideInfo = 'You cannot apply the backfill to the parent item.';

      this.isBackfillDisabled = true;
      this.backfillInfo = 'You cannot apply the backfill to the parent item.';

      if (tab === HistoryTabs.KitSales) {
        this.isSwitchVisible = false;
        this.loadKitAggregations();
      }

      if (tab === HistoryTabs.TotalSales) {
        this.isSwitchVisible = false;
        this.loadTotalAggregations();
      }
    } else {
      this.isOverrideDisabled = false;
      this.overrideInfo =
        'The Apply Override option allows you to modify existing data points or add missing data points to your historic sales data for any given month. When enabled, those overridden data points will be used when performing forecast and purchase calculations moving forward.';

      this.loadBackfillInfo();

      this.loadDirectAggregations();
    }

    this.checkDisableAdd();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (!sort[0].dir) {
      return;
    }

    switch (this.currentTab) {
      case HistoryTabs.DirectSales:
        this.gridItems$ = of(orderBy(this.trueHistoryData || [], sort));
        break;
      case HistoryTabs.KitSales:
        this.gridItems$ = of(orderBy(this.kitHistoryData || [], sort));
        break;
      case HistoryTabs.TotalSales:
        this.gridItems$ = of(orderBy(this.totalHistoryData || [], sort));
        break;
    }

    this.colorMatrix.reverse();
    this.kitColorMatrix.reverse();
  }

  cellCloseHandler(args: any): void {
    const { formGroup, dataItem, column } = args;

    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      Object.assign(dataItem, formGroup.value);

      this.historyOverrideService
        .getHistoryOverride(this.choosenItem.key)
        .pipe(this.autoCleanUp())
        .subscribe((histOverrides) => {
          this.historyOverrides = histOverrides;

          const sameLegendHist = this.historyData.find(
            (history) => dataItem.legend === history.legend
          );
          const sameLegendHistory = lodash.cloneDeep(sameLegendHist);

          const deleteObservable$ = this.deleteOverride(
            column,
            dataItem.legend
          );

          this.isLoading = true;
          this.saveInProgress = true;

          // add old history overrides
          let overrides: HistoryOverride[] = [];
          this.historyOverrides.forEach((histOverride) => {
            const override = new HistoryOverride();
            override.itemKey = histOverride.itemKey;
            override.itemName = histOverride.itemName;
            override.orderQty = histOverride.orderQty;
            override.grid = histOverride.grid;
            override.forecastKey = histOverride.forecastKey;
            override.start = histOverride.start;

            overrides.push(override);
          });

          // add new history override
          if (
            sameLegendHistory[column.field] !== dataItem[column.field] &&
            (dataItem[column.field] || dataItem[column.field] === 0)
          ) {
            const override = new HistoryOverride();
            override.itemKey = this.choosenItem.key;
            override.itemName = this.choosenItem.name;
            override.orderQty = dataItem[column.field];
            const thatMonth = this.months.indexOf(column.field);
            let grid: string;
            let gridYear: number;

            if (this.currentMonths.includes(thatMonth)) {
              gridYear = parseInt(dataItem.legend.split('-')[1]);
              grid = `${column.field[0].toUpperCase()}${column.field.slice(
                1
              )}_${gridYear}`;
            } else if (this.prevMonths.includes(thatMonth)) {
              gridYear = parseInt(dataItem.legend.split('-')[0]);
              grid = `${column.field[0].toUpperCase()}${column.field.slice(
                1
              )}_${gridYear}`;
            }
            override.grid = grid;
            override.start = Date.UTC(gridYear, thatMonth, 15);
            override.forecastKey = 'm';
            overrides.push(override);
          }

          deleteObservable$.pipe(this.autoCleanUp()).subscribe(() => {
            this.historyOverrideService
              .save(this.choosenItem.key, overrides)
              .subscribe(() => {
                this.isLoading = false;
                this.saveInProgress = false;

                // If Overrides is turn off, turn it on
                this.toggleOverrides(true);
              });
          });
        });
    }
  }

  cellClickHandler({
    sender,
    rowIndex,
    column,
    columnIndex,
    dataItem,
    isEdited,
  }) {
    if (!isEdited && !this.saveInProgress && column.field !== 'legend') {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
      //store the original just in case we need to fall back.
      //Just like object.assign, this copies only properties,
      //arrays and nested objects are copied by reference.
      this.originalValue = lodash.cloneDeep(dataItem);
    }
  }

  toggleOverrides(forcedValue: boolean | null = null): void {
    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];

    if (forcedValue === null) {
      this.choosenItem.useHistoryOverride =
        !this.choosenItem.useHistoryOverride;
    } else {
      this.choosenItem.useHistoryOverride = forcedValue;
    }

    this.gridItems$ = this.applyOverlay();

    this.choosenItem.forecastDirty = true;
    this.saveItem$.next();
  }

  toggleBackfill(forcedValue: boolean | null = null): void {
    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];

    if (forcedValue === null) {
      this.choosenItem.useBackfill = !this.choosenItem.useBackfill;
      this.gridItems$ = this.applyOverlay();
    } else {
      this.choosenItem.useBackfill = forcedValue;
    }

    this.choosenItem.forecastDirty = true;
    this.saveItem$.next();
  }

  toggleLostSales(forcedValue: boolean | null = null): void {
    this.sort = [
      {
        field: 'legend',
        dir: 'desc',
      },
    ];

    if (forcedValue === null) {
      this.choosenItem.useLostSalesOverride =
        !this.choosenItem.useLostSalesOverride;
      this.gridItems$ = this.applyOverlay();
    } else {
      this.choosenItem.useLostSalesOverride = forcedValue;
    }

    this.choosenItem.forecastDirty = true;
    this.saveItem$.next();
  }

  private loadDirectAggregations(): void {
    this.isLoading = true;

    this.isOverrideDisabled = false;

    let isNull = true;
    this.gridItems$ = this.directDemandAggregations$.pipe(
      switchMap((aggregations) => {
        let items: any;
        [items, isNull] = this.calcHistory(aggregations, isNull);
        this.isDirectSalesEmpty = isNull;

        setTimeout(() => (this.isLoading = false));

        this.loadChartData(items);

        this.historyData = lodash.cloneDeep(items);
        this.trueHistoryData = items;

        return this.applyOverlay();
      })
    );
  }

  private initKitAggregationData(): void {
    this.kitHistoryData = [];
    this.legends.forEach((legend) => {
      let item: any = {};
      item.legend = legend;
      this.months.forEach((month) => (item[month] = null));
      this.kitHistoryData.push(item);
    });
  }

  private loadKitAggregationData(): Observable<boolean> {
    let historyIndex = this.result?.settingsSources?.bomHistory?.length - 1;
    this.kitHistoryData
      .slice()
      .reverse()
      .forEach((history) => {
        this.reversedAccurateMonths.forEach((month) => {
          if (historyIndex < 0) {
            history[month] = null;
            return;
          }
          history[month] =
            this.result?.settingsSources?.bomHistory?.[historyIndex];
          --historyIndex;
        });
      });
    return of(!this.result?.settingsSources?.bomHistory?.length);
  }

  private loadKitAggregations(): void {
    this.initKitColorMatrix();

    this.isLoading = true;
    this.initKitAggregationData();

    this.loadKitAggregationData().subscribe((updatedIsNull) => {
      this.isLoading = false;

      this.loadChartData(this.kitHistoryData);
      if (updatedIsNull) {
        this.gridItems$ = of(null);

        return;
      }
      this.gridItems$ = of(
        orderBy(this.kitHistoryData || [], [
          {
            field: 'legend',
            dir: 'desc',
          },
        ])
      );
    });
  }

  private loadTotalAggregations(): void {
    this.initKitColorMatrix();
    this.isLoading = true;

    this.totalHistoryData = [];
    this.legends.forEach((legend) => {
      let item: any = {};
      item.legend = legend;
      this.months.forEach((month) => (item[month] = null));
      this.totalHistoryData.push(item);
    });

    let historyIndex = this.result?.settingsSources?.bomHistory?.length - 1;
    this.totalHistoryData
      .slice()
      .reverse()
      .forEach((history) => {
        const trueHistoryOfYear = this.trueHistoryData?.find(
          (h) => h.legend === history.legend
        );
        this.reversedAccurateMonths.forEach((month) => {
          if (historyIndex < 0) {
            history[month] = trueHistoryOfYear[month] || 0;
            return;
          }
          history[month] =
            this.result?.settingsSources?.bomHistory?.[historyIndex] +
            (trueHistoryOfYear[month] || 0);
          --historyIndex;
        });
      });
    this.removeTailingZero(this.totalHistoryData);

    this.loadChartData(this.totalHistoryData);
    this.isLoading = false;

    if (
      this.isDirectSalesEmpty &&
      !this.result?.settingsSources?.bomHistory?.length
    ) {
      this.gridItems$ = of(null);

      return;
    }

    this.gridItems$ = of(
      orderBy(this.totalHistoryData || [], [
        {
          field: 'legend',
          dir: 'desc',
        },
      ])
    );
  }

  private calcHistory(aggregations: DemandAggregation[], isNull: boolean): any {
    let items = [];
    this.legends.forEach((legend) => {
      const item: any = {};
      const currentYear = Number.parseInt(legend.split('-')[1]);
      const prevYear = Number.parseInt(legend.split('-')[0]);
      item.legend = legend;
      this.months.forEach((month, index) => {
        let isExist = false;
        let value;

        if (aggregations) {
          value = aggregations.reduce((a, current) => {
            const dueDate = new Date(current.dueDate);
            const isCurrentOrPreYear =
              (dueDate.getFullYear() === currentYear &&
                this.currentMonths.includes(index)) ||
              (dueDate.getFullYear() === prevYear &&
                this.prevMonths.includes(index));
            if (dueDate.getMonth() === index && isCurrentOrPreYear) {
              isExist = true;
              isNull = false;
              return a + current.orderQty;
            }
            return a;
          }, 0);
        }

        if (!isExist) {
          value = null;
        }

        item[month] = value;
      });

      items.push(item);
    });

    return [items, isNull];
  }

  private loadChartData(items: any[]): void {
    this.chartData = items.map((history) => {
      let result = [];
      for (const property in history) {
        if (property === 'legend') {
          continue;
        }
        result.push({
          qty: history[property],
          month: `${property[0].toUpperCase()}${property.slice(1)}`,
        });
      }

      const currentMonth = new Date().getMonth();
      const accurateDataOrder = [];
      accurateDataOrder.push(...result.slice(currentMonth));
      accurateDataOrder.push(...result.slice(0, currentMonth));
      return accurateDataOrder;
    });
  }

  private createFormGroup(dataItem: any): FormGroup {
    const formGroup = new FormGroup({});

    this.columns.forEach((col) => {
      formGroup.addControl(
        col.field,
        this.formBuilder.control(0, [
          Validators.min(0),
          Validators.max(Number.MAX_SAFE_INTEGER),
        ])
      );
    });
    formGroup.patchValue(dataItem);
    return formGroup;
  }

  private deleteOverride(column: any, legend: string): Observable<any> {
    let overrideToDelete: HistoryOverride;
    const thatMonth = this.months.indexOf(column.field);
    let grid: string;

    if (this.currentMonths.includes(thatMonth)) {
      grid = `${column.field[0].toUpperCase()}${column.field.slice(1)}_${
        legend.split('-')[1]
      }`;
    } else if (this.prevMonths.includes(thatMonth)) {
      grid = `${column.field[0].toUpperCase()}${column.field.slice(1)}_${
        legend.split('-')[0]
      }`;
    }

    overrideToDelete = this.historyOverrides.find(
      (histOverride) => histOverride.grid === grid
    );
    let overrides: HistoryOverride[];

    if (overrideToDelete) {
      overrides = this.historyOverrides.filter(
        (histOverride) => histOverride.grid !== overrideToDelete.grid
      );
    }

    if (overrides) {
      this.historyOverrides = overrides;
    }

    const overridesToDelete = new HistoryOverride({
      itemKey: this.choosenItem.key,
      itemName: this.choosenItem.name,
    });

    return this.historyOverrideService.delete(overridesToDelete);
  }

  private overrideHistory(): Observable<any> {
    if (!this.choosenItem.useHistoryOverride) {
      // Ensure that history will override only when the toggle button is on
      return of(this.historyData);
    }

    this.historyOverrides$ = this.historyOverrideService.getHistoryOverride(
      this.choosenItem.key
    );

    let items = lodash.cloneDeep(this.historyData);

    return this.historyOverrides$.pipe(
      map((overrides) => {
        overrides?.forEach((override) => {
          const year: string = override.grid.split('_')[1];
          const month: string = override.grid.split('_')[0].toLowerCase();
          const thatMonth: number = this.months.indexOf(month);

          let item: any;

          if (this.currentMonths.includes(thatMonth)) {
            item = items.find((i) => year === i.legend.split('-')[1]);
          } else if (this.prevMonths.includes(thatMonth)) {
            item = items.find((i) => year === i.legend.split('-')[0]);
          }

          if (item && (override?.orderQty || override?.orderQty === 0)) {
            item[month] = override.orderQty;
          }
        });
        return orderBy(items || [], this.sort);
      })
    );
  }

  private loadBackfillInfo(): void {
    this.backfillInfo = `You already have ${
      (this.historyData.length - 1) * 12 + 1
    } months worth of data. You do not need to backfill this item.`;
    this.isBackfillDisabled = true;

    const tempHistoryData = orderBy(this.trueHistoryData || [], [
      {
        field: 'legend',
        dir: 'desc',
      },
    ]);

    // Check if any rows has full data to determine whether backfill should be disabled
    const isRowFull = this.handleIsBackfillRowFull(tempHistoryData);
    if (!isRowFull) {
      this.toggleBackfill(false);
      return;
    }

    // Check if item already has enough history data to determine whether backfill should be disabled
    this.handleBackfillValid();

    if (this.isBackfillDisabled) {
      this.toggleBackfill(false);
    }
  }

  private loadLostSalesInfo(): void {
    this.lostSalesInfo =
      'You must have at least three months worth of item history for this item in order to use the LostSales feature.';
    this.isLostSalesDisabled = true;

    if (this.currentCompany.isLostSaleTracking) {
      this.lostSalesInfo =
        'Lost Sales can not be disabled while Lost Sales Tracking is on';
      this.isLostSalesDisabled = true;
      this.toggleLostSales(true);
      return;
    }

    let count = 0;
    this.historyData.forEach((data) => {
      for (const property in data) {
        if (property === 'legend') {
          continue;
        }

        if (data[property]) {
          count++;
        }

        if (count >= 3) {
          break;
        }
      }
    });

    if (count < 3) {
      this.toggleLostSales(false);
      return;
    }

    this.lostSalesInfo =
      'Use item inventory history as well as forecast data to determine lost sales. Use this feature with caution.';
    this.isLostSalesDisabled = false;
  }

  private lostSalesHistory(): Observable<any> {
    if (!this.choosenItem.useLostSalesOverride) {
      return of(this.historyData);
    }

    this.lostSalesOverrides$ =
      this.lostSalesOverrideService.getLostSalesOverride(this.choosenItem.key);

    let items = lodash.cloneDeep(this.historyData);

    return this.lostSalesOverrides$.pipe(
      map((overrides) => {
        overrides?.forEach((override) => {
          const year: string = override.grid.split('_')[1];
          const month: string = override.grid.split('_')[0].toLowerCase();
          const thatMonth: number = this.months.indexOf(month);

          let item: any;

          if (this.currentMonths.includes(thatMonth)) {
            item = items.find((item) => year === item.legend.split('-')[1]);
          } else if (this.prevMonths.includes(thatMonth)) {
            item = items.find((item) => year === item.legend.split('-')[0]);
          }

          if (override?.orderQty) {
            item[month] = override.orderQty;
          }
        });

        return orderBy(items || [], this.sort);
      })
    );
  }

  private applyOverlay(): Observable<any> {
    return forkJoin([this.overrideHistory(), this.lostSalesHistory()]).pipe(
      map(([overriddenData, lostSalesData]) => {
        let items = lodash.cloneDeep(this.historyData);

        this.resetAndInitColorMatrix();

        overriddenData = orderBy(overriddenData || [], [
          {
            field: 'legend',
            dir: 'desc',
          },
        ]);

        lostSalesData = orderBy(lostSalesData || [], [
          {
            field: 'legend',
            dir: 'desc',
          },
        ]);

        let currentYearHistory = items[items.length - 1];

        if (this.choosenItem.useLostSalesOverride) {
          currentYearHistory = lostSalesData[0];
        }
        this.prepareDataForApplyingOverlay(currentYearHistory, overriddenData);

        this.applyOverlayBackfill(items, currentYearHistory);
        this.applyOverlayLostSales(lostSalesData, items);
        this.applyOverlayHistoryOverride(overriddenData, items);

        this.loadChartData(items);

        this.trueHistoryData = items;

        this.loadBackfillInfo();
        this.loadLostSalesInfo();

        return orderBy(items || [], this.sort);
      })
    );
  }

  private resetAndInitColorMatrix(): void {
    this.colorMatrix = [];
    this.legends.forEach((legend, row) => {
      this.colorMatrix.push([]);
      this.months.forEach((month, column) => {
        this.colorMatrix[row].push('normal');
      });
    });
  }

  private initKitColorMatrix(): void {
    this.kitColorMatrix = [];
    this.legends.forEach((legend, row) => {
      this.kitColorMatrix.push([]);
      this.months.forEach((month, column) => {
        this.kitColorMatrix[row].push('normal');
      });
    });
  }

  private removeTailingZero(historyData: any[]) {
    let shouldBreak = false;
    for (let history of historyData) {
      if (shouldBreak) {
        break;
      }

      for (let month of this.reversedAccurateMonths.slice().reverse()) {
        if (shouldBreak) {
          break;
        }
        if (!history[month]) {
          history[month] = null;
          continue;
        }

        shouldBreak = true;
      }
    }
  }

  createCanvas(): Observable<HTMLCanvasElement> {
    return from(htmlToImage.toCanvas(this.historyView.nativeElement));
  }

  addImages() {
    this.createCanvas()
      .pipe(
        this.autoCleanUp(),
        tap((canvas) => {
          this.canvas = canvas;
          const imgContent = canvas.toDataURL('image/jpeg');

          this.currentImagesExport.push(imgContent);
        })
      )
      .subscribe((res) => {
        this.isLoadingAdd = false;
      });
  }

  onExportPdf() {
    this.pdf = new jspdf('p', 'mm', [
      (this.canvas.height * this.currentImagesExport?.length) / 5,
      this.canvas.width / 5,
    ]);

    const imgWidth = this.pdf.internal.pageSize.getWidth();
    const imgHeight = (this.canvas.height * imgWidth) / this.canvas.width;

    if (this.currentImagesExport.length > 0) {
      this.currentImagesExport.forEach((item, index) => {
        this.pdf.addImage(
          item,
          'JPEG',
          0,
          index * imgHeight,
          imgWidth,
          imgHeight,
          '',
          'FAST'
        );
      });
    }

    this.pdf && this.pdf.save(`history_${this.choosenItem.name}.pdf`);
  }

  onAddRemovePdf() {
    switch (this.currentTab) {
      case HistoryTabs.DirectSales:
        this.currentTabsExport = [
          ...this.currentTabsExport,
          HistoryTabs.DirectSales,
        ];

        break;

      case HistoryTabs.KitSales:
        this.currentTabsExport = [
          ...this.currentTabsExport,
          HistoryTabs.KitSales,
        ];

        break;

      case HistoryTabs.TotalSales:
        this.currentTabsExport = [
          ...this.currentTabsExport,
          HistoryTabs.TotalSales,
        ];

        break;
    }

    this.addImages();
    this.checkDisableAdd();
    this.isLoadingAdd = true;
  }

  checkDisableAdd() {
    this.isDisabledAdd = this.currentTabsExport.some(
      (item) => item === this.currentTab
    );
  }

  onRefreshTable() {
    this.currentTabsExport = [];
    this.currentImagesExport = [];

    this.checkDisableAdd();
  }

  saveAndRemindToRunForecast(): Observable<any> {
    if (this.isSaving || this.saveItemCount < 2) {
      this.saveItemCount++;
      return of();
    }

    this.isSaving = true;
    return this.companyService
      .updateCompanyInfo({
        ...this.currentCompany,
        willRemindToRunForecast: true,
      })
      .pipe(
        this.autoCleanUp(),
        tap((c) => {
          this.companyService.updateCacheCompany({
            ...c,
            isAuthorized: this.currentCompany.isAuthorized,
          });
        }),
        finalize(() => (this.isSaving = false))
      );
  }

  saveItem() {
    return this.itemService.save(this.choosenItem, 'key');
  }

  downloadInitialTemplate() {
    this.syncService
      .downloadInitialTemplate('history-override')
      .pipe(this.autoCleanUp(), first())
      .subscribe((res) => {
        const headers: HttpHeaders = res.headers;

        const dispostion: string = headers.get('Content-Disposition') || '';
        const matches = /filename=([^;]+)/gi.exec(dispostion);
        const fileName = (matches[1] || 'untitled')
          .replace('"', '')
          .replace('"', '')
          .trim();

        const a = document.createElement('a');
        const blob = res.body;
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  loadCsvboxImporter(): void {
    this.importer = new CSVBoxImporter(
      environment.csvboxLicenseKeys.asc.historyOverride,
      {},
      () => {}
    );

    const dynamicColumns = [];
    this.isAscCompany
      ? dynamicColumns.push({
          column_name: 'SKU',
          matching_keywords: 'SKU',
          type: 'text',
          info_hint:
            'All items are required to be supplied with a SKU for the Multiple Item option, but only one item is needed for the Single Item option',
        })
      : dynamicColumns.push({
          column_name: 'Item Key',
          matching_keywords: 'Item Key',
          type: 'text',
          info_hint:
            'All items are required to be supplied with an Item Key for the Multiple Item option, but only one item is needed for the Single Item option',
        });
    this.importer.setDynamicColumns(dynamicColumns);

    this.importer.listen('onSubmit', () => {
      this.isLoading = true;
    });
  }

  processFileNotification(data): void {
    if (data.key !== NotificationKey.csvStatus) {
      return;
    }

    switch (data.type) {
      case 'close':
        Swal.fire({
          html: `
            <div >
              Upload file successfully
            </div>
          `,
          type: 'success',
          showCloseButton: false,
          allowOutsideClick: false,
          cancelButtonText: 'Undo Last Upload',
          reverseButtons: true,
          showCancelButton: true,
        }).then((res) => {
          if (res?.value) {
            this.calculateUndoLeftTime()
              .pipe(
                this.autoCleanUp(),
                switchMap(() => this.itemService.getById(this.choosenItem.key)),
                tap((item) => {
                  this.choosenItem = item;
                  this.loadDirectAggregations();
                })
              )
              .subscribe();
          }

          if (res?.dismiss) {
            this.undoLastUpload();
          }
        });

        this.isLoading = false;
        break;
      case 'notice':
        if (!this.isImportFailModalOpen) {
          this.isImportFailModalOpen = true;

          const isCsvFile = data.req.body.fileName.includes('.csv');
          if (!isCsvFile) {
            data.msg = {
              ...data.msg,
              error: ['Invalid file type. Please import the CSV file.'],
            };
          }

          this.altModalService.open(ImportFailModalComponent, {
            data: { message: data },
            backdrop: 'static',
            keyboard: false,
            backdropClass: 'custom-backdrop',
            windowClass: 'custom-modal',
            onError: () => {
              this.isImportFailModalOpen = false;
            },
          });
          this.calculateUndoLeftTime()
            .pipe(
              this.autoCleanUp(),
              switchMap(() => this.itemService.getById(this.choosenItem.key)),
              tap((item) => {
                this.choosenItem = item;
                this.loadDirectAggregations();
              })
            )
            .subscribe();
        }

        this.isLoading = false;
        break;
    }
  }

  openImporter(
    behaviorType: UploadHistoryBehaviorType,
    amountType: UploadHistoryAmountType
  ) {
    this.importer.setUser({
      user_id: this.authService.currentUser.userId,
      companyType: this.currentCompany.companyType,
      companyKey: this.currentCompany.companyKey,
      isAdmin: this.authService.currentUser.isAdmin,
      fileOptions: btoa(
        JSON.stringify({
          fileType: 'historyOverride',
          isCreateNew: true,
          uploadHistoryBehaviorType: behaviorType,
          uploadHistoryAmountType: amountType,
        })
      ),
    });
    this.importer.openModal();
  }

  undoLastUpload() {
    this.isLoading = true;

    this.syncService
      .undoLastUpload('history-override')
      .pipe(
        this.autoCleanUp(),
        tap(
          () => {
            this.remainingUndoTime = 0;
            this.isShowUndoToast = true;
          },
          () => (this.isLoading = false)
        )
      )
      .subscribe();
  }

  private handleIsBackfillRowFull(tempHistoryData) {
    let isRowFull = false;
    tempHistoryData.forEach((histories, idx, arr) => {
      if (isRowFull || idx === arr.length - 1) {
        return;
      }

      for (const property in histories) {
        if (!histories[property]) {
          this.backfillInfo =
            'You must have at least one full year worth of data for this item in order to use the Backfill feature.';
          this.isBackfillDisabled = true;
          return;
        }
      }

      isRowFull = true;
    });

    return isRowFull;
  }

  private handleBackfillValid() {
    this.historyData.forEach((history, index) => {
      if (index === 0 && history[this.months[this.lastMonth]]) {
        // Skip to next year
        return;
      }
      for (const property in history) {
        if (!history[property]) {
          this.backfillInfo =
            "Use current year's sales history for previous years to help find seasonality. Use this feature with caution.";
          this.isBackfillDisabled = false;
          return;
        }
      }
    });
  }

  private prepareDataForApplyingOverlay(currentYearHistory, overriddenData) {
    if (this.choosenItem.useHistoryOverride) {
      for (const property in currentYearHistory) {
        if (property === 'legend') {
          continue;
        }

        if (
          overriddenData[0][property] !==
          this.historyData[this.historyData.length - 1][property]
        ) {
          currentYearHistory[property] = overriddenData[0][property];
          this.isDirectSalesEmpty =
            this.isDirectSalesEmpty &&
            !overriddenData[0][property] &&
            overriddenData[0][property] !== 0;
        }
      }
    }
  }

  private applyOverlayBackfill(items, currentYearHistory) {
    if (!this.choosenItem.useBackfill) {
      return;
    }

    items.forEach((item, index, arr) => {
      if (index === 0) {
        if (!item[this.months[this.lastMonth]]) {
          item[this.months[this.lastMonth]] =
            currentYearHistory[this.months[this.lastMonth]] || 0;
          this.isDirectSalesEmpty =
            this.isDirectSalesEmpty &&
            !currentYearHistory[this.months[this.lastMonth]] &&
            currentYearHistory[this.months[this.lastMonth]] !== 0;

          this.colorMatrix[this.legends.length - 1 - index][11] = 'backfill';
        }
        return;
      }
      if (index === arr.length - 1) {
        if (!item[this.months[this.lastMonth]]) {
          item[this.months[this.lastMonth]] =
            currentYearHistory[this.months[this.lastMonth]] || 0;
          this.isDirectSalesEmpty =
            this.isDirectSalesEmpty &&
            !currentYearHistory[this.months[this.lastMonth]] &&
            currentYearHistory[this.months[this.lastMonth]] !== 0;

          this.colorMatrix[this.legends.length - 1 - index][11] = 'backfill';
        }
        return;
      }

      this.applyOverlayBackfillSupport(item, currentYearHistory, index);
    });
  }

  private applyOverlayBackfillSupport(item, currentYearHistory, index) {
    for (let m = 0; m < 12; m++) {
      if (!item[this.months[m]]) {
        item[this.months[m]] = currentYearHistory[this.months[m]] || 0;
        this.isDirectSalesEmpty =
          this.isDirectSalesEmpty &&
          !currentYearHistory[this.months[m]] &&
          currentYearHistory[this.months[m]] !== 0;

        let columnIndex = m - (this.lastMonth + 1);
        if (columnIndex < 0) {
          columnIndex += 12;
        }

        this.colorMatrix[this.legends.length - 1 - index][columnIndex] =
          'backfill';
      }
    }
  }

  private getIsDirectSalesEmpty(history, property: string) {
    return (
      this.isDirectSalesEmpty && !history[property] && history[property] !== 0
    );
  }

  private applyOverlayLostSales(lostSalesData, items) {
    if (!this.choosenItem.useLostSalesOverride) {
      return;
    }

    lostSalesData.forEach((lostSales, index) => {
      for (const property in lostSales) {
        if (property === 'legend') {
          continue;
        }

        if (
          (lostSales[property] || lostSales[property] === 0) &&
          lostSales[property] !==
            this.historyData[this.historyData.length - 1 - index][property]
        ) {
          items[items.length - 1 - index][property] = lostSales[property];
          this.isDirectSalesEmpty = this.getIsDirectSalesEmpty(
            lostSales,
            property
          );

          let columnIndex =
            this.months.indexOf(property) - (this.lastMonth + 1);
          if (columnIndex < 0) {
            columnIndex += 12;
          }
          this.colorMatrix[index][columnIndex] = 'lostSales';
        }
      }
    });
  }

  private applyOverlayHistoryOverride(overriddenData, items) {
    if (!this.choosenItem.useHistoryOverride) {
      return;
    }

    overriddenData.forEach((overridden, index) => {
      for (const property in overridden) {
        if (property === 'legend') {
          continue;
        }

        if (
          (overridden[property] || overridden[property] === 0) &&
          overridden[property] !==
            this.historyData[this.historyData.length - 1 - index][property]
        ) {
          items[items.length - 1 - index][property] = overridden[property];
          this.isDirectSalesEmpty = this.getIsDirectSalesEmpty(
            overridden,
            property
          );

          let columnIndex =
            this.months.indexOf(property) - (this.lastMonth + 1);
          if (columnIndex < 0) {
            columnIndex += 12;
          }
          this.colorMatrix[index][columnIndex] = 'override';
        }
      }
    });
  }

  private calculateUndoLeftTime() {
    return this.companyService.getNewById(this.currentCompany.companyKey).pipe(
      this.autoCleanUp(),
      tap((company) => {
        this.currentCompany = company;
        this.remainingUndoTime =
          HOURS_IN_DAY -
          moment().diff(company.historyOverrideLastUploadDate, 'hours');
      })
    );
  }

  private processUndoNotification(data) {
    if (
      data.type !== NotificationType.close ||
      data.key !== NotificationKey.undoUploadStatus
    ) {
      return;
    }

    if (this.isShowUndoToast) {
      if (this.currentToast) {
        this.snotifyService.remove(this.currentToast.id);
      }

      this.currentToast = this.snotifyService
        .success('Undo upload successfully')
        .on('mounted', () => {
          this.isShowUndoToast = false;
        });
    }

    this.remainingUndoTime = 0;
    this.gridItems$ = this.applyOverlay();
    this.isLoading = false;
  }

  private handleInitialPusher() {
    this.pusherService.userChannel.bind('notification', (data) => {
      this.processFileNotification(data);
      this.processUndoNotification(data);
    });
  }
}
