import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompanyService } from './../services/company.service';
import { Company } from '../models/company';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  constructor(private companyService: CompanyService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    //add company currently in use to the request.

    const company: Company = this.companyService.currentCompany();
    let companyKey: string = 'NONE';
    let companyType: string = 'NONE';

    if (company) {
      companyKey = company.companyKey;
      companyType = company.companyType;
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const modifiedReq = request.clone({
      headers: request.headers
        .set('COMPANY-KEY', companyKey)
        .set('COMPANY-TYPE', companyType)
        .set('TIME-ZONE', timeZone),
    });

    return next.handle(modifiedReq);
  }
}
