import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import {
  FormBuilder,
  FormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { ShipmentLocation } from 'src/app/core/infrastructure/enums/shipment-location.enum';
import { RestockSuggestion } from 'src/app/core/models/restock-suggestion';
import { ShipmentService } from 'src/app/core/services/shipment.service';
import { ShipmentItem } from 'src/app/core/models/shipment-item';
import { tap } from 'rxjs/operators';
import { SupplierDropdown } from './create-shipment-modal.constant';
import { ItemField } from 'src/app/core/infrastructure/enums/meta-data-field.enum';
import { MetaDataField } from 'src/app/core/infrastructure/classes/meta-data-field';
import { Observable } from 'rxjs';
import { Item } from 'src/app/core/models/item';
import { Shipment } from 'src/app/core/models/shipment';
import { PurchaseOrder } from 'src/app/core/models/purchase-order';
@Component({
  selector: 'app-create-shipment-modal',
  templateUrl: './create-shipment-modal.component.html',
  styleUrls: ['./create-shipment-modal.component.scss'],
})
export class CreateShipmentModalComponent
  extends BaseComponent
  implements OnInit
{
  @Input() supplier: string;
  @Input() recommendedField: string;
  @Input() restockSuggestion: RestockSuggestion;
  @Input() restockSuggestions?: RestockSuggestion[];
  @Input() itemData?: Item;
  @Input() metadataFields?: MetaDataField[];
  @Input() isMultiple = false;
  @Input() isUpload = false;
  @Input() purchaseOrder: PurchaseOrder;

  readonly ShipmentLocation = ShipmentLocation;
  readonly AMAZON_LOGO_SOURCE = 'assets/images/logo-amazon.svg';
  readonly ItemField = ItemField;

  shipmentForm: FormGroup;
  isLoading: boolean = false;
  supplierMetadata: MetaDataField;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private shipmentService: ShipmentService,
  ) {
    super();
  }

  get shipmentName(): AbstractControl {
    return this.shipmentForm?.controls?.shipmentName;
  }

  get shipmentLocation(): AbstractControl {
    return this.shipmentForm?.controls?.shipmentLocation;
  }

  supplierDefault: SupplierDropdown = {
    text: 'Select supplier...',
    value: null,
  };

  ngOnInit(): void {
    this.shipmentForm = this.fb.group({
      shipmentName: ['', [Validators.required]],
      shipmentLocation: [ShipmentLocation.AMAZON, [Validators.required]],
    });
  }

  createShipment(): Observable<Shipment> {
    if (this.isUpload) {
      return this.shipmentService.uploadInventory(
        this.shipmentName.value,
        null,
        null,
        this.supplier
      );
    }

    if (this.purchaseOrder) {
      return this.shipmentService.createShipmentFromPurchaseOrder(
        this.shipmentName.value,
        this.purchaseOrder
      );
    }

    return this.isMultiple
      ? this.shipmentService.createShipmentWithMultipleItems(
          this.shipmentName.value,
          this.supplier,
          this.recommendedField
        )
      : this.shipmentService.createShipmentWithOneItem(
          this.shipmentName.value,
          this.supplier,
          new ShipmentItem({
            itemKey: this.restockSuggestion.key,
            shipmentQty: this.restockSuggestion.orderQty,
          }),
          this.restockSuggestion.supplierCost
        );
  }

  saveShipment(): void {
    if (this.shipmentForm.invalid) {
      return;
    }

    this.isLoading = true;

    this.createShipment()
      .pipe(
        tap(
          (shipment) => {
            this.activeModal.close({
              shipment,
            });
          },
          () => {
            this.isLoading = false;
            this.shipmentName.setErrors({ unique: true });
          }
        )
      )
      .subscribe();
  }
}
