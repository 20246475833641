export class GridStateColumnSort {
  // Need to allow multiple sort, in case single sort, it's not necessary
  // priority: number = 1;
  direction: string = '';

  constructor(init?: Partial<GridStateColumnSort>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
