export class RestockSuggestionSupplier {
  flag: string = '';
  vendorName: string = '';
  orangeAlerts: number = 0;
  greenAlerts: number = 0;
  yellowAlerts: number = 0;
  redAlerts: number = 0;
  tealAlerts: number = 0;
  restockUnits: number = 0;
  restockTotal: number = 0;
  targetOrderValue: number = 0;
  freeFreightMinimum: number = 0;

  constructor(init?: Partial<RestockSuggestionSupplier>) {
    if (init) {
      // if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
