import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Vendor } from '../models/vendor';
import { ResourceService } from './resource-service';
import { SortAttribute } from 'src/app/core/infrastructure/classes/sort-attribute';
import { StockaidResponse } from '../misc/stockaid-response';
import { Observable } from 'rxjs';
import { Supplier } from '../models/supplier';
import { Address } from '../models/address';

@Injectable({
  providedIn: 'root',
})
export class VendorService extends ResourceService<Vendor> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/vendor');
  }

  public stockaidPaginated(
    offset: number,
    limit: number,
    sortAttributes: SortAttribute[],
    filters: []
  ): Observable<StockaidResponse<Vendor[]>> {
    sortAttributes = sortAttributes || [];
    const body: any = {
      offset,
      limit,
      sort: sortAttributes,
      filter: [],
      operation: 'paginate',
    };

    return this.httpClient.post<StockaidResponse<Vendor[]>>(this.apiUrl, body);
  }

  getAddressesByVendorKey(vendorKey: string) {
    return this.httpClient.get<{
      suppliers: Supplier[];
      billingAddresses: Address[];
      shipToAddresses: Address[];
    }>(`${this.apiUrl}/${vendorKey}/address`);
  }

  saveAddress(address: Address) {
    return this.httpClient.post(`${this.apiUrl}/create-address`, address);
  }
}
