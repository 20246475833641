export enum MetaDataFieldType {
  String = 'STRING',
  Integer = 'INTEGER',
  Decimal = 'DECIMAL',
  DateOnly = 'DATEONLY',
  Boolean = 'BOOLEAN',
  UUID = 'UUID',
  Json = 'JSON',
  Date = 'DATE',
}
