import { FieldValidatorType } from "../../../enums/field-validator-type.enum";
import { IFieldValidator } from "../../../interfaces/i-field-validator";

export class MinMaxValidator implements IFieldValidator {
  type = FieldValidatorType.MinMax;
  min: number = 0;
  max: number = 0;

  constructor(init?: Partial<MinMaxValidator>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }
}
