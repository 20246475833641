import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from '@stockaid/core';
import { AuthenticationService, MODAL_DATA } from '@stockaid/services';
import { tap } from 'rxjs/operators';
import { User } from 'src/app/core/models/user';
import { DynamicScriptsService } from 'src/app/core/services/shared/dynamic-scripts.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { ISoftwareModalData } from './software-modal.model';

@Component({
  selector: 'app-software-modal',
  templateUrl: './software-modal.component.html',
  styleUrls: ['./software-modal.component.scss'],
})
export class SoftwareModalComponent extends BaseComponent {
  constructor(
    @Inject(MODAL_DATA) public data: ISoftwareModalData,
    private activeModal: NgbActiveModal,
    private dynamicScriptService: DynamicScriptsService,
    private userService: UserService,
    private authService: AuthenticationService,
  ) {
    super();
    this.getCurrentUser();
  }

  @ViewChild('container')
  public container: ElementRef;

  isLoadedCalendly: boolean;
  currentUser: User;

  ngOnInit(): void {
    this.dynamicScriptService
      .loadDynamicScript(
        'https://assets.calendly.com/assets/external/widget.js'
      )
      .subscribe();
      this.loadCalendly();
  }

  loadCalendly(): void {
    let count = 0;
    let maxRetry = 10;
    const loadCalendlyJob = setInterval(() => {
      if (count > maxRetry || this.isLoadedCalendly) {
        this.isLoadedCalendly = true;
        clearInterval(loadCalendlyJob);
        return;
      }

      ++count;

      const Calendly = (window as any).Calendly;
      
      if (!this.container || !Calendly) {
        return;
      }

      if (Calendly) {
        Calendly.initInlineWidget({
          url: environment.calendlyFirstRegistrationUrl,
          prefill: {
            name: this.currentUser?.displayName,
            email: this.currentUser?.userId,
          },
          parentElement: this.container.nativeElement,
        });

        clearInterval(loadCalendlyJob);
        this.checkLoadedIframe();
      }
    }, 1000);
  }

  checkLoadedIframe() {
    let count = 0;
    let maxRetry = 10;

    const checkingIframe = setInterval(() => {
      const iframes =
        this.container?.nativeElement?.getElementsByTagName('iframe');

      if (count > maxRetry || this.isLoadedCalendly) {
        this.isLoadedCalendly = true;
        clearInterval(checkingIframe);

        return;
      }

      ++count;

      if (!iframes?.[0]) {
        return;
      }

      if (iframes[1]) {
        iframes[1].style.display = 'none';
      }

      iframes[0].onload = () => {
        this.isLoadedCalendly = true;
        clearInterval(checkingIframe);
      };
    }, 1000);
  }

  private getCurrentUser() {
    this.authService
      .getLoggedInUserFromSession()
      .pipe(
        this.autoCleanUp(),
        tap((userResponse) => {
          const [currentUser] = userResponse?.model || [];

          this.currentUser = currentUser;
        }),
      )
      .subscribe();
  }

  confirm() {
    this.activeModal.close();
  }

  dismiss() {
    this.currentUser.preferences.isHideSoftwareModal = true;
    this.userService.editUser(this.currentUser).subscribe();
    this.activeModal.dismiss();
  }
}
