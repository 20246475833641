<ul class="navbar-nav ml-auto text-secondary">
  <li *ngIf="isQuicksBookSupport.showIcon">
    <div
      class="nav-item__button-container"
      [ngClass]="{ 'nav-item__button-container--modified': isSyncInProcess }"
    >
      <a href="https://support.forecastrx.com/hc/en-us/sections/115001573087-QuickBooks-Desktop" target="_blank"
        (click)="onClickQBSupport()"
        style="text-decoration: none;"
        rel="noopener"
      >
        <div
        class="nav-item__button"
        [ngClass]="{ 'nav-item__button--modified': isSyncInProcess }"
        (mouseenter)="isQuicksBookSupport.showDialog = false"
        >
          <i class="nav-item__button-icon icon feather icon-alert-triangle feather--modified"></i>
          <span
            *ngIf="isQuicksBookSupport.showMinorIcon"
            class="nav-item__qb_minor_icon position-absolute top-0 translate-middle order border-light rounded-circle"
          >
          </span>

          <div
            class="nav-item__qb_dialog"
            [class.nav-item__qb_dialog--hidden]="!isQuicksBookSupport.showDialog"
            [class.nav-item__qb_dialog--animate]="isQuicksBookSupport.showDialog"
          >
            Do you experience syncing issues?<br/> Have you updated QuickBooks to the most recent version?<br/> Please click here for additional details!
          </div>

          <div class="nav-item__qb_info">
            <p class="nav-item__qb_detail">
              QuickBooks&nbsp;Support
            </p>
          </div>
        </div>
      </a>
    </div>
  </li>

  <li
    *ngIf="
      !isSyncInProcess && company?.lastForecastDate && !isSubscriptionExpired
    "
  >
    <div
      class="nav-right__report"
      [ngClass]="{ 'nav-right__report--modified': isSyncInProcess }"
      (click)="forecastReport()"
    >
      <span
        *ngIf="!isExporting"
        class="nav-right__report-icon"
        [ngClass]="{ 'nav-right__report-icon--modified': isSyncInProcess }"
      >
        <i class="icon feather icon-book"></i>
      </span>

      <span
        *ngIf="isExporting"
        title="Please wait while the application is exporting"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      >
      </span>

      <span class="nav-right__report-content"> Forecast&nbsp;Report </span>
    </div>
  </li>

  <li
    *ngIf="!isSyncInProcess && company?.lastSyncDate && !isSubscriptionExpired"
  >
    <app-nav-sync></app-nav-sync>
  </li>
  <li
    *ngIf="
      !isSyncInProcess && company?.lastForecastDate && !isSubscriptionExpired
    "
  >
    <app-nav-forecast></app-nav-forecast>
  </li>
  <li>
    <div
      title="Support"
      class="nav-right__chat-support"
      (click)="redirectToHelpCenter()"
    >
      <i class="icon feather icon-help-circle"></i>
    </div>
  </li>

  <li>
    <div
      (click)="toggleFullScreen()"
      class="nav-right__full-screen js-fullscreen d-none d-lg-block cursor-pointer"
    >
      <div class="position-relative">
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Full Screen"
        >
          <i class="icon feather icon-maximize"></i>
        </span>
      </div>
    </div>
  </li>

  <li *ngIf="isShowIconNotification" class="pr-0">
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a
        href="javascript:"
        ngbDropdownToggle
        data-toggle="dropdown"
        class="user-icon text-decoration-none"
        [dataTestID]="'notifications-icon-btn'"
      >
        <div class="position-relative">
          <span
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Notifications"
          >
            <i class="far fa-bell"></i>
            <span
              class="position-absolute top-0 translate-middle border border-light rounded-circle"
              [class.nav-item__badge]="getObjectKeys(notifications)?.length"
            >
            </span>
          </span>
        </div>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-notification"
        ngbDropdownMenu
      >
        <div class="pro-head bg-white border-bottom text-dark">
          <strong (click)="navigateToNotification()" class="fw-bold cursor-pointer"> Notifications </strong>
        </div>
        <ul *ngIf="!isLoading" class="pro-body py-2">
          <li *ngFor="let notification of getObjectKeys(notifications)">
            <a
              href="javascript://"
              class="dropdown-item"
              (click)="onClickNotification(notification)"
            >
              <span class="d-flex">
                <span
                  class="d-flex bg-red align-items-center justify-content-cent text-white rounded-circle"
                  style="width: 18px; height: 18px"
                  [class]="getBackgroundIcon(notification)"
                >
                  <span class="f-11 m-auto">
                    {{ notifications[notification]?.length }}
                  </span>
                </span>
                <span class="ml-2 text-secondary">
                  {{ getNotificationName(notification, notifications[notification]?.length) }}
                </span>
              </span>
            </a>
          </li>
        </ul>
        <div
          class="d-flex mb-3 justify-content-center text-secondary opacity-50"
          *ngIf="!getObjectKeys(notifications)?.length && !isLoading"
        >
          No notifications to display
        </div>
      </div>
    </div>
  </li>

  <li class="pr-0">
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a
        href="javascript:"
        ngbDropdownToggle
        data-toggle="dropdown"
        class="user-icon text-decoration-none"
        [dataTestID]="'profile-icon-btn'"
      >
        <i
          [title]="user?.displayName || '?'"
          class="icon feather icon-user"
        ></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right profile-notification"
        ngbDropdownMenu
      >
        <div class="pro-head bg-white border-bottom text-dark">
          <strong class="fw-bold" [dataTestID]="'user-name'">{{
            user?.displayName || "?"
          }}</strong>
        </div>
        <ul class="pro-body py-2">
          <li>
            <a
              href="javascript://"
              class="dropdown-item"
              (click)="navigateToProfile()"
              [dataTestID]="'profile-item'"
              ><i class="feather icon-user" title="Profile"></i> Profile</a
            >
          </li>
          <li>
            <a
              href="javascript://"
              class="dropdown-item"
              (click)="navigateToSubscription()"
              title="Subscriptions"
              [dataTestID]="'subscription-item'"
              ><i class="fa fa-dollar-sign"></i> Subscription(s)</a
            >
          </li>
        </ul>
        <div class="pro-body mt-0 border-top py-2">
          <li>
            <a
              href="javascript://"
              class="dropdown-item"
              (click)="logout()"
              title="Logout"
              [dataTestID]="'logout-item'"
              ><i class="feather icon-log-out"></i> Logout</a
            >
          </li>
        </div>
      </div>
    </div>
  </li>
</ul>

<section
  class="header-user-list"
  *ngIf="visibleUserList && !this.gradientConfig['rtl-layout']"
  [ngClass]="{ open: visibleUserList }"
  [@slideInOutLeft]
>
  <app-chat-user-list
    (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="
      this.visibleUserList = !this.visibleUserList; this.chatMessage = false
    "
  ></app-chat-user-list>
</section>

<section
  class="header-chat"
  *ngIf="chatMessage && !this.gradientConfig['rtl-layout']"
  [ngClass]="{ open: chatMessage }"
  [@slideInOutLeft]
>
  <app-chat-msg
    (onChatToggle)="this.chatMessage = !this.chatMessage"
    [friendId]="friendId"
  ></app-chat-msg>
</section>

<section
  class="header-user-list"
  *ngIf="visibleUserList && this.gradientConfig['rtl-layout']"
  [ngClass]="{ open: visibleUserList }"
  [@slideInOutRight]
>
  <app-chat-user-list
    (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="
      this.visibleUserList = !this.visibleUserList; this.chatMessage = false
    "
  ></app-chat-user-list>
</section>

<section
  class="header-chat"
  *ngIf="chatMessage && this.gradientConfig['rtl-layout']"
  [ngClass]="{ open: chatMessage }"
  [@slideInOutRight]
>
  <app-chat-msg
    (onChatToggle)="this.chatMessage = !this.chatMessage"
    [friendId]="friendId"
  ></app-chat-msg>
</section>
