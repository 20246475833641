import { InventorySourcePreference } from "../infrastructure/enums/inventory-source-preference.enum";

export class Summary {
  itemKey = '';
  itemName = '';

  asin = '';
  fnsku = '';

  description = '';
  tag = '';
  vendorKey = '';
  vendorName = '';
  consolidatedQty = 0;
  onNewPo = 0;
  recommendedQty = 0;
  type = '';
  dueDate: Date = null;
  moq = 0;
  lotMultipleQty = 0;
  purchaseQty = 0;
  vendorPrice = 0;
  total = 0;
  leadTime = 0;
  orderInterval = 0;
  addedToSupplies = 0;
  documentUuid = '';
  purchasingSummariesUuid = '';

  s7d = 0;
  s30d = 0;
  s90d = 0;
  s180d = 0;
  s365d = 0;
  sMtd = 0;
  sYtd = 0;

  snapshotQty = 0;
  meanLtd = 0;
  restockMeanLtd = 0;
  poMeanLtd = 0;
  restockPoMeanLtd = 0;
  fullMeanLtd = 0;
  restockFullMeanLtd = 0;
  safetyStockLtd = 0;

  onHand = 0;
  onHandMin = 0;
  onHandFbm = 0;
  onHandThirdParty = 0;
  onHandThirdPartyMin = 0;

  lastOrderDate: Date = null;
  nextScheduledOrderDate: Date = null;
  daysUntilNextScheduledOrder = 0;

  outOfStockDate: Date = null;
  daysLeft = 0;
  recommendedOrderDate: Date = null;
  daysRemaining = 0;

  openPurchaseOrders = 0;
  openSalesOrders = 0;

  inbound = 0;
  inboundAwd = 0;
  inboundPrice = 0;
  inboundSalesLast30Days = 0;
  inboundAvailable = 0;
  inboundFcTransfer = 0;
  inboundFcProcessing = 0;
  inboundCustomerOrder = 0;
  inboundUnfulfillable = 0;
  inboundAlert = '';
  inboundWorking = 0;
  mwsFulfillmentFee = 0;
  isFbm = false;
  doNotOrder = false;

  restockOnHand = 0;
  restockOnHandMin = 0;
  restockOnHandThirdParty = 0;
  restockOnHandThirdPartyMin = 0;
  restockInbound = 0;
  restockInboundAwd = 0;
  restockInboundFcTransfer = 0;
  restockOpenPurchaseOrders = 0;
  isChild: boolean;

  isolatedValues: Summary;

  newMeanLtd = 0;
  newPoMeanLtd = 0;
  newFullMeanLtd = 0;
  warehouseMeanLtd = 0;
  warehousePoMeanLtd = 0;
  warehouseFullMeanLtd = 0;

  newOutOfStockDate: Date = null;
  inventorySourcePreference: InventorySourcePreference;

  constructor(init?: Partial<Summary>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export interface ISummaryResponse {
  err?: string;

  model: Summary[];
}

export class SummaryItems {
  onHandCount = 0;
  onHandThirdPartyCount = 0;
  missingVendorCount = 0;
  olderThan30DaysCount = 0;
  differentOutStockItemsCount = 0;
  differentWarehouseOutStockItemsCount = 0;
  differentOlderThan30DaysCount = 0;
  differentMissingVendorCount = 0;
  totalEstimatedStorageFees = 0;
  differenceTotalEstimatedStorageFees = 0;

  constructor(init?: Partial<SummaryItems>) {
    // if no values are passed in, fresh instantiation
    Object.assign(this, init || {});
  }
}

export class SummaryByVendors {
  count = 0;
  totalPrice = 0;
  totalQty = 0;
  uniqueItems = 0;
  differentSuggestPOItems = 0;
  differentSuggestPOuniqueItems = 0;
  differentSuggestPOTotalPrice = 0;
  differentSuggestPOTotalQty = 0;

  constructor(init?: Partial<SummaryByVendors>) {
    // if no values are passed in, fresh instantiation
    Object.assign(this, init || {});
  }
}

export interface ISummaryItemsResponse {
  err: string;
  model: SummaryItems;
}

export interface ISummaryByVendorsResponse {
  err: string;
  model: SummaryByVendors;
}
