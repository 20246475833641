import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ItemService } from 'src/app/core/services/item.service';
import {
  HeaderModel,
  SummaryItemEnum,
  SummaryItemNameEnum,
  SummaryItemStatusEnum,
} from './header.model';
import { Company } from './../../../../core/models/company';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { getMarketPlace } from '@stockaid/utils';
import { getCurrencyCodeByMarketplaceId } from 'src/app/core/constants/amazon-link.constant';
import { Currencies } from 'src/app/core/constants/currency.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() currentCompany: Company;
  @Input() isInteraction = true;
  @Input() set dataRows(value: HeaderModel[]) {
    this._dataRows = value;
  }

  marketPlaceName: string = '';
  readonly SummaryItemNameEnum = SummaryItemNameEnum;
  readonly SummaryItemStatusEnum = SummaryItemStatusEnum;

  get dataRows(): HeaderModel[] {
    return this._dataRows;
  }

  get displayCurrency() {
    const currencyCodeByMarketPlaceId = getCurrencyCodeByMarketplaceId(this.currentCompany?.marketplaceId);
    const currencyCodeSetting = this.currentCompany?.currencyCode;
    const code = currencyCodeSetting || currencyCodeByMarketPlaceId || 'USD';
    const currency = Currencies.find(currency => currency.code === code);
    return { code:currency.code, symbol:currency.symbol };
  }

  @Output() clickRow = new EventEmitter();

  _dataRows: HeaderModel[];
  isSummary = true;

  readonly CompanyType = CompanyType;

  constructor(public itemService: ItemService) {}

  ngOnInit(): void {
    this.marketPlaceName = getMarketPlace(this.currentCompany?.marketplaceId);
  }

  onClickItem(name: SummaryItemEnum): void {
    this.clickRow.emit(name);
  }

  isHiddenTextDecoration(name: string) {
    switch (name) {
      case SummaryItemNameEnum.TotalUnitsOnSuggestedPurchaseOrders:
      case SummaryItemNameEnum.TotalItemsOnSuggestedPurchaseOrders:
      case SummaryItemNameEnum.TotalCostOfSuggestedPurchaseOrders:
      case SummaryItemNameEnum.SuggestedPurchaseOrders:
        return true;
      default:
        return false;
    }
  }

  getIcon(name: string) {
    switch (name) {
      case SummaryItemNameEnum.AmazonItems:
      case SummaryItemNameEnum.ItemsOutOfStock:
      case SummaryItemNameEnum.SuggestedPurchaseOrders:
        if (
          [CompanyType.CSV, CompanyType.QBFS].includes(
            this.currentCompany?.companyType as CompanyType
          ) &&
          name !== SummaryItemNameEnum.AmazonItems
        ) {
          return 'fa fa-store';
        }
        return 'fab fa-amazon';
      case SummaryItemNameEnum.WarehouseItems:
      case SummaryItemNameEnum.TotalCostOfSuggestedPurchaseOrders:
        return 'feather icon-home';
      case SummaryItemNameEnum.NewItems:
      case SummaryItemNameEnum.TotalItemsOnSuggestedPurchaseOrders:
      case SummaryItemNameEnum.TotalEstimatedStorageFees:
        return 'feather icon-package';
      case SummaryItemNameEnum.NoSuppliersAssigned:
      case SummaryItemNameEnum.TotalUnitsOnSuggestedPurchaseOrders:
        return 'feather icon-alert-triangle';
    }
  }
}
