import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {AuthGuard} from './core/guards/auth.guard';
import { AdminAuthGuard } from "./core/guards/admin-auth.guard";
import {CustomPreloadingStrategyService} from './core/services/custom-preloading-strategy.service';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    data: {preload: true},
    children: [
      {
        path: '',
        redirectTo: 'account',
        pathMatch: 'full',
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./routes/account/account.module').then(
            (module) => module.AccountModule
          ),
      },
    ],
  },
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'sample-page',
        loadChildren: () =>
          import('./demo/pages/sample-page/sample-page.module').then(
            (module) => module.SamplePageModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'dashboard',
        loadChildren: () =>
          import('./routes/dashboard/dashboard.module').then(
            (module) => module.DashboardModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'onboarding',
        loadChildren: () =>
          import('./routes/onboarding/onboarding.module').then(
            (module) => module.OnboardingModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'subscriptions',
        loadChildren: () =>
          import('./routes/subscriptions/subscriptions.module').then(
            (module) => module.SubscriptionsModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'profile',
        loadChildren: () =>
          import('./routes/profile/profile.module').then(
            (module) => module.ProfileModule
          ),
      },
      {
        canActivate: [AuthGuard],
        path: 'notification',
        loadChildren: () =>
          import('./routes/notification/notification.module').then(
            (module) => module.NotificationModule
          ),
      },
      {
        canActivate: [AdminAuthGuard],
        path: 'administration',
        loadChildren: () => import('./routes/administration/administration.module').then(module => module.AdministrationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
