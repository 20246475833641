import { Component, OnInit } from '@angular/core';
import { NEVER } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/core/services/company.service';
import { SyncService } from 'src/app/core/services/sync.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { ProcessingService } from 'src/app/core/services/processing.service';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';
import { HeaderService } from 'src/app/core/services/header.service';
import { Company } from 'src/app/core/models/company';
import { UpdateSPOCompanyModalComponent } from 'src/app/theme/shared/components/modal/spo-company-modal/update-spo-company-modal.component';
import { ModalService } from '@stockaid/services';
import { GenericModalComponent } from 'src/app/theme/shared/components/modal/generic-modal/generic-modal.component';
import { ManuallyImportDataModalComponent } from 'src/app/theme/shared/components/modal/manually-import-data-modal/manually-import-data-modal.component';
import { NotificationKey } from 'src/app/core/infrastructure/enums/notification.enum';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nav-sync',
  templateUrl: './nav-sync.component.html',
  styleUrls: ['./nav-sync.component.scss'],
})
export class NavSyncComponent extends BaseComponent implements OnInit {
  isHide = false;
  lastSync: Date;
  isProcessing = false;
  isSyncInProcess = false;
  currentCompany: Company;
  header;
  message;
  isSyncDisabled = false;

  readonly CompanyType = CompanyType;

  get isCurrentCompanyASC(): boolean {
    return this.currentCompany?.companyType === CompanyType.ASC;
  }

  constructor(
    private syncService: SyncService,
    private companyService: CompanyService,
    private pusherService: PusherService,
    private processingService: ProcessingService,
    private headerService: HeaderService,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit() {
    this.headerService.processManualImportCompleted$
      .pipe(
        this.autoCleanUp(),
        tap(() => {
          this.isProcessing = true;
        })
      )
      .subscribe();
    this.pusherService.userChannel.bind('notification', (data) => {
      switch (data.type) {
        case 'header':
          this.header = data.msg;
          break;
        case 'title':
          this.message = data.msg;
          break;
        case 'message':
          this.message = data.msg;
          break;
        case 'notice':
        case 'close':
          if (data.key === NotificationKey.spReportStart && data.type === 'close') {
            Swal.fire({
              html: `
                <div>Upload file successfully</div>
                <div>Please run forecast to see the new updates.</div>
              `,
              type: 'success',
              showCloseButton: true,
              allowOutsideClick: false,
            });
          }

          this.isProcessing = false;
          this.isSyncInProcess = false;
          this.getCurrentCompany(true).subscribe();
          break;
        default:
          throw Error('message type not accounted for.');
      }

      if (!this.isProcessing) {
        this.message = '';
        this.header = '';
      }
    });

    this.processingService.processingStartedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.processingService.processingStoppedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.headerService
      .getCompany()
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data?.jobInitiator ? true : false;
      });

    this.getCurrentCompany().subscribe();

    this.headerService.requestToOpenSyncSubject
      .pipe(this.autoCleanUp())
      .subscribe(() => {
        this.togglePopup();
      });
  }

  getCurrentCompany(getNew = false) {
    const getCompanyFunction = getNew
      ? this.companyService.getById(this.currentCompany.companyKey)
      : this.companyService.getCurrentCompany();

    return getCompanyFunction.pipe(
      this.autoCleanUp(),
      tap((company) => {
        this.currentCompany = company;
        this.lastSync = company.lastSyncDate;
      }),
      switchMap(() => this.currentCompany.companyKey !== '0' && this.syncService.getAscShouldMaintain()),
      tap(({ shouldShowMaintenanceMessage }) => {
        this.isSyncDisabled = shouldShowMaintenanceMessage;
      })
    );
  }

  togglePopup() {
    if (this.isSyncInProcess) {
      return;
    }

    const modalTitle = this.isCurrentCompanyASC
      ? 'Sync with Amazon Seller Central'
      : 'Sync with QuickBooks Desktop';
    const modalContent = this.isCurrentCompanyASC
     ? `
      <div class="generic-modal__content text-center mb-3">You have chosen to sync your data. This will pull the latest data and all the shipments with open status from <strong>Amazon Seller Central</strong>. Please note that this may take some time.</div>
      <div class="generic-modal__content text-center""><strong>Would you like to sync your data now?</strong></div>
    `
    : `
      <div class="generic-modal__content text-center"">Please initiate your data syncs from your <strong>QuickBooks Web Connector</strong>.</div>
    `;
    const modalSuccessBtn = this.isCurrentCompanyASC
      ? 'Start Sync'
      : 'OK';
    const modalErrorBtn = this.isCurrentCompanyASC
      ? 'Cancel'
      : null;
    const modalSuccessHandle = this.isCurrentCompanyASC
      ? () => this.startSync()
      : () => {};

    this.modalService.open(GenericModalComponent, {
      data: {
        titleIconClass: 'icon feather icon-repeat',
        title: modalTitle,
        content: modalContent,
        successBtn: modalSuccessBtn,
        errorBtn: modalErrorBtn,
        theme: 'light',
      },
      backdrop: 'static',
      onSuccess: modalSuccessHandle,
      modalDialogClass: 'sync-modal',
      backdropClass: 'sync-backdrop'
    });
  }

  startSync() {
    if (this.isSyncInProcess || this.isProcessing) {
      return;
    }

    if (
      [CompanyType.ASC, CompanyType.QBO].includes(
        this.currentCompany.companyType as CompanyType
      )
    ) {
      this.syncService
        .ascTestConnection(this.currentCompany)
        .pipe(
          switchMap(({ isConnectionOK, shouldShowMaintenanceMessage }) => {
            if (!isConnectionOK && !shouldShowMaintenanceMessage) {
              this.modalService.open(UpdateSPOCompanyModalComponent, {
                data: {
                  company: this.currentCompany,
                },
                size: 'lg',
                backdrop: 'static',
                keyboard: false,
              });
            }

            if (!isConnectionOK || shouldShowMaintenanceMessage) {
              return NEVER;
            }

            return this.syncService.companySync(this.currentCompany);
          })
        )
        .subscribe(() => {
          this.isProcessing = true;
        });
    }
  }

  popupMaintenanceMessage() {
    const self = this;
    this.modalService.open(GenericModalComponent, {
      data: {
        title: '🛠️ Amazon API Maintenance Notice 🚧',
        content: `
          <p class="generic-modal__content">Attention Amazon Users!</p>
          <p class="generic-modal__content">We apologize for any inconvenience caused. The Amazon API is currently undergoing unscheduled maintenance. Fear not! Our expert team is working diligently to get it back up and running as soon as possible. The issue is only impacting Amazon syncs and the creation of shipments; forecasts and restocking recommendations are still available.</p>
          <p class="generic-modal__content">You can attempt manually importing the data to avoid affecting and interrupting your forecasting and restocking recommendations. Doing it is pretty simple.</p>
          <div class="generic-modal__content">Thank you for your patience and understanding.</div>
        `,
        successBtn: 'Manual Import Data',
      },
      size: 'lg',
      backdrop: 'static',
      onSuccess() {
        self.modalService.open(ManuallyImportDataModalComponent, {
          size: 'lg',
          backdrop: 'static',
          onSuccess() {
            self.isProcessing = true;
          },
        });
      },
      onError() {
        self.headerService.amazonMaintenanceNotfiy$.next(true);
      },
    });
  }
}
