import { FieldValidatorType } from "../../../enums/field-validator-type.enum";
import { IFieldValidator } from "../../../interfaces/i-field-validator";

export class CustomPatternValidator implements IFieldValidator {
  type: FieldValidatorType = FieldValidatorType.Pattern;
  pattern: string | RegExp = "";

  constructor(init?: Partial<CustomPatternValidator>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }


}
