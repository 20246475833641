import { Injectable } from '@angular/core';
import { ResourceService } from './resource-service';
import { User } from './../models/user';
import { IUserResponse } from '../infrastructure/interfaces/user-response.interface';
import { Company } from './../models/company';
import { Realm } from './../models/realm';
import { HttpClient } from '@angular/common/http';
import { StockaidResponse } from '../misc/stockaid-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ResourceService<User> {
  private cache: any = {};
  public adminApiUrl = '/api/admin/users';

  constructor(httpClient: HttpClient) {
    super(httpClient, '/api/user');
  }

  addUserToCompany(
    userId: string,
    company: Company
  ): Observable<StockaidResponse<Realm>> {
    const data: any = {
      userId,
      companyKey: company.companyKey,
      companyType: company.companyType,
      companyName: company.companyName,
      operation: 'addToCompany',
    };

    return this.httpClient.post<StockaidResponse<Realm>>(this.apiUrl, data);
  }

  removeUserFromCompany(userId: string, company: Company): Observable<any> {
    const data: any = {
      userId,
      companyKey: company.companyKey,
      companyType: company.companyType,
      companyName: company.companyName,
      operation: 'removeFromCompany',
    };

    return this.httpClient.post<StockaidResponse<any>>(this.apiUrl, data);
  }

  getUserExists(userId: string): Observable<any> {
    const data: any = {
      userId,
      operation: 'getUserExists',
    };

    return this.httpClient.post(this.apiUrl, data);
  }

  getSpecificUsers(userId: string): Observable<any> {
    const data: any = {
      userIds: [userId],
      operation: 'getSpecificUsers',
    };

    return this.httpClient.post(this.apiUrl, data);
  }

  getUserById(userId: string): Observable<User> {
    return this.httpClient.get<User>(`${this.adminApiUrl}/${userId}`);
  }

  editUser(user: any): Observable<IUserResponse> {
    return this.httpClient.put<IUserResponse>(`${this.apiUrl}`, { user });
  }

  changePassword(
    password: string,
    newPassword: string
  ): Observable<IUserResponse> {
    return this.httpClient.put<IUserResponse>(this.apiUrl + '/password', {
      password,
      newPassword,
    });
  }

  getAllUsers(): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.adminApiUrl}`);
  }

  editUserByAdmin(user: User): Observable<IUserResponse> {
    return this.httpClient.put<IUserResponse>(
      `${this.adminApiUrl}/${user.userId}`,
      user
    );
  }

  delete(userId: string): Observable<any> {
    return this.httpClient.delete(`${this.adminApiUrl}/${userId}`);
  }

  setUserGridState(state: any) {
    this.cache['userGridState'] = state;
  }

  getUserGridState() {
    return this.cache['userGridState'];
  }

  /**
   *
   * @returns current selected supplier for Restock Suggestion grid
   */
  getRestockSuggestionSupplier(): string {
    return sessionStorage.getItem('restockSuggestionSupplier');
  }

  /**
   *
   * @param supplierName current selected supplier, empty means returning to Supplier List tab
   */
  saveRestockSuggestionSupplier(supplierName?: string): void {
    if (!supplierName) {
      sessionStorage.removeItem('restockSuggestionSupplier');
      return;
    }

    sessionStorage.setItem('restockSuggestionSupplier', supplierName);
  }

  getGridKey(type: string): string {
    return sessionStorage.getItem(type);
  }

  saveGridKey(type: string, key?: string): void {
    if (!key) {
      sessionStorage.removeItem(type);
      return;
    }

    sessionStorage.setItem(type, key);
  }
}
