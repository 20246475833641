import { Logic } from '../enums/logic.enum';
export class GridStateColumnFilter {
  filters = [];
  logic: Logic;

  constructor(init?: Partial<GridStateColumnFilter>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
