export const NOTIFICATIONS = {
  accountAlerts: [
    {
      title: 'Subscription Ending Soon',
      link: 'Renew/Change Subscription',
    },
    { title: 'Payment Method Expired', link: 'Update Payment Method' },
  ],
  missingInformations: [
    { title: 'Items Without Supplier', link: '# Items' },
    { title: 'Items Missing Cost', link: '# Items' },
    { title: 'No MOQ Entered', link: '# Items' },
    { title: 'No Case Pack Information', link: '# Items' },
    { title: 'Supplier Missing an Address', link: '# Items' },
    { title: 'Items With 0 Warehouse Qty', link: '# Items' },
  ],
  metrics: [
    {
      title: 'Top 10 Items With Highest Total Orders in the Period',
      link: '# Items',
      icon: 'feather icon-plus-circle text-success',
    },
    {
      title: 'Items That Did Not Sell Any/Least Units in the Period',
      link: '# Items',
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Items With High Out of Stock %',
      link: '# Items',
      icon: 'feather icon-info text-warning',
    },
  ],
  purchasingStatusChanges: [
    {
      title: 'Out of Stock',
      link: '#Items',
      icon: 'far fa-times-circle text-danger',
    },
    {
      title: 'Low Stock',
      link: '#Items',
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Well Stocked',
      link: '#Items',
      icon: 'feather icon-plus-circle text-success',
    },
    {
      title: 'Over Stocked',
      link: '#Items',
      icon: 'far fa-times-circle text-danger',
    },
    {
      title: 'Purchase Orders With Upcoming Receive Dates',
      link: '#Items',
      icon: 'feather icon-info text-warning',
    },
  ],
  purchasingSuggestions: [
    {
      title: 'Items With Upcoming Recommended Order Dates',
      link: '#Items',
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Number of Suggested POs',
      link: '#Items',
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Total # Items on Suggested POs',
      link: '#Items',
      icon: 'feather icon-info text-warning',
    },
  ],
  restockAMZStatusChanges: [
    {
      title: 'Change in Buy Box Price or Avg 7 Day Price',
      links: ['#Items'],
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Items With Inbound Quantities',
      links: ['#Items'],
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Shipment Status Change',
      links: ['# Shipped', '# In Transit', '# Receiving', '# Closed'],
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Shipments in Pending Status',
      links: ['# Shipments'],
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Shipments in Working Status',
      links: ['# Shipments'],
      icon: 'feather icon-info text-warning',
    },
    {
      title: 'Items With Unfulfillable Units',
      links: ['# Items'],
      icon: 'feather icon-info text-warning',
    },
  ],
  restockAMZSuggestions: [
    {
      title: 'Stock Status',
      links: [
        {
          icon: 'fa fa-flag fa-fw text-danger',
          iconColor: '',
          text: 'Out of Stock',
        },
        {
          icon: 'fa fa-flag fa-fw',
          iconColor: '#fe8945',
          text: 'Restock Now',
        },
        {
          icon: 'fa fa-flag fa-fw text-warning',
          iconColor: '',
          text: 'Restock Soon',
        },
        {
          icon: 'fa fa-flag fa-fw text-success',
          iconColor: '',
          text: 'Well Stocked',
        },
        {
          icon: 'fa fa-flag fa-fw text-primary',
          iconColor: '',
          text: 'New Items',
        },
      ],
      icon: 'feather icon-info text-warning',
    },
    {
      title:
        'Items With Warehouse Restock Recommendation Exceeding the Warehouse Qty',
      links: [ '# Items' ],
      icon: 'feather icon-info text-warning',
    }
  ],
};

export const PURCHASING_STATUS_CHANGES = {
  outOfStock: {
    content: 'Out of Stock',
    icon: 'far fa-times-circle text-danger',
  },
  lowStock: {
    content: 'Low Stock',
    icon: 'feather icon-info text-warning',
  },
  wellStocked: {
    content: 'Well Stocked',
    icon: 'feather icon-plus-circle text-success',
  },
  overStocked: {
    content: 'Over Stocked',
    icon: 'far fa-times-circle text-danger',
  },
  supplyWithUpcomingReceiveDates: {
    content: 'Purchase Orders With Upcoming Receive Dates',
    icon: 'feather icon-info text-warning',
  },
};

export const RESTOCKAMZ_STATUS_CHANGE = {
  shipmentShipped: {
    field: 'shipmentShipped',
    name: 'Shipped',
  },
  shipmentInTransit: {
    field: 'shipmentInTransit',
    name: 'In Transit',
  },
  shipmentReceiving: {
    field: 'shipmentReceiving',
    name: 'Receiving',
  },
  shipmentClosed: {
    field: 'shipmentClosed',
    name: 'Closed',
  },
};

export const StockStatus = {
  outOfStock: {
    field: 'outOfStock',
    name: 'Out Of Stock',
    icon: 'fa fa-flag fa-fw text-danger',
    iconColor: '',
  },
  restockNow: {
    field: 'restockNow',
    name: 'Restock Now',
    icon: 'fa fa-flag fa-fw',
    iconColor: '#fe8945',
  },
  restockSoon: {
    field: 'restockSoon',
    name: 'Restock Soon',
    icon: 'fa fa-flag fa-fw text-warning',
  },
  wellStock: {
    field: 'wellStock',
    name: 'Well Stocked',
    icon: 'fa fa-flag fa-fw text-success',
    iconColor: '',
  },
  newItems: {
    field: 'newItems',
    name: 'New Items',
    icon: 'fa fa-flag fa-fw text-primary',
    iconColor: '',
  },
};

export const NOTIFICATIONS_DISPLAY = {
  ACCOUNT_ALERTS: {
    field: 'ACCOUNT_ALERTS',
    name: 'Account Alert',
    bgIcon: 'bg-red'
  },
  MISSING_NOTIFICATION: {
    field: 'MISSING_NOTIFICATION',
    name: 'Missing Information',
    bgIcon: 'bg-red'
  },
  PURCHASING_STATUS_CHANGE: {
    field: 'PURCHASING_STATUS_CHANGE',
    name: 'Purchasing Status Change',
    bgIcon: 'bg-yellow'
  },
  PURCHASING_SUGGESTION: {
    field: 'PURCHASING_SUGGESTION',
    name: 'Purchasing Suggestion',
    bgIcon: 'bg-yellow'
  },
  RESTOCK_AMZ_STATUS_CHANGE: {
    field: 'RESTOCK_AMZ_STATUS_CHANGE',
    name: 'RestockAMZ Status Change',
    bgIcon: 'bg-yellow'
  },
  RESTOCK_AMZ_SUGGESTION: {
    field: 'RESTOCK_AMZ_SUGGESTION',
    name: 'RestockAMZ Suggestion',
    bgIcon: 'bg-yellow'
  },
  METRIC: {
    field: 'METRIC',
    name: 'Metric',
    bgIcon: 'bg-yellow'
  },
};

export enum Metrics {
  iconTopItemsWithHighestOrders = 'feather icon-plus-circle text-success',
  iconTopItemsDidNotSells = 'feather icon-info text-warning',
  iconTopItemWithHightOutStock = 'feather icon-info text-warning'
}

export enum NotificationTypes {
  noSupplier = 'no-supplier',
  noSupplierPrice = 'no-supplier-price',
  noMOQ = 'no-moq',
  noCasePackQty = 'no-case-pack-qty',
  noWarehouseQty = 'no-warehouse-qty',
  topOutOfStock = 'top-out-of-stock',
  upcomingReceiveDates = 'upcoming-receive-dates',
  inboundQuantities = 'inbound-quantities',
  unfulfillable = 'unfulfillable',
  warehouseRestockRecomendation = 'warehouse-restock-recommendation-exceeding-warehouse-qty'
}
