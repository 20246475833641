import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '../models/company';
import { User } from '../models/user';
import { CompanyService } from '../services/company.service';
import { BaseComponent } from './base.component';
import { CompanyType } from '../infrastructure/enums/company-type.enum';

@Component({
  selector: 'app-base',
  template: `
    <p>
      base works!
    </p>
  `,
  styles: [
  ]
})
export class DashboardBaseComponent extends BaseComponent implements OnInit {
  currentCompany: Company;
  currentUser: User;

  constructor(
    public companyService: CompanyService,
    public authService: AuthenticationService,
    public router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.companyService.currentCompany()) {
      this.refreshCompany(this.companyService.currentCompany());
    }

    this.getCurrentCompany().pipe(this.autoCleanUp()).subscribe();
    this.currentUser = this.authService.currentUser;
  }

  getCurrentCompany(): Observable<Company> {
    return this.companyService.getCurrentCompany().pipe(
      tap((company) => {
        this.refreshCompany(company);
      })
    );
  }

  refreshCompany(newCompany: Company) {
    this.currentCompany = newCompany;
    if (this.currentCompany.companyType === CompanyType.ASC && !this.currentCompany.isAuthorized) {
      return;
    }

    //if no forecast date, the company may need more work on the onboarding.
    if (!this.currentCompany?.jobInitiator && !this.currentCompany?.lastForecastDate) {
      this.router.navigate([
        '/onboarding/processing',
        { companyKey: this.currentCompany.companyKey },
      ]);
    }
  }

  onReloadCompany(isReload: boolean): void {
    this.companyService.getById(this.currentCompany.companyKey, true).subscribe(company => {
      setTimeout(() => {
        this.refreshCompany(company);
      }, 1000);
    })
  }
}
