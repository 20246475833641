export class Pagination {
  offset:number = 0;
  limit:number = 0;

  constructor(init?: Partial<Pagination>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }
}
