import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Warehouse } from '../models/warehouse';
import { CacheableResourceService } from './cacheable-resource-service';

@Injectable({
  providedIn: 'root',
})
export class WarehouseService extends CacheableResourceService<Warehouse> {
  public adminApiUrl = '/api/admin/companies';

  constructor(httpClient: HttpClient) {
    super(httpClient, '/api/warehouse');
  }

  getWareHouses() {
    return super.cache['/api/warehouse'];
  }
}
