<div class="forecast-header row d-flex-md align-items-stretch">
  <div *ngFor="let item of dataRows" class="col-md-3 col-sm-12 mb-4">
    <div class="card h-100">
      <div class="card-body">
        <div class="row">
          <div class="col mt-0">
            <a
              (click)="isInteraction && onClickItem(item.key)"
              class="text-muted"
              [class.text-decoration-none]="isHiddenTextDecoration(item?.name)"
              [class.k-cursor-default]="isHiddenTextDecoration(item?.name)"
            >
              <h5 class="card-title">
                <span [ngSwitch]="item?.name">
                  {{ item?.name }}
                  <span *ngSwitchCase="SummaryItemNameEnum.AmazonItems">
                    <br />
                    {{ SummaryItemStatusEnum.OutOfStock }}
                  </span>
                  <span *ngSwitchCase="SummaryItemNameEnum.WarehouseItems">
                    <br />
                    {{ SummaryItemStatusEnum.OutOfStock }}
                  </span>
                  <span *ngSwitchCase="SummaryItemNameEnum.NewItems">
                    <br />
                    {{ SummaryItemStatusEnum.Lats30Days }}
                  </span>
                </span>
              </h5>
            </a>
          </div>

          <div class="col-auto">
            <div class="stat text-primary">
              <i class="{{ getIcon(item?.name) }} item-center"></i>
            </div>
          </div>
        </div>
        <h1 class="mt-1 mb-3">
          {{
            item?.isMoney
              ? (item.total
                | currency : displayCurrency.code : displayCurrency.symbol : '1.2')
              : (item?.total | number : 0)
          }}
        </h1>
        <span class="mr-1" [ngClass]="{
          'blue-pale': [
            SummaryItemNameEnum.AmazonItems,
            SummaryItemNameEnum.ItemsOutOfStock,
            SummaryItemNameEnum.SuggestedPurchaseOrders
          ].includes(item?.name),
          'red-pale': [
            SummaryItemNameEnum.NoSuppliersAssigned,
            SummaryItemNameEnum.TotalUnitsOnSuggestedPurchaseOrders
          ].includes(item?.name),
          'green-pale': [
            SummaryItemNameEnum.NewItems,
            SummaryItemNameEnum.WarehouseItems,
            SummaryItemNameEnum.TotalCostOfSuggestedPurchaseOrders,
            SummaryItemNameEnum.TotalItemsOnSuggestedPurchaseOrders,
            SummaryItemNameEnum.TotalEstimatedStorageFees
          ].includes(item?.name)
        }">
          {{ item.different }}%
        </span>
        <span class="card-title"> Since last week</span>
      </div>
    </div>
  </div>
</div>
