// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { version } from '../../package.json';

export const environment = {
  version,
  production: false,
  rollbarToken: '11b08741b2314675957649a8a6a1b122',
  pusher: {
    PUSHER_API_KEY: '76eeac4edb5f47debfcc',
    PUSHER_API_CLUSTER: 'ap1'
  },
  calendlyUrl: 'https://calendly.com/forecast-alt/30min',
  calendlyFirstRegistrationUrl: 'https://calendly.com/d/cpz4-q59-hjr/forecastrx-company-setup',
  csvboxLicenseKeys: {
    csv: {
      item: 'pCCjwOHxlcoAn5vXyzafLALVXKUdX9',
      supplier: 'sZoGaWOppHRHMq0oW6LLre9MEJ08LR',
      demand: 'RWiCSB7tlmwRqJckbagUQHjymlLpMY',
      supply: 'QAhKevuEDKvIcRw56wvyECmyUKsPUY',
      bom: '6c8HwrdXo6fReyuVMXJ6VF3PTp8m6n',
      'historyOverride': 'Bf2rHWb17dhxfZ4C3lNQV126Cmc8UU',
      itemsite: 'HPiOzgFW0yfoakzP4OTVI6GNdyPkwh',
      forecastedHistorySetting: 'dQ6tvGmaUKasQAbeRBIl7hgAYIUirT'
    },
    asc: {
      item: 'cHrSGUHPHcgyE6nEnolaF5KJu5Y7yT',
      supplier: 'fHsBw6PpbXavEUXF9kGKLTbbnLeddC',
      supply: 'gJitnTLzcQHuWvqtaC4naa9m4hjc2H',
      bom: '6c8HwrdXo6fReyuVMXJ6VF3PTp8m6n',
      shipmentItem: 'hDSrQBJHXx252AxQhCTuLqKhl4Z6aX',
      'historyOverride': 'Bf2rHWb17dhxfZ4C3lNQV126Cmc8UU',
      forecastedHistorySetting: 'dQ6tvGmaUKasQAbeRBIl7hgAYIUirT',
      shipmentItemRS: 'hCpLMF2CL3yjr68vIbrA4bcqmDTIHy'
    },
    qb: {
      item: 'NjP27MSPes3gZBMiq5Xm4TMjGh8PLr',
      supplier: '8dDYN4jfayd0E2lCpRjvE0eJjXqddl',
      'historyOverride': 'Bf2rHWb17dhxfZ4C3lNQV126Cmc8UU',
      forecastedHistorySetting: 'dQ6tvGmaUKasQAbeRBIl7hgAYIUirT'
    },
  },
  s3PublicBucketUrl: 'https://stockaid-public.s3.amazonaws.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
