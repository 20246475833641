import { Injectable } from '@angular/core';
import { RestockSuggestion } from '../models/restock-suggestion';
import { ResourceService } from './resource-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Pagination } from '../infrastructure/classes/pagination';
import { stringifyJSONWithDates } from '@stockaid/utils';

@Injectable({
  providedIn: 'root',
})
export class RestockSuggestionService extends ResourceService<RestockSuggestion> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/restock-suggestion');
  }

  getByIdWithType(
    itemKey: string,
    salesVelocitySettingsType: SalesVelocitySettingsType = SalesVelocitySettingsType.restockAMZ,
    marketplaceId?: string,
    currencyCode?: string,
  ): Observable<RestockSuggestion> {
    let params = new HttpParams();
    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    return this.httpClient.get<RestockSuggestion>(
      `${this.apiUrl}/${encodeURIComponent(itemKey)}/${salesVelocitySettingsType}`,
      { params }
    );
  }

  save(
    item: RestockSuggestion,
    objectKey: any = 'id',
    salesVelocitySettingsType: SalesVelocitySettingsType = SalesVelocitySettingsType.restockAMZ
  ): Observable<any> {
    // new item
    if ((item[objectKey] === '' || !item[objectKey])) {
      return this.httpClient.post(`${this.apiUrl}`, item);
    }

    return this.httpClient.put(`${this.apiUrl}/${encodeURIComponent(item[objectKey])}/${salesVelocitySettingsType}`, item);
  }

  getItemsAdvanced(state: {
    type: string;
    skip: number;
    take: number;
    filter: any;
    sort: SortDescriptor[];
    marketplaceId: string;
    currencyCode: string;
  }): Observable<{ model: RestockSuggestion[]; count: number }> {
    const { type, skip, take, filter, sort, marketplaceId, currencyCode } = state;

    const pagination = new Pagination({ offset: skip, limit: take });

    const sortArray = this.parseSortT(sort);

    let params = new HttpParams();

    if (pagination) {
      params = params.set('offset', pagination.offset?.toString());
      params = params.set('limit', pagination.limit?.toString());
    }

    if (sortArray.length) {
      params = params.set('sort', JSON.stringify(sortArray));
    }

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    params = params.set('advancedFilter', type);

    return this.httpClient.get<any>(
      `${this.apiUrl}/advanced`,
      { params }
    );
  }

}
