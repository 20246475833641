import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource-service';
import { Bom } from '../models/bom';
import { SortAttribute } from 'src/app/core/infrastructure/classes/sort-attribute';
import { StockaidResponse } from '../misc/stockaid-response';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BomService extends ResourceService<Bom> {

  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/bom');
  }

  public stockaidPaginated(
    offset: number,
    limit: number,
    sortAttributes: SortAttribute[],
    filters: []
  ): Observable<StockaidResponse<Bom[]>> {
    sortAttributes = sortAttributes || []
    const body: any = {
      offset: offset,
      limit: limit,
      sort: sortAttributes,
      filter: [],
      operation: 'paginate',
    };

    return this.httpClient.post<StockaidResponse<Bom[]>>(this.apiUrl, body);
  }

getBomsByParentId(itemKey: string): Observable<Bom[]> {
    return this.httpClient.get<Bom[]>(`${this.apiUrl}/${encodeURIComponent(itemKey)}`);
  }
}
