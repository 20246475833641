import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Company } from '../models/company';
import { Realm } from '../models/realm';
import { ISubscription } from '../models/subscription';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private companySubject: Subject<Company> = new Subject<Company>();
  private changeSubject: Subject<boolean> = new Subject<boolean>();
  private searchSubject: Subject<boolean> = new Subject<boolean>();
  private bannerSubject: Subject<boolean> = new Subject<boolean>();
  private realmSubject: Subject<Realm> = new Subject<Realm>();
  private companyNameSubject: Subject<any> = new Subject<any>();
  private adminStatusChangeSubject: Subject<any> = new BehaviorSubject<boolean>(
    true
  );
  private subscriptionRevertSubject: Subject<ISubscription> =
    new Subject<ISubscription>();
  private menuCollapseSubject: Subject<boolean> = new Subject<boolean>();
  private navbarCollapseSubject = new Subject<boolean>();
  navbarCollapseSubject$ = this.navbarCollapseSubject.asObservable();

  public goToItemManagementSubject: Subject<any> = new Subject<any>();
  public isFullScreen: Subject<boolean> = new Subject<boolean>();
  public requestToOpenSyncSubject: Subject<any> = new Subject<any>();
  public requestToOpenForecastSubject: Subject<boolean> = new Subject<boolean>();
  public isStepCompletedSubject: Subject<any> = new Subject<any>();
  public loadShipmentSubject: Subject<any> = new BehaviorSubject<any>(null);
  public notifyForecastSubject: Subject<boolean> = new Subject<boolean>();
  public amazonMaintenanceNotfiy$ = new Subject<any>();
  public processManualImportCompleted$ = new Subject<any>();

  sendCompany(company: Company): void {
    this.companySubject.next(company);
  }

  getCompany(): Observable<Company> {
    return this.companySubject.asObservable();
  }

  clearCompany(): void {
    this.companySubject.next();
  }

  setChange(isChange: boolean): void {
    this.changeSubject.next(isChange);
  }

  getChange(): Observable<boolean> {
    return this.changeSubject.asObservable();
  }

  setSearchStatus(searchOn: boolean): void {
    this.searchSubject.next(searchOn);
  }

  getSearchStatus(): Observable<boolean> {
    return this.searchSubject.asObservable();
  }

  setShowBanner(searchOn: boolean): void {
    this.bannerSubject.next(searchOn);
  }

  isShowBanner(): Observable<boolean> {
    return this.bannerSubject.asObservable();
  }

  sendRealm(realm: Realm): void {
    this.realmSubject.next(realm);
  }

  getLatestRealm(): Observable<Realm> {
    return this.realmSubject.asObservable();
  }

  sendCompanyName(newName: string, companyKey: string): void {
    this.companyNameSubject.next({ newName, companyKey });
  }

  getCompanyName(): Observable<any> {
    return this.companyNameSubject.asObservable();
  }

  setAdminStatusChange(): void {
    this.adminStatusChangeSubject.next(true);
  }

  getAdminStatusChange(): Observable<boolean> {
    return this.adminStatusChangeSubject.asObservable();
  }

  revertSubscription(subscription: ISubscription): void {
    this.subscriptionRevertSubject.next(subscription);
  }

  getRevertSubscription(): Observable<ISubscription> {
    return this.subscriptionRevertSubject.asObservable();
  }

  setMenuCollapse(isCollapsed: boolean): void {
    this.menuCollapseSubject.next(isCollapsed);
  }

  setNavbarCollapse(value: boolean) {
    this.navbarCollapseSubject.next(value);
  }

  getMenuCollapse(): Observable<boolean> {
    return this.menuCollapseSubject.asObservable();
  }

  setStepCompleted(stepName: string, isCompleted: boolean): void {
    this.isStepCompletedSubject.next({ stepName, isCompleted });
  }

  getStepCompleted(): Observable<{ stepName: string; isCompleted: boolean }> {
    return this.isStepCompletedSubject.asObservable();
  }
}
