export class POItem {
  inPO?: boolean = false;
  companyType: string = '';
  companyKey: string = '';
  itemKey: string = '';
  itemName: string = '';
  asin?: string = '';
  fnsku?: string = '';
  description: string = '';
  tag: string = '';
  tags: string = '';
  vendorKey: string = '';
  vendorName: string = '';
  consolidatedQty: number = 0;
  onNewPo: number = 0;
  recommendedQty: number = 0;
  type: string = '';
  dueDate: string = '';
  moq: number = 0;
  lotMultipleQty: number = 0;
  purchaseQty: number = 0;
  vendorPrice: number = 0;
  total: number = 0;
  leadTime: number = 1;
  orderInterval: number = 0;
  addedToSupplies: number = 0;
  documentUuid?: any = '';
  purchasingSummariesUuid: string = '';
  s7d: number = 0;
  s30d: number = 0;
  s90d: number = 0;
  s180d: number = 0;
  s365d: number = 0;
  sMtd: number = 0;
  sYtd: number = 0;
  snapshotQty: number = 0;
  meanLtd: number = 0;
  safetyStockLtd: number = 0;
  onHand: number = 0;
  onHandMin: number = 0;
  onHandThirdParty: number = 0;
  onHandThirdPartyMin: number = 0;
  inbound: number = 0;
  inboundPrice: number = 0;
  inboundSalesLast30Days: number = 0;
  inboundAvailable: number = 0;
  inboundFcTransfer: number = 0;
  inboundFcProcessing: number = 0;
  inboundCustomerOrder: number = 0;
  inboundUnfulfillable: number = 0;
  inboundAlert?: any;
  inboundWorking: number = 0;
  mwsFulfillmentFee: number = 0;
  lastOrderDate?: string = '';
  nextScheduledOrderDate?: any;
  daysUntilNextScheduledOrder?: any;
  outOfStockDate: string;
  daysLeft: number = 0;
  recommendedOrderDate: string;
  daysRemaining: number = 0;
  openPurchaseOrders: number = 0;
  openSalesOrders: number = 0;
  created_at: Date = new Date();
  updated_at: Date = new Date();
  trueRecommendedQty: number = 0;
  orderQty: number = 0;
  isInPO: boolean = false;

  constructor(init?: Partial<POItem>) {
    if (init) {
      // if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
