<div #itemDetail class="item-detail" *ngIf="choosenItem">
  <div class="modal-header text-center">
    <div class="modal-title w-100">
      <h5 class="text-white">{{ itemName }}</h5>
      <button class="item-detail__go-btn btn btn btn-light" (click)="goToItemManagement()">
        <i class="feather icon-external-link"></i>
        Go To Item Management
      </button>
      <div class="d-flex">
        <button
          type="button"
          class="btn mx-1 my-3 btn-primary btn-export-item-card"
          aria-label="Close"
          [class.btn-export-item-card__hidden]="isExportPDF"
          (click)="onExportPDF()"
        >
          <i class="fas fa-download mr-2"></i>
          Export
        </button>

        <button
          *ngIf="shouldDisplayOverride"
          type="button"
          class="btn mx-1 my-3"
          [ngClass]="
            choosenItem?.isCustom ? 'btn-override' : 'btn-override--off'
          "
          aria-label="Override"
          (click)="handleOverrideClick()"
        >
          Override Forecast Data: {{ choosenItem?.isCustom ? 'ON' : 'OFF' }}
        </button>

        <button
          *ngIf="isTotalComponentDataToggleVisible"
          type="button"
          class="btn mx-1 my-3"
          [ngClass]="
            isTotalComponentDataOn ? 'btn-total-component' : 'btn-total-component--off'
          "
          aria-label="Total Component Data"
          (click)="handleTotalComponentDataClick($event)"
          ngbTooltip="Toggle on for sales/inventory to reflect total usage of the component and turn off for SKU only data"
          placement="bottom"
          container="body"
        >
          Total Component Data: {{ isTotalComponentDataOn ? 'ON' : 'OFF' }}
        </button>
      </div>

    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div *ngIf="isHidden && !isPO" class="text-center">
      <button class="btn btn-warning" (click)="makeVisible()">Make visible</button>
    </div>
    <div *ngIf="!isHidden && isLoading" class="text-center">
      <h5 class="text-white">Loading...</h5>
    </div>
    <div *ngIf="!isHidden && !isReady && !isLoading && !isPO" class="text-center">
      <h6 class="text-white">Demand information is not available for this Item.</h6>
      <h5 class="text-white">Please run a FORECAST to summarize all available information.</h5>
      <button class="btn btn-danger" (click)="openForecastModal()">Open Forecast Modal</button>
    </div>
    <ngb-accordion *ngIf="!isLoading && !isHidden && isReady" activeIds="panel-0">
      <!-- Forecast Graph -->
      <ngb-panel id="panel-0"  *ngIf="chartData?.length">
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Forecast Graph</span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <kendo-chart>

            <!-- Chart legend -->
            <kendo-chart-legend position="bottom" orientation="horizontal">
            </kendo-chart-legend>

            <!-- Chart data -->
            <kendo-chart-series>
              <kendo-chart-series-item
                *ngFor="let legend of legends; let i = index"
                [name]="legend"
                type="line"
                field="qty"
                categoryField="month"
                [data]="chartData[i]"
                [color]="chartColorArray[i]"
              >
                <kendo-chart-series-item-tooltip>
                  <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                    {{legend}}: {{value}}
                  </ng-template>
                </kendo-chart-series-item-tooltip>
              </kendo-chart-series-item>
            </kendo-chart-series>

          </kendo-chart>
        </ng-template>
      </ngb-panel>

      <!-- Forecast Data -->
      <ngb-panel >
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Forecast Data</span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <kendo-grid
            [data]="gridItems"
            [sortable]="true"
            [sort]="sort"
            height="200"
            (sortChange)="sortChange($event)"
            [resizable]="true"
            [navigatable]="true"
          >
            <kendo-grid-column
              *ngFor="let col of columns"
              [field]="col.field"
              [title]="col.displayName"
              [width]="col.field === 'legend' ? 130 : 50"
              class="text-center px-1 py-1"
            >
              <ng-template
                kendoGridCellTemplate
                let-dataItem
                let-rowIndex="rowIndex"
                let-columnIndex="columnIndex"
              >
                <span
                  class="text-center d-block"
                  [style.color]="col.field === 'legend' ? pickTextColorBasedOnBgColor(colorArray[rowIndex]) : ''"
                  [style.backgroundColor]="col.field === 'legend' ? colorArray[rowIndex] : ''"
                >
                  {{ dataItem[col.field] }}
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </ng-template>
      </ngb-panel>

      <!-- Purchasing Calculations and Details -->
      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Purchasing Calculations and Details</span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex justify-content-center">
            <!-- Recent Historical Data & Inventory Status -->
            <div class="flex-fill">
              <!-- Recent Historical Data -->
              <app-generic-data-table
                *ngIf="recentHistoricalData.rows"
                [title]="recentHistoricalData.title"
                [rows]="recentHistoricalData.rows"
              ></app-generic-data-table>

              <!-- Inventory Status -->
              <div [class.mt-5]="isPurchasing">
                <app-generic-data-table
                  *ngIf="inventoryStatusData.rows"
                  [title]="inventoryStatusData.title"
                  [rows]="inventoryStatusData.rows"
                ></app-generic-data-table>
              </div>
            </div>

            <!-- Future Projections -->
            <app-generic-data-table
              class="flex-fill"
              *ngIf="futureProjectionsData.rows"
              [title]="futureProjectionsData.title"
              [rows]="futureProjectionsData.rows"
            ></app-generic-data-table>

            <!-- Sales History -->
            <app-generic-data-table
              class="flex-fill"
              *ngIf="salesHistoryData.rows"
              [title]="salesHistoryData.title"
              [rows]="salesHistoryData.rows"
            ></app-generic-data-table>
          </div>
        </ng-template>
      </ngb-panel>

      <!-- Settings -->
      <ngb-panel>
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Settings</span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="d-flex justify-content-center">
            <!-- Ordering Parameters -->
            <app-generic-data-table
              class="flex-fill"
              *ngIf="orderingParametersData.rows"
              [title]="orderingParametersData.title"
              [rows]="orderingParametersData.rows"
            ></app-generic-data-table>

            <!-- Vendor Information -->
            <app-generic-data-table
              class="flex-fill"
              *ngIf="vendorInformationData.rows"
              [title]="vendorInformationData.title"
              [rows]="vendorInformationData.rows"
            ></app-generic-data-table>

            <!-- Item Relationships -->
            <app-generic-data-table
              class="flex-fill"
              *ngIf="itemRelationshipsData.rows"
              [title]="itemRelationshipsData.title"
              [rows]="itemRelationshipsData.rows"
            ></app-generic-data-table>
          </div>
        </ng-template>
      </ngb-panel>

      <!-- Purchase Orders -->
      <ngb-panel *ngIf="supplies?.length !== 0">
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Purchase Orders</span>
            <span
              class="ml-1"
              ngbTooltip="POs with prior receive dates may not factored into purchasing recommendations."
              container="body"
            >
              <i class="fas fa-info-circle cursor-pointer"></i>
            </span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-generic-kendo-grid
            *ngIf="purchaseOrdersData.gridItems"
            [columns]="purchaseOrdersData.columns"
            [gridItems]="purchaseOrdersData.gridItems"
          ></app-generic-kendo-grid>
        </ng-template>
      </ngb-panel>

      <!-- Kit Demand (Children) -->
      <ngb-panel *ngIf="snapshots?.length !== 0 && isPurchasing">
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Kit Demand (Children)</span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-generic-kendo-grid
            *ngIf="kitDemandData.gridItems"
            [columns]="kitDemandData.columns"
            [gridItems]="kitDemandData.gridItems"
          ></app-generic-kendo-grid>
        </ng-template>
      </ngb-panel>

      <!-- Kit Demand (Parent) -->
      <ngb-panel *ngIf="kitSnapshots?.length !== 0 && isPurchasing">
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Kit Demand</span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-generic-kendo-grid
            *ngIf="parentKitDemandData.gridItems"
            [columns]="parentKitDemandData.columns"
            [gridItems]="parentKitDemandData.gridItems"
          ></app-generic-kendo-grid>
        </ng-template>
      </ngb-panel>

      <!-- Consolidate -->
      <ngb-panel *ngIf="consolidations?.length !== 0">
        <ng-template ngbPanelHeader let-opened="opened">
          <button
            ngbPanelToggle
            class="btn btn-link container-fluid text-left pl-0"
          >
            <i *ngIf="!opened" class="fas fa-plus ml-2 mr-1"></i>
            <i *ngIf="opened" class="fas fa-minus ml-2 mr-1"></i>
            <span class="ml-1">Consolidate</span>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-generic-kendo-grid
            *ngIf="consolidateData.gridItems"
            [columns]="consolidateData.columns"
            [gridItems]="consolidateData.gridItems"
          ></app-generic-kendo-grid>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </div>
  <div class="item-card__sync-cover" *ngIf="isLoadingExport">
    <div class="item-card__sync-cover-content">
      <div class="spinner-border text-light" role="status">
        <span class="sr-only"></span>
      </div>
      <p class="item-card__sync-cover-message">
        Loading...
      </p>
    </div>
  </div>
</div>


