export class ShipmentItem {
  itemKey: string = '';
  restockKey: string = '';
  shipmentId: string = '';
  destinationFulfillmentCenterId: string = '';
  labelType: string = '';
  localQty: number = 0;
  orderQty: number = 0;
  shipmentQty: number = 0;
  isShipByCase: boolean;
  whoPreps: string = '';
  caseQty: number = 0;
  cost: number;
  receivedQty: number = 0;
  hasSticker: boolean;
  stickerQty: number = 0;

  constructor(init?: Partial<ShipmentItem>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
