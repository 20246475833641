import { GridStateColumn } from './grid-state-column';
import { GridStatePagination } from './grid-state-pagination';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  State,
} from '@progress/kendo-data-query';
import { Logic } from '../enums/logic.enum';
import { GridView } from '../../models/grid-view';

export class GridState {
  columns: GridStateColumn[] = [];
  scrollFocus: any = {};
  selection: any[] = [];
  grouping: any = {};
  treeView: any = {};
  pagination: GridStatePagination = new GridStatePagination();
  filter: CompositeFilterDescriptor = {
    logic: Logic.and,
    filters: [],
  };
  sort: SortDescriptor[] = [];
  searchTerm: string;
  selectedGridView: GridView;
  selectedGridViewState: State;
  removalOrdersFilter: CompositeFilterDescriptor;

  constructor(init?: Partial<GridStateColumn>) {
    if (init) {
      // if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}
