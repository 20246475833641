import { CompanyType } from '../infrastructure/enums/company-type.enum';
import { SalesVelocitySettingData } from './sales-velocity';
import { SalesVelocitySettingsType } from '@stockaid/shared-enums';

export class Vendor {
  key: string = '';
  name: string = '';
  description: string = '';
  isHidden: boolean = false;
  shipVia: string = '';
  email: string = '';
  moq: number = 1;
  additionalInventory: number = 0;
  leadTime: number = 1;
  orderInterval: number = 0;
  serviceLevel: number = 85;
  forcastTags: any[] = [];
  phone: string = '';
  fax: string = '';
  website: string = '';
  addressShippingUuid: string = '';
  addressBillingUuid: string = '';
  targetOrderValue: number = 0;
  freeFreightMinimum: number = 0;
  restockModel: string = '';
  averageHistoryLength: number = 0;

  constructor(init?: Partial<Vendor>) {
    if (init) {
      //if no values are passed in, fresh instantiation
      Object.assign(this, init);
    }
  }
}

export class SummaryByVendor {
  vendorKey: string = '';
  vendorName: string = '';
  earliestDueDate: string;
  uniqueItems: number = 0;
  totalQty: number = 0;
  totalPrice: number = 0;
  earliestRecommendedOrderDate: string;
  companyKey: string = '';
  companyType: CompanyType;

  constructor(init?: Partial<SummaryByVendor>) {
    //if no values are passed in, fresh instantiation
    Object.assign(this, init || {});
  }
}

export class VendorSalesVelocitySettings {
  companyType:string;
  companyKey:string;
  vendorKey:string;
  restockModel:string;
  localLeadTime: number;
  targetQtyOnHandMin: number;
  targetQtyOnHandMax: number;
  salesVelocityType?: string;
  salesVelocitySettingData?: SalesVelocitySettingData;
  salesVelocitySettingsType?: SalesVelocitySettingsType;

  constructor(init?: Partial<VendorSalesVelocitySettings>) {
    //if no values are passed in, fresh instantiation
    Object.assign(this, init || {});
  }
}
