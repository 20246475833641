
import { transformBooleanToYesNo } from '@stockaid/utils';

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dataTransformBoolean1'
})

export class DataTransformBooleanPipe implements PipeTransform {
  transform(value: boolean): any {
    return transformBooleanToYesNo(value);
  }
}
