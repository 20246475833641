import { Injectable } from '@angular/core';
import { Form } from './form';
import { IFormFactory } from './form.model';

@Injectable({
  providedIn: 'root',
})
export class FormFactory {
  create<T>({ formGroup, onSubmit }: IFormFactory<T>) {
    return new Form(formGroup, onSubmit);
  }
}
