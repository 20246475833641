import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService, MODAL_DATA } from '@stockaid/services';
import { Observable } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';
import { downloadFile } from 'src/app/core/utils';
import { SyncService } from 'src/app/core/services/sync.service';
import { CompanyService } from 'src/app/core/services/company.service';
import { Company } from 'src/app/core/models/company';
import { BaseComponent } from '@stockaid/core';
import { UpdateSPOCompanyModalComponent } from '../../components/modal/spo-company-modal/update-spo-company-modal.component';
import { CompanyType } from 'src/app/core/infrastructure/enums/company-type.enum';

@Component({
  selector: 'app-import-fail-modal',
  templateUrl: './import-fail-modal.component.html',
  styleUrls: ['./import-fail-modal.component.scss'],
})
export class ImportFailModalComponent extends BaseComponent implements OnInit, OnDestroy {
  errorTitle: string;
  errors: string[] = [];
  errorInfo: string;
  reportLink: string;
  reportId: string;
  errorNumber: number;
  isLoading = false;

  constructor(
    @Inject(MODAL_DATA) private data: any,
    public activeModal: NgbActiveModal,
    private syncService: SyncService,
    private companyService: CompanyService,
    private modalService: ModalService,
  ) {
    super();
  }

  get currentCompany(): Company {
    return this.companyService.currentCompany();
  }

  ngOnInit(): void {
    const { message, errorTitle, errorInfo } = this.data;
    this.errorTitle = errorTitle;

    if (message?.type === 'notice') {
      this.errorInfo = message?.msg?.note || errorInfo;
      this.errors = message?.msg?.error || [];
      this.reportLink = message?.msg?.reportLink;

      if (this.errorInfo) {
        const result = this.errorInfo.match(/\d+/g)?.map(Number) || [];
        this.errorNumber = result[1];
      }
    }

    if (this.errorNumber > 5) {
      this.isLoading = true;
      this.loadAdditionalErrors().subscribe();
    }
  }

  ngOnDestroy() {
    if(this.currentCompany.companyType !== CompanyType.ASC) return;
    this.syncService.ascTestConnection(this.currentCompany)
      .pipe(this.autoCleanUp())
      .subscribe(({isConnectionOK, shouldShowMaintenanceMessage}) => {
        if(!isConnectionOK && !shouldShowMaintenanceMessage) {
          this.modalService.open(UpdateSPOCompanyModalComponent, {
            data: {
              company: this.currentCompany
            },
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        }
      })
  }

  private loadAdditionalErrors(): Observable<any> {
    const linkFragments = this.reportLink.split('/');
    this.reportId = linkFragments[linkFragments.length - 1];

    return this.syncService.getErrorReport(this.reportLink, this.errorNumber > 1000 ? this.reportId : null).pipe(
      first(),
      tap((data) => {
        this.errors = data || [];
      }),
      finalize(() => {
        if (this.errors?.length && this.errorNumber > 500) {
          this.errors.push('Please download the extended report to see the full error(s)')
        }
        this.isLoading = false;
      })
    );
  }

  downloadErrors(): void {
    if (this.isLoading) {
      return;
    }

    const reportBlob = new Blob([this.errors.join('\n')], {
      type: 'text/plain',
      endings: 'native',
    });

    downloadFile(this.reportLink || reportBlob as File, `error-report_${this.reportId}`);
  }
}
