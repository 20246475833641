<kendo-dropdownlist
  *ngIf="field !== 'serviceLevel'; else serviceLevelDropDown"
  [data]="data"
  (valueChange)="onChange($event)"
  [defaultItem]="defaultItem"
  [value]="selectedValue"
  [valuePrimitive]="true"
  [textField]="textField"
  [valueField]="valueField"
>
</kendo-dropdownlist>

<ng-template #serviceLevelDropDown>
  <kendo-dropdownlist
  [popupSettings]="{ height: 200, width: 300 }"
  [data]="serviceLevelData"
  [defaultItem]="defaultItem"
  (valueChange)="onChange($event)"
  [value]="selectedValue"
  [valuePrimitive]="true"
  [textField]="textField"
  [valueField]="valueField"
>
  <ng-template kendoDropDownListItemTemplate let-dataItem>
    <div
      class="dropdown-list-item"
      [style]="{
        'background-color': dataItem.bgColor,
        'color': getContrastYIQ(dataItem.bgColor)
      }"
    >
      <span class="pl-2">{{ dataItem.display }}</span>
    </div>
  </ng-template>
</kendo-dropdownlist>
</ng-template>
