<div
  class="software-modal"
  [dataTestID]="'confirm-popup'"
>
  <!-- Header -->
  <div class="modal-header px-3 py-2 d-flex align-items-center">
    <h4 class="modal-title fw-bold text-center w-100">{{ data?.header}}</h4>

    <!-- Close -->
    <span class="software-modal__close" (click)="dismiss()">&times;</span>
  </div>

  <!-- Body -->
  <div class="modal-body px-3 pt-0">
    <h4 class="text-center font-weight-normal pt-4 pb-2 bg-white position-relative" 
    [ngClass]="{'pop-up-body': isLoadedCalendly}"
    >
      {{data?.body}}
    </h4>
    <div class="software-modal__widget">
      <div
        #container
        class="calendly-inline-widget position-relative"
        data-auto-load="false"
      ></div>
    </div>
  </div>

  <!-- Footer -->
  <div class="modal-footer p-2">
    <button class="btn btn-secondary mr-1" (click)="dismiss()">Close</button>
  </div>
</div>
