import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { GradientConfig } from '../../../../app-config';
import { HeaderService } from 'src/app/core/services/header.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit {
  public gradientConfig: any;
  public menuClass: boolean;
  public collapseStyle: string;
  public windowWidth: number;
  public navBarHeight: number;
  public isHiddenIconCollapse: boolean = false;

  @ViewChild('mainHeader', { read: ElementRef, static: false }) elementView: ElementRef;

  @Input() isSubscriptionExpired: boolean;
  @Input() companyId: string;
  @Input() navCollapsed: string;

  @Output() onNavCollapse = new EventEmitter();
  @Output() onNavHeaderMobCollapse = new EventEmitter();
  @Output() afterViewInit = new EventEmitter();

  constructor(private headerService: HeaderService) {
    this.gradientConfig = GradientConfig.config;
    this.menuClass = false;
    this.collapseStyle = 'none';
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    this.headerService.navbarCollapseSubject$.subscribe(value => {
      if (!value) {
        return;
      }

      this.navCollapse();
    });

    if (this.windowWidth >= 992) {
      this.isHiddenIconCollapse = true;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.navBarHeight = this.elementView.nativeElement.parentElement.offsetHeight;
      this.afterViewInit.emit(this.navBarHeight);
    })
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }

  navCollapse() {
    if (this.windowWidth >= 992) {
      this.onNavCollapse.emit();
    } else {
      this.onNavHeaderMobCollapse.emit();
    }
  }

}
