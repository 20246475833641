import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  HistoryOverride,
  IHistoryOverrideResponse,
} from '../models/history-override';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HistoryOverrideService {
  constructor(private httpClient: HttpClient) {}

  apiUrl = '/api/history-override';

  getHistoryOverride(itemKey: string): Observable<HistoryOverride[]> {
    return this.httpClient
      .get<IHistoryOverrideResponse>(`${this.apiUrl}?id=${itemKey}`)
      .pipe(map((Ihistoryoverride) => Ihistoryoverride.model));
  }

  delete(history: HistoryOverride): Observable<any> {
    history.start = null;

    return this.httpClient.request<any>('delete', `${this.apiUrl}`, {
      body: history,
    });
  }

  save(itemKey: string, histories: HistoryOverride[]): Observable<any> {
    const requestBody = {
      key: itemKey,
      rows: histories,
    };

    return this.httpClient.put<any>(`${this.apiUrl}`, requestBody);
  }
}
