export const InventoryAgeDisplayField = {
  inventoryAge0to60: '0-60',
  inventoryAge61to90: '61-90',
  inventoryAge91to180: '91-180',
  inventoryAge181to330: '181-330',
  inventoryAge331to365: '331-365',
  inventoryAge365plus: '365+',
};

export const InventoryAgeDetail = (dataItem) => [
  {
    field: 'inventoryAge0to90',
    value:
      dataItem?.inventoryAges.inventoryAge0to60 +
      dataItem?.inventoryAges.inventoryAge61to90,
    displayName: '0-90 days',
    children: [
      {
        value: dataItem?.inventoryAges.inventoryAge0to30,
        displayName: '0-30 days',
      },
      {
        value: dataItem?.inventoryAges.inventoryAge31to60,
        displayName: '31-60 days',
      },
      {
        value: dataItem?.inventoryAges.inventoryAge61to90,
        displayName: '61-90 days',
      },
    ],
  },
  {
    value: dataItem?.inventoryAges.inventoryAge91to180,
    displayName: '91-180 days',
  },
  {
    field: 'inventoryAge181to330',
    value: dataItem?.inventoryAges.inventoryAge181to330,
    displayName: '181-330 days',
    children: [
      {
        value: dataItem?.inventoryAges.inventoryAge181to270,
        displayName: '181-270 days',
      },
      {
        value:
          dataItem?.inventoryAges.inventoryAge181to330 -
          dataItem?.inventoryAges.inventoryAge181to270,
        displayName: '271-330 days',
      },
    ],
  },
  {
    value: dataItem?.inventoryAges.inventoryAge331to365,
    displayName: '331-365 days',
  },
  {
    value: dataItem?.inventoryAges.inventoryAge365plus,
    displayName: '365+ days',
  },
  {
    value:
      dataItem?.inventoryAges.inventoryAge0to60 +
      dataItem?.inventoryAges.inventoryAge61to90 +
      dataItem?.inventoryAges.inventoryAge91to180 +
      dataItem?.inventoryAges.inventoryAge181to330 +
      dataItem?.inventoryAges.inventoryAge331to365 +
      dataItem?.inventoryAges.inventoryAge365plus,
    displayName: 'Total*',
    isTotal: true,
  },
];
