<div *ngIf="realms.length; else loading" [dataTestID]="'company-dropdown'">
  <div
    [class.dropdown-custom-quickbook]="isCreateQuickBook"
    class="dropdown drp-user"
    ngbDropdown
    placement="auto"
  >
    <a
      class="nav-link dropdown-toggle"
      id="resourcesDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span> {{ selectedRealm?.companyName }} </span>
      <i
        *ngIf="!isCreateQuickBook"
        [style]="{ 'font-size': '12px' }"
        class="fas fa-chevron-down ml-1"
      ></i>
    </a>
    <div
      [class.dropdown-menu-custom-quickbook]="isCreateQuickBook"
      class="dropdown-menu dropdown-menu-right profile-notification"
      ngbDropdownMenu
    >
      <ul class="pro-body py-2">
        <li
          *ngFor="let realm of realms; let index = index"
          class="p-2 cursor-pointer"
          [class.disabled]="selectedRealm?.companyKey === realm?.companyKey"
          [dataTestID]="
            index === 0 ? 'add-company-option' : realm?.companyName + '-option'
          "
          (click)="switchRealms(realm)"
        >
          <span>
            {{ realm?.companyName }}
            <span *ngIf="realm?.companyType === CompanyType.ASC">
              ({{ realm?.marketplaceName }})
            </span>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="nav-item dropdown">
    <span
      class="nav-link dropdown-toggle"
      id="resourcesDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span> Loading Companies... </span>
      <i [style]="{ 'font-size': '12px' }" class="fas fa-chevron-down ml-1"></i>
    </span>
  </div>
</ng-template>
