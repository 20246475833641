import { LookUpMappingType } from "../../../enums/look-up-mapping-type.enum";

export class LookUpMapping {

  to: string = '';
  from: string = '';
  type: LookUpMappingType;


  constructor(init?: Partial<LookUpMapping>) {
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }

}
