import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DropDownListComponent } from "@progress/kendo-angular-dropdowns";
import { Observable } from 'rxjs';
import { GridName } from '../forecast-rxdata-table/forcast-rxdata-table.constant';


@Component({
  selector: 'app-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.scss']
})
export class ListInputComponent implements OnInit {

  view: any[];
  @Input() source: Observable<any>;
  @Input() textField: string;
  @Input() valueField: string;
  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() group: FormGroup;
  @Input() itemName: string;
  @ViewChild('dropdown') public dropdown: DropDownListComponent;
  defaultItem: any = {id:'', name: 'Selection...'}

  ngOnInit(): void {
    this.source.subscribe((data: any) => {
      this.view = data;
    });

    if(this.itemName === GridName.ManageSupplier) {
      this.defaultItem = {name: 'Selection...', countryCode: ''}
    }
  }


  valueChange(value): void {
    this.group.markAsDirty();

    if (value !== null || value !== undefined) {
      this.control.setValue(value);
    }
    else {
      this.control.setValue('');
    }
  }

}
