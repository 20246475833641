export enum GridType {
  Vendors = 'Vendors',
  Items = 'Items',
  Boms = 'Boms',
  Supply = 'Supply',
  Demand = 'Demand',
  Inventory = 'Inventory',
  UploadInventory = 'UploadInventory',
  ShipmentSummary = 'ShipmentSummary',
  ShipmentOptions = 'ShipmentOptions',
  PackingOptions = 'PackingOptions',
  ShipmentReview = 'ShipmentReview',
  ShipmentCasePack = 'ShipmentCasePack',
  Complete = 'Complete',
  POItemSelection = 'POItemSelection',
  UpdatePOItemSelection = 'UpdatePOItemSelection',
  POItemReview = 'POItemReview',
}
