<div class="import-fail">
  <div class="modal-header">
    <div class="modal-title w-100">
      <h5>{{errorTitle || 'Import Errors'}}</h5>
    </div>

    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- The first few errors -->
    <div class="card">
      <h6 class="text-center m-3" *ngIf="isLoading">Loading...</h6>
      <ul class="mt-2 import-fail__error-list" *ngIf="!isLoading">
        <li class="my-2 mr-3" *ngFor="let error of errors; let i = index">
          <strong *ngIf="errors?.length && errorNumber > 500 && i === errors.length - 1; else normalError">
            {{ error }}
          </strong>
          <ng-template #normalError>{{ error }}</ng-template>
        </li>
      </ul>
    </div>

    <!-- Additional -->
    <div class="ml-3">
      <h6 class="text-white">{{ errorInfo }}</h6>
      <div class="text-white" *ngIf="errorNumber > 5 && !isLoading">
        Download your extended
        <a href="javascript:" (click)="downloadErrors()"><b>error report</b></a>
      </div>
    </div>
  </div>
</div>
