const SalesVelocitySettingsType = require('./sales-velocity-settings-type.enum');
const OrderQtyAutofillType = require('./order-qty-autofill-type.enum');
const ShipmentDetailType = require('./shipment-detail-type.enum');
const OrderStatus = require('./order-status.enum');
const FrequencyType = require('./frequency-type.enum');
const UploadHistoryBehaviorType = require('./upload-history-behavior-type.enum');
const UploadHistoryAmountType = require('./upload-history-amount-type.enum');
const ForecastJobType = require('./forecast-job-type.enum');
const ItemRelationshipType = require('./item-relationship-type.enum');
const StorageType = require('./storage-type.enum');

module.exports = {
    SalesVelocitySettingsType,
    OrderQtyAutofillType,
    ShipmentDetailType,
    OrderStatus,
    FrequencyType,
    UploadHistoryBehaviorType,
    UploadHistoryAmountType,
    ForecastJobType,
    ItemRelationshipType,
    StorageType
}