const OrderStatus = {
  Shipped: "Shipped",
  Cancelled: "Cancelled",
  Unshipped: "Unshipped",
  Closed: "Closed",
  Open: "Open",
  ToBeDeleted: "ToBeDeleted"
};

module.exports = Object.freeze(OrderStatus);
