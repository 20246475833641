import { LookUpConfig } from "../support/look-up-config";
import { DisplayControl } from "./display-control";

export class LookUpDisplayControl extends DisplayControl {

  lookUpConfig: LookUpConfig;

  constructor(init?: Partial<LookUpDisplayControl>) {
    super(init);
    if (init) //if no values are passed in, fresh instantiation
    {
      Object.assign(this, init);
    }
  }

}
