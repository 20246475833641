import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanySelectionService {

  private companySubject: Subject<Company> = new Subject<Company>();
  private removeCompanySubject: Subject<Company> = new Subject<Company>();

  sendCompany(company:Company): void {
    this.companySubject.next(company);
  }

  getCompany() : Observable<Company> {
    return this.companySubject.asObservable();
  }

  clearCompany() : void {
    this.companySubject.next();
  }

  removeCompany(company: Company): void {
    this.removeCompanySubject.next(company);
  }

  getRemovedCompany(): Observable<Company> {
    return this.removeCompanySubject.asObservable();
  }
  
}
