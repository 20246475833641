import { Injectable } from '@angular/core';
import { ResourceService } from './resource-service';
import { Item } from '../models/item';
import { PurchasingSummary } from '../models/purchasing-summary';
import { ISummaryItemsResponse } from '../models/summary';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Pagination } from '../infrastructure/classes/pagination';
import { stringifyJSONWithDates } from 'src/app/core/utils';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StockaidResponse } from '../misc/stockaid-response';
import { SortAttribute } from 'src/app/core/infrastructure/classes/sort-attribute';
import { SummaryOnHandType } from '../infrastructure/enums/summary-on-hand-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ItemService extends ResourceService<Item> {
  constructor(protected httpClient: HttpClient) {
    super(httpClient, '/api/item');
  }

  public stockaidPaginated(
    offset: number,
    limit: number,
    sortAttributes: SortAttribute[],
    filters: []
  ): Observable<StockaidResponse<Item[]>> {
    sortAttributes = sortAttributes || []
    const body: any = {
      offset,
      limit,
      sort: sortAttributes,
      filter: [],
      operation: 'paginate',
    };

    return this.httpClient.post<StockaidResponse<Item[]>>(this.apiUrl, body);
  }

  getSummaryItems(
    summary: boolean,
    companyKey: string,
    companyType: string,
    summaryOnHandType: SummaryOnHandType
  ): Observable<ISummaryItemsResponse> {
    let params = new HttpParams();
    params = params.set('summary', summary.toString());
    params = params.set('companyKey', companyKey);
    params = params.set('companyType', companyType);
    params = params.set('summaryOnHandType', encodeURIComponent(summaryOnHandType));

    return this.httpClient.get<ISummaryItemsResponse>(`${this.apiUrl}`, {
      params,
    });
  }

  getFilteredItem(filter: any): Observable<Item[]> {
    let params = new HttpParams();

    if (filter) {
      params = params.set('where', JSON.stringify(filter));
    }

    return this.httpClient.get<Item[]>(`${this.apiUrl}`, { params });
  }

  getItemsAdvanced(state: {
    type: string;
    skip: number;
    take: number;
    filter: any;
    sort: SortDescriptor[];
    marketplaceId: string;
    currencyCode: string;
  }): Observable<{ model: Item[]; count: number }> {
    const { type, skip, take, filter, sort, marketplaceId, currencyCode } = state;

    const pagination = new Pagination({ offset: skip, limit: take });

    const sortArray = this.parseSortT(sort);

    let params = new HttpParams();

    if (pagination) {
      params = params.set('offset', pagination.offset?.toString());
      params = params.set('limit', pagination.limit?.toString());
    }

    if (sortArray.length) {
      params = params.set('sort', JSON.stringify(sortArray));
    }

    if (filter) {
      params = params.set('where', stringifyJSONWithDates(filter));
    }

    if (currencyCode) {
      params = params
        .set('marketplaceId', marketplaceId)
        .set('currencyCode', currencyCode);
    }

    params = params.set('type', type);

    return this.httpClient.get<{ model: Item[]; count: number }>(
      `${this.apiUrl}/advanced`,
      { params }
    );
  }

  getConsolidatedQty(id: string, willGetSingle = false): Observable<PurchasingSummary[]> {
    let params = new HttpParams();
    if (willGetSingle) {
      params = params.set('willGetSingle', 'true');
    }

    return this.httpClient.get<PurchasingSummary[]>(
      `${this.apiUrl}/${encodeURIComponent(id)}/get-consolidated-qty`,
      {
        params
      }
    );
  }

  eraseWarehouseInventory() {
    return this.httpClient.put(`${this.apiUrl}/warehouse-erase`, {});
  }
}
