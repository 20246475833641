import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Consolidation } from '../models/consolidation';

@Injectable({
  providedIn: 'root',
})
export class ConsolidationService {
  apiUrl = '/api/consolidation';

  constructor(private httpClient: HttpClient) {}

  getConsolidations(itemKey: string): Observable<Consolidation[]> {
    return this.httpClient.get<Consolidation[]>(
      `${this.apiUrl}?groupKey=${encodeURIComponent(itemKey)}`
    );
  }
}
