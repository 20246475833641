import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/core/services/company.service';
import { ForecastService } from 'src/app/core/services/forecast.service';
import { PusherService } from 'src/app/core/services/pusher.service';
import { ProcessingService } from 'src/app/core/services/processing.service';
import { BaseComponent } from 'src/app/core/infrastructure/classes/base-component';
import { HeaderService } from 'src/app/core/services/header.service';
import { Company } from 'src/app/core/models/company';
import { ModalService } from '@stockaid/services';
import { GenericModalComponent } from 'src/app/theme/shared/components/modal/generic-modal/generic-modal.component';

@Component({
  selector: 'app-nav-forecast',
  templateUrl: './nav-forecast.component.html',
  styleUrls: ['./nav-forecast.component.scss'],
})
export class NavForecastComponent extends BaseComponent implements OnInit {
  isHide = false;
  lastForecast: Date;
  isProcessing = false;
  isSyncInProcess = false;
  currentCompany: Company;
  header;
  message;
  willRemindToRunForecast: boolean = true;
  willShowRemindingDialog: boolean = this.willRemindToRunForecast;

  constructor(
    private foreCastService: ForecastService,
    private companyService: CompanyService,
    private pusherService: PusherService,
    private processingService: ProcessingService,
    private headerService: HeaderService,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit() {
    this.pusherService.userChannel.bind('notification', (data) => {
      switch (data.type) {
        case 'header':
          this.header = data.msg;
          this.message = '...';
          break;
        case 'title':
          this.message = data.msg;
          break;
        case 'message':
          this.message = data.msg;
          break;
        case 'notice':
        case 'close':
          this.isProcessing = false;
          this.isSyncInProcess = false;

          this.getCurrentCompany(true).subscribe();
          break;
        default:
          throw Error('message type not accounted for.');
      }

      if (!this.isProcessing) {
        this.message = '';
        this.header = '';
      }
    });

    this.processingService.processingStartedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.processingService.processingStoppedSync
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data;
      });

    this.headerService
      .getCompany()
      .pipe(this.autoCleanUp())
      .subscribe((data) => {
        this.isSyncInProcess = data?.jobInitiator ? true : false;
      });

    this.headerService.requestToOpenForecastSubject.pipe(
      this.autoCleanUp()
    ).subscribe(() => {
      this.togglePopup();
    })

    this.headerService.notifyForecastSubject
      .pipe(this.autoCleanUp())
      .subscribe((willRemind) => this.willRemindToRunForecast = this.willShowRemindingDialog = willRemind)

    this.getCurrentCompany().subscribe();
  }

  getCurrentCompany(getNew = false): Observable<Company> {
    const getCompanyFunction = getNew
     ? this.companyService.getById(this.currentCompany.companyKey)
     : this.companyService.getCurrentCompany();

    return getCompanyFunction.pipe(
      this.autoCleanUp(),
      tap((company) => {
        this.currentCompany = company;
        this.lastForecast = company.lastForecastDate;
        this.willRemindToRunForecast = this.willShowRemindingDialog = company.willRemindToRunForecast;
      })
    )
  }

  togglePopup() {
    if (this.isSyncInProcess) {
      return;
    }

    this.modalService.open(GenericModalComponent, {
      data: {
        titleIconClass: 'icon feather icon-trending-up',
        title: 'Forecast',
        content: `
          <div class="generic-modal__content text-center mb-3">You have selected to run a Forecast. This will include your latest data in predictive calculations and will incorporate any updated values in your data. Please note that this may take some time.</div>
          <div class="generic-modal__content text-center""><strong>Are you sure that you would like to run a forecast now?</strong></div>
        `,
        successBtn: 'Start Forecast',
        errorBtn: 'Cancel',
        theme: 'light',
      },
      backdrop: 'static',
      onSuccess: () => {
        this.startForecast();
      },
      modalDialogClass: 'sync-modal',
      backdropClass: 'sync-backdrop'
    });
  }

  startForecast() {
    if (this.isSyncInProcess || this.isProcessing) {
      return;
    }

    this.foreCastService.runForecast(this.currentCompany)
      .subscribe(() => {
        this.isProcessing = true;
      });
  }
}
